import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service'

@Component({
  selector: 'app-faire-visite-dialog',
  templateUrl: './faire-visite-dialog.component.html',
  styleUrls: ['./faire-visite-dialog.component.scss']
})
export class FaireVisiteDialogComponent implements OnInit {

  constructor(public DataService: DataService) { }

  ngOnInit(): void {
  }

}
