<!--app-navigation-admin></app-navigation-admin-->

<div class="padding-card padding-card-pos">
    <mat-card>
         <mat-card-title class="title title-pos">Ajout collaborateur</mat-card-title>
         <mat-card-content class="content-pos" style="text-align: center;">
                <mat-button-toggle class="btn btn-margin btn-txt btn_collabo" value="underline" routerLink="../form-syndic">Admin syndic </mat-button-toggle>
                <mat-button-toggle class="btn btn-margin btn-txt btn_collabo" value="underline" routerLink="../form-prestataires-admin">Prestataires</mat-button-toggle>
                <mat-button-toggle class="btn btn-margin btn-txt btn_collabo" value="underline" routerLink="../form-courtiers-admin">Courtiers</mat-button-toggle>
                <mat-button-toggle class="btn btn-margin btn-txt btn_collabo" value="underline" routerLink="../form-architectes">Architectes</mat-button-toggle>
         </mat-card-content>
    </mat-card>
</div>

<div class="padding-card padding-card-pos">
    <div fxLayout="row wrap">
        <mat-card fxLayout="row wrap" fxFlex="100">
            <div fxFlex="50" fxFlex.sm="100">
                <mat-card-title class="title title-pos">Gérer prestataires</mat-card-title>
                <div fxLayout="row wrap">
                    <div fxLayoutAlign.xs="center" fxFlex="66" fxFlex.xs="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.lg="60" class="padding-card">
                        <mat-card-content fxFlex="100" class="title-pos">
                            <mat-form-field fxLayoutAlign="right center" class="input_search" appearance="outline">
                                <mat-label>Nom du prestataire</mat-label>
                                <input matInput [(ngModel)]="searchVal" (keyup)="searchEngine()"/>
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <mat-card-content style="position: relative" >
                    <div fxLayout="row wrap" class="font-normal">
                        <div fxFlex="50" fxFlex.xs="100"  fxFlex.sm="100" fxFlex.md="50" *ngFor="let detail of resultSearch | slice:resultSearchIndexStart:resultSearchIndexEnd; let last=last; let i = index">
                            <div fxLayout="row wrap" fxLayoutAlign="center">
                                <div fxFlex="80" fxFlex.xs="100" fxFlex.sm="100" id={{detail.id_el}} class="box_shadow pad_colab">

                                    <div fxLayoutAlign="center">
                                      <div *ngIf="detail.src_img == !null" mat-card-avatar class="imgs marge_photo" [ngStyle]="{ 'background-position': 'center', ' background-color': '#F9F9F9', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-image': 'url(' + detail.src_img + ')'}"></div>
                                      <div *ngIf="detail.src_img == null" mat-card-avatar class="imgs marge_photo ProfilSansImg"></div>
                                    </div>
                                    <div fxLayoutAlign="center" class="marge_name name_collabo">{{ detail.name }}</div>
                                    <div fxLayoutAlign="center" class="marge_name name_collabo">[{{ detail.company }}]</div>
                                    <div fxLayoutAlign="center" class="marge_colab"><mat-button-toggle class="btn-gris btn-text droit_collabo" routerLink="../changer-droits-admin/{{detail.id_el}}">Gérer le prestataire</mat-button-toggle></div>
                                    <div fxLayoutAlign="center" class="delet_icon"><mat-button-toggle (click)="onEvent($event)"  (click)="confirmation($event)"><img src="../../../../assets/img/icones/croix-medium-black.png"></mat-button-toggle></div>
                                </div>
                            </div>
                        </div>
                      <button style="position: absolute; top: -30px; width: 90.6%; left: 4.7%" class="gradientButtonTop" mat-button (click)="onScroll('resultSearchIndexStart', 'resultSearchIndexEnd', false, resultSearch.length)" ><mat-icon style="font-size: 32px; padding-bottom: 5px;">keyboard_arrow_up</mat-icon></button>
                      <button style="position: absolute; bottom: -30px; width: 90.6%; left: 4.7%" class="gradientButton" mat-button (click)="onScroll('resultSearchIndexStart', 'resultSearchIndexEnd', true, resultSearch.length)" ><mat-icon style="font-size: 32px; padding-bottom: 5px;">keyboard_arrow_down</mat-icon></button>
                    </div>
                </mat-card-content>
            </div>
            <div fxFlex="50" fxFlex.sm="100">
                <mat-card-title class="title title-pos">Gérer courtiers</mat-card-title>
                <div fxLayout="row wrap">
                    <div fxLayoutAlign.xs="center" fxFlex="66" fxFlex.xs="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.lg="60" class="padding-card">
                        <mat-card-content fxFlex="100" class="title-pos">
                            <mat-form-field fxLayoutAlign="right center" class="input_search" appearance="outline">
                                <mat-label>Nom du courtier</mat-label>
                                <input matInput [(ngModel)]="search2Val" (keyup)="searchEngine2()"/>
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
              <mat-card-content style="position: relative" >
                    <div fxLayout="row wrap" class="font-normal">
                        <div fxFlex="50" fxFlex.xs="100"  fxFlex.sm="100" fxFlex.md="50" *ngFor="let detail2 of resultSearch2 | slice:resultSearchIndexStart2:resultSearchIndexEnd2; let last=last">
                            <div fxLayout="row wrap" fxLayoutAlign="center">
                                <div fxFlex="80" fxFlex.xs="100" fxFlex.sm="100" id={{detail2.id_el}} class="box_shadow pad_colab">

                                    <div fxLayoutAlign="center">
                                        <div *ngIf="detail2.src_img == !null" mat-card-avatar class="imgs marge_photo" [ngStyle]="{ 'background-position': 'center', ' background-color': '#F9F9F9', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-image': 'url(' + detail2.src_img + ')'}"></div>
                                        <div *ngIf="detail2.src_img == null" mat-card-avatar class="imgs marge_photo ProfilSansImg"></div>
                                    </div>
                                    <div fxLayoutAlign="center" class="marge_name name_collabo">{{ detail2.name }}</div>
                                    <div fxLayoutAlign="center" class="marge_name name_collabo">[{{ detail2.company }}]</div>
                                    <div fxLayoutAlign="center" class="marge_colab"><mat-button-toggle class="btn-gris btn-text droit_collabo" routerLink="../gerer-courtiers/{{detail2.id_el}}">Gérer le courtier</mat-button-toggle></div>
                                    <div fxLayoutAlign="center" class="delet_icon"><mat-button-toggle (click)="onEvent($event)"  (click)="confirmation2($event)"><img src="../../../../assets/img/icones/croix-medium-black.png"></mat-button-toggle></div>
                                </div>
                            </div>
                        </div>
                      <button style="position: absolute; top: -30px; width: 90.6%; left: 4.7%" class="gradientButtonTop" mat-button (click)="onScroll('resultSearchIndexStart2', 'resultSearchIndexEnd2', false, resultSearch2.length)" ><mat-icon style="font-size: 32px; padding-bottom: 5px;">keyboard_arrow_up</mat-icon></button>
                      <button style="position: absolute; bottom: -30px; width: 90.6%; left: 4.7%" class="gradientButton" mat-button (click)="onScroll('resultSearchIndexStart2', 'resultSearchIndexEnd2', true, resultSearch2.length)" ><mat-icon style="font-size: 32px; padding-bottom: 5px;">keyboard_arrow_down</mat-icon></button>
                    </div>
                </mat-card-content>
            </div>
            <div fxFlex="50" fxFlex.sm="100">
                <mat-card-title class="title title-pos">Gérer architectes</mat-card-title>
                <div fxLayout="row wrap">
                    <div fxLayoutAlign.xs="center" fxFlex="66" fxFlex.xs="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.lg="60" class="padding-card">
                        <mat-card-content fxFlex="100" class="title-pos">
                            <mat-form-field fxLayoutAlign="right center" class="input_search" appearance="outline">
                                <mat-label>Nom de l'architecte</mat-label>
                                <input matInput [(ngModel)]="search3Val" (keyup)="searchEngine3()"/>
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
              <mat-card-content style="position: relative" >
                    <div fxLayout="row wrap" class="font-normal">
                        <div fxFlex="50" fxFlex.xs="100"  fxFlex.sm="100" fxFlex.md="50" *ngFor="let detail3 of resultSearch3 | slice:resultSearchIndexStart3:resultSearchIndexEnd3; let last=last">
                            <div fxLayout="row wrap" fxLayoutAlign="center">
                                <div fxFlex="80" fxFlex.xs="100" fxFlex.sm="100" id={{detail3.id_el}} class="box_shadow pad_colab">

                                    <div fxLayoutAlign="center">
                                        <div *ngIf="detail3.src_img == !null" mat-card-avatar class="imgs marge_photo" [ngStyle]="{ 'background-position': 'center', ' background-color': '#F9F9F9', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-image': 'url(' + detail3.src_img + ')'}"></div>
                                        <div *ngIf="detail3.src_img == null" mat-card-avatar class="imgs marge_photo ProfilSansImg"></div>
                                    </div>
                                    <div fxLayoutAlign="center" class="marge_name name_collabo">{{ detail3.name }}</div>
                                    <div fxLayoutAlign="center" class="marge_colab"><mat-button-toggle class="btn-gris btn-text droit_collabo" routerLink="../gerer-architectes/{{detail3.id_el}}">Gérer l'architecte</mat-button-toggle></div>
                                    <div fxLayoutAlign="center" class="delet_icon"><mat-button-toggle (click)="onEvent($event)"  (click)="confirmation3($event)"><img src="../../../../assets/img/icones/croix-medium-black.png"></mat-button-toggle></div>
                                </div>
                            </div>
                        </div>
                      <button style="position: absolute; top: -30px; width: 90.6%; left: 4.7%" class="gradientButtonTop" mat-button (click)="onScroll('resultSearchIndexStart3', 'resultSearchIndexEnd3', false, resultSearch3.length)" ><mat-icon style="font-size: 32px; padding-bottom: 5px;">keyboard_arrow_up</mat-icon></button>
                      <button style="position: absolute; bottom: -30px; width: 90.6%; left: 4.7%" class="gradientButton" mat-button (click)="onScroll('resultSearchIndexStart3', 'resultSearchIndexEnd3', true, resultSearch3.length)" ><mat-icon style="font-size: 32px; padding-bottom: 5px;">keyboard_arrow_down</mat-icon></button>
                    </div>
                </mat-card-content>
            </div>
            <div fxFlex="50" fxFlex.sm="100" >
                <mat-card-title class="title title-pos">Gérer Syndics</mat-card-title>
                <div fxLayout="row wrap">
                    <div fxLayoutAlign.xs="center" fxFlex="66" fxFlex.xs="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.lg="60" class="padding-card">
                        <mat-card-content fxFlex="100" class="title-pos">
                            <mat-form-field fxLayoutAlign="right center" class="input_search" appearance="outline">
                                <mat-label>Nom du syndic</mat-label>
                                <input matInput [(ngModel)]="search4Val" (keyup)="searchEngine4()"/>
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
              <mat-card-content style="position: relative" >
                    <div fxLayout="row wrap" class="font-normal">
                        <div fxFlex="50" fxFlex.xs="100"  fxFlex.sm="100" fxFlex.md="50" *ngFor="let detail4 of resultSearch4 | slice:resultSearchIndexStart4:resultSearchIndexEnd4; let last=last">
                            <div fxLayout="row wrap" fxLayoutAlign="center">
                                <div fxFlex="80" fxFlex.xs="100" fxFlex.sm="100" id={{detail4.id_el}} class="box_shadow pad_colab">

                                    <div fxLayoutAlign="center">
                                        <div *ngIf="detail4.src_img == !null" mat-card-avatar class="imgs marge_photo" [ngStyle]="{ 'background-position': 'center', ' background-color': '#F9F9F9', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-image': 'url(' + detail4.src_img + ')'}"></div>
                                        <div *ngIf="detail4.src_img == null" mat-card-avatar class="imgs marge_photo ProfilSansImg"></div>
                                    </div>
                                    <div fxLayoutAlign="center" class="marge_name name_collabo">{{ detail4.name }}</div>
                                    <div fxLayoutAlign="center" class="marge_colab"><mat-button-toggle class="btn-gris btn-text droit_collabo" routerLink="../gerer-syndics/{{detail4.id_el}}">Gérer le syndic</mat-button-toggle></div>
                                    <div fxLayoutAlign="center" class="delet_icon"><mat-button-toggle (click)="onEvent($event)"  (click)="confirmation4($event)"><img src="../../../../assets/img/icones/croix-medium-black.png"></mat-button-toggle></div>
                                </div>
                            </div>
                        </div>
                      <button style="position: absolute; top: -30px; width: 90.6%; left: 4.7%" class="gradientButtonTop" mat-button (click)="onScroll('resultSearchIndexStart4', 'resultSearchIndexEnd4', false, resultSearch4.length)" ><mat-icon style="font-size: 32px; padding-bottom: 5px;">keyboard_arrow_up</mat-icon></button>
                      <button style="position: absolute; bottom: -30px; width: 90.6%; left: 4.7%" class="gradientButton" mat-button (click)="onScroll('resultSearchIndexStart4', 'resultSearchIndexEnd4', true, resultSearch4.length)" ><mat-icon style="font-size: 32px; padding-bottom: 5px;">keyboard_arrow_down</mat-icon></button>
                    </div>
                </mat-card-content>
            </div>
        </mat-card>
    </div>
</div>
