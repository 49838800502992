<div fxLayout="row wrap" fxLayoutAlign.sm="center">
    <div fxLayout="row wrap" fxFlex="33" fxFlex.xs="100" fxFlex.md="50" fxFlex.sm="75" fxFlex.lg="33" fxFlex.gt-lg="25" class="padding-card padding-card-pos sans_shadow" *ngFor="let detail of resultSearch">
        <mat-card fxFlex="100" fxFlex.xs="100" fxFlex.sm="100">
            <div class="avec_shadow pointer" routerLink="../visites-formulaire/{{detail.id_info}}/{{detail.coproId}}">
                <mat-card-content class="content-40 font-normal">
                    <div fxLayoutAlign="center">
                        <div class="result-box res_img" fxLayoutAlign="center center">
                            <div *ngIf="detail.src_img" width="70%" class="img_virtuelle_imm" [ngStyle]="{ 'background-position': 'center', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-image': 'url(' + detail.src_img + ')'}">
                              <div *ngIf="detail.faiteLe" class="visitefaiteLe2"><b>Visite déjà effectuée</b></div>
                            </div>
                            <div *ngIf="!detail.src_img" width="70%" class="img_virtuelle_imm" [ngStyle]="{ 'background-position': 'center', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-image': 'url(../../../../assets/img/photo/immeuble-base.jpg)'}">
                                <div *ngIf="detail.faiteLe" class="visitefaiteLe">Visite déjà effectuée</div>
                            </div>
                        </div>
                    </div>
                    <div class="res_name marge25">
                        <div fxLayoutAlign="center" class="title data_dynamic">{{ detail.name }}</div>
                        <div class="adresse">
                            <div fxLayoutAlign="center" class="data_dynamic">{{ detail.address }}</div>
                            <div fxLayoutAlign="center" class="data_dynamic">{{ detail.codePostal }} {{ detail.ville }}</div>
                            <div fxLayoutAlign="center">
                            <span>N°</span>
                            <span class="resultat_marge_left data_dynamic">{{ detail.reference }}</span>
                            </div>
                        </div>

                    </div>
                    <div fxLayout="row wrap" class="res_center">
                        <div fxFlex="50">
                            <div class="icon_height" fxLayoutAlign="center"><img src="../../../../assets/img/icones/square-gold-medium.jpg"></div>
                            <div fxLayoutAlign="center" class="resultat data_dynamic">{{ detail.surface }} m²</div>
                        </div>
                        <div fxFlex="50">
                            <div class="icon_height" fxLayoutAlign="center"><img src="../../../../assets/img/icones/doc.jpg"></div>
                            <div fxLayoutAlign="center" class="resultat data_dynamic">{{detail.nbVisites}}</div>
                        </div>
                        <div fxLayoutAlign="center" fxFlex="100" class="ic_info">
                            <div class="pointer info_icon" mat-raised-button  (click)="onEvent($event)" (click)="toggle_info($event)"><img src="../../../../assets/img/icones/info-small.png"></div>
                        </div>
                    </div>
                </mat-card-content>
            </div>
        </mat-card>
        <div fxFlex="100" id="{{ detail.id_info }}info" class="{{ detail.display_global }}">
            <mat-card>
                <div fxLayout="column" class="avec_shadow pad_box_shadow">

                    <div fxLayoutAlign="center" class="info_courtier">Syndic: {{ detail.syndicName  }}</div>

                    <div fxLayoutAlign="center"><span class="bar_grey"></span></div>

                    <div *ngIf="!detail.gestionnaireId" fxLayoutAlign="center" class="info_courtier">Aucun gestionnaire assigné</div>
                    <div *ngIf="detail.gestionnaireId" fxLayoutAlign="center" class="info_courtier">Gestionnaire: {{detail?.gestionnaireId?.firstName}}  {{detail?.gestionnaireId?.lastName}}</div>
                    <div *ngIf="detail.gestionnaireId" fxLayoutAlign="center" class="info_courtier">Email: {{detail?.gestionnaireId?.email}}</div>
                    <div *ngIf="detail.gestionnaireId" fxLayoutAlign="center" class="info_courtier info_mid">Port: {{detail?.gestionnaireId?.phone}}</div>

                    <div fxLayoutAlign="center"><span class="bar_grey"></span></div>

                    <div *ngIf="detail.accessCode && !detail.faiteLe" fxLayoutAlign="center" class="info_courtier">Code d'accès: {{ detail.accessCode }}</div>
                    <div *ngIf="detail.gardien && !detail.faiteLe" fxLayoutAlign="center" class="info_courtier">Gardien: {{detail.gardien ? 'oui' : 'non'}}</div>
                    <div *ngIf="detail.cleCabinet && !detail.faiteLe" fxLayoutAlign="center" class="info_courtier">Clés au cabinet: {{detail.cleCabinet ? 'oui' : 'non'}}</div>
                    <div *ngIf="detail.commentaire && !detail.faiteLe" fxLayoutAlign="center" class="info_courtier">Commentaire: {{ detail.commentaire }}</div>

                    <div fxLayoutAlign="center" *ngIf="!detail.faiteLe"><span class="bar_grey"></span></div>

                    <div fxLayoutAlign="center" class="info_courtier" *ngIf="!detail.faiteLe">Président du conseil: {{ detail.nomPCS  }}</div>
                    <div fxLayoutAlign="center" class="info_courtier" *ngIf="!detail.faiteLe">Mail: {{ detail.emailPCS  }}</div>
                    <div fxLayoutAlign="center" class="info_courtier" *ngIf="!detail.faiteLe">Port: {{ detail.phonePCS  }}</div>
                </div>
            </mat-card>
        </div>
    </div>
</div>
