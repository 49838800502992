import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';

export interface Section {
  src_img: string;
  name: string;
  id_el: string;
}

export interface SectionPrest {
  src_img   : string;
  name      : string;
  company   : string;
  corpsEtat : [string];
  id_el     : string;
}

export interface SectionCourt {
  src_img : string;
  name    : string;
  id_el   : string;
  company : string;
}

export interface SectionArchi {
  src_img: string;
  name: string;
  id_el: string;
  zoneInter: [string];
}

export interface SectionSyndi {
  src_img: string;
  name: string;
  id_el: string;
}

@Component({
  selector: 'app-dashboard-admin',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.scss']
})
export class DashboardAdminComponent implements OnInit {

  onEvent(event) {
    event.stopPropagation();
  }

  confirmation(event) {
    let i : any;
    let id = event.srcElement.parentNode.parentNode.parentNode.parentNode.parentNode.id
    if (id.length == 0) {
      id = event.srcElement.parentNode.parentNode.parentNode.parentNode.id
    }
    if (confirm("Confirmer la suppresion ?")) {
      this.DataService.api('/gestion/delete-presta', {_id: id},
        (data) => {
          for ( i in this.listCopro) {
            if (this.listCopro[i].id_el == id) {
              this.listCopro.splice(i, 1)
            }
          }
          for ( i in this.resultSearch) {
            if (this.resultSearch[i].id_el == id) {
              this.resultSearch.splice(i, 1)
            }
          }
          alert(data.message)
        },
        (error) => {
          console.log(error.error.message);
          alert(error.error.message);
        })
    }
  }

  confirmation2(event) {
    let i : any;
    let id = event.srcElement.parentNode.parentNode.parentNode.parentNode.parentNode.id
    if (id.length == 0) {
      id = event.srcElement.parentNode.parentNode.parentNode.parentNode.id
    }
    if (confirm("Confirmer la suppresion ?")) {
      this.DataService.api('/gestion/delete-courtier', {_id: id},
        (data) => {
          for ( i in this.listCopro2) {
            if (this.listCopro2[i].id_el == id) {
              this.listCopro2.splice(i, 1)
            }
          }
          for ( i in this.resultSearch2) {
            if (this.resultSearch2[i].id_el == id) {
              this.resultSearch2.splice(i, 1)
            }
          }
          alert(data.message);
        },
        (error) => {
          console.log(error);
          alert(error.error.message);
        })
    }
  }

  confirmation3(event) {
    let i : any;
    let id = event.srcElement.parentNode.parentNode.parentNode.parentNode.parentNode.id
    if(id.length == 0) {
      id = event.srcElement.parentNode.parentNode.parentNode.parentNode.id
    }
    if (confirm("Confirmer la suppresion ?")) {
      this.DataService.api('/gestion/delete-archi', {_id: id},
        (data) => {
          for ( i in this.listCopro3) {
            if (this.listCopro3[i].id_el == id) {
              this.listCopro3.splice(i, 1)
            }
          }
          for ( i in this.resultSearch3) {
            if (this.resultSearch3[i].id_el == id) {
              this.resultSearch3.splice(i, 1)
            }
          }
          alert(data.message);
        },
        (error) => {
          console.log(error);
          alert(error.error.message);
        });
    }
  }

  confirmation4(event) {
    let i : any;
    let id = event.srcElement.parentNode.parentNode.parentNode.parentNode.parentNode.id
    if(id.length == 0) {
      id = event.srcElement.parentNode.parentNode.parentNode.parentNode.id
    }
    if (confirm("Confirmer la suppresion ?")) {
      this.DataService.api_delete_syndic({_id: id},
        (data) => {
          for( i in this.resultSearch4) {
            if (this.resultSearch4[i].id_el === id) {
              this.resultSearch4.splice(i, 1)
            }
          }
          alert(data.message);
        },
        (error) => {
          console.log(error.error.message)
        })
    }
  }

  searchVal: string;
  resultSearch = [];
  resultSearchIndexStart = 0;
  resultSearchIndexEnd = 4;
  listCopro:SectionPrest [] = [];

  search2Val: string;
  resultSearch2 = [];
  resultSearchIndexStart2 = 0;
  resultSearchIndexEnd2 = 4;
  listCopro2:SectionCourt [] =[];

  search3Val: string;
  resultSearch3 = [];
  resultSearchIndexStart3 = 0;
  resultSearchIndexEnd3 = 4;
  listCopro3:SectionArchi [] = [ ];

  search4Val: string;
  resultSearch4 = [];
  resultSearchIndexStart4 = 0;
  resultSearchIndexEnd4 = 4;
  listCopro4:SectionSyndi [] = [ ];

  constructor(public DataService: DataService) {

    this.DataService.api_get_prestataire(
      (data) => {
        for (let key in data.prestataires) {
          this.listCopro[key] = {
            src_img: data.prestataires[key].image,
            name: data.prestataires[key].representant.lastName,
            company: data.prestataires[key].company,
            id_el: data.prestataires[key]._id
          };
        }

        this.resultSearch = this.listCopro;
      },
      (error)=>{
        console.log(error)
      }
    );


    this.DataService.api_get_courtier(
      (data) => {
        for(let key in data.courtiers) {
          this.listCopro2[key] = {
            src_img: data.courtiers[key].img,
            name: data.courtiers[key].lastName,
            id_el: data.courtiers[key]._id,
            company: data.courtiers[key].company
          };
        }

        this.resultSearch2 = this.listCopro2;
      },
      (error)=>{
        console.log(error.status)
      }
    );

    this.DataService.api_get_syndic(
      (data) => {
        for (let key in data.syndics) {
          this.listCopro4[key] = {
            src_img: data.syndics[key].img,
            name: data.syndics[key].nomSyndic ?? data.syndics[key].company,
            id_el: data.syndics[key]._id};
        }

        this.resultSearch4 = this.listCopro4;
      },
      (error)=>{
        console.log(error.status)
      }
    );

    this.DataService.api_get_architecte(
      (data) => {
        for (let key in data.architectes) {
          let tmp = [];
          tmp.push(data.architectes[key].codePostal)
          this.listCopro3[key] = {
            src_img: data.architectes[key].img,
            name: data.architectes[key].lastName,
            id_el: data.architectes[key]._id,
            zoneInter: data.architectes[key].zoneInter.length > 0 ? data.architectes[key].zoneInter : tmp
          };
        }
        this.resultSearch3 = this.listCopro3;
      },
      (error)=>{
        console.log(error.status)
      }
    );

    this.resultSearch = this.listCopro;
    this.resultSearch2 = this.listCopro2;
    this.resultSearch3 = this.listCopro3;
    this.resultSearch4 = this.listCopro4;
  }

  ngOnInit(): void {}

  onScroll(start, end, bottom, totalLength) {
    if (this[start] === 0 && !bottom || (bottom && this[end] >= totalLength))
      return
    this[start] += bottom ? 4 : -4
    this[end] += bottom ? 4 : -4

  }

  searchEngine() {
    this.resultSearch = [];
    this.resultSearchIndexStart = 0;
    this.resultSearchIndexEnd = 4;
    for(let key in this.listCopro) {
      if(this.listCopro[key].name.toLowerCase().indexOf(this.searchVal.toLowerCase())>-1 && this.resultSearch.length<4 || this.listCopro[key].company.toLowerCase().indexOf(this.searchVal.toLowerCase())>-1 || (this.listCopro[key].corpsEtat?.find(metier => metier.indexOf(this.searchVal)>-1))){
        this.resultSearch.push(this.listCopro[key]);
      }
    }
  }

  searchEngine2() {

    this.resultSearch2 = [];
    this.resultSearchIndexStart2 = 0;
    this.resultSearchIndexEnd2 = 4;

    for(let key in this.listCopro2) {
      if(this.listCopro2[key].name.toLowerCase().indexOf(this.search2Val.toLowerCase())>-1 && this.resultSearch2.length<4 || this.listCopro2[key].company.toLowerCase().indexOf(this.search2Val.toLowerCase())>-1){
        this.resultSearch2.push(this.listCopro2[key]);
      }
    }
  }

  searchEngine3() {
    this.resultSearch3 = [];
    this.resultSearchIndexStart3 = 0;
    this.resultSearchIndexEnd3 = 4;

    for(let key in this.listCopro3) {
      if((this.listCopro3[key].name.toLowerCase().indexOf(this.search3Val.toLowerCase())>-1 && this.resultSearch3.length<4) || (this.listCopro3[key].zoneInter.find(zone => zone.indexOf(this.search3Val)>-1))){
        this.resultSearch3.push(this.listCopro3[key]);
      }
    }
  }

  searchEngine4() {

    this.resultSearch4 = [];
    this.resultSearchIndexStart4 = 0;
    this.resultSearchIndexEnd4 = 4;

    for(let key in this.listCopro4) {
      if(this.listCopro4[key].name.toLowerCase().indexOf(this.search4Val.toLowerCase())>-1 && this.resultSearch4.length<4){
        this.resultSearch4.push(this.listCopro4[key]);
      }
    }
  }
}
