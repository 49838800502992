  import { Component, OnInit } from '@angular/core';
  import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS,} from '@angular/material-moment-adapter';
  import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
  import { DataService } from '../../../data.service'
  import { ActivatedRoute } from '@angular/router';
  import { HttpClient } from '@angular/common/http';
  import { identity } from 'rxjs';
  import {ExportExcelService} from "../../../services/export-excel.service";

  export interface SectionSyndic {
    email : string;
    firstName : string;
    lastName : string;
    nomSyndic : string;
    siren : string;
    address : string;
    codePostal : string;
    ville : string;
    phone : string;
    _id : string;
  }

  export interface SectionGestionnaire {
    firstName : string,
    lastName  : string,
    email     : string,
    phone     : string,
    _id       : string
  }

  export interface SectionCourtier {
    company : string,
    surface : string,
    nbCopro : number,
    _id     : string
  }

  export interface SectionPrestataire {
    company : string,
    abo     : boolean,
    _id     : string
  }

  @Component({
    selector: 'app-gerer-syndics',
    templateUrl: './gerer-syndics.component.html',
    styleUrls: ['./gerer-syndics.component.scss'],
    providers:
    [
      {provide: MAT_DATE_LOCALE, useValue: 'fr'},
      {
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
      },
      {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    ],
  })

  export class GererSyndicsComponent implements OnInit {
    creditDispo = 0;
    creditAjout = 0;
    resultSyndic: SectionSyndic;
    resultGest: SectionGestionnaire[] = [];
    resultCourt: SectionCourtier[] = [];
    resultPresta: SectionPrestataire[] = [];

    ajoutImmeubleParc = 0;
    visitesEnCoursParc = 0;
    immeubleVisiteParc = 0;
    ajoutParcSurface = 0;
    visteParcSurface = 0;
    immubleParcSurface = 0;


    ajoutImmeubleSelect = 0;
    visitesEnCoursSelect = 0;
    immeubleVisiteSelect = 0;
    ajoutSelectSurface = 0;
    visteSelectSurface = 0;
    immubleSelectSurface = 0;

    debutPeriode = null;
    finPeriode = null;

    dataJson = [];
    dataForExcel = [];

    title = "";

    addZ(n) {
      return n < 10 ? '0'+n : n;
    }

    ajoutCredit(_id) {
      this.DataService.api('/gestion/credit', {syndic: _id, credit: this.creditAjout},
        (data) => {
          this.creditDispo = data.credit;
          this.creditAjout = 0;
          alert(data.message);
        },
        (error) => {
          alert(error.error.message)
        });
    }

    retrieveData() {
      if (!this.debutPeriode?._d)
        alert("Veuillez selectionner le début de la période");
      else if (!this.finPeriode?._d)
        alert("Veuillez selectionner la fin de la période");
      else {
        let deb = this.debutPeriode._d;
        let fin = this.finPeriode._d;
        this.activatedRoute.params.subscribe(params => {
          const id = params['id'];
          this.DataService.api('/retrieve/demande-period',
            {syndicId: id, dateDebut: deb, dateFin: fin},
            async (data) => {
              for (let i in data.parc) {
                this.dataJson.push({
                  "N° interne Syndic": data.parc[i].reference,
                  Adresse: data.parc[i].address,
                  "Code Postal": data.parc[i].codePostal,
                  Ville: data.parc[i].ville,
                  Surface: data.parc[i].surface,
                  Courtier: data.parc[i].courtier?.company ?? "Sans Courtier",
                  Service: "Mon Parc",
                  "Date de la demande de visite": this.addZ(new Date(data.parc[i].dateDemandeVisite).getDate()) + '/' + this.addZ(new Date(data.parc[i].dateDemandeVisite).getMonth() + 1) + '/' + new Date(data.parc[i].dateDemandeVisite).getFullYear()
                })
              }
              for (let j in data.encours) {
                this.dataJson.push({
                  "N° interne Syndic": data.encours[j].reference,
                  Adresse: data.encours[j].address,
                  "Code Postal": data.encours[j].codePostal,
                  Ville: data.encours[j].ville,
                  Surface: data.encours[j].surface,
                  Courtier: data.encours[j].courtier?.company ?? "Sans Courtier",
                  Service: "EC séléction",
                  "Date de la demande de visite": this.addZ(new Date(data.encours[j].dateDemandeVisite).getDate()) + '/' + this.addZ(new Date(data.encours[j].dateDemandeVisite).getMonth() + 1) + '/' +new Date(data.encours[j].dateDemandeVisite).getFullYear()
                })
              }

              //this.dataJson.sort((a, b) => new Date(a["Date de la demande de visite"]) > new Date(b["Date de la demande de visite"]) ? 1 : (new Date(a["Date de la demande de visite"]) < new Date(b["Date de la demande de visite"]) ? -1 : 0));

              this.dataJson.forEach((row: any) => {
                this.dataForExcel.push(Object.values(row))
              })
              let reportData = {
                title: this.title + this.addZ(deb.getDate()) + '/' + this.addZ(deb.getMonth() + 1) + '/' + deb.getFullYear() + '_' + this.addZ(fin.getDate()) + '/' + this.addZ(fin.getMonth() + 1) + '/' + fin.getFullYear(),
                data: this.dataForExcel,
                headers: Object.keys(this.dataJson[0])
              }

              this.ete.exportExcel(reportData);
            },
            (error) => {
              console.log(error)
            })
        })
      }
    }

    numericOnly(event): boolean {
      let patt = /^([0-9])$/;
      return  patt.test(event.key);
    }

    onEvent(event) {
      event.stopPropagation();
    }

    constructor(
      private http: HttpClient,
      private activatedRoute: ActivatedRoute,
      public DataService: DataService,
      public ete: ExportExcelService,
      private _adapter: DateAdapter<any>
    ) {}


    french() {
      this._adapter.setLocale('fr');
    }

    ngOnInit() {
      this.activatedRoute.params.subscribe(params => {
        const id = params['id'];
        this.DataService.api_post_syndic({_id:id},
          (data) => {
            this.creditDispo = data.syndic.credit;
            this.title = data.syndic.nomSyndic + "_période_";
            this.resultSyndic = {
              email : data.syndic.email,
              firstName : data.syndic.firstName,
              lastName : data.syndic.lastName,
              nomSyndic : data.syndic.nomSyndic,
              siren : data.syndic.siren,
              address : data.syndic.address,
              codePostal : data.syndic.codePostal,
              ville : data.syndic.ville,
              phone : data.syndic.phone,
              _id : data.syndic._id,
            }
            for (let i in data.syndic.gestionnaires) {
              this.resultGest[i] = {
                _id       : data.syndic.gestionnaires[i]._id,
                firstName : data.syndic.gestionnaires[i].firstName,
                lastName  : data.syndic.gestionnaires[i].lastName,
                email     : data.syndic.gestionnaires[i].email,
                phone     : data.syndic.gestionnaires[i].phone,
              }
            }
            for (let i in data.syndic.courtiers) {
              this.resultCourt[i] = {
                _id     : data.syndic.courtiers[i]._id,
                company : data.syndic.courtiers[i].company,
                surface : 0,
                nbCopro : 0
              }
            }
            for (let i in data.syndic.prestataires) {
              this.resultPresta[i] = {
                _id     : data.syndic.prestataires[i]._id,
                company : data.syndic.prestataires[i].company,
                abo     : data.syndic.prestataires[i].abonnements.includes(data.syndic._id)
              }
            }
            for (let i in data.syndic.parc) {
              if (data.syndic.parc[i].dateVisite) {
                this.immeubleVisiteParc += 1;
                this.immubleParcSurface += data.syndic.parc[i].surface;
              } else if (data.syndic.parc[i].dateDemandeVisite) {
                this.visitesEnCoursParc += 1;
                this.visteParcSurface += data.syndic.parc[i].surface;
              } else {
                this.ajoutImmeubleParc += 1;
                this.ajoutParcSurface += data.syndic.parc[i].surface;
              }

              for (let j in this.resultCourt) {
                if (this.resultCourt[j]._id === data.syndic.parc[i].courtier) {
                  this.resultCourt[j].surface += data.syndic.parc[i].surface;
                  this.resultCourt[j].nbCopro += 1;
                }
              }
            }
            for (let i in data.syndic.enCoursSelect) {
              if (data.syndic.enCoursSelect[i].dateVisite && !data.syndic.enCoursSelect[i].perdu && !data.syndic.enCoursSelect[i].sansSuite) {
                this.immeubleVisiteSelect += 1;
                this.immubleSelectSurface += data.syndic.enCoursSelect[i].surface;
              } else if (data.syndic.enCoursSelect[i].dateDemandeVisite && !data.syndic.enCoursSelect[i].perdu && !data.syndic.enCoursSelect[i].sansSuite) {
                this.visitesEnCoursSelect += 1;
                this.visteSelectSurface += data.syndic.enCoursSelect[i].surface;
              } else if (!data.syndic.enCoursSelect[i].perdu && !data.syndic.enCoursSelect[i].sansSuite) {
                this.ajoutImmeubleSelect += 1;
                this.ajoutSelectSurface += data.syndic.enCoursSelect[i].surface;
              }

              for (let key in this.resultCourt) {
                if (this.resultCourt[key]._id === data.syndic.enCoursSelect[i].courtier) {
                  this.resultCourt[key].surface += data.syndic.enCoursSelect[i].surface;
                  this.resultCourt[key].nbCopro += 1;
                }
              }
            }
          },
          (error)=>{
            console.log("erreur")
          }
        );
    });
  }
}
