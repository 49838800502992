import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service'

@Component({
  selector: 'app-gestionnaire-unassign-dialog',
  templateUrl: './gestionnaire-unassign-dialog.component.html',
  styleUrls: ['./gestionnaire-unassign-dialog.component.scss']
})
export class GestionnaireUnAssignDialogComponent implements OnInit {

  constructor(public DataService: DataService) { }

  retourFormCourtier() {
    window.location.reload();
  }

  ngOnInit(): void {
  }

}
