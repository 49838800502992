<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center" class="padding-card padding-card-pos" style="margin-bottom: 50px;">
    <div fxFlex="50%;" fxFlex.xs="100%" fxFlex.md="75%" fxFlex.sm="75%" fxFlex.lg="33%">
        <mat-card class="resultat_abo" fxFlex="90%;">
            <div class="padding-card padding-card-pos">
                <div class="txt_abo">
                    <div fxLayoutAlign="center" class="title">BASIQUE</div>
                    <div fxLayoutAlign="center" class="marge30bot">Reception des demandes de devis avec le descriptif des dommages sans métrages ni photos</div>
                    <div fxLayoutAlign="center" class="marge30bot">Dépot de devis</div>
                    <div fxLayoutAlign="center" class="marge30bot">Information à jour pour <br> accéder à l’immeuble</div>
                </div>
            </div>
        </mat-card>
    </div>
    <div fxFlex="50%;" fxFlex.xs="100%" fxFlex.md="75%" fxFlex.sm="75%" fxFlex.lg="33%">
        <mat-card class="resultat_abo" fxFlex="90%;">
            <div class="padding-card padding-card-pos">
                <div fxLayoutAlign="center" class="title">PREMIUM</div>
                <div class="txt_abo">
                    <div fxLayoutAlign="center" class="marge30bot">Accès aux rapports de visite</div>
                    <div fxLayoutAlign="center" class="marge30bot">Accès service Evaluation</div>
                    <div fxLayoutAlign="center" class="marge30bot">Retour sur les devis proposés</div>
                    <div fxLayoutAlign="center" class="marge30bot">Le suivi de qualité de son équipe</div>
                    <div fxLayoutAlign="center" class="marge30bot">Son score qualité indiqué au <br> tableau de synthèse </div>
                </div>
            </div>
        </mat-card>
    </div>
</div>

<div fxLayout="row wrap">
    <div fxLayoutAlign.xs="center" fxLayoutAlign.sm="center" fxFlex="33" fxFlex.xs="100" fxFlex.md="33" fxFlex.sm="50" fxFlex.lg="33" fxFlex.gt-lg="25" id="{{ detail.id_el }}" class="padding-card padding-card-pos" *ngFor="let detail of resultSearch ; let i = index">
        <mat-card fxFlex="100">
            <!--div class="ic_right"><mat-button-toggle (click)="onEvent($event)"  (click)="confirmation($event)" class="ic_croix"><img src="../../../../../assets/img/icones/croix.png"></mat-button-toggle></div-->
            <div class="ic_right"><mat-button-toggle class="ic_cloche ic_cloche_pos"><img src=""></mat-button-toggle></div>
            <mat-card-content class="box_shadow pad20 content-140 contentxs font_result">
                <div style="margin-top: -50px;">
                    <div fxLayoutAlign="center">
                        <div class="result-box res_img" fxLayoutAlign="center center">
                            <div width="70%" class="img_virtuelle_icone" [ngStyle]="{ 'background-position': 'center', 'background-repeat': 'no-repeat', 'background-size': 'contain', 'background-image': 'url(' + detail.src_img + ')'}"></div>
                        </div>
                    </div>
                    <div fxLayoutAlign="center" class="res_name marge25 data_dynamic"> {{detail.name}}</div>

                    <div fxLayout="row wrap" class="ic_marge ic_bot">
                        <div fxFlex="33" fxFlex.xs="100">
                            <div fxLayoutAlign="center" class="icon_height iconesxs"><img src="../../../../../assets/img/icones/recherche.jpg"></div>
                            <div fxLayoutAlign="center" class="data_dynamic res_marge_bot">0</div>
                        </div>
                        <div fxFlex="33" fxFlex.xs="100">
                            <div fxLayoutAlign="center" class="icon_height iconesxs"><img src="../../../../../assets/img/icones/calcule.jpg"></div>
                            <div fxLayoutAlign="center" class="data_dynamic res_marge_bot">0</div>
                        </div>
                        <div fxFlex="33" fxFlex.xs="100">
                            <div fxLayoutAlign="center" class="icon_height iconesxs"><img src="../../../../../assets/img/icones/tools.png"></div>
                            <div fxLayoutAlign="center" class="data_dynamic res_marge_bot">0 <span>€</span></div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
            <div fxLayoutAlign="center" id="{{ detail.id_el + '-' + detail.abo  }}">
                <mat-button-toggle class="{{detail.abo ? 'btn btn-margin btn-txt' : 'btn-noir btn-margin btn-txt'}}" value="underline">{{detail.abo ? 'Premium' : 'Basique'}}</mat-button-toggle>
            </div>
        </mat-card>
    </div>
</div>
