import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service'

@Component({
  selector: 'app-courtier-etude-dialog',
  templateUrl: './courtier-etude-dialog.component.html',
  styleUrls: ['./courtier-etude-dialog.component.scss']
})
export class CourtierEtudeDialogComponent implements OnInit {

  constructor(public DataService: DataService) { }

  retourFormCourtier() {
    /*
    if (this.DataService.router.url.includes('/mon-parc/mon-parc-immeuble/'))
      this.DataService.router.navigate(['mon-parc/dashboard']);
    else
      this.DataService.router.navigate(['en-cours-selection/dashboard']);

     */
    window.location.reload();
  }

  ngOnInit(): void {
  }

}
