import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-mon-abonnement',
  templateUrl: './mon-abonnement.component.html',
  styleUrls: ['./mon-abonnement.component.scss']
})
export class MonAbonnementComponent implements OnInit {
  PrestatairesDetailsArray;
  searchVal: string;
  resultSearch = [];
  listCopro = [
    {
      "src_img": "../assets/img/icones/broker.png",
      "name": "Chris"
    },
    {
      "src_img": "../assets/img/icones/broker.png",
      "name": "Bob"
    }, {
      "src_img": "../assets/img/icones/broker.png",
      "name": "Alex"
    },
    {
      "src_img": "../assets/img/icones/broker.png",
      "name": "Chris"
    }
  ];

  constructor() {
    this.resultSearch = this.listCopro;
  }

  ngOnInit(): void {
  }

  searchEngine() {
    this.resultSearch = [];
    //if(this.searchVal)
    for(var key in this.listCopro) {
      if(this.listCopro[key].name.toLowerCase().indexOf(this.searchVal.toLowerCase())>-1 && this.resultSearch.length<4){
        this.resultSearch.push(this.listCopro[key]);
      }
    }
    console.log(this.resultSearch)
  }


}