<div fxLayout="row wrap" *ngIf="router.url !== '/admin-copro/dashboard'">
  <div fxLayoutAlign="start" fxFlex="30" >


    <div class="padding-card padding-card-retour"
    *ngIf="router.url !== '/admin-copro/form-syndic' &&
    router.url !== '/admin-copro/form-prestataires-admin'  &&
    router.url !== '/admin-copro/form-courtiers-admin' &&
    router.url !== '/admin-copro/form-architectes'
    ">

      <mat-button-toggle routerLink="../../dashboard" class="pad10">
          <span fxLayoutAlign="center center" fxFlex="15" class="icon_arr"><img class="img_retour" src="../../../../assets/img/icones/retour-arr.png"></span>
          <div fxLayoutAlign="center center" fxFlex="85"><span class="retour_parc">Retour au tableau de bord</span></div>
      </mat-button-toggle>
    </div>

    <div class="padding-card padding-card-retour"
    *ngIf="router.url == '/admin-copro/form-syndic' ||
    router.url == '/admin-copro/form-prestataires-admin'  ||
    router.url == '/admin-copro/form-courtiers-admin' ||
    router.url == '/admin-copro/form-architectes'
    ">

      <mat-button-toggle routerLink="../dashboard" class="pad10">
          <span fxLayoutAlign="center center" fxFlex="15" class="icon_arr"><img class="img_retour" src="../../../../assets/img/icones/retour-arr.png"></span>
          <div fxLayoutAlign="center center" fxFlex="85"><span class="retour_parc">Retour au tableau de bord</span></div>
      </mat-button-toggle>
    </div>


  </div>
  <div fxLayoutAlign="end" fxFlex="70" class="icons_top">
    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="unseenNotif()" aria-label="Example icon-button with a menu">
      <mat-icon
        [matBadge]="unseenCount"
        matBadgePosition="after"
        matBadgeColor="accent"
        matBadgeSize="small"
        class="pointer default-image icon_notifications">
        notifications
      </mat-icon>
    </button>
    <div style="position: absolute;">
      <mat-nav-list>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-icon class="default-image pointer icon_profil">person</mat-icon>
          </mat-expansion-panel-header>
            <a class="list_profil" style="text-align: center; background-color: #fff;" mat-list-item routerLink="../profil-admin">Profil</a>
            <a class="list_profil" style="text-align: center; background-color: #fff;" mat-list-item routerLink="../contact-admin">Contact</a>
            <a class="list_profil" (click)="deletToken($event)" style="text-align: center; background-color: #fff;" mat-list-item>Déconnexion</a>
          </mat-expansion-panel>
      </mat-nav-list>
    </div>
    <mat-menu #menu="matMenu">
      <button mat-menu-item disabled *ngIf="notifs?.length === 0">
        <mat-icon>notifications_off</mat-icon>
        <span>Aucune notifications pour le moment</span>
      </button>
      <mat-list-item
        *ngFor="let notif of notifs"
        style="font-size: 10px;border-bottom: 0.5px solid #F9F9F9;"
        (click)="notify()"
      >
        <button mat-menu-item
                style="margin-bottom: 8px"
                [ngStyle]="{'background-color' : notif.date_seen === null ? 'rgba(255, 215, 64, 0.2)' : 'transparent'}"
                (click)="redirectTo(notif.url)">
          <mat-icon>{{notif.date_seen === null ? "notification_important" : "notifications_on"}}</mat-icon>
          <span>{{notif.message}}</span>
        </button>
      </mat-list-item>
      <!--button mat-menu-item disabled>
        <mat-icon>notifications_on</mat-icon>
        <span>Activer les notifications</span>
      </button>
      <button mat-menu-item>
        <mat-icon>notifications_off</mat-icon>
        <span>Désactiver les notifications</span>
      </button-->
    </mat-menu>
  </div>
</div>

<div fxLayout="row wrap" *ngIf="router.url === '/admin-copro/dashboard'" >
  <div fxLayoutAlign="end" fxFlex="100" class="icons_top">
    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="unseenNotif()" aria-label="Example icon-button with a menu" class="notifications">
      <mat-icon
        [matBadge]="unseenCount"
        matBadgePosition="after"
        matBadgeColor="accent"
        matBadgeSize="small"
        class="pointer default-image icon_notifications">
        notifications
      </mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item disabled *ngIf="!notifs">
        <mat-icon>notifications_off</mat-icon>
        <span>Aucune notifications pour le moment</span>
      </button>
      <mat-list-item
        *ngFor="let notif of notifs"
        style="font-size: 10px;border-bottom: 0.5px solid #F9F9F9;"
        (click)="notify()"
      >
        <button mat-menu-item
                style="margin-bottom: 8px"
                [ngStyle]="{'background-color' : notif.date_seen === null ? 'rgba(255, 215, 64, 0.2)' : 'transparent'}"
                (click)="redirectTo(notif.url)">
          <mat-icon>{{notif.date_seen === null ? "notification_important" : "notifications_on"}}</mat-icon>
          <span>{{notif.message}}</span>
        </button>
      </mat-list-item>
      <!--button mat-menu-item disabled>
        <mat-icon>notifications_on</mat-icon>
        <span>Activer les notifications</span>
      </button>
      <button mat-menu-item>
        <mat-icon>notifications_off</mat-icon>
        <span>Désactiver les notifications</span>
      </button-->
    </mat-menu>
    <div style="position: absolute;">
      <mat-nav-list>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-icon class="default-image pointer icon_profil">person</mat-icon>
          </mat-expansion-panel-header>
            <a class="list_profil" style="text-align: center; background-color: #fff;" mat-list-item routerLink="/admin-copro/profil-admin">Profil</a>
            <a class="list_profil" style="text-align: center; background-color: #fff;" mat-list-item routerLink="/admin-copro/contact-admin">Contact</a>
            <a class="list_profil" (click)="deletToken($event)" style="text-align: center; background-color: #fff;" mat-list-item>Déconnexion</a>
        </mat-expansion-panel>
      </mat-nav-list>
    </div>
  </div>
</div>


