import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { TestBed } from '@angular/core/testing';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { DataService } from '../data.service'

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class GalleryComponent implements OnInit {

  items: GalleryItem[];

  imageEvaluation = dataEvaluation;
  imageDescription = dataDescription;
  imageReception = dataReception;
  imageValider = dataValider;
  imageDevis = dataDevis;
  imageAvant = dataAvant;
  imageApres = dataApres;

  // imageFacades = dataFacades;
  imageEntree = dataEntree;
  imageArriere = dataArriere;
  imageEtage1 = dataEtage1;

  imageCarnet = dataCarnet;

  imageAfaire = dataAfaire;
  imageEnattente = dataEnattente;
  imageRealises = dataRealises;



  // urlfacade


  dataFacades = []

  constructor(public gallery: Gallery, public lightbox: Lightbox,  public DataService: DataService) {


  }


  ngOnInit() {



    // this.urlfacade = this.DataService.imgFacade1


    /** Basic Gallery Example */

    // Creat gallery items
    this.items = this.imageEvaluation.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));

    this.items = this.imageDescription.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.items = this.imageReception.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.items = this.imageValider.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));

    this.items = this.imageDevis.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.items = this.imageAvant.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.items = this.imageApres.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));

    this.items = this.dataFacades.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.items = this.imageEntree.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.items = this.imageArriere.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.items = this.imageEtage1.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));

    this.items = this.imageCarnet.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));

    this.items = this.imageAfaire.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.items = this.imageEnattente.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    this.items = this.imageRealises.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));


    /** Lightbox Example */

    // Get a lightbox gallery ref
    // const lightboxRef = this.gallery.ref('lightbox');

    // // Add custom gallery config to the lightbox (optional)
    // lightboxRef.setConfig({
    //   imageSize: ImageSize.Cover,
    //   thumbPosition: ThumbnailsPosition.Top
    // });

    // // Load items into the lightbox gallery ref
    // lightboxRef.load(this.items);
  }
}

const dataEvaluation = [
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall1.jpg',
    previewUrl: '../../../../../assets/img/gallery/immeubleBig3.jpg',
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall2.jpg',
    previewUrl: '../../../../../assets/img/gallery/immeubleBig1.jpg',
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall3.jpg',
    previewUrl: '../../../../../assets/img/gallery/immeubleBig1.jpg',
  },
];

const dataDescription = [
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall1.jpg',
    previewUrl: '../../../../../assets/img/gallery/immeubleBig1.jpg',
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall2.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall3.jpg',
    previewUrl: '../../../../../assets/img/gallery/immeubleBig1.jpg',
  },
];

const dataReception = [
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall1.jpg',
    previewUrl: '../../../../../assets/img/gallery/immeubleBig1.jpg',
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall2.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall3.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
];

const dataValider = [
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall1.jpg',
    previewUrl: '../../../../../assets/img/gallery/immeubleBig1.jpg',
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall2.jpg',
    previewUrl: '../../../../../assets/img/gallery/immeubleBig1.jpg',
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall3.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
];

const dataDevis = [
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall1.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall2.jpg',
    previewUrl: '../../../../../assets/img/gallery/immeubleBig1.jpg',
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall3.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
];

const dataAvant = [
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall1.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall2.jpg',
    previewUrl: '../../../../../assets/img/gallery/immeubleBig1.jpg',
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall3.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
];

const dataApres = [
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall1.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall2.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall3.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
];
// var test = "lol";
// const dataFacades = [
//   {
//     srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/e372e2cfec6b79c3925a1f66a92576f96a97e8f1.png'+(test),
//     previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/e372e2cfec6b79c3925a1f66a92576f96a97e8f1.png '
//   },
//   {
//     srcUrl: '../../../../../assets/img/gallery/immeubleSmall2.jpg',
//     previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
//   },
//   {
//     srcUrl: '../../../../../assets/img/gallery/immeubleSmall3.jpg',
//     previewUrl: '../../../../../assets/img/gallery/immeubleBig1.jpg',
//   },
// ];

const dataEntree = [
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall1.jpg',
    previewUrl: '../../../../../assets/img/gallery/immeubleBig1.jpg',
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall2.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall3.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
];

const dataArriere = [
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall1.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall2.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall3.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
];

const dataEtage1 = [
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall1.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall2.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall3.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
];

const dataCarnet = [
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall1.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall2.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall3.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
];

const dataAfaire = [
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall1.jpg',
    previewUrl: '../../../../../assets/img/gallery/immeubleBig1.jpg',
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall2.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall3.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
];

const dataEnattente = [
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall1.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall2.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall3.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
];

const dataRealises = [
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall1.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall2.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
  {
    srcUrl: '../../../../../assets/img/gallery/immeubleSmall3.jpg',
    previewUrl: '../../../../../assets/img/photo/travaux1.jpg'
  },
];
