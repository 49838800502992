import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service'

@Component({
  selector: 'app-courtier-demande-dialog',
  templateUrl: './courtier-demande-dialog.component.html',
  styleUrls: ['./courtier-demande-dialog.component.scss']
})
export class CourtierDemandeDialogComponent implements OnInit {

  constructor(public DataService: DataService) { }

  retourFormCourtier() {
    this.DataService.router.navigate(['ajout-collaborateurs/dashboard']);
  }

  ngOnInit(): void {
  }

}
