
<div class="padding-card padding-card-pos">
  <mat-button-toggle routerLink="../../dashboard" class="pad10">
    <span fxLayoutAlign="center center" fxFlex="15" class="icon_arr"><img class="img_retour" src="../../../../assets/img/icones/retour-arr.png"></span>
    <div fxLayoutAlign="center center" fxFlex="85"><span class="retour_parc">Retour à l'accueil</span></div>
  </mat-button-toggle>
</div>

<div fxLayout="row wrap" fxLayoutAlign="center">

<!------------------- BLOC LEFT -------------------------->

  <div fxFlex="30" fxFlex.xs="100" fxFlex.md="40" fxFlex.sm="100" fxFlex.lg="30" class="padding-card padding-card-pos">
    <mat-card fxFlex="100">
      <mat-card-content class="content-40">
        <div fxLayoutAlign="center" style="box-shadow:2px 2px 14px 0 rgba(0, 0, 0, 0.08)">
          <img width="90%" class="res_img" *ngIf="imgBat !== 'https://copro-back.cantem.fr/uploads/batiment/undefined'" [src]="imgBat">
        </div>
        <div fxLayout="row wrap" *ngIf="dataParcelle[0]?.srcUrl !== 'https://copro-back.cantem.fr/uploads/batiment/undefined'" >
          <div class="title_descr txt_medium" fxFlex="100">Parcelle Cadastrale</div>
          <!------Gallery Images-------->
          <div fxFlex="100">
                      <span class="" gallerize="gallery_8">
                        <span *ngFor="let img of dataParcelle" ><img *ngIf="img.srcUrl !== 'https://copro-back.cantem.fr/uploads/batiment/undefined'" class="sizeDescription"  [src]="img.srcUrl"> </span>
                      </span>
          </div>
          <!-------------->
        </div>
        <div fxLayout="row wrap" *ngIf="dataVueGG[0]?.srcUrl !== 'https://copro-back.cantem.fr/uploads/batiment/undefined'" >
          <div class="title_descr txt_medium" fxFlex="100">Vue Générale Google</div>
          <!------Gallery Images-------->
          <div fxFlex="100">
                      <span class="" gallerize="gallery_9">
                        <span *ngFor="let img of dataVueGG" ><img *ngIf="img.srcUrl !== 'https://copro-back.cantem.fr/uploads/batiment/undefined'" class="sizeDescription"  [src]="img.srcUrl"> </span>
                      </span>
          </div>
          <!-------------->
        </div>
        <div fxLayout="row wrap" style="margin: 30px 0;" fxLayoutAlign="center">
          <div fxFlex="90">
            <div style="font-weight: 600; margin-bottom: 15px; font-size: 20px"></div>
            <div>
              <span class="font_immeuble">Numéro interne : {{copro?.reference}}</span>
              <span class="data_dynamic_done result_imm resultat_marge_left"><!--{{ detail.nbrLot }}--></span>
            </div>
            <div>
              <span class="font_immeuble">Surface : {{copro?.surface}} ㎡</span>
              <span class="data_dynamic_done result_imm resultat_marge_left"></span>
            </div>
            <div>
              <span class="font_immeuble">Multi Devis : {{copro?.multiDevis}}</span>
              <span class="data_dynamic_done result_imm resultat_marge_left"></span><span class="resultat_marge_left">€</span>
            </div>
            <div>
              <span class="font_immeuble">Max travaux : {{copro?.maxTravaux}}</span>
              <span class="data_dynamic_done result_imm resultat_marge_left"></span><span class="resultat_marge_left">€</span>
            </div>
            <div>
              <span class="font_immeuble">Mois AG : {{copro?.moisAG | date:'MMMM'}}</span>
              <span class="data_dynamic_done result_imm resultat_marge_left"></span>
            </div>
            <div>
              <span class="font_immeuble">Compagnie : {{copro?.compagnie?.assurance}}</span>
              <span class="data_dynamic_done result_imm resultat_marge_left"><!--{{ detail.compagnie }}--></span>
            </div>
            <div>
              <span class="font_immeuble">Echéance : {{copro?.compagnie?.echeance | date: 'dd/MM'}}</span>
              <span class="data_dynamic_done result_imm resultat_marge_left"><!--{{ detail.echeance }}--></span>
            </div>
            <div>
              <span class="font_immeuble">
                Courtier Partenaire : {{copro?.courtier?.lastName || 'Sans Courtier'}} [{{copro?.courtier?.company || '-'}}]
              </span>
              <span class="data_dynamic_done result_imm resultat_marge_left"></span><span class="resultat_marge_left"></span>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

<!------------------- BLOC RIGHT --------------------------->

  <div fxFlex="70" fxFlex.xs="100" fxFlex.md="60" fxFlex.sm="100" fxFlex.lg="70" class="padding-card padding-card-pos">
    <mat-card fxFlex="100">
      <mat-card-content class="remove-border-bottom">
        <mat-tab-group headerPosition="above" [selectedIndex]="3">

<!------------------- TAB 1 EVALUTATION -------------------------->

<mat-tab label="Evalutation" class="label_tab">
    <br *ngIf="resultCarnet.length === 0"/>
    <div fxLayoutAlign="center center" fxFlex="100" fxFlex.xs="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.lg="100" *ngIf="resultDevis.length === 0">
      <div *ngIf="resultCarnet.length === 0" style="background-color: #F9F9F9; padding: 35px 15px;" fxLayoutAlign="center center">
        <div class="font_content">Aucun désordre identifié</div>
      </div>
    </div>
    <div *ngFor="let detail of resultCarnet" fxLayout="row wrap" class="marge_tab">
        <div fxFlex="50" fxFlex.xs="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.lg="50" class="bloc_travaux">
            <div class="font_immeuble marge_devis">
                <span>Le</span>
                <span class="data_dynamic resultat_marge_left">{{detail?.date | date: 'dd/MM/yyyy'}}</span>
            </div>
            <div>
                <span class="font_content">Désordre :</span>
                <span class="font_resultat_tab  data_dynamic_done resultat_marge_left">{{ detail?.desordre }}</span>
            </div>
            <div>
                <span class="font_content">Situation :</span>
                <span class="font_resultat_tab  data_dynamic_done resultat_marge_left">{{ detail?.situation }}</span>
            </div>
            <div class="bloc-right maxWidth350">
                <span class="font_content">Descriptif :</span>
                <span class="font_resultat_tab  data_dynamic_done resultat_marge_left">{{ detail?.description }}</span>
            </div>
            <div>
                <span class="font_content">Métrages :</span>
                <span class="font_resultat_tab  data_dynamic_done resultat_marge_left"></span>{{ detail?.metrages }}<span class="resultat_marge_left">m²</span>
            </div>
            <div>
                <span class="font_content">Corps d'état : </span>
                <span class="font_resultat_tab  data_dynamic_done resultat_marge_left">{{ detail?.corpsEtat }}</span>
            </div>
            <div>
                <span class="font_content">Commentaire Architecte:</span>
                <span class="font_resultat_tab  data_dynamic_done resultat_marge_left"></span>
                <span class="font_resultat_tab  data_dynamic_done resultat_marge_left">{{ detail?.commentaire }}</span>
            </div>

            <div fxLayout="row wrap" class="marge_add">
                <div fxFlex="100">
                    <div fxFlex="100">
                        <div class="txt_medium500 marge_devis font_immeuble">
                          <span>Photos : {{detail?.images?.length}}</span>
                          <span class="data_dynamic txt_small resultat_marge_left"><!--{{ detail.carnetPhoto }}--></span>
                        </div>
                        <span class="" gallerize="gallery{{ detail?._id }}">
                            <span *ngIf="detail.images">
                                <img *ngIf="detail?.images[0]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+detail?.images[0]" style="width: 50px; height: 50px; margin-right: 5px;">
                                <img *ngIf="detail?.images[1]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+detail?.images[1]" style="width: 50px; height: 50px; margin-right: 5px;">
                                <img *ngIf="detail?.images[2]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+detail?.images[2]" style="width: 50px; height: 50px; margin-right: 5px;">
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div  fxFlex="50" fxFlex.xs="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.lg="50">
            <div style="background-color: #F9F9F9; padding: 35px 15px;">
                <div>
                    <div fxFlex="30">
                      <div class="font_content">Synthèse d'évalutation</div>
                      <!--div>Le<span class="data_dynamic_done resultat_marge_left font_resultat_tab ">00/00/00</span></div-->
                    </div>
                    <div class="font_content" fxLayoutAlign="center" fxFlex="40">Prestataire</div>
                    <div class="font_content" fxFlex="30">commentaire</div>
                    <div class="font_content" fxFlex="30">Demander devis</div>
                </div>
                <div style="margin: 30px 0;" *ngFor="let eval of resultEvaluation; let i = index">
                    <div fxFlex="30" *ngIf="detail._id === eval.incidentId._id">
                      <div>
                        <span class="font_content">Montant ttc  </span>
                        <span class="data_dynamic_done font_resultat_tab " style="color: green;">{{eval.evaluationTTC}} €</span>
                      </div>
                    </div>
                    <div fxFlex="40" fxLayoutAlign="center" class="company_logo" *ngIf="detail._id === eval.incidentId._id">
                      <span class="data_dynamic_done font_resultat_tab " >{{eval.prestataireId.company}}</span>
                    </div>
                    <div fxLayoutAlign="center" fxFlex="30" *ngIf="detail._id === eval.incidentId._id">
                      <div class="pointer info_icon" mat-raised-button (click)="toggle_info($event, eval.commentaire)">
                        <img *ngIf="eval.commentaire.length" src="../../../../../assets/img/icones/info-small.png">
                        <img *ngIf="!eval.commentaire.length" src="../../../../../assets/img/icones/highlight_off-24px.svg">
                      </div>
                    </div>
                    <div fxFlex="30" fxLayoutAlign="center" *ngIf="detail._id === eval.incidentId._id">
                      <mat-slide-toggle checked="{{eval.demandeDevis}}" [disabled]="true"></mat-slide-toggle>
                    </div>
                  </div>
                </div>
              </div>
            </div>

</mat-tab>

<!------------------- TAB 2 DEVIS -------------------------->

<mat-tab label="Devis">
    <br *ngIf="resultCarnetDevis.length === 0"/>
    <div fxLayoutAlign="center center" fxFlex="100" fxFlex.xs="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.lg="100" *ngIf="this.resultDevis.length === 0">
      <div *ngIf="resultCarnetDevis.length === 0" style="background-color: #F9F9F9; padding: 35px 15px;" fxLayoutAlign="center center">
        <div class="font_content">Pas de Devis en cours, pour l'instant...</div>
      </div>
    </div>
    <div *ngFor="let detail of resultCarnetDevis" fxLayout="row wrap" class="marge_tab">
        <div fxFlex="15" fxFlex.xs="20" fxFlex.sm="20">
            <!--div fxLayoutAlign="center" class="marge_icon"><mat-button-toggle><img src="../../../../../assets/img/icones/croix.jpg"></mat-button-toggle></div>
            <div fxLayoutAlign="center" class="marge_icon"><mat-button-toggle><img src="../../../../../assets/img/icones/cloche.jpg"></mat-button-toggle></div-->
        </div>
        <div fxLayout="row wrap" fxFlex="85" fxFlex.xs="80" fxFlex.sm="80">
            <div fxFlex="50" fxFlex.xs="100">
                <div class="title_devis">{{detail?.desordre}}</div>
                <div class="marge_devis title_devis">
                    <span>Le :</span>
                    <span class="data_dynamic resultat_marge_left">{{detail?.date | date: 'dd/MM/yyyy'}}</span>
                </div>
                <div class="marge_devis txt_devis">
                    <span class="txt_medium">Descriptif :</span>
                    <span class="data_dynamic txt_small resultat_marge_left">{{ detail?.description }}</span>
                </div>
                <div class="txt_devis">
                    <span class="txt_medium">Corps d'état :</span>
                    <span class="data_dynamic txt_small resultat_marge_left">{{ detail?.corpsEtat }}</span>
                </div>
                <div class="txt_devis">
                    <span class="txt_medium">Situation :</span>
                    <span class="data_dynamic txt_small resultat_marge_left">{{ detail?.situation }}</span>
                </div>
                <div class="txt_devis">
                    <span class="txt_medium">Métrage :</span>
                    <span class="data_dynamic txt_small resultat_marge_left">{{ detail?.metrages }} m²</span>
                </div>
                <div>
                  <span class="font_content">Commentaire Architecte:</span>
                  <span class="font_resultat_tab  data_dynamic_done resultat_marge_left"></span>
                  <span class="font_resultat_tab  data_dynamic_done resultat_marge_left">{{ detail?.commentaire }}</span>
                </div>
                <div fxLayout="row wrap" class="marge_add">
                  <div fxFlex="100">
                    <div fxFlex="100">
                      <div class="txt_medium500 marge_devis font_immeuble">
                        <span>Photos : {{detail?.images?.length}}</span>
                        <span class="data_dynamic txt_small resultat_marge_left"><!--{{ detail.carnetPhoto }}--></span>
                      </div>
                      <span class="" gallerize="gallery{{ detail?._id }}">
                        <span *ngIf="detail?.images">
                          <img *ngIf="detail?.images[0]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+detail?.images[0]" style="width: 50px; height: 50px; margin-right: 5px;">
                          <img *ngIf="detail?.images[1]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+detail?.images[1]" style="width: 50px; height: 50px; margin-right: 5px;">
                          <img *ngIf="detail?.images[2]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+detail?.images[2]" style="width: 50px; height: 50px; margin-right: 5px;">
                        </span>
                      </span>
                    </div>
                  </div>
              </div>
            </div>
            <div fxFlex="50" fxFlex.xs="100">
            </div>
        </div>
        <div  fxFlex="100" fxFlex.xs="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.lg="100">
          <div style="background-color: #F9F9F9; padding: 35px 15px;">
            <div>
              <div fxFlex="30">
                <div class="font_content">Synthèse devis</div>
              </div>
              <div class="font_content" fxLayoutAlign="center" fxFlex="40">Prestataire</div>
              <div class="font_content" fxFlex="30">commentaire</div>
              <div class="font_content" fxFlex="30">Télécharger devis</div>
            </div>
            <div style="margin: 30px 0;" *ngFor="let eval of resultDevis; let i = index">
              <div fxFlex="30" *ngIf="detail?._id === eval?.incidentId?._id">
                <div>
                  <span class="font_content">Montant TTC:  </span>
                  <span class="data_dynamic_done font_resultat_tab " >{{eval?.devisTTC}} €</span>
                </div>
              </div>
              <div fxFlex="40" fxLayoutAlign="center" class="company_logo" *ngIf="detail?._id === eval?.incidentId?._id">
                <span class="data_dynamic_done font_resultat_tab " >{{eval?.prestataireId?.company}}</span>
              </div>
              <div fxLayoutAlign="center" fxFlex="30" *ngIf="detail?._id === eval?.incidentId?._id">
                <div class="pointer info_icon"  mat-raised-button (click)="toggle_info($event, eval?.commentaire)"><img src="../../../../../assets/img/icones/info-small.png"></div>
              </div>
              <div fxFlex="30" fxLayoutAlign="center" *ngIf="detail?._id === eval?.incidentId?._id">

                <a [href]="'https://copro-back.cantem.fr/uploads/devis/' + eval?.devisPDF" target="_blank"><img src="../../../../assets/img/icones/upload.png"></a>
                <mat-button-toggle class="icon_upload"></mat-button-toggle>

              </div>
            </div>
          </div>
        </div>
      <hr style="border: .5vm solid; width: 100%"/>
    </div>
</mat-tab>

<!------------------- TAB 3 PRE-RECEPTION -------------------------->

<mat-tab label="Avis Travaux">
    <br *ngIf="!resultReception.length"/>
    <div *ngIf="!resultReception.length" fxLayoutAlign="center center" fxFlex="100" fxFlex.xs="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.lg="100">
      <div style="background-color: #F9F9F9; padding: 35px 15px;" fxLayoutAlign="center center">
        <div class="font_content">Pas d'Avis Travaux, pour l'instant...</div>
      </div>
    </div>
    <div *ngFor="let detail of resultReception" fxLayout="row wrap" class="marge_tab">
        <div fxFlex="15" fxFlex.xs="20" fxFlex.sm="20">
            <!--div fxLayoutAlign="center" class="marge_icon"><mat-button-toggle><img src="../../../../../assets/img/icones/croix.jpg"></mat-button-toggle></div>
            <div fxLayoutAlign="center" class="marge_icon"><mat-button-toggle><img src="../../../../../assets/img/icones/cloche.jpg"></mat-button-toggle></div-->
        </div>
        <div fxLayout="row wrap" fxFlex="85" fxFlex.xs="80" fxFlex.sm="80">
            <div fxFlex="50" fxFlex.xs="100">
                <div class="txt_medium marge_devis">
                    <span>N°</span>
                    <span class="data_dynamic_done resultat_marge_left" style="margin-right: 15px;">{{ detail?._id }}</span>
                    <span>Visite le: {{detail?.date | date: 'dd/MM/yyyy'}}</span>
                    <!--span style="margin-left:25px"><mat-slide-toggle></mat-slide-toggle></span-->
                </div>
                <div fxLayout="row wrap" class="marge_devis">
                    <div class="txt_medium" fxFlex="30" fxFlex.gt-lg="20">Prestataire :</div>
                    <div fxFlex="70" fxFlex.gt-lg="80">
                      <div class="marge_presta" fxLayoutAlign="left">
                        <span>{{detail.prestataireId.company}} </span>
                        <span class="resultat_marge_left widthimg"><img class="stars_pos" src="../../../../../assets/img/icones/stars.jpg"></span>
                      </div>
                    </div>
                </div>
            </div>
            <div fxFlex="50">
            </div>
        </div>
        <div fxFlex="15" fxFlex.xs="20" fxFlex.sm="20"></div>
        <div fxFlex="85" fxFlex.xs="80" fxFlex.sm="80">
            <div fxLayout="row wrap">
                <div class="txt_medium" fxFlex="60" fxFlex.xs="100">
                    <div class="marge_devis">
                      <span>Désordre :</span>
                      <span class="data_dynamic_done txt_small resultat_marge_left">{{ detail?.desordre }}</span>
                    </div>
                    <div class="marge_devis">
                        <span>Descriptif :</span>
                        <span class="data_dynamic_done txt_small resultat_marge_left">{{ detail?.description }}</span>
                    </div>
                    <div>
                        <span>Montant ttc des travaux :</span>
                        <span class="data_dynamic_done txt_small resultat_marge_left">{{ detail?.evaluationTTC }}</span>
                        <span class="resultat_marge_left txt_small">€</span>
                    </div>
                    <div class="marge_devis">
                        <span>Corps d'état :</span>
                        <span class="data_dynamic_done txt_small resultat_marge_left">{{ detail?.corpsEtat }}</span>
                    </div>
                    <div>
                        <span>Conformité des travaux réalisés :</span>
                        <span class="data_dynamic_done txt_small resultat_marge_left">{{ detail?.conformite ? 'oui' : 'non' }}</span>
                    </div>
                    <div class="marge_devis">
                        <span>Qualité des travaux réalisés :</span>
                        <star-rating  value="{{detail?.rate}}" checkedcolor="gold" uncheckedcolor="gray" totalstars="{{5}}" readonly="true"></star-rating>
                    </div>
                    <div class="marge_devis">
                        <span>Remarques :</span>
                        <span class="data_dynamic_done txt_small resultat_marge_left">{{ detail?.remarque }}</span>
                    </div>
                    <div fxLayout="row wrap" class="marge_add">
                        <div fxFlex="70">

                        <div class="" style="margin-top: 10px;">
                                <span class="" gallerize="gallery_3">
                                    <span *ngIf="detail?.images_bf">
                                      <img *ngIf="detail?.images_bf[0]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+detail?.images_bf[0]" style="width: 50px; height: 50px; margin-right: 5px;">
                                      <img *ngIf="detail?.images_bf[1]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+detail?.images_bf[1]" style="width: 50px; height: 50px; margin-right: 5px;">
                                      <img *ngIf="detail?.images_bf[2]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+detail?.images_bf[2]" style="width: 50px; height: 50px; margin-right: 5px;">
                                    </span>
                                </span>
                        </div>

                        <div fxFlex="20" fxLayoutAlign="start center" class="ajouter_img">
                          Avant
                        </div>
                      </div>
                    </div>
                    <div fxLayout="row wrap" class="marge_add">
                      <div fxFlex="70">

                        <div class="" style="margin-top: 10px;">
                                <span class="" gallerize="gallery_4">
                                    <span *ngIf="detail?.images_af">
                                      <img *ngIf="detail?.images_af[0]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+detail?.images_af[0]" style="width: 50px; height: 50px; margin-right: 5px;">
                                      <img *ngIf="detail?.images_af[1]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+detail?.images_af[1]" style="width: 50px; height: 50px; margin-right: 5px;">
                                      <img *ngIf="detail?.images_af[2]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+detail?.images_af[2]" style="width: 50px; height: 50px; margin-right: 5px;">
                                    </span>
                                </span>
                        </div>

                        <div fxFlex="20" fxLayoutAlign="start center" class="ajouter_img">
                          Après
                        </div>
                      </div>
                    </div>
                  </div>
                  <div fxFlex="40" fxFlex.xs="100" style="text-align: right; padding-right: 15px;">
                    <div class="txt_medium marge_devis">
                      <span>Télécharger devis</span>
                      <a [href]="'https://copro-back.cantem.fr/uploads/devis/' + detail?.devisPDF" target="_blank">
                        <span class="resultat_marge_left"><mat-button-toggle><img src="../../../../../assets/img/icones/upload.png"></mat-button-toggle></span>
                      </a>
                      <!--mat-button-toggle class="icon_left icon_upload" datasrc="https://copro-back.cantem.fr/uploads/devis/{{detail.devisPDF}}"><img src="../../../../../assets/img/icones/upload.png"></mat-button-toggle-->
                    </div>
                    <div class="txt_medium marge_devis">
                      <span>Télécharger facture</span>
                      <a [href]="'https://copro-back.cantem.fr/uploads/devis/' + detail?.facturePDF" target="_blank">
                        <span class="resultat_marge_left"><mat-button-toggle><img src="../../../../../assets/img/icones/upload.png"></mat-button-toggle></span>
                      </a>
                      <!--mat-button-toggle class="icon_left icon_upload" datasrc="https://copro-back.cantem.fr/uploads/devis/{{detail.facturePDF}}"><img src="../../../../../assets/img/icones/upload.png"></mat-button-toggle-->
                    </div>
                  </div>
                </div>
              </div>
              <hr style="border: .5vm solid; width: 100%"/>
            </div>
          </mat-tab>

          <!------------------- TAB 4 DESCRIPTION -------------------------->

          <mat-tab label="Description">
            <div *ngFor="let detail of resultDescription" fxLayout="row wrap" class="marge_tab">
              <div fxFlex="60" fxFlex.xs="100">

                <div fxLayout="row wrap">
                  <div>
                    <span class="font_content">Fait le :</span>
                    <span class="font_resultat_tab  data_dynamic_done resultat_marge_left">{{detail?.faitLe | date: 'dd/MM/yyyy'}}</span>
                  </div>

                </div>
                <div fxLayout="row wrap">
                  <div fxFlex="100" class="title_descr font_immeuble">Façade avant</div>
                  <div class="txt_medium" fxFlex="90" *ngIf="detail.facadeRue">
                    <div fxLayout="row wrap"><span fxFlex="70">Etat général</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ detail?.facadeRue.etatGen }}</span></div>
                    <div fxLayout="row wrap"><span fxFlex="70">Commerces</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail?.facadeRue.commerces ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap" *ngIf="detail.facadeRue.commerces"><span fxFlex="70">Nature des commerces</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ detail?.facadeRue.natCommerce }}</span></div>
                  <div fxLayout="row wrap"><span fxFlex="70">Commerces contigus</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.facadeRue.commercesContiguite ? 'oui' : 'non'}}</span></div>
                  <div fxLayout="row wrap" style="padding-left: 5px;" *ngIf="detail.facadeRue.commercesContiguite"><span fxFlex="70">Nature des commerces</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ detail.facadeRue.natCommerceContiguite }}</span></div>

                </div>
            </div>
            <div fxLayout="row wrap">
                <div fxFlex="100" class="title_descr font_immeuble">Façade arrière</div>
                <div class="txt_medium" fxFlex="90" *ngIf="detail.facadeRue">
                    <div fxLayout="row wrap"><span fxFlex="70">Accès</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail?.facadeRue.access ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap"><span fxFlex="70">Etat général</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ detail?.facadeRue.etatGen }}</span></div>
                </div>
            </div>

            <div fxLayout="row wrap">
                <div class="txt_medium" fxFlex="90">
                    <div  class="title_descr font_immeuble" fxLayout="row wrap"><span fxFlex="70">Nombre d'étages</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail?.nbEtages}}</span></div>
                </div>
            </div>

            <div fxLayout="row wrap" *ngFor="let entree of resultEntree">
                <div fxFlex="100" class="title_descr font_immeuble">Entrée</div>
                <div class="txt_medium" fxFlex="90">

                  <div fxLayout="row wrap"><span fxFlex="70">Porte d'entrée avec :</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{entree?.specAccess1 === 'autre(précisez)' ? entree?.specAutre1 : entree?.specAccess1}}</span></div>
                  <div fxLayout="row wrap"  style="padding-left: 5px;"><span fxFlex="70">Porte vitrée</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{entree.porteVitree ? 'oui' : 'non'}}</span></div>

                  <div fxLayout="row wrap"><span fxFlex="70">Second contrôle accès</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{entree.isAccess2 ? 'oui' : 'non'}}</span></div>
                  <div fxLayout="row wrap"><span fxFlex="70">Porte d'entrée avec </span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{entree?.specAccess2 === 'autre(précisez)' ? entree?.specAutre2 : entree?.specAccess2}}</span></div>
                  <div fxLayout="row wrap"  style="padding-left: 5px;"><span fxFlex="70">Porte vitrée</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{entree.porteVitreeSecond ? 'oui' : 'non'}}</span></div>

                  <div fxLayout="row wrap"><span fxFlex="70">Ascenseur</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{entree.Ascenceur ? 'Oui' : 'Non'}} </span></div>
                  <div fxLayout="row wrap" *ngIf="entree.Ascenceur"><span fxFlex="70">Etat global des portes et de la cabine d'ascenseur :</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ entree.etatAscenceur ? entree.etatAscenceur : 'Pas indiqué'}}</span></div>
                  <div fxLayout="row wrap"><span fxFlex="70">Alarme incendie</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{entree.alarmeIncendie ? 'oui' : 'non'}}</span></div>
                  <div fxLayout="row wrap"><span fxFlex="70">Caméra de surveillance</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{entree.cameraVideo ? 'oui' : 'non'}}</span></div>
                  <div fxLayout="row wrap"><span fxFlex="70">Commande de désenfumage</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{entree.cmdDesenfumage ? 'oui' : 'non'}}</span></div><br>

                  <div fxLayout="row wrap" *ngIf="entree.escalier"><span fxFlex="70">Escalier</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">oui</span></div>

                  <div fxLayout="row wrap" *ngIf="entree.escalier"><span fxFlex="70">Etat général des escalier</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ entree.escalier.etatGenEscaliers }}</span></div>
                  <div fxLayout="row wrap" *ngIf="entree.escalier"><span fxFlex="70">Etat général des murs</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ entree.escalier.etatGenMurs }}</span></div>
                  <div fxLayout="row wrap" *ngIf="entree.escalier"><span fxFlex="70">Etat général du plafond</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ entree.escalier.etatGenPlafonds }}</span></div>
                  <div fxLayout="row wrap" *ngIf="entree.escalier"><span fxFlex="70">Nature des marches</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ entree.escalier.natureMarches }}</span></div>
                  <div fxLayout="row wrap" *ngIf="entree.escalier"><span fxFlex="70">Nature du palier</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ entree.escalier.naturePaliers }}</span></div>
                  <div fxLayout="row wrap" *ngIf="entree.escalier"><span fxFlex="70">Extincteurs</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{entree.escalier.extincteurs ? 'oui' : 'non'}}</span></div>
                  <div fxLayout="row wrap" *ngIf="entree.escalier && entree.escalier.extincteurs" style="padding-left: 5px;"><span fxFlex="70">Visite moins de 12 mois</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{entree.escalier.visite12mois ? 'oui' : 'non'}}</span></div><br>

                </div>
            </div>
            <div fxLayout="row wrap">
              <div fxFlex="60" style="white-space: nowrap;" class="title_descr font_immeuble">Cave</div> <div *ngIf="detail.cave === undefined || detail.cave.presence === false" fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">non</div>
              <div class="txt_medium500" fxFlex="90" *ngIf="detail.cave && detail.cave.presence">
                <div fxLayout="row wrap"><span fxFlex="70">Présence de caves</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.cave.presence ? 'oui' : 'non'}}</span></div>
                <div fxLayout="row wrap"><span fxFlex="70">Caves accessibles</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.cave.accessible ? 'oui' : 'non'}}</span></div>
                <div fxLayout="row wrap"><span fxFlex="70">Encombrement des caves</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ detail.cave.encombrement }}</span></div>
                <div fxLayout="row wrap"><span fxFlex="70">Extincteur</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.cave.extincteurs ? 'oui' : 'non'}}</span></div>
                <div fxLayout="row wrap"  *ngIf="detail.cave.extincteurs" style="padding-left: 5px;"><span fxFlex="70">Visite moins de 12 mois</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.cave.visite12mois ? 'oui' : 'non'}}</span></div>
                <div fxLayout="row wrap"><span fxFlex="70">Nombre de niveaux</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ detail.cave.nbSousSol }}</span></div>
              </div>
            </div>
            <div fxLayout="row wrap">
              <div fxFlex="60" style="white-space: nowrap;" class="title_descr font_immeuble">Chaufferie</div> <div *ngIf="detail.chaufferie === undefined" fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">non</div>
              <div class="txt_medium500" fxFlex="90" *ngIf="detail.chaufferie">
                <div fxLayout="row wrap"  *ngIf="detail.chaufferie.collective"><span fxFlex="70">Chaufferie collective</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.chaufferie.collective ? 'oui' : 'non'}}</span></div>
                <div fxLayout="row wrap"  *ngIf="detail.chaufferie.collective"><span fxFlex="70">Avez-vous eu accès ?</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.chaufferie.Access ? 'oui' : 'non'}}</span></div>
                <div fxLayout="row wrap"  *ngIf="detail.chaufferie.collective" style="padding-left: 5px;"><span fxFlex="70">Extincteur extérieur</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.chaufferie.exitincteursExt ? 'oui' : 'non'}}</span></div>
                <div fxLayout="row wrap" *ngIf="detail.chaufferie.collective && detail.chaufferie.exitincteursExt" style="padding-left: 10px;"><span fxFlex="70"> Visite moins de 12 mois</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.chaufferie.visite12moisCollecExt ? 'oui' : 'non'}}</span></div>
                <div fxLayout="row wrap" *ngIf="detail.chaufferie.Access && detail.chaufferie.collective" style="padding-left: 5px;"><span fxFlex="70">Extincteur intérieur</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.chaufferie.exitincteursInt ? 'oui' : 'non'}}</span></div>
                <div fxLayout="row wrap" *ngIf="detail.chaufferie.Access && detail.chaufferie.collective && detail.chaufferie.exitincteursInt" style="padding-left: 10px;"><span fxFlex="70"> Visite moins de 12 mois</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.chaufferie.visite12moisCollecInt ? 'oui' : 'non'}}</span></div>
                <div fxLayout="row wrap" *ngIf="detail.chaufferie.Access && detail.chaufferie.collective" style="padding-left: 5px;"><span fxFlex="70">Date de la dernière visite</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.chaufferie.dateLastVisite | date: 'dd/MM/yyyy'}}</span></div>
                <div fxLayout="row wrap" *ngIf="detail.chaufferie.Access && detail.chaufferie.collective" style="padding-left: 5px;"><span fxFlex="70">Présence de carnet d'entretien</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.chaufferie.carnet ? 'oui' : 'non'}}</span></div>
                <div fxLayout="row wrap"><span fxFlex="70" *ngIf="detail.chaufferie.collective && detail.chaufferie.genre">Type de chaufferie</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ detail.chaufferie.genre }}</span></div>

                <div fxLayout="row wrap" *ngIf="!detail.chaufferie.collective"><span fxFlex="70">Chaufferie individuelle</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">oui</span></div>
                <div fxLayout="row wrap" style="padding-left: 5px;" *ngIf="!detail.chaufferie.collective"><span fxFlex="70">Extincteur extérieur</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.chaufferie.exitincteursIndivExt ? 'oui' : 'non'}}</span></div>
                <div fxLayout="row wrap" style="padding-left: 10px;" *ngIf="!detail.chaufferie.collective && detail.chaufferie.exitincteursIndivExt"><span fxFlex="70"> Visite moins de 12 mois</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.chaufferie.visite12moisIndivExt ? 'oui' : 'non'}}</span></div>
                <div fxLayout="row wrap"><span fxFlex="70" *ngIf="!detail.chaufferie.collective && detail.chaufferie.genreIndiv">Type de chaufferie</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ detail.chaufferie.genreIndiv }}</span></div>
              </div>
            </div>
            <div fxLayout="row wrap">
              <div fxFlex="60" style="white-space: nowrap;" class="title_descr font_immeuble">Parking souterrain</div> <div *ngIf="detail.parkingST === undefined || detail.parkingST && detail.parkingST.presence" fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">non</div>
              <div class="txt_medium500" fxFlex="90" *ngIf="detail.parkingST && detail.parkingST.presence">
                <div fxLayout="row wrap"><span fxFlex="70">Présence de parking souterrain</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.parkingST.presence ? 'oui' : 'non'}}</span></div>
                <div fxLayout="row wrap"><span fxFlex="70">Avez-vous eu accès ?</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.parkingST.access ? 'oui' : 'non'}}</span></div>
                <div fxLayout="row wrap"><span fxFlex="70">Contrôl d'accès</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.parkingST.controlAccess ? 'oui' : 'non'}}</span></div>
                <div fxLayout="row wrap"><span fxFlex="70">Etat de la porte</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.parkingST.etatPorte === 'sansObjet' ? "Sans objet" : detail.parkingST.etatPorte}}</span></div>
                <div fxLayout="row wrap"><span fxFlex="70">Nombre de niveaux</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.parkingST.nbNiveaux}}</span></div>
                <div fxLayout="row wrap"><span fxFlex="70">Extincteurs</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.parkingST.extincteurs ? 'oui' : 'non'}}</span></div>
                <div fxLayout="row wrap" *ngIf="detail.parkingST.extincteurs"><span fxFlex="70">Visite moins de 12 mois</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.parkingST.visite12mois ? 'oui' : 'non'}}</span></div>
                <div fxLayout="row wrap"><span fxFlex="70">Plan d'évacuation</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.parkingST.planEvac ? 'oui' : 'non'}}</span></div>
                <div fxLayout="row wrap"><span fxFlex="70">Présence d'un bac à sable</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.parkingST.bacSable ? 'oui' : 'non'}}</span></div>
              </div>
            </div>
        </div>
        <div fxFlex="40" fxFlex.xs="100">
            <div fxLayout="row wrap" *ngIf="detail.image.facadeRue.length !== 0" >
                <div class="title_descr txt_medium" fxFlex="100">Façades</div>
                <!------Gallery Images-------->
                <div fxFlex="100">
                    <span class="" gallerize="gallery_1">
                        <span *ngFor="let img of dataFacades" ><img *ngIf="img.srcUrl !== 'https://copro-back.cantem.fr/uploads/batiment/undefined'" class="sizeDescription"  [src]="img?.srcUrl"> </span>
                    </span>
                  </div>
                </div>

                <div fxLayout="row wrap">
                  <div fxLayout="row wrap">
                    <div class="title_descr txt_medium" fxLayoutAlign="start" fxFlex="100%">Entrée{{resultEntree?.length > 1 ? 's' : ''}}</div>
                    <div fxFlex="100" fxLayout="row wrap" style="display: flex; justify-content: space-between;">
                      <div *ngFor="let entree of resultEntree; let indexPhotoEntree = index;" fxLayout="row wrap" >
                        <div
                          style="margin-bottom: 5px;"
                          *ngIf="entree.photosEntree && entree.photosEntree.length || entree.photosAscenseur && entree.photosAscenseur.length">
                          <span *ngIf="resultEntree?.length > 1"  fxLayoutAlign="start" style="text-align: left; font-size: 12px;margin-bottom: 5px;">Entrée {{indexPhotoEntree + 1}}</span>
                          <div *ngIf="entree.photosEntree && entree.photosEntree.length">
                            <span fxLayoutAlign="start" style="; font-size: 11px; margin: 0 0 3px 10px">Photos de l'entrée</span>
                          </div>
                          <span class="" gallerize="gallery_{{indexPhotoEntree + 14}}">
                        <span  *ngFor="let photoEntreeUrl of entree.photosEntree; let i = index;" >
                            <img [src]="'https://copro-back.cantem.fr/uploads/batiment/'+ photoEntreeUrl" style="width: 50px; height: 50px; margin-right: 5px;">
                        </span>
                      </span>

                      <div *ngIf="entree.photosAscenseur && entree.photosAscenseur.length">
                        <span fxLayoutAlign="start" style="; font-size: 11px; margin: 0 0 3px 10px;" >Photos de l'ascenseur</span>
                      </div>
                      <span class="" gallerize="gallery_{{indexPhotoEntree + 16}}">
                        <span  *ngFor="let photoAscenseurUrl of entree.photosAscenseur">
                          <img [src]="'https://copro-back.cantem.fr/uploads/batiment/' + photoAscenseurUrl" style="width: 50px; height: 50px; margin-right: 5px;">
                        </span>
                      </span>
                    </div>
                  </div>
                  <!-- <span fxLayoutAlign="start" *ngFor="let entree of FormService.batiments[y][3].tab[i]" style="text-align: left;"> -->
                </div>
                <!-------------->
              </div>
            </div>
            <div fxLayout="row wrap" *ngIf="detail.image.caves.length !== 0">
                <div class="title_descr txt_medium" fxFlex="100%">Caves</div>
                <!------Gallery Images-------->
                <div fxFlex="100">
                    <span class="" gallerize="gallery_4">
                        <span *ngFor="let img of dataCaves"><img class="sizeDescription" *ngIf="img.srcUrl !== 'https://copro-back.cantem.fr/uploads/batiment/undefined'" [src]="img?.srcUrl"></span>
                    </span>
                </div>
                <!-------------->
            </div>
            <div fxLayout="row wrap" *ngIf="detail.image.parking.length !== 0">
                <div class="title_descr txt_medium" fxFlex="100%">Parking sous terrain</div>
                <!------Gallery Images-------->
                <div fxFlex="100">
                    <span class="" gallerize="gallery_5">
                        <span *ngFor="let img of dataParking"><img class="sizeDescription" *ngIf="img.srcUrl !== 'https://copro-back.cantem.fr/uploads/batiment/undefined'" [src]="img?.srcUrl"></span>
                    </span>
                </div>
                <!-------------->
            </div>
            <div fxLayout="row wrap"  *ngIf="detail.image.environnement.length !== 0">
                <div class="title_descr txt_medium" fxFlex="100%">Environnement</div>
                <!------Gallery Images-------->
                <div fxFlex="100">
                    <span class="" gallerize="gallery_6">
                        <span *ngFor="let img of dataEnvironnement"><img class="sizeDescription" *ngIf="img.srcUrl !== 'https://copro-back.cantem.fr/uploads/batiment/undefined'" [src]="img?.srcUrl"></span>
                    </span>
                </div>
                <!-------------->
            </div>
            <div fxLayout="row wrap" *ngIf="detail.image.contiguite.length !== 0">
                <div class="title_descr txt_medium" fxFlex="100%">Contiguïté</div>
                <!------Gallery Images-------->
                <div fxFlex="100">
                    <span class="" gallerize="gallery_7">
                        <span *ngFor="let img of dataContiguite"><img class="sizeDescription" *ngIf="img.srcUrl !== 'https://copro-back.cantem.fr/uploads/batiment/undefined'" [src]="img?.srcUrl"></span>
                    </span>
                </div>
                <!-------------->
            </div>
        </div>
    </div>
</mat-tab>

<!------------------- TAB 5 CARNET -------------------------->

<mat-tab label="Carnet">
    <div fxLayout="row wrap" class="marge_tab">
        <div fxFlex="100">
            <div class="title_descr font_immeuble">Synthèse</div>
            <div class="txt_medium500">
                <span>Nombre d'évaluation :</span>
                <span class="data_dynamic txt_small resultat_marge_left">{{nbEval}}</span>
            </div>
            <div class="txt_medium500">
                <span>Nombre de devis :</span>
                <span class="data_dynamic txt_small resultat_marge_left">{{resultDevis.length}}</span>
            </div>
            <div class="txt_medium500">
                <span>Nombre de réception :</span>
                <span class="data_dynamic txt_small resultat_marge_left">{{resultReception.length}}</span>
            </div>
            <div class="title_under" fxLayoutAlign="left center">
                <img src="../../../../../assets/img/icones/tool-black-medium.png">
                <span class="data_dynamic font_immeuble resultat_marge_left"><!--{{ detail.prixTravaux }}--></span>
                <span class="resultat_marge_left font_immeuble">{{travaux}} €</span>
            </div>
            <!--div class="txt_medium">
                <span>Tableau de Synthèse</span>
                <mat-button-toggle class="icon_upload icon_left"><img src="../../../../../assets/img/icones/upload.png"></mat-button-toggle>
            </div-->
        </div>
    </div>
    <div fxLayout="row wrap">
        <div fxFlex="100" fxLayoutAlign="center">
            <div class="title_travaux">Travaux à faire ({{resultCarnet.length}})</div>
        </div>
        <div *ngFor="let detail of resultCarnet" fxFlex="50%" fxFlex.xs="100%" fxFlex.md="100%" fxFlex.sm="100%" fxFlex.lg="50%">
            <div fxFlex="90%" class="bloc_travaux">
                <div class="font_immeuble data_dynamic"><!--{{ detail.lieuxTravaux }}--></div>
                <div class="font_immeuble marge_devis">
                    <span>Le</span>
                    <span class="data_dynamic resultat_marge_left">{{detail?.date | date: 'dd/MM/yyyy'}}</span>
                </div>
                <div>
                    <span class="font_content">Désordre :</span>
                    <span class="font_resultat_tab  data_dynamic_done resultat_marge_left">{{ detail?.desordre }}</span>
                </div>
                <div>
                    <span class="font_content">Situation :</span>
                    <span class="font_resultat_tab  data_dynamic_done resultat_marge_left">{{ detail?.situation }}</span>
                </div>
                <div class="bloc-right maxWidth350">
                    <span class="font_content">Descriptif :</span>
                    <span class="font_resultat_tab  data_dynamic_done resultat_marge_left">{{ detail?.description }}</span>
                </div>
                <div>
                    <span class="font_content">Métrages :</span>
                    <span class="font_resultat_tab  data_dynamic_done resultat_marge_left"></span>{{ detail?.metrages }}<span class="resultat_marge_left">㎡</span>
                </div>
                <div>
                    <span class="font_content">Corps d'état : </span>
                    <span class="font_resultat_tab  data_dynamic_done resultat_marge_left">{{ detail?.corpsEtat }}</span>
                </div>
                <div>
                    <span class="font_content">Commentaire :</span>
                    <span class="font_resultat_tab  data_dynamic_done resultat_marge_left"></span>
                    <span class="font_resultat_tab  data_dynamic_done resultat_marge_left">{{ detail?.commentaire }}</span>
                </div>

                <div class="txt_medium500 marge_devis font_immeuble">
                    <span>Photos :</span>
                    <span class="data_dynamic txt_small resultat_marge_left"><!--{{ detail.carnetPhoto }}--></span>
                </div>

                <div fxLayout="row wrap" class="marge_add">
                    <div fxFlex="100">
                        <div fxFlex="100">
                            <span class="" gallerize="gallery{{ detail._id }}">
                                <span *ngIf="detail.images">
                                    <img *ngIf="detail.images[0]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+detail?.images[0]" style="width: 50px; height: 50px; margin-right: 5px;">
                                    <img *ngIf="detail.images[1]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+detail?.images[1]" style="width: 50px; height: 50px; margin-right: 5px;">
                                    <img *ngIf="detail.images[2]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+detail?.images[2]" style="width: 50px; height: 50px; margin-right: 5px;">
                                </span>
                            </span>
                        </div>
                    </div>
                  </div>
                  <div class="font_immeuble marge_devis">
                    <span>Nombre de réponse :</span>
                    <span class="data_dynamic txt_small resultat_marge_left">{{detail.devis.length}}</span>
                </div>
            </div>
        </div>
    </div>
    <br/>
    <hr style="border: 1px solid gray; align-self: center"/>
    <div fxLayout="row wrap">
      <div fxFlex="100" fxLayoutAlign="center">
        <div class="title_travaux">Travaux finis ({{resultReception.length}})</div>
      </div>
      <div *ngFor="let detail of resultReception" fxFlex="50%" fxFlex.xs="100%" fxFlex.md="100%" fxFlex.sm="100%" fxFlex.lg="50%">
        <div fxFlex="90%" class="bloc_travaux" style="margin-top: 5px;">
          <div class="font_immeuble data_dynamic" *ngIf="detail?.refDesordre">N° {{ detail?.refDesordre}}</div>
          <div class="font_immeuble marge_devis">
            <span>Le</span>
            <span class="data_dynamic resultat_marge_left">{{detail?.date | date: 'dd/MM/yyyy'}}</span>
          </div>
          <div>
            <span class="font_content">Désordre :</span>
            <span class="font_resultat_tab  data_dynamic_done resultat_marge_left">{{ detail?.desordre }}</span>
          </div>
          <div>
            <span class="font_content">Situation :</span>
            <span class="font_resultat_tab  data_dynamic_done resultat_marge_left">{{ detail?.situation }}</span>
          </div>
          <div class="bloc-right maxWidth350">
            <span class="font_content">Descriptif :</span>
            <span class="font_resultat_tab  data_dynamic_done resultat_marge_left">{{ detail?.description }}</span>
          </div>
          <div>
            <span class="font_content">Métrages :</span>
            <span class="font_resultat_tab  data_dynamic_done resultat_marge_left"></span>{{ detail?.metrages }}<span class="resultat_marge_left">㎡</span>
          </div>
          <div>
            <span class="font_content">Corps d'état :</span>
            <span class="font_resultat_tab  data_dynamic_done resultat_marge_left">{{ detail?.corpsEtat }}</span>
          </div>
          <div>
            <span class="font_content">Commentaire :</span>
            <span class="font_resultat_tab  data_dynamic_done resultat_marge_left"></span>
            <span class="font_resultat_tab  data_dynamic_done resultat_marge_left">{{ detail?.remarque }}</span>
          </div>
          <div>
            <span class="font_content">Conformité des travaux réalisés :</span>
            <span class="font_resultat_tab  data_dynamic_done resultat_marge_left">{{detail?.conformite ? 'oui' : 'non'}}</span>
          </div>
          <div>
            <span class="font_content">Qualité des travaux réalisés :</span>
            <star-rating value="{{detail?.rate}}" checkedcolor="gold" uncheckedcolor="gray" totalstars="{{5}}" readonly="true"></star-rating>
          </div>
          <div fxLayout="row wrap" class="marge_add">
            <div fxFlex="70">
              <span class="font_content">Photos Avant: </span>
            </div>
            <div fxFlex="70">
              <div class="" style="margin-top: 10px;">
                          <span class="" gallerize="gallery_3">
                            <span *ngIf="detail?.images_bf">
                              <img *ngIf="detail?.images_bf[0]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+detail?.images_bf[0]" style="width: 50px; height: 50px; margin-right: 5px;">
                              <img *ngIf="detail?.images_bf[1]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+detail?.images_bf[1]" style="width: 50px; height: 50px; margin-right: 5px;">
                              <img *ngIf="detail?.images_bf[2]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+detail?.images_bf[2]" style="width: 50px; height: 50px; margin-right: 5px;">
                            </span>
                          </span>
              </div>
            </div>
          </div>
          <div fxLayout="row wrap" class="marge_add">
            <div fxFlex="70">
              <span class="font_content">Photos Après: </span>
            </div>
            <div fxFlex="70">
              <div class="" style="margin-top: 10px;">
                          <span class="" gallerize="gallery_4">
                            <span *ngIf="detail?.images_af">
                              <img *ngIf="detail?.images_af[0]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+detail?.images_af[0]" style="width: 50px; height: 50px; margin-right: 5px;">
                              <img *ngIf="detail?.images_af[1]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+detail?.images_af[1]" style="width: 50px; height: 50px; margin-right: 5px;">
                              <img *ngIf="detail?.images_af[2]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+detail?.images_af[2]" style="width: 50px; height: 50px; margin-right: 5px;">
                            </span>
                          </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</mat-tab>

</mat-tab-group>
</mat-card-content>
</mat-card>
</div>
</div>
