import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-desordres-dialog',
  templateUrl: './desordres-dialog.component.html',
  styleUrls: ['./desordres-dialog.component.scss']
})
export class DesordresDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
