<!-- ON COURS SELECTION ONGLET MENU-->
<div id="en_cours_onglet">
  <div fxLayout="row wrap">
    <div fxLayoutAlign.xs="center" fxFlex="33" fxFlex.xs="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.lg="40" class="padding-card padding-card-pos">
      <div class="liste_fixe">
        <mat-nav-list>
          <mat-expansion-panel>
            <mat-expansion-panel-header *ngIf="role === 'syndic'" class="btn_immeuble">
              <div class="box_en_cours" fxLayoutAlign="start center">
                <mat-icon class="icon_immeuble icon_add_parc">add_box</mat-icon>
                <span class="add-imm span_en_cours">Ajouter immeuble(s)</span>
              </div>
            </mat-expansion-panel-header>
            <a class="list_profil btn_immeuble" mat-list-item routerLink routerLink="../ajouter-un-immeuble">Ajouter un immeuble</a>
          </mat-expansion-panel>
        </mat-nav-list>
      </div>
    </div>

    <div fxLayoutAlign.xs="center" fxFlex="66" fxFlex.xs="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.lg="60" class="padding-card padding-card-pos">
      <mat-card-content fxFlex="94">
        <mat-form-field fxLayoutAlign="right center" class="input_search" appearance="outline">
          <mat-label>N° immeuble, rue...</mat-label>
          <input matInput [(ngModel)]="searchVal" (keyup)="searchEngine()"/>
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </mat-card-content>
    </div>
  </div>

  <div fxLayout="row wrap">
    <div fxLayoutAlign.xs="center" fxLayoutAlign.sm="center" fxFlex="33" fxFlex.xs="100" fxFlex.md="33" fxFlex.sm="50" fxFlex.lg="33" fxFlex.gt-lg="25" class="padding-card padding-card-pos override-display-block" *ngFor="let detail of resultSearch; let i = index">
      <mat-card fxFlex="100" *ngIf="!detail.visite && !detail.ajout" id={{detail.id_el}} (click)="detail.dateVisite ? info(detail.id_el) : null">
        <mat-card-content>
          <div fxLayoutAlign="center" class="btn_couleur_hover">
            <mat-button-toggle style="position: absolute;z-index: 9999;" *ngIf="!detail.dateDemandeVisite || detail.dateVisite" class="ic_croix btn_without_bg" (click)="onEvent($event)" (click)="confirmation($event)"><img  src="../../../../../assets/img/icones/croix.png"></mat-button-toggle>

            <div *ngIf="!detail.imgProfil" class="img_virtuelle res_img" [ngStyle]="{ 'background-size': 'cover', 'background-image': 'url(../../../../../assets/img/photo/immeuble-base.jpg)'}">
              <div *ngIf="!detail.dateDemandeVisite && !detail.dateVisite && detail.sansSuite === false" fxLayoutAlign="center" style="margin-top: 20px;">
                <mat-button-toggle  (click)="onEvent($event)" mat-raised-button (click)="detail.visite = true" class="pointer btn">Demander la visite</mat-button-toggle>
              </div>
              <div *ngIf="detail.dateDemandeVisite && !detail.dateVisite" fxLayoutAlign="center" style="color: #888; background-color: rgba(248, 213, 126, 0.3); margin-top: 25px;">En cours de visite</div>
              <div *ngIf="detail.dateDemandeVisite && !detail.dateVisite" fxLayoutAlign="center" style="margin-top: 25px;">
                <mat-button-toggle  (click)="onEvent($event)" (click)="confirmation2(detail.id_el, i)" mat-raised-button class="pointer btn-warning">Annuler la visite</mat-button-toggle>
              </div>
              <div *ngIf="!detail.sansSuite && detail.perdu" fxLayoutAlign="center" style="color: #888; background-color: rgba(248, 213, 126, 0.3); margin-top: 25px;"><b>Perdue</b></div>
              <div *ngIf="detail.sansSuite && !detail.perdu" fxLayoutAlign="center" style="color: #888; background-color: rgba(248, 213, 126, 0.3); margin-top: 25px;"><b>Sans Suite</b></div>

              <!--div *ngIf="detail.dateVisite" fxLayoutAlign="center" style="margin-top: 20px;">
                <mat-button-toggle (click)="info(detail.id_el)" class="pointer btn">Plus d'informations</mat-button-toggle>
              </div-->

              <mat-button-toggle *ngIf="!detail.dateDemandeVisite && !detail.dateVisite" (click)="onEvent($event)" mat-raised-button (click)="detail.ajout = true"  class="btn_blanc pos_btn">
                <img mat-raised-button (click)="onEvent($event)" (click)="detail.ajout = true" src="../../../../../assets/img/icones/user_encours.png">
                CP
                <img mat-raised-button (click)="onEvent($event)" (click)="detail.ajout = true" src="../../../../../assets/img/icones/plus_encours.png">
              </mat-button-toggle>
            </div>

            <div *ngIf="detail.imgProfil" class="img_virtuelle res_img pointer" [ngStyle]="{ 'background-size': 'cover', 'background-image': 'url(https://copro-back.cantem.fr/uploads/batiment/'+ detail.src_img +')'}">
              <div *ngIf="detail.dateDemandeVisite === undefined && !detail.sansSuite" fxLayoutAlign="center" style="margin-top: 20px;">
                <mat-button-toggle *ngIf="detail.dateDemandeVisite === undefined"  (click)="onEvent($event)" mat-raised-button (click)="detail.visite = true" class="pointer btn">Demander la visite</mat-button-toggle>
              </div>
              <div *ngIf="detail.dateDemandeVisite && !detail.dateVisite" fxLayoutAlign="center" style="color: #888; background-color: rgba(248, 213, 126, 0.3); margin-top: 25px;">En cours de visite</div>
              <div *ngIf="!detail.sansSuite && detail.perdu" fxLayoutAlign="center" style="color: #888; background-color: rgba(248, 213, 126, 0.3); margin-top: 25px;"><b>Perdue</b></div>
              <div *ngIf="detail.sansSuite" fxLayoutAlign="center" style="color: #888; background-color: rgba(248, 213, 126, 0.3); margin-top: 25px;"><b>Sans Suite</b></div>

              <!--div *ngIf="detail.dateVisite" fxLayoutAlign="center" style="margin-top: 20px;">
                <mat-button-toggle (click)="info(detail.id_el)" class="pointer btn">Plus d'informations</mat-button-toggle>
              </div-->

              <mat-button-toggle *ngIf="detail.dateDemandeVisite && detail.dateVisite" (click)="onEvent($event)" mat-raised-button (click)="detail.ajout = true"  class="btn_blanc pos_btn">
                <img mat-raised-button (click)="onEvent($event)" (click)="detail.ajout = true" src="../../../../../assets/img/icones/user_encours.png">
                CP
                <img mat-raised-button (click)="onEvent($event)" (click)="detail.ajout = true" src="../../../../../assets/img/icones/plus_encours.png">
              </mat-button-toggle>
            </div>
          </div>

          <div fxLayout="row wrap max_width" fxLayoutAlign="center" class="font_en_cours pointer">
            <div class="res_adresse  max_width" fxFlex="70">
              <div class="data_dynamic_done">{{detail.address}}</div>
              <div class="data_dynamic_done">{{detail.codePostal}} {{detail.ville}}</div>
              <div class="data_dynamic_done">N° interne: {{detail.reference || ' Non Assigné'}}</div>
            </div>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="center" class="icon_pos">
            <div fxFlex="70" fxFlex.xs="65" fxFlex.md="53">
              <div fxLayout="row wrap">
                <div fxFlex="25" fxFlex.xs="50">
                  <div fxLayoutAlign="center" class="icon_height"><img src="../../../../../assets/img/icones/recherche.jpg"></div>
                  <div class="txt_marge data_dynamic_done txt_icon" fxLayoutAlign="center">{{detail.nbEval || 0}}</div>
                </div>
                <div fxFlex="25" fxFlex.xs="50">
                  <div fxLayoutAlign="center" class="icon_height"><img src="../../../../../assets/img/icones/calcule.jpg"></div>
                  <div class="txt_marge data_dynamic_done txt_icon" fxLayoutAlign="center">{{detail.nbDevis || 0}}</div>
                </div>
                <div fxFlex="25" fxFlex.xs="50">
                  <div fxLayoutAlign="center" class="icon_height"><img src="../../../../../assets/img/icones/doc.jpg"></div>
                  <div class="txt_marge data_dynamic_done txt_icon" fxLayoutAlign="center">{{detail.nbVisite.length || 0}}</div>
                </div>
                <div fxFlex="25" fxFlex.xs="50">
                  <div fxLayoutAlign="center" class="icon_height"><img src="../../../../../assets/img/icones/silouette.jpg"></div>
                  <div class="txt_marge data_dynamic_done txt_icon" fxLayoutAlign="center">{{detail.gestionnaire?.lastName || 'aucun'}}</div>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card fxFlex="100" *ngIf="detail.ajout">
        <mat-card-content class="txt_immeuble pad30">
          <div mat-raised-button fxLayoutAlign="end" class="pointer" (click)="detail.ajout = false; detail.action = '';"><img src="../../../../../assets/img/icones/retour-arr.png"></div>
          <div class="ajouter_immeuble">Modification</div>
          <mat-form-field appearance="outline" class="fill-width marge_formfield">
            <mat-label>Que voulez-vous faire?</mat-label>
            <mat-select [(ngModel)]="detail.action" (click)="detail.errSwitch = ''">
              <mat-option [value]="'nomi'">Nomination</mat-option>
              <mat-option [value]="'pcs'" *ngIf="role === 'syndic' || (role === 'gestionnaire' && detail.gestionnaire.permissions[2] === 1)">Ouvrir accès PCS</mat-option>
              <mat-option [value]="'cour'">Assigner Courtier</mat-option>
              <mat-option [value]="'gest'" *ngIf="role === 'syndic'">Assigner Gestionnaire</mat-option>
            </mat-select>
          </mat-form-field>
          <br/>
          <div class="pad7" *ngIf="detail.action === 'nomi'">Envoyer vers mon parc?</div>
          <div class="pad7" *ngIf="detail.action === 'nomi'">non <mat-slide-toggle [(ngModel)]="detail.isChecked"></mat-slide-toggle> oui</div>
          <div class="data_dynamic_done" style="color: red;" *ngIf="detail.errSwitch">{{detail.errSwitch}}</div>
          <div class="pad7" *ngIf="detail.action === 'cour'">Assigner un Courtier à cette Copro</div>
          <div *ngIf="detail.action === 'cour'">
            <mat-form-field appearance="outline" class="fill-width marge_formfield">
              <mat-label>Choix courtier</mat-label>
              <mat-select>
                <mat-option value="Sans" (click)="chooseCourtier('null')">Sans Courtier</mat-option>
                <mat-option *ngFor="let courtier of listCourtiers" [value]="courtier.id_el" (click)="chooseCourtier(courtier.id_el)">
                  {{ courtier.lastName }} [{{courtier.company}}]
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="pad7" *ngIf="detail.action === 'gest' && role === 'syndic'">Assigner un Gestionnaire à cette Copro</div>
          <div *ngIf="detail.action === 'gest'">
            <mat-form-field appearance="outline" class="fill-width marge_formfield">
              <mat-label>Choix gestionnaire</mat-label>
              <mat-select>
                <mat-option [value]="null" *ngIf="listGestionnaires.length === 0">Aucun Gestionnaire enregistré</mat-option>
                <mat-option *ngFor="let gestionnaire of listGestionnaires"
                            [value]="gestionnaire.id_el"
                            (click)="chooseGestionnaire(gestionnaire.id_el)">
                  {{ gestionnaire.firstName }} {{gestionnaire.lastName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="pad7" *ngIf="detail.action === 'pcs'">Veuillez remplir les informations du nouveau PCS</div>
          <div fxLayout="row wrap" *ngIf="detail.action === 'pcs'">
            <div class="res_adresse  max_width" fxFlex="70" *ngIf="detail?.pcs?._id">
              <br />
              <div class="data_dynamic_done" style="color: green;">PCS actuel: {{detail.pcs?.actuelP}} {{detail.pcs?.actuelN}}</div>
            </div>
            <div class="res_adresse  max_width" fxFlex="70" *ngIf="!detail?.pcs?._id">
              <br />
              <div class="data_dynamic_done" style="color: red;">Aucun PCS assigné</div>
            </div>
            <div fxFlex="90" class="txt_visite AvecBordure">
              <div>
                <div class="input_titre">Nom</div>
                <mat-form-field appearance="outline" class="input350">
                  <input [(ngModel)]="detail.pcs.firstName" matInput/>
                </mat-form-field>
                <div class="data_dynamic_done" style="color: red;" *ngIf="errNomPCS">{{errNomPCS}}</div>
              </div>
              <div>
                <div class="input_titre">Prenom</div>
                <mat-form-field appearance="outline" class="input350">
                  <input [(ngModel)]="detail.pcs.lastName" matInput/>
                </mat-form-field>
                <div class="data_dynamic_done" style="color: red;" *ngIf="errPrenomPCS">{{errPrenomPCS}}</div>
              </div>
              <div>
                <div class="input_titre">Email</div>
                <mat-form-field appearance="outline" class="input350">
                  <input [(ngModel)]="detail.pcs.email" matInput />
                </mat-form-field>
                <div class="data_dynamic_done" style="color: red;" *ngIf="errEmailPCS">{{errEmailPCS}}</div>
              </div>
              <div>
                <div class="input_titre">Téléphone</div>
                <mat-form-field appearance="outline" class="input350">
                  <input [(ngModel)]="detail.pcs.phone" matInput />
                </mat-form-field>
                <div class="data_dynamic_done" style="color: red;" *ngIf="errPhonePCS">{{errPhonePCS}}</div>
              </div>
              <div fxLayoutAlign="center" class="marge_commentaires">
                <mat-button-toggle
                  (click)="accesPCS(detail.key, detail.pcs.firstName, detail.pcs.lastName, detail.pcs.email, detail.pcs.phone, i)"
                  class="btn">Ouvrir Accès</mat-button-toggle>
              </div>
            </div>
          </div>
          <div class="pad7 marge_bouton" fxLayoutAlign="center" *ngIf="detail.action && detail.action !== 'pcs'">
            <mat-button-toggle class="btn-white" (click)="validerCP(detail.id, detail.action, i)">Valider</mat-button-toggle>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card fxFlex="100" *ngIf="detail.visite">
        <mat-card-content>
          <div mat-raised-button fxLayoutAlign="end" class="pointer" (click)="fillFormPCS(detail, i, false)"><img src="../../../../../assets/img/icones/retour-arr.png"></div>
          <div fxLayout="row wrap">
            <div fxFlex="90" class="txt_visite AvecBordure">
              <div>Demander la visite</div>
              <div>
                <div class="input_titre">Nom du PCS</div>
                <mat-form-field appearance="outline" class="input350">
                  <input [(ngModel)]="nomPCS" value="{{detail.pcs?.lastName}}" matInput/>
                </mat-form-field>
                <div class="data_dynamic_done" style="color: red;" *ngIf="errNomPCS">{{errNomPCS}}</div>
              </div>
              <div>
                <div class="input_titre">Email du PCS</div>
                <mat-form-field appearance="outline" class="input350">
                  <input [(ngModel)]="emailPCS" matInput />
                </mat-form-field>
              </div>
              <div>
                <div class="input_titre">Téléphone du PCS</div>
                <mat-form-field appearance="outline" class="input350">
                  <input [(ngModel)]="phonePCS" matInput />
                </mat-form-field>
                <div class="data_dynamic_done" style="color: red;" *ngIf="errPhonePCS">{{errPhonePCS}}</div>
              </div>
              <div class="margebot15">
                <div class="input_titre">Code d'accès</div>
                <mat-form-field appearance="outline" class="input350">
                  <input [(ngModel)]="accessCode" matInput />
                </mat-form-field>
              </div>
              <section class="checkpos">
                <mat-checkbox [(ngModel)]="cleCabinet" matInput labelPosition="before" class="checksize">Clé au cabinet ? </mat-checkbox>
              </section>
              <section class="checkpos">
                <mat-checkbox [(ngModel)]="gardien" matInput labelPosition="before" class="checksize">Gardien ? </mat-checkbox>
              </section>
              <div class="marge_commentaires">
                <div class="textarea_marge margebot15">Commentaires</div>
                <textarea [(ngModel)]="commentaire" matInput></textarea>
              </div>
              <div fxLayoutAlign="center" class="marge_commentaires">
                <mat-button-toggle
                  (click)="addVisite(detail.key, nomPCS,emailPCS,phonePCS,accessCode,gardien,cleCabinet,commentaire)"
                  class="btn">Envoyer</mat-button-toggle>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

