import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataService } from "../../../../data.service";

export interface Section {
  src_img: string,
  address: string,
  surface: number,
  ville: string,
  nomPCS: string,
  emailPCS: string,
  phonePCS: string,
  commentaire: string,
  accessCode: string,
  gardien: string,
  cleCabinet: boolean,
  codePostal: string,
  reference: string,
  name: string,
  id_info: string,
  coproId: string,
  faiteLe: string,
  syndicName: string,
  receptionDone: string,
  nbVisites: number,
  display_global: string
  gestionnaireId: object
}

@Component({
  selector: 'app-dashboard-pre-reception-details',
  templateUrl: './dashboard-pre-reception-details.component.html',
  styleUrls: ['./dashboard-pre-reception-details.component.scss']
})

export class DashboardPreReceptionDetailsComponent implements OnInit {

  onEvent(event) {
    event.stopPropagation();
  }


  toggle_info(event) {
    let id = event.srcElement.parentNode.parentNode.parentNode.parentNode.parentNode.nextElementSibling.id;

    if (document.getElementById(id).className === "display_none") {
      document.getElementById(id).classList.remove('display_none');
      document.getElementById(id).className = "display_block"
    } else if(document.getElementById(id).className === "display_block") {
      document.getElementById(id).classList.remove('display_block');
      document.getElementById(id).className = "display_none"
    } else {
      return
    }
  }

  confirmation(event) {
    console.log (event)
    let i : any;
    let id = event.srcElement.parentNode.parentNode.parentNode.parentNode.parentNode.parentElement.id
    if(id.length == 0) {
      id = event.srcElement.parentNode.parentNode.parentNode.parentNode.parentElement.id
    }
    if (confirm("Confirmer la suppresion ?")) {
      for ( i in this.resultSearch) {
        if(this.resultSearch[i].id_el == id) {
          this.resultSearch.splice(i, 1)
        }
      }
    }
  }

  PrestatairesDetailsArray;
  searchVal: string;
  resultSearch = [];
  listCopro:Section [] = [];

  constructor(public DataService: DataService) {
    this.DataService.api_get_visite(
      (data)=>{
        for (let key in data.visites) {
          if (data.visites[key].demandeReception) {
            let src_img = data.visites[key].img ? 'https://copro-back.cantem.fr/uploads/batiment/'+data.visites[key].img : '../../../../../assets/img/photo/immeuble-base.jpg';
            this.listCopro.push({
              src_img: src_img,
              address: data.visites[key].coproId.address,
              surface: data.visites[key].coproId.surface,
              ville: data.visites[key].coproId.ville,
              nomPCS: data.visites[key].nomPCS,
              emailPCS: data.visites[key].emailPCS,
              phonePCS: data.visites[key].phonePCS,
              commentaire: data.visites[key].commentaire,
              accessCode: data.visites[key].accessCode,
              gardien: data.visites[key].gardien,
              cleCabinet: data.visites[key].cleCabinet,
              codePostal: data.visites[key].coproId.codePostal,
              reference: data.visites[key].coproId.reference,
              name: data.visites[key].coproId.nomCopro,
              id_info: data.visites[key]._id,
              coproId: data.visites[key].coproId._id,
              faiteLe: data.visites[key].faiteLe,
              syndicName: data.visites[key].syndicId.nomSyndic,
              receptionDone: data.visites[key].receptionDone,
              nbVisites: data.visites.filter(el => el._id === data.visites[key]._id).length,
              display_global: "display_none",
              gestionnaireId: data.visites[key].gestionnaireId
            });
          }
        }
        this.resultSearch = this.listCopro;
      },
      (error)=>{
        console.log(error)
      }
    );
  }

  sendIdToChild(visiteId) {
    this.DataService.fillMessage(visiteId);
  }

  ngOnInit(): void {}

  searchEngine() {
    this.resultSearch = [];

    for(var key in this.listCopro) {
      if(
        this.listCopro[key].name.toLowerCase().indexOf(this.searchVal.toLowerCase())>-1 && this.resultSearch.length<4 ||
        this.listCopro[key].address.toLowerCase().indexOf(this.searchVal.toLowerCase())>-1 && this.resultSearch.length<4 ||
        this.listCopro[key].codePostal.toLowerCase().indexOf(this.searchVal.toLowerCase())>-1 && this.resultSearch.length<4 ||
        this.listCopro[key].ville.toLowerCase().indexOf(this.searchVal.toLowerCase())>-1 && this.resultSearch.length<4
        ){
        this.resultSearch.push(this.listCopro[key]);
      }
    }
    console.log(this.resultSearch)
  }


}
