import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service'

@Component({
  selector: 'app-ajout-immeuble-dialog',
  templateUrl: './ajout-immeuble-dialog.component.html',
  styleUrls: ['./ajout-immeuble-dialog.component.scss']
})
export class AjoutImmeubleDialogComponent implements OnInit {

  constructor(public DataService: DataService) { }

  retourFormImmeuble() {
    this.DataService.router.navigate(['mon-parc/ajouter-un-immeuble']);
  }

  ngOnInit(): void {}

}
