import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { GalleryComponent } from '../../../../gallery/gallery.component';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from '../../../../data.service'
import { HttpClient } from '@angular/common/http';
import {MatDialog} from "@angular/material/dialog";
import {SectionCarnet} from "../../../mon-parc/mon-parc-immeuble/mon-parc-immeuble/mon-parc-immeuble.component";

export interface SectionAvis {
  _id             : string,
  src_img         : string,
  evaluationTTC   : number,
  metrages        : number,
  comArchi        : string,
  comPrest        : string,
  desordre        : string,
  description     : string,
  situation       : string,
  corpsEtat       : [],
  images_bf       : [],
  images_af       : [],
  date            : string,
  conformite      : boolean,
  rate            : number,
  remarque        : string,
  incidentId      : string,
  coproId    	    : string,
  pcsId           : string,
  devisId         : string,
  syndicId        : string,
  visiteId        : string,
  courtierId      : string,
  architecteId    : string,
  prestataireId   : string,
  gestionnaireId  : string,
  demandeDevis    : boolean,
  devisPDF        : string,
  dateDepotDevis  : string,
  facturePDF      : string,
  dateDepotFacture: string,
  refDesordre     : string,
  noteGlobale     : string
}

@Component({
  selector: 'app-syndics-courtiers-details',
  templateUrl: './syndics-courtiers-details.component.html',
  styleUrls: ['./syndics-courtiers-details.component.scss'],
})

export class SyndicsCourtiersDetailsComponent implements OnInit{

  reference;surface;multiDevis;maxTravaux;moisAG;compagnie;echeance;courtier;

  copro = this.DataService.copro;

  resultInfo;
  _id;
  imgIncident

  dataFacades = []
  dataAscenseur = []
  dataEntree = []
  dataCaves = []
  dataParking = []
  dataEnvironnement = []
  dataContiguite = []
  dataIncidents = []
  dataParcelle = []
  dataVueGG = []

  resultReception:SectionAvis [] = [];

  nbEval = 0;
  nbDevis = 0;
  nbFacture = 0;
  totalTravaux = 0;

  resultDescription;resultEntree;resultCave;resultCarnet;resultCopro;resultTravaux;imgBat;imgInfo;

  constructor(private http: HttpClient,
              public GalleryComponent: GalleryComponent,
              private activatedRoute: ActivatedRoute,
              public DataService: DataService,
              public dialog: MatDialog) {

    const id =  this.activatedRoute.snapshot.params['id'];

    this.DataService.api_single_copro({_id:id},
      (data) => {
        this.copro = data.copro;

        this.nbEval = data.nbEval;
        this.nbDevis = data.nbDevis;
        this.nbFacture = data.nbFacture;

        if (this.copro !== "undefined") {
          this.resultDescription = this.copro.batiments;
          this.resultEntree = this.copro.batiments[0]['entrees'];
          this.resultCave = this.copro.batiments[0].cave;
          this.resultCarnet = this.copro.incidentId;
          this.resultCopro = this.copro;

          this.resultTravaux = this.resultCarnet.length
          this.imgBat = 'https://copro-back.cantem.fr/uploads/batiment/'+this.copro.batiments[0].image.facadeRue[0]
          //console.log("resultat incident",this.copro)
          //console.log('img bat', this.imgBat)
          this.imgInfo = this.copro.batiments[0].image.facadeRue[0]

          // this.imgIncident = 'https://copro-back.cantem.fr/uploads/batiment/'+this.copro.incidentId[2].images[0]
          // //console.log ("image incident", this.imgIncident)
        }
        else {
          this.resultDescription = this.DataService.copro.batiments;
          this.resultEntree = this.DataService.copro.batiments[0]['entrees'];
          this.resultCave = this.DataService.copro.batiments[0].cave;
          this.resultCarnet = this.DataService.copro.incidentId;
          this.resultCopro = this.DataService.copro
          this.resultTravaux = this.resultCarnet.length
          this.imgBat = 'https://copro-back.cantem.fr/uploads/batiment/'+this.copro.batiments[0].image.facadeRue[0]
          this.imgInfo = this.copro.batiments[0].image.facadeRue[0]

          // this.imgIncident = 'https://copro-back.cantem.fr/uploads/batiment/'+this.copro.incidentId[2].images[0]
          // //console.log ("image incident", this.imgIncident)
        }

        this.dataParcelle = [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.ParcelleCadastrale[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.ParcelleCadastrale[0]),
          }
        ];
        this.dataVueGG = [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.VueGenGoogle[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.VueGenGoogle[0]),
          }
        ];
        this.dataFacades = [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[0]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[1]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[1]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[2]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[2]),
          },
        ];
        this.dataAscenseur= [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[0]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[1]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[1]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[2]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[2]),
          },
        ];
        this.dataEntree= [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[0]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[1]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[1]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[2]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[2]),
          },
        ];
        this.dataCaves= [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[0]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[1]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[1]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[2]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[2]),
          },
        ];
        this.dataParking= [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[0]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[1]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[1]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[2]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[2]),
          },
        ];
        this.dataEnvironnement = [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[0]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[1]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[1]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[2]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[2]),
          },
        ];
        this.dataContiguite = [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[0]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[1]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[1]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[2]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[2]),
          },
        ];

        this.DataService.api_get_all_receptions({coproId: id},
          (data) => {
            for (let key in data.receptions) {
              this.resultReception[key] = data.receptions[key];
              this.resultReception[key].noteGlobale = Math.ceil(data.receptions[key].prestataireId.noteGlobale);
              this.totalTravaux += data.receptions[key].factureTTC;
              this.resultCarnet = this.resultCarnet.filter(c => c._id !== data.receptions[key].incidentId);
              //this.resultCarnetDevis = this.resultCarnetDevis.filter(c => c._id !== data.receptions[key].incidentId);
            }
          },
          (error) => {
            console.log(error)
          }
        )
      },
      (error)=>{
        console.log("error: ", error)
        this.copro = error.err.copro
      }
    );

    this._id = id

    /** Bloc left mon parc **/
    this.DataService.api_post_mon_parc({_id:id},
      (data)=>{
        console.log("data: ",data)
        this.resultInfo = data.copro
        this.reference = this.resultInfo.reference;
        this.surface = this.resultInfo.surface;
        this.multiDevis = this.resultInfo.multiDevis;
        this.maxTravaux = this.resultInfo.maxTravaux;
        this.moisAG = this.resultInfo.moisAG;
        this.compagnie = this.resultInfo.compagnie?.assurance?? null;
        this.echeance = this.resultInfo.compagnie?.echeance?? null;
        this.courtier = this.resultInfo.courtier?.lastName?? 'Sans Courtier';
      },
      (error)=>{
        console.log(error)
        this.copro = error.err.copro
      }
    );
  }

  ngOnInit() {}
}
