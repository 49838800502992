import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataService } from "../../../../data.service";

@Component({
  selector: 'app-dashboard-presta',
  templateUrl: './dashboard-presta.component.html',
  styleUrls: ['./dashboard-presta.component.scss']
})
export class DashboardPrestaComponent implements OnInit {
  PrestatairesDetailsArray;
  searchVal: string;
  resultSearch = [];
  listPresta = [];

  roundVal(n) {
    return Math.ceil(n)
  }

  constructor(public DataService: DataService) {
    this.DataService.api_get_prestataire(
      (data) => {
        let prestataires = []
        for (let key in data.prestataires) {
          this.listPresta[key] = data.prestataires[key];
          this.listPresta[key].nbEval = 0;
          this.listPresta[key].nbDevis = 0;
          this.listPresta[key].travaux = 0;
          prestataires.push(data.prestataires[key]._id);
        }
        this.DataService.api_get_devis({prestataires},
          (data) => {
            for (let i in data.list) {
              for (let j in this.listPresta) {
                if (data.list[i].prestataireId === this.listPresta[j]._id) {
                  this.listPresta[j].nbEval += 1;
                  if (data.list[i].devisPDF)
                    this.listPresta[j].nbDevis += 1;
                  if (data.list[i].factureTTC)
                    this.listPresta[j].travaux += data.list[i].factureTTC;
                }
              }
            }
            this.resultSearch = this.listPresta;
          },
          (error) => {
            console.log(error);
          });
      },
      (error) => {
        console.log(error)
      });
  }

  corpsEtat = [
    'Chauffagiste',
    'Contrôle d’accès',
    'Cordiste',
    'Couvreur',
    'Electricien',
    'Façadier',
    'Infiltrations',
    'Maçon',
    'Menuisier',
    'Peintre',
    'Plombier',
    'Porte de garage',
    'Ravalement',
    'Recherche de fuite',
    'Sécurité incendie',
    'Serrurier',
    'Vitrier',
  ]

  ngOnInit(): void {}

  searchEngine() {
    this.resultSearch = [];

    for (let key in this.listPresta) {
      if (this.listPresta[key].corpsEtat.includes(this.searchVal) || this.searchVal === "Tous")
        this.resultSearch.push(this.listPresta[key]);
    }
    console.log(this.resultSearch)
  }
}
