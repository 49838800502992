import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service'


@Component({
  selector: 'app-architecte-dialog',
  templateUrl: './architecte-dialog.component.html',
  styleUrls: ['./architecte-dialog.component.scss']
})
export class ArchitecteDialogComponent implements OnInit {

  constructor(public DataService: DataService) { }

  retourFormArchitecte() {
    this.DataService.router.navigate(['admin-copro/form-architectes']);
  }

  ngOnInit(): void {
  }

}
