
<div fxLayout="row wrap" class="padding-card padding-card-pos-search">
  <mat-card-content fxFlex="100">
    <mat-form-field fxLayoutAlign="left center" class="input_search" appearance="outline">
      <mat-label>N° immeuble, rue ...</mat-label>
      <input matInput [(ngModel)]="searchVal" (keyup)="searchEngine()"/>
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </mat-card-content>
</div>

<div fxLayout="row wrap">
  <div fxLayout="row wrap"  fxLayoutAlign.xs="center"  fxLayoutAlign.sm="center" fxFlex="33" fxFlex.xs="100" fxFlex.md="33" fxFlex.sm="50" fxFlex.lg="33" fxFlex.gt-lg="25" class="padding-card padding-card-info sans_shadow" *ngFor="let detail of resultSearch">
    <mat-card fxFlex="100" class="pointer">

      <div class="avec_shadow">
        <div class="ic_left">
          <mat-button-toggle
            style="position: absolute;z-index: 9999;"
            *ngIf="true"
            class="ic_croix btn_without_bg"
            style="background-color: transparent; margin-left: -35px; margin-top: -35px;"
            (click)="deassignCoproEtude(detail)"
          ><img src="../../../../../assets/img/icones/croix.png">
          </mat-button-toggle>

        </div>
        <div class="ic_right">

          <mat-button-toggle (click)="onEvent($event)" class="ic_bell">
            <img src="../../../../../assets/img/icones/cloche.png">
          </mat-button-toggle>
        </div>
        <mat-card-content class="content-100 contentxs font-normal">
          <div routerLink="../a-etudier-details/{{detail.id_el}}">
            <div fxLayoutAlign="center">
              <div width="70%" class="img_virtuelle max_width res_img" [ngStyle]="{ 'background-size': 'cover', 'background-image': 'url(' + detail.src_img + ')'}"></div>
            </div>
            <div style="margin: 15px 0;">
              <div class="data_dynamic company_name" fxLayoutAlign="center">{{ detail.nomCopro }}</div>
              <div class="adresse">
                <div class="data_dynamic" fxLayoutAlign="center">{{detail.address}}</div>
                <div class="data_dynamic" fxLayoutAlign="center">{{detail.codePostal}} {{detail.ville}}</div>
                <div fxLayoutAlign="center">
                  <span>N° </span>
                  <span class="resultat_marge_left data_dynamic">{{detail.reference}}</span>
                </div>
              </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="center" class="icon_pos">
              <div fxFlex="70">
                <div fxLayout="row wrap">
                  <div fxFlex="33" fxFlex.xs="100" class="iconesxs">
                    <div fxLayoutAlign="center" class="iconesxs"><img style="margin-bottom: 10px; width: 30px; height: 30px;" src="../../../../../assets/img/icones/recherche.jpg"></div>
                    <div fxLayoutAlign="center" class="txt_medium">
                      <span class="data_dynamic " style="font-size: 15px;text-align: center;">{{detail.surface}} ㎡</span>
                    </div>
                  </div>
                  <div fxFlex="33" fxFlex.xs="100" fxLayoutAlign="center center" class="iconesxs txt_medium data_dynamic">
                    {{detail.compagnie?.assurance}}
                  </div>
                  <div fxFlex="33" fxFlex.xs="100" class="iconesxs">
                    <div fxLayoutAlign="center" class="iconesxs"><img style="margin-bottom: 10px; width: 30px; height: 30px;" src="../../../../../assets/img/icones/tools.png"></div>
                    <div fxLayoutAlign="center">
                      <span class="data_dynamic txt_medium" style="font-size: 15px;text-align: center;">0 €</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="res_info" fxLayoutAlign="center">
            <div class="pointer info_icon" mat-raised-button (click)="toggle_info($event)"><img src="../../../../../assets/img/icones/info-small.png"></div>
          </div>
        </mat-card-content>
      </div>
    </mat-card>
    <div fxFlex="100" id="{{ detail.id_el }}" class="{{ detail.display_global }}">
      <mat-card>
        <div fxLayout="column" class="avec_shadow pad_box_shadow">
          <div fxLayoutAlign="center" class="info_courtier">Gestionnaire: {{detail?.gest?.lastName || ' - '}}</div>
          <div fxLayoutAlign="center" class="info_courtier">Email: {{detail?.gest?.email || ' - '}}</div>
          <div fxLayoutAlign="center" class="info_courtier info_mid">Port: {{ detail?.gest?.phone || ' - ' }}</div>

          <div fxLayoutAlign="center" class="info_courtier">Président du conseil: {{detail?.pcs?.lastName || ' - '}}</div>
          <div fxLayoutAlign="center" class="info_courtier">Email: {{detail?.pcs?.email || ' - '}}</div>
          <div fxLayoutAlign="center" class="info_courtier">Port: {{detail?.pcs?.phone || ' - '}}</div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
