import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visites-a-faires',
  templateUrl: './visites-a-faires.component.html',
  styleUrls: ['./visites-a-faires.component.scss']
})
export class VisitesAFairesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
