import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-en-cours-selection',
  templateUrl: './en-cours-selection.component.html',
  styleUrls: ['./en-cours-selection.component.scss']
})
export class EnCoursSelectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
