import { Component, ChangeDetectionStrategy, OnInit, Injectable } from '@angular/core';
import { GalleryComponent } from '../../../../gallery/gallery.component';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from '../../../../data.service'
import { HttpClient } from '@angular/common/http';
import { Gallery, GalleryItem, ImageItem } from 'ng-gallery';
import {MatDialog} from "@angular/material/dialog";


import {CommentaireDialogComponent} from "../../../../dialog/commentaire-dialog/commentaire-dialog.component";


export interface SectionDevis {
  _id             : string,
  incidentId      : string,
  evaluationTTC   : number,
  devisTTC        : number,
  factureTTC      : number,
  metrages        : number,
  coproId    	    : string,
  pcsId           : string,
  syndicId        : string,
  visiteId        : string,
  courtierId      : string,
  architecteId    : string,
  prestataireId   : string,
  gestionnaireId  : string,
  commentaire     : string,
  desordre        : string,
  description     : string,
  situation       : string,
  corpsEtat       : [string],
  images          : [string],
  date            : string,
  demandeDevis    : boolean,
  devisPDF        : string,
  facturePDF      : string
}

export interface SectionCarnet {
  images          : [string],
  date            : string,
  metrages        : number,
  desordre        : string,
  situation       : string,
  description     : string,
  corpsEtat       : [string],
  courtierId      : string,
  gestionnaireId  : string,
  architecteId    : string,
  visiteId        : string,
  syndicId        : string,
  coproId         : string,
  commentaire     : string,
  evaluationTTC   : number,
  devisTTC        : number,
  factureTTC      : number,
  devis           : [object],
  _id             : string
}

export interface SectionAvis {
  _id             : string,
  src_img         : string,
  evaluationTTC   : number,
  metrages        : number,
  comArchi        : string,
  comPrest        : string,
  desordre        : string,
  description     : string,
  situation       : string,
  corpsEtat       : [],
  images_bf       : [],
  images_af       : [],
  date            : string,
  conformite      : boolean,
  rate            : number,
  remarque        : string,
  incidentId      : string,
  coproId    	    : string,
  pcsId           : string,
  devisId         : string,
  syndicId        : string,
  visiteId        : string,
  courtierId      : string,
  architecteId    : string,
  prestataireId   : string,
  gestionnaireId  : string,
  demandeDevis    : boolean,
  devisPDF        : string,
  dateDepotDevis  : string,
  facturePDF      : string,
  dateDepotFacture: string,
  refDesordre     : string,
  noteGlobale     : string
}

export interface SectionCourtier {
  id_el: string,
  lastName: string,
  company: string,
}

export interface SectionPresta {
  id_el: string,
  lastName: string,
  company: string,
}

@Component({
  selector: 'app-mon-parc-immeuble',
  templateUrl: './mon-parc-immeuble.component.html',
  styleUrls: ['./mon-parc-immeuble.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MonParcPCSImmeubleComponent implements OnInit{

  resultDevis:SectionDevis [] = [];
  resultReception:SectionAvis [] = [];
  resultEvaluation:SectionDevis [] = [];

  listEvaluationIds = [];
  listDevisIds = [];

  show = false;
  show2 = false;
  buttonName = 'Show';
  hide: any;

  toggle() {
    this.show = !this.show
    this.show2 = false;

    if(this.show) {
      this.buttonName = 'Hide'
    }
    else {
      this.buttonName = 'Show'
    }
  }

  toggle_info(event, com) {
    let dialogRef = this.dialog.open(CommentaireDialogComponent, {
      height: 'auto',
      width: '100%',
      maxWidth: '450px',
    });

    dialogRef.componentInstance.commentaire = com;
  }

  reference;surface;multiDevis;maxTravaux;moisAG;compagnie;echeance;courtier;
  update = {
    _id: null,
    reference: null,
    surface: null,
    multiDevis: null,
    maxTravaux: null,
    moisAG: null,
    "compagnie.assurance": null,
    "compagnie.echeance": null,
  };

  copro=this.DataService.copro;

  _id;
  imgIncident

  dataFacades = []
  dataAscenseur = []
  dataEntree = []
  dataCaves = []
  dataParking = []
  dataEnvironnement = []
  dataContiguite = []
  dataIncidents = []
  dataParcelle = []
  dataVueGG = []
  travaux = 0

  coproLoaded: Promise<boolean>;
  resultDescription;resultEntree;resultCave;resultCopro;resultTravaux;imgBat;imgInfo;
  resultCarnet: SectionCarnet [] = [];
  resultCarnetDevis: SectionCarnet [] = [];
  nbEval = 0;

  constructor(public gallery: Gallery,
              private http: HttpClient,
              public GalleryComponent: GalleryComponent,
              private activatedRoute: ActivatedRoute,
              public DataService: DataService,
              public dialog: MatDialog) {

    const id =  this.activatedRoute.snapshot.params['id'];

    this.DataService.api_get_devis_copro(
      {coproId: id, option: true},
      async (data) => {
        for (let key in data.list) {
          if (data.list[key].evaluationTTC)
            this.nbEval += 1;
          if (data.list[key].devisPDF) {
            //await this.listEvaluationIds.push(data.list[key].prestataireId._id);
            await this.listDevisIds.push(data.list[key].incidentId._id);
            await this.resultDevis.push(data.list[key]);
          }
            await this.listEvaluationIds.push(data.list[key].prestataireId._id);
            await this.resultEvaluation.push(data.list[key])
        }
      },
      (error) => {
        console.log(error)
      }
    )

    this.DataService.api_single_copro({_id:id},
      async (data) => {
        this.copro = await data.copro;

        if (this.copro !== "undefined") {
          this.imgBat = 'https://copro-back.cantem.fr/uploads/batiment/'+this.copro.batiments[0].image.facadeRue[0]
          this.resultDescription = await this.copro.batiments;
          this.resultEntree = await this.copro.batiments[0].entrees;
          this.resultCave = await this.copro.batiments[0].cave;
          this.resultCarnet = await this.copro.incidentId;
          this.resultCopro = await this.copro;
          this.resultTravaux = await this.resultCarnet.length;
          this.imgInfo = await this.copro.batiments[0].image.facadeRue[0];

          this.resultCarnetDevis = await this.copro.incidentId.filter(el => this.listDevisIds.includes(el._id));

          this.reference = await this.copro.reference;
          this.surface = await this.copro.surface;
          this.multiDevis = await this.copro.multiDevis;
          this.maxTravaux = await this.copro.maxTravaux;
          this.moisAG = await this.copro.moisAG;
          this.compagnie = await this.copro.compagnie?.assurance?? null;
          this.echeance = await this.copro.compagnie?.echeance?? null;
          this.courtier = await this.copro.courtier?.lastName?? 'Sans Courtier';

          this.dataParcelle = [{
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.ParcelleCadastrale[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.ParcelleCadastrale[0]),
          }];
          this.dataVueGG = [
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.VueGenGoogle[0]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.VueGenGoogle[0]),
            }
          ];
          this.dataFacades = [
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[0]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[0]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[1]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[1]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[2]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[2]),
            },
          ];
          this.dataAscenseur= [
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[0]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[0]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[1]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[1]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[2]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[2]),
            },
          ];
          this.dataEntree= [
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[0]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[0]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[1]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[1]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[2]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[2]),
            },
          ];
          this.dataCaves= [
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[0]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[0]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[1]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[1]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[2]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[2]),
            },
          ];
          this.dataParking= [
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[0]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[0]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[1]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[1]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[2]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[2]),
            },
          ];
          this.dataEnvironnement = [
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[0]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[0]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[1]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[1]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[2]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[2]),
            },
          ];
          this.dataContiguite = [
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[0]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[0]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[1]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[1]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[2]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[2]),
            },
          ];
        }

        this.coproLoaded = Promise.resolve(true);
      },
      async (error)=>{
        console.log(error)
        this.copro = await error.err.copro
      }
    );

    this.DataService.api_get_all_receptions({coproId: id},
      (data) => {
        for (let key in data.receptions) {
          this.resultReception[key] = data.receptions[key];
          this.resultCarnet = this.resultCarnet.filter(c => c._id !== data.receptions[key].incidentId);
          this.travaux += data.receptions[key].factureTTC;
        }
      },
      (error) => {
        console.log(error)
      });
  }

  ngOnInit() {}
}
