import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../../../data.service';
import {GestionnaireAssignDialogComponent} from "../../../../dialog/gestionnaire-assign-dialog/gestionnaire-assign-dialog.component";
import {GestionnaireUnAssignDialogComponent} from "../../../../dialog/gestionnaire-unassign-dialog/gestionnaire-unassign-dialog.component";
import {MatDialog} from "@angular/material/dialog";

export interface Section {
  image: string;
  toggle: string;
  address: string;
  codePostal: string;
  reference: string;
  id: string;
}

export interface SectionGest {
  image: string;
  firstName: string;
  lastName: string;
  id: number;
  permissions: [number];
}

@Component({
  selector: 'app-changer-droits',
  templateUrl: './changer-droits.component.html',
  styleUrls: ['./changer-droits.component.scss']
})

export class ChangerDroitsComponent implements OnInit {
  color_line(event){
    if(event.currentTarget.parentElement.classList.contains("droits")) {
      event.currentTarget.parentElement.classList.remove("droits");
      event.currentTarget.parentElement.classList.add("droits_selected");

    } else if (event.currentTarget.parentElement.classList.contains('droits_selected')) {
      event.currentTarget.parentElement.classList.remove('droits_selected');
      event.currentTarget.parentElement.classList.add('droits');
    }
  }

  move_item_colonne_gauche_to_droite_by_id(id) {
    let i;
    for (i in this.listCoproAssigned) {
      if (this.listCoproAssigned[i].id == id) {
        this.listCoproDisponible.push(this.listCoproAssigned[i]);
        this.listCoproAssigned.splice(i, 1);
      }
    }
  }

  move_item_colonne_droite_to_gauche_by_id(id) {
    let i;
    for (i in this.listCoproDisponible) {
      if (this.listCoproDisponible[i].id == id) {
        this.listCoproAssigned.push(this.listCoproDisponible[i]);
        this.listCoproDisponible.splice(i, 1);
      }
    }
  }


  echange_line(event) {
    if(confirm("Voulez-vous enlever cet Immeuble de votre selection?")) {
     let el = document.querySelectorAll(".colonne_gauche.droits_selected");
     for (let i in el) {
       this.DataService.apiUnassignGestionnaireToCopro({gestionnaireId: this.gestionnaire.id, coproId: el[i].id, isParc: true},
         (data) => {
           this.move_item_colonne_gauche_to_droite_by_id(el[i].id)
         },
         (error) => {
           console.log("assign gestionnaire error: ", error);
         });
     }
      this.dialog.open(GestionnaireUnAssignDialogComponent, {
        height: 'auto',
        width: '100%',
        maxWidth: '450px',
      });
   }
  }

  echange_line_2(event) {
    if (confirm("Voulez-vous ajouter cet Immeuble à votre selection?")) {
      let isParc = true;
      let el = document.querySelectorAll(".colonne_droite.droits_selected");
      for (let i in el) {
        this.DataService.apiAssignGestionnaireToCopro({gestionnaireId: this.gestionnaire.id, coproId: el[i].id, isParc},
          (data) => {
            this.move_item_colonne_droite_to_gauche_by_id(el[i].id)
          },
      (error) => {
            console.log("assign gestionnaire error: ", error);
          });
      }
      this.dialog.open(GestionnaireAssignDialogComponent, {
        height: 'auto',
        width: '100%',
        maxWidth: '450px',
      });
    }
  }

  changePermissions(i) {
    if (this.gestionnaire.permissions[i] === 1)
      this.gestionnaire.permissions[i] = 0
    else
      this.gestionnaire.permissions[i] = 1
  }

  updatePermissions() {
    this.DataService.api_change_droits({_id: this.gestionnaire.id, permissions: this.gestionnaire.permissions},
      (data) => {
        alert("les droits on bien été mis à jour");
      },
      (error) => {
        console.log(error);
        alert("une erreur est survenue");
      });
  }

  listCoproAssigned: Section[] = [];

  listCoproDisponible: Section[] = [];

  etatsArray: String[] = [];
  gestionnaire: SectionGest;

  constructor(public DataService: DataService, private activatedRoute: ActivatedRoute, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      const id = params['id'];
      this.DataService.api_get_single_gestionnaire({_id: id},
         async (data) => {
          this.gestionnaire = {
            image: data.gestionnaire.image,
            firstName: data.gestionnaire.firstName,
            lastName: data.gestionnaire.lastName,
            id: data.gestionnaire._id,
            permissions: data.gestionnaire.permissions,
          };
        },
        (error)=>{
          console.log(error)
        }
      );
      this.DataService.api_get_mon_parc(
        async (data) => {
          for (let i in data.parc) {
            if (data.parc[i].gestionnaire?._id === id)
              this.listCoproAssigned.push({
                image: '../assets/img/icones/building.png',
                toggle: 'string',
                address: data.parc[i].address,
                codePostal: data.parc[i].codePostal,
                reference: data.parc[i].reference,
                id: data.parc[i]._id,
              });
            else if (!data.parc[i].gestionnaire)
              this.listCoproDisponible.push({
                image: '../assets/img/icones/building.png',
                toggle: 'string',
                address: data.parc[i].address,
                codePostal: data.parc[i].codePostal,
                reference: data.parc[i].reference,
                id: data.parc[i]._id,
              });
          }
          console.log("assigned: ", this.listCoproAssigned);
        },
        (error) => {
          console.log(error)
        });
    });
    this.etatsArray = ["Retrait immeuble", "Gestion courtiers", "Ouvrir un accès au PCS"];
  }

}
