<div fxLayout="row wrap" class="padding-card padding-card-pos">
  <mat-card>
    <div fxLayout="row wrap">
      <div fxLayoutAlign="start" fxLayoutAlign.xs="start" class="title title-pos">Demande Ajout courtier</div>
    </div>
    <mat-card-content class="title-pos input_outline">
      <form fxLayout="row wrap" [formGroup]="formCourtier" class="formCreation">
        <div fxLayout="row wrap" class="pos_input">
          <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
            <div class="input-gestion">
              <mat-label>Email du compte :</mat-label>
            </div>
            <mat-form-field appearance="outline" class="input350">
              <mat-label>Email du compte</mat-label>
              <input [(ngModel)]="email" formControlName="emailForm" matInput required>
              <mat-error class="ErrorInline" *ngIf="formCtrl.emailForm.hasError('required')">Le champ email est requis</mat-error>
              <mat-error class="ErrorInline" *ngIf="formCtrl.emailForm.hasError('email')">Format de mail invalide.</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
            <div class="input-gestion">
              <mat-label>Nom/raison sociale du Courtier :</mat-label>
            </div>
            <mat-form-field appearance="outline" class="input350">
              <mat-label>Nom/raison sociale</mat-label>
              <input [(ngModel)]="company" formControlName="companyForm" matInput  required minlength="3" maxlength="50">
              <mat-error class="ErrorInline" *ngIf="formCtrl.companyForm.hasError('required')">Le champ Nom/Raison sociale est requis</mat-error>
              <mat-error class="ErrorInline" *ngIf="formCtrl.companyForm.hasError('minlength')">Minimum <b>3 caractères</b>, maximum <b>50</b>.</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
            <div class="input-gestion">
              <mat-label>Nom de l'utilisateur :</mat-label>
            </div>
            <mat-form-field appearance="outline" class="input350">
              <mat-label>Nom</mat-label>
              <input [(ngModel)]="lastName" formControlName="lastNameForm" matInput required minlength="3" maxlength="50">
              <mat-error class="ErrorInline" *ngIf="formCtrl.lastNameForm.hasError('required')">Le champ nom est requis</mat-error>
              <mat-error class="ErrorInline" *ngIf="formCtrl.lastNameForm.hasError('minlength')">Minimum <b>3 caractères</b>, maximum <b>50</b>.</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
            <div class="input-gestion">
              <mat-label>Adresse (n° et nom de rue) du siege social :</mat-label>
            </div>
            <mat-form-field appearance="outline" class="input350">
              <mat-label>Adresse du siege social</mat-label>
              <input [(ngModel)]="address" formControlName="addressForm" matInput required minlength="5" maxlength="200">
              <mat-error class="ErrorInline" *ngIf="formCtrl.addressForm.hasError('required')">Le champ adresse est requis</mat-error>
              <mat-error class="ErrorInline" *ngIf="formCtrl.addressForm.hasError('minlength')">Minimum <b>5 caractères</b>, maximum <b>200</b>.</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
            <div class="input-gestion">
              <mat-label>Prénom de l'utilisateur :</mat-label>
            </div>
            <mat-form-field appearance="outline" class="input350">
              <mat-label>Prénom</mat-label>
              <input [(ngModel)]="firstName" formControlName="firstNameForm" matInput required minlength="3" maxlength="100">
              <mat-error class="ErrorInline" *ngIf="formCtrl.firstNameForm.hasError('required')">Le champ prénom est requis</mat-error>
              <mat-error class="ErrorInline" *ngIf="formCtrl.firstNameForm.hasError('minlength')">Minimum <b>3 caractères</b>, maximum <b>100</b>.</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
            <div class="input-gestion">
              <mat-label>Code postal du siège social :</mat-label>
            </div>
            <mat-form-field appearance="outline" class="input350">
              <mat-label>Code postal</mat-label>
              <input [(ngModel)]="codePostal" formControlName="codePostalForm" matInput (keypress)="numericOnly($event)" required minlength="5" maxlength="5">
              <mat-error class="ErrorInline" *ngIf="formCtrl.codePostalForm.hasError('required')">Le champ code postal est requis</mat-error>
              <mat-error class="ErrorInline" *ngIf="formCtrl.codePostalForm.hasError('minlength')">Il doit comporter <b>5 chiffres</b></mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
            <div class="input-gestion">
              <mat-label>Numéro de contact du courtier :</mat-label>
            </div>
            <mat-form-field appearance="outline" class="input350">
              <mat-label>Numéro de téléphone</mat-label>
              <input [(ngModel)]="phone" formControlName="phoneForm" matInput (keypress)="numericOnly($event)" required minlength="10" maxlength="10">
              <mat-error class="ErrorInline" *ngIf="formCtrl.phoneForm.hasError('required')">Le champ téléphone est requis</mat-error>
              <mat-error class="ErrorInline" *ngIf="formCtrl.phoneForm.hasError('minlength')">Il doit comporter <b>10 chiffres</b></mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
            <div class="input-gestion">
              <mat-label>Ville du siege social :</mat-label>
            </div>
            <mat-form-field appearance="outline" class="input350">
              <mat-label>Ville</mat-label>
              <input [(ngModel)]="ville" formControlName="villeForm" matInput required minlength="3" maxlength="100">
              <mat-error class="ErrorInline" *ngIf="formCtrl.villeForm.hasError('required')">Le champ ville est requis</mat-error>
              <mat-error class="ErrorInline" *ngIf="formCtrl.villeForm.hasError('minlength')">Il doit comporter <b>3 caractères</b> minimum</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row wrap" class="button_connexion marge15T">
          <div fxFlex="100">
                        <span>
                            <mat-button-toggle
                              [disabled]="!formCourtier.valid"
                              (click)="addCourtier(email, firstName, lastName, company, address, codePostal, ville, phone)"
                              class="btn">
                                Soumettre a Coprovisit
                            </mat-button-toggle>
                        </span>
            <span class="alert_msg margeL10" *ngIf="msgErreur"> {{ msgErreur }}</span>
          </div>

          <div class="champsAlert" *ngIf="!formCourtier.valid">* Remplissez tous les champs obligatoires pour créer le courtier</div>
          <div class="champsOk" *ngIf="formCourtier.valid">* Vous pouvez créer le courtier</div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>


