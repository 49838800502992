<div class="padding-card padding-card-pos">
  <mat-button-toggle routerLink="../../dashboard" class="pad10">
    <span fxLayoutAlign="center center" fxFlex="15" class="icon_arr"><img class="img_retour" src="../../../../../assets/img/icones/retour-arr.png"></span>
    <div fxLayoutAlign="center center" fxFlex="85"><span class="retour_parc">Retour dans Mes Courtiers</span></div>
  </mat-button-toggle>
</div>

<div fxLayout="row wrap" class="padding-card padding-card-pos-search">
    <mat-card-content fxFlex="100">
        <mat-form-field fxLayoutAlign="left center" class="input_search" appearance="outline">
            <mat-label>Rechercher</mat-label>
            <input matInput [(ngModel)]="searchVal" (keyup)="searchEngine()"/>
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
    </mat-card-content>
</div>

<div class="padding-card padding-card-pos">
    <mat-card>
        <mat-card-content class="content-pos">
          <div *ngIf="resultSearch.length === 0">
            <span class="txt_medium">Aucun Immeuble n'a été assigné à ce Courtier</span>
          </div>
            <div fxLayout="row wrap" fxLayout.xs="column">
                <div  fxFlex="33" fxFlex.xs="100" fxFlex.md="50" fxFlex.sm="50" fxFlex.lg="33" fxFlex.gt-lg="25" class="padding-card padding-card-pos"  *ngFor="let detail of resultSearch">
                    <mat-card fxFlex="100">
                        <mat-card-content class="content-align">
                            <div fxLayoutAlign="center">
                                <div class="img_virtuelle max_width res_img" [ngStyle]="{ 'background-size': 'cover', 'background-image': 'url(' + detail.src_img + ')'}"></div>
                            </div>
                            <div fxLayout="row wrap" fxLayoutAlign="center">
                                <div class="res_adresse" fxFlex="75" fxFlex.xs="58" fxFlex.md="51" fxFlex.sm="75">
                                    <div class="txt_medium data_dynamic">{{ detail.address}}</div>
                                    <div class="txt_medium data_dynamic">{{ detail.codePostal }} {{ detail.ville }}</div>
                                    <div class="txt_medium">
                                        <span>N° interne: </span>
                                        <span class="data_dynamic">{{ detail.reference }}</span>
                                    </div>
                                </div>
                                <div class="res_ligne" fxFlex="75" fxFlex.xs="58" fxFlex.md="51" fxFlex.sm="75">
                                    <div>
                                        <span class="txt_medium">Surface :</span>
                                        <span class="data_dynamic txt_small resultat_marge_left">{{ detail.surface }}</span>
                                        <span class="resultat_marge_left txt_small">m²</span>
                                    </div>
                                    <div>
                                        <span class="txt_medium">Compagnie: </span>
                                        <span class="data_dynamic txt_small resultat_marge_left">{{ detail.compagnie.assurance || 'non' }}</span>
                                    </div>
                                    <div>
                                      <span class="txt_medium">Echéance :</span>
                                      <span class="data_dynamic txt_small resultat_marge_left">{{ detail.compagnie.echeance | date: 'dd/MM/yyyy' }}</span>
                                    </div>
                                </div>
                            </div>
                            <!--div fxLayoutAlign="center" id="{{ detail.id_select }}" class="marge_top30">
                                <div class="select_courtier">
                                    <mat-form-field appearance="outline" class="select_bouton">
                                     <mat-select [(value)]="detail.valeur">
                                       <mat-option value="option1">Avec courtier</mat-option>
                                       <mat-option value="option2">Sans courtier</mat-option>
                                     </mat-select>
                                   </mat-form-field>
                                 </div>
                            </div-->
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
