import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../data.service'
import {FormControl, Validators, FormBuilder} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { GestionnaireDialogComponent } from '../../../../dialog/gestionnaire-dialog/gestionnaire-dialog.component'

@Component({
  selector: 'app-form-gestionnaires',
  templateUrl: './form-gestionnaires.component.html',
  styleUrls: ['./form-gestionnaires.component.scss']
})
export class FormGestionnairesComponent implements OnInit {

  hide = true;


  etatsArray: String[] = [];

  permissions: Number[] = [0, 0, 0];

  email; firstName; lastName; phone;

  showOptions(event): void {
    this.DataService.error = '';
    if (event.checked)
      this.permissions[event.source.value] = 1;
    else
      this.permissions[event.source.value] = 0;
  }

  addGestionnaire(email, firstName, lastName, phone) {
    this.DataService.error = '';
    if (this.permissions.length === 0) {
      this.DataService.error = "Veuillez assigner des droits au Gestionnaire";
      return this.formGestionnaire.controls;
    } else
      this.DataService.api('/create/gestionnaire', {email: email, firstName: firstName, lastName: lastName, phone: phone, permissions: this.permissions, syndic: localStorage.getItem('id')},

      (res)=>{
        console.log("Yipi :", res)
        if(this.formGestionnaire.valid) {
          this.dialog.open(GestionnaireDialogComponent, {
            height: 'auto',
            width: '100%',
            maxWidth: '450px',
          });
           this.DataService.router.navigate(['ajout-collaborateurs/dashboard']);
        }
        else {
          console.log ("refus")
        }
      },
      (error) => {
         console.log (error)
         if (error.error.message) {
           this.DataService.error= error.error.message
         }
      })
  }

  formGestionnaire = this.fb.group({
    emailForm: ['', [Validators.required, Validators.email]],
    firstNameForm: ['', [Validators.required, Validators.minLength(3)]],
    lastNameForm: ['', [Validators.required, Validators.minLength(3)]],
    phoneForm: ['', [Validators.required, Validators.minLength(10)]],
  });

  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  get formCtrl() { return this.formGestionnaire.controls; }

  constructor(public DataService : DataService, private fb: FormBuilder, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.etatsArray = ["Retrait immeuble", "Gestion courtiers", "Ouvrir un accès au PCS"];
  }

}
