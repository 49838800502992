import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class FormService {


    refVisite;
    refVisiteRetour;
    syndicId;
    public api_post_single_visite(params, callback_success, callback_error){

      this.http.post("https://copro-back.cantem.fr/retrieve/visite-one", params).subscribe(
        (response) => {
              callback_success(response)
          console.log("response: ", response)
              this.refVisite = response["visite"].reference
              this.syndicId = response["visite"].syndicId
        },
        (error) => {
            callback_error(error)
      });
    }
    batiments = new Array()
    toctoctoc=[
        //conditionForm
        {"name":"specAccess1","type":"list_select","label":"Porte d'entrée avec :","value":"","classes":"red","list":["Interphone","Digicode","Autre(précisez)"]},
        {"name":"specAutre1","type":"textarea","condition":{pos:0,value:"autre(précisez)"},"label":"Interphone/digicode/autre (précisez) :","value":"","classes":"green"},
        {"name":"porteVitree","type":"ouinon","label":"Porte vitrée : ","value":null,"oui":false,"non":false},
        //conditionForm
        {"name":"isAccess2","type":"ouinon","label":"Second contrôle accès :","value":null,"classes":"red","oui":false,"non":false},
        {"name":"specAccess2","type":"list_select","condition":{pos:3,value:true},"label":"Porte d'entrée avec :","value":"","classes":"orange","list":["Interphone","Digicode","Autre(précisez)"]},
        {"name":"porteVitreeSecond","type":"ouinon","label":"Porte vitrée : ","condition":{pos:3,value:true},"value":null,"oui":false,"non":false},
        {"name":"specAutre2","type":"textarea","condition":{pos:4,value:"autre(précisez)"},"label":"Interphone/digicode/autre (précisez) :","value":"","classes":"green"},
        {"name":"cameraVideo","type":"ouinon","label":"Caméra vidéo :","value":null,"oui":false,"non":false},

        {"name":"hallEntree","type":"txt","label":"Hall d'entrée","img_upload":"entrees"},
        {"name":"planEvac","type":"ouinon","label":"Plan d'évacuation dans le hall :","value":null,"oui":false,"non":false},
        {"name":"cmdDesenfumage","type":"ouinon","label":"Commande de désenfumage :","value":false,"oui":false,"non":false},
        {"name":"alarmeIncendie","type":"ouinon","label":"Boitier d'alarme incendie :","value":null,"oui":false,"non":false},

        //conditionForm /** Ascenseur
        {"name":"Ascenceur","type":"ouinon","label":"Ascenseur :","value":false,"classes":"red titre_gras","oui":false,"non":false},
        {"name":"etatAscenceur","condition":{pos:12,value:true},"type":"list_select","label":"Etat global des portes et de la cabine d'ascenseur :","value":"","classes":"green","img_upload":"facadeArriere","list":["Bon","Moyen","Mauvais"]},
        {"name":"escalier_escalier","type":"txt","label":"Escalier principal :","classes":"titre_gras",},
        {"name":"escalier_natureMarches","type":"list_select","label":"Nature des marches :","value":"", "list":["en dur","bois"]},
        {"name":"escalier_naturePaliers","type":"list_select","label":"Nature des paliers :","value":"","list":["en dur","bois","bois et en dur"]},
        {"name":"escalier_etatGenEscaliers","type":"list_select","label":"Etat global de l'escalier :","value":"","list":["Bon","Moyen","Mauvais"]},
        {"name":"escalier_etatGenMurs","type":"list_select","label":"Etat global des murs de la cage d'escalier :","value":"","list":["Bon","Moyen","Mauvais"]},
        {"name":"escalier_etatGenPlafonds","type":"list_select","label":"Etat global des plafonds :","value":"","list":["Bon","Moyen","Mauvais"]},

        {"name":"escalier_extincteurs","type":"ouinon","label":"Exctincteurs :","value":null,"oui":false,"non":false},
        {"name":"escalier_visite12mois","type":"ouinon","label":"Visite - de 12 mois :","value":null,"oui":false,"non":false},
      ]
    entrees = new Array();
    // Formulaire Visite à faire
    nbEtages;
    localisation;
    reference;
    envoi = false;
    nbrbat = 1;
    formIntro = [[
      /** FACADE RUE **/
      {"name":"facaderue","type":"txt","label":"Façade avant :","classes":"titre_gras", "img_upload":"facadeRue", file: null},
      {"name":"facadeRue_etatGen","type":"list_select","label":"Etat général de la façade : ","value":"","list":["Bon","Moyen","Mauvais","Dangereux"]},
      //conditionForm /** /*Façade avant */
      {"name":"facadeRue_commerces","type":"ouinon","label":"Commerces : ","value": null,"classes":"red","oui":false,"non":false },
      {"name":"facadeRue_natCommerce","type":"textarea","condition":{pos:2,value:true},"label":"Nature des commerces, et situation :","value":"","classes":"green"},

      //conditionForm /** /*façade arrière */
      {"name":"facadeArriere_access","type":"ouinon","label":"Accès à la façade arrière : ","value":null,"classes":"red","oui":false,"non":false},
      {"name":"facadeArriere_etatGen","type":"list_select","condition":{pos:4,value:true},"label":"Etat global :","value":"","classes":"green","list":["Bon","Moyen","Mauvais"]},
      {"name":"facadeArriere","type":"txt","label":"", "condition":{pos:4,value:true},"classes":"titre_gras", "img_upload":"facadeArriere", file: null},
    ]]
    formulaire =
    [
      // {"name":"reference","type":"inputReadOnly","value": "","label":"Référence","classes":"titre_gras"},
      //{"name":"nbrbat","type":"list_num","label":"Nombre de bâtiment","value": 0,"classes":"titre_gras"},

      {"name":"nbEtages","type":"list_num","label":"Nombre d'étages :","value":0,},
      {"name":"localisation","type":"inputNormal","label":"Localisation :","value":""},

      /** ENTREE **/
      {"name":"nbrethall","type":"nbentrees","label":"Nombre de hall d'entrée :","value":1,},

      {"name":"entrees","type":"entrees", tab: []},



      /*********************************** Fin BATIMENT 1 ***************************************/

      //conditionForm /** CAVE
      {"name":"cave_presence","type":"ouinon","label":"Caves :","value":null,"classes":"red titre_gras","oui":false,"non":false},
      {"name":"cave_accessible","type":"ouinon","condition":{pos:4,value:true},"label":"Avez-vous eu accès ?","value":null,"classes":"green","oui":false,"non":false},
      {"name":"cave_encombrement","type":"list_select","condition":[{pos:4,value:true},{pos:5, value:true}],"label":"Encombrement des caves :","value":"", "classes":"green","img_upload":"caves","list":["Bon","Moyen","Mauvais"]},
      {"name":"cave_nbSousSol","type":"list_num","condition":[{pos:4,value:true},{pos:5, value:true}],"label":"Nombre de sous sol de caves :","value":"","classes":"green"},
      {"name":"cave_extincteurs","type":"ouinon","condition":[{pos:4,value:true},{pos:5, value:true}],"label":"Extincteurs :","value":null,"classes":"green","oui":false,"non":false},
      {"name":"cave_visite12mois","type":"ouinon","condition":[{pos:4,value:true},{pos:5, value:true}],"label":"Visite - de 12 mois :","value": null,"classes":"green","oui":false,"non":false},


      /** CHAUFFERIE **/
      {"name":"chaufferie_collective","type":"list_select_3","label":"Chaufferie :","value":true,"classes":"red titre_gras","list":["Collective", "Individuelle"]},
      {"name":"chaufferie_Access","type":"ouinon","condition":{pos:10,value:true},"label":"Avez-vous eu accès ?","value":null,"classes":"green","oui":false,"non":false},
      {"name":"chaufferie_exitincteursInt","type":"ouinon","condition":[{pos:10,value:true}, {pos: 11, value: true}],"label":"Extincteurs intérieur :","value":null,"classes":"green","oui":false,"non":false},
      {"name":"chaufferie_visite12moisCollecInt","type":"ouinon","condition":[{pos:10,value:true}, {pos: 11, value: true}, {pos: 12, value: true}],"label":"Visite - de 12 mois :","value":null,"classes":"green","oui":false,"non":false},
      {"name":"chaufferie_carnet","type":"ouinon","condition":[{pos:10,value:true}, {pos: 11, value: true}],"label":"Présence de carnet d'entretien :","value":null,"classes":"green","oui":false,"non":false},
      {"name":"chaufferie_dateLastVisite","type":"datePicker","condition":[{pos:10,value:true},{pos:11,value:true}, {pos: 14, value: true}],"label":"Date de la dernière visite :","value":"", "placeholder": "Date dernière visite"},
      {"name":"chaufferie_genre","type":"list_select_2","condition":{pos:10,value:true},"label":"","value":"","classes":"green","list":["Gaz","Fuel", "Cpcu", "Inconnu"]},
      {"name":"chaufferie_exitincteursExt","type":"ouinon","condition":{pos:10,value:true},"label":"Extincteurs exterieur :","value":null,"classes":"green","oui":false,"non":false},
      {"name":"chaufferie_visite12moisCollecExt","type":"ouinon","condition":[{pos:10,value:true}, {pos: 17 , value: true}],"label":"Visite - de 12 mois :","value":null,"classes":"green","oui":false,"non":false},
        // Individuelle
      {"name":"chaufferie_genreIndiv","type":"list_select_2","condition":{pos:10,value:false},"label":"","value":"","classes":"green","list":["Gaz","Electrique", "Inconnu"]},
      {"name":"chaufferie_exitincteursIndivExt","type":"ouinon","condition":{pos:10,value:false},"label":"Extincteurs exterieur :","value":null,"classes":"green","oui":false,"non":false},
      {"name":"chaufferie_visite12moisIndivExt","type":"ouinon","condition":[{pos:10,value:false}, {pos: 20, value: true}],"label":"Visite - de 12 mois :","value":null,"classes":"green","oui":false,"non":false},

      /** PARKING SOUS TERRAIN **/
      {"name":"parkingST_presence","type":"ouinon","label":"Parking sous terrain :","value":null,"classes":"red titre_gras","oui":false,"non":false},
      {"name":"parkingST_access","type":"ouinon","condition":{pos:22,value:true},"label":"Avez-vous eu accès ?","value":null,"classes":"green","oui":false,"non":false},
      {"name":"parkingST_img","type":"txt","condition":{pos:22,value:true},"img_upload":"parking"},
      {"name":"parkingST_nbNiveaux","type":"list_num","condition":[{pos:22,value:true}, {pos: 23,value:true}],"label":"Nombre de niveaux :","value":"","classes":"green"},
      {"name":"parkingST_extincteurs","type":"ouinon","condition":[{pos:22,value:true}, {pos: 23,value:true}],"label":"Extincteurs :","value":null,"classes":"green","oui":false,"non":false},
      {"name":"parkingST_visite12mois","type":"ouinon","condition":[{pos:22,value:true}, {pos: 23,value:true}, {pos: 26, value: true}],"label":"Visite - de 12 mois :","value":null,"classes":"green","oui":false,"non":false},
      {"name":"parkingST_controlAccess","type":"ouinon","condition":[{pos:22,value:true}, {pos: 23,value:true}],"label":"Contrôle d'accès entrée/sortie :","value":null,"classes":"green","oui":false,"non":false},
      {"name":"parkingST_etatPorte","type":"list_select","condition":[{pos:22,value:true}, {pos: 23,value:true}],"label":"Etat de la porte de parking","value":"","classes":"green","img_upload":"avec","list":["Bon", "Moyen", "Mauvais", "Sans objet"]},
      {"name":"parkingST_planEvac","type":"ouinon","condition":[{pos:22,value:true}, {pos: 23,value:true}],"label":"Plan d'évacuation :","value":null,"classes":"green","oui":false,"non":false},
      {"name":"parkingST_bacSable","type":"ouinon","condition":[{pos:22,value:true}, {pos: 23,value:true}],"label":"Présence d'un bac à sable :","value":null,"classes":"green","oui":false,"non":false},

      {"name":"environnement","type":"txt","label":"Environnement :","value":"","img_upload":"environnement"},
      {"name":"contiguite","type":"txt","label":"Contiguïté :","value":"","img_upload":"contiguite"},
      {"name":"facadeRue_commercesContiguite","type":"ouinon","label":"Commerces contigus : ","value": null,"classes":"red","oui":false,"non":false },
      {"name":"facadeRue_natCommerceContiguite","type":"textarea","condition":{pos:34,value:true},"label":"Nature des commerces, et situation :","value":"","classes":"green"},


    ]


/***********************************************************************************************/

      // Formulaire Ajouter un immeuble

      form_AjoutImmeuble =  [

        {
          "name":"nomCopro",
          "type":"inputNomCopro",
          "label":"Nom de la copro :",
          "value": "",
          "classes":"",
          "model": "nom de la copro",
          "controlForm": "nomCoproForm",
        },

        {
          "name":"reference",
          "type":"inputReference",
          "label":"Référence interne :",
          "value": "",
          "classes":"",
          "model": "référence interne",
          "controlForm": "referenceForm",
        },
        {
          "name":"address",
          "type":"inputAdresse",
          "label":"Adresse :",
          "value": "",
          "classes":"",
          "model": "adresse",
          "controlForm": "addressForm",
        },
        {
          "name":"ville",
          "type":"inputVille",
          "label":"Ville :",
          "value": "test",
          "classes":"",
          "model": "ville",
          "controlForm": "villeForm",
        },
        {
          "name":"codePostal",
          "type":"inputCodePostal",
          "label":"Code postal :",
          "value": 0,
          "classes":"",
          "model": "code postal",
          "controlForm": "codePostalForm",
        },
        {
          "name":"nbBatiments",
          "type":"inputNbBatiments",
          "label":"Nombre de bâtiments :",
          "value": 0,
          "classes":"",
          "model": "nombre de bâtiments",
          "controlForm": "nbBatimentsForm",
        },
        {
          "name":"surface",
          "type":"inputSurface",
          "label":"Surface en m² :",
          "value": 0,
          "classes":"",
          "model": "surface",
          "controlForm": "surfaceForm",
        },
        {
          "name":"checkedOui",
          "type":"ouinon",
          "label":"Etes-vous syndic de l'immeuble ",
          "value": null,
          "oui":false,
          "non":false,
          "classes":"",
          "img_upload":"",
          "condition": 'testcond',
          "liste":
          [
            {
              "name":"Choix courtier",
              "type":"list_select",
              "label":"Courtier :",
              "value":"test",
              "classes":"",
              "value1": "Sans courtier",
              "values": [],
              "condition_enfant":"testcond"
            },
          ]
        },
      ]

    /***********************************************************************************************/

    // public api_post_single_visite(params, callback_success, callback_error){
    //   console.log("Request on URL:", "https://copro-back.cantem.fr/retrieve/visite-one", "with params:", params)
    //   this.http.post("https://copro-back.cantem.fr/retrieve/visite-one", params).subscribe(
    //     (response) => {
    //           callback_success(response)
    //     },
    //     (error) => {
    //          callback_error(error)
    //   });
    // }



    public additem(name, type, label, value, classes, img_upload, condition, value1, value2, value3, value4, value5) {

      if (!value)
      {
        value = null
      }

      let temp = Object();
      temp.name = name
      temp.type = type
      temp.label = label
      temp.value = value
      temp.classes = classes
      temp.img_upload = img_upload
      temp.condition = condition
      temp.value1 = value1
      temp.value2 = value2
      temp.value3 = value3
      temp.value4 = value4
      temp.value5 = value5

      if(type == "ouinon")
      {
        temp.oui = false;
        temp.non = false;
        this.formulaire.push(temp)
      }
      if(type == "textarea")
      {
        this.formulaire.push(temp)
      }
      if(type == "list_select")
      {
        this.formulaire.push(temp)
      }
      if(type == "txt")
      {
        this.formulaire.push(temp)
      }
      if(type == "list_num")
      {
        this.formulaire.push(temp)
      }
    }

    public ouinon($event, elem, value){
      if(!this.envoi){
        elem.value = value;
        if(value == true)
        {
          elem.oui = true;
          elem.non = false;
        }
        else {
          elem.oui = false;
          elem.non = true;
        }
      }
    }

    loggedIn() {
      return localStorage.getItem('token')
    }

    getToken() {
      return !!localStorage.getItem('token')
    }

  constructor(private http: HttpClient) {
    //this.entrees.push(JSON.parse(JSON.stringify(this.toctoctoc)))
    this.formulaire[3].tab.push(JSON.parse(JSON.stringify(this.toctoctoc)))
    this.batiments.push(JSON.parse(JSON.stringify(this.formulaire)))
  }
}
