import { Component, OnInit } from '@angular/core';
import { GalleryComponent } from '../../../gallery/gallery.component';
import { DataService } from "../../../data.service";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-evaluation-prestataires',
  templateUrl: './evaluation-prestataires.component.html',
  styleUrls: ['./evaluation-prestataires.component.scss']
})

export class EvaluationPrestatairesComponent implements OnInit {

  coproId: string;
  syndicId;
  src_img;
  gestionnaireId;
  pcsId;
  incidents;
  accessCode;
  gardien;
  cleCabinet;
  commentaire;
  abonnement = false;
  corpsEtat: [] = [];

  evaluationTTC = [];
  comEval = [];
  loaderEval = {}
  id =  this.activatedRoute.snapshot.params['id'];

  constructor(public GalleryComponent: GalleryComponent, private DataService: DataService, private activatedRoute: ActivatedRoute, public router: Router) {
    this.DataService.sharedMessage.subscribe(id => this.coproId = id);
  }

  sendIdToChild(syndicId) {
    this.DataService.fillMessage(syndicId);
  }

  goBack(): void {
    this.DataService.fillIndex('0');
    this.router.navigate([`./mes-syndics-prestataires/${this.syndicId}`], { relativeTo: this.activatedRoute.parent });
  }

  doEvaluation(incidentId, evaluationTTC, metrages, coproId, pcsId, syndicId, visiteId, courtierId, architecteId, gestionnaireId, commentaire, desordre, description, situation, corpsEtat, images) {
    let prestataireId = localStorage.getItem('id');
    this.loaderEval[incidentId] = true;
    this.DataService.api_evaluation(
      {incidentId, evaluationTTC, metrages, prestataireId, coproId, pcsId, syndicId, visiteId, courtierId, architecteId, gestionnaireId, commentaire, desordre, description, situation, corpsEtat, images},
      (data) => {
        this.loaderEval[incidentId] = false
        setTimeout(() => {
          alert(data.message);
        }, 100)
        for (let i in this.aFaire) {
          if (this.aFaire[i]._id === incidentId) {
            this.enAttente.push({...this.aFaire[i], evaluationTTC: this.evaluationTTC, comEval: this.comEval});
            this.evaluationTTC = [];
            this.comEval = [];
            break;
          }
        }
        this.aFaire = this.aFaire?.filter(inc => inc._id !== incidentId);
      },
      (error) => {
        this.loaderEval[incidentId] = false;
        console.log(error);
      });

  }

  nomPCS;
  emailPCS;
  phonePCS;
  aFaire = [];
  enAttente = []
  devis = [];
  evaluation = [];
  ngOnInit() {
    let _id = localStorage.getItem('id');
    this.DataService.api_get_devis({prestataireId: _id},
      (data) => {
        for (let i in data.list) {
          this.devis.push(data.list[i].incidentId);
          this.evaluation.push(data.list[i]);
        }
      },
      (error) => {
        console.log(error)
      });

    this.DataService.apiIncident(
      '/retrieve/incident-list',
      {coproId: this.id},
      async (data) => {
        this.src_img = data.copro.batiments[0]?.image?.facadeRue[0] ? `https://copro-back.cantem.fr/uploads/batiment/${data.copro.batiments[0].image.facadeRue[0]}` : "../assets/img/photo/immeuble-base.jpg";
        this.gestionnaireId = data.copro.gestionnaire;
        this.pcsId = data.copro.pcs;
        this.incidents = await data.incidents;
        this.abonnement = data.abonnements.includes(data.incidents[0].syndicId);
        this.aFaire = this.incidents?.filter(inc => !this.devis.includes(inc._id)) ?? [];
        this.enAttente = this.incidents?.filter(inc => this.devis.includes(inc._id)) ?? [];
        for (let key in this.aFaire) {
          this.loaderEval[this.aFaire[key]._id] = false;
          let item = this.evaluation.filter(el => this.aFaire[key]._id === el.incidentId);
          this.aFaire[key] = {
            ...this.aFaire[key],
            evaluationTTC: item[0]?.evaluationTTC,
            comEval: item[0]?.commentaire
          };
        }
        for (let key in this.enAttente) {
          let item = this.evaluation.filter(el => this.enAttente[key]._id === el.incidentId);
          this.enAttente[key] = {
            ...this.enAttente[key],
            evaluationTTC: item[0]?.evaluationTTC,
            comEval: item[0]?.commentaire
          };
        }
        if (data.incidents[data.incidents.length - 1].visiteId)
          this.DataService.api_post_single_visite(
            {_id: data.incidents[data.incidents.length - 1].visiteId},
            (data) => {
              this.accessCode = data.visite.accessCode;
              this.gardien = data.visite.gardien;
              this.cleCabinet = data.visite.cleCabinet;
              this.commentaire = data.visite.commentaire;
              this.syndicId = data.visite.syndicId;
              this.nomPCS = data.visite.nomPCS;
              this.emailPCS = data.visite.emailPCS;
              this.phonePCS = data.visite.phonePCS;
            },
            (error) => {
              console.log(error)
            })
      },
      (error) => {
        console.log(error)
      }
    )
  }

}
