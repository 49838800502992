import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataService} from "../../../../data.service";
import {ActivatedRoute} from "@angular/router";

export interface Section {
  src_img: string;
  name: string;
  id_el: string;
  address: string;
  codePostal: string;
  ville: string;
  nbEval: number;
  nbDevis: number;
}

@Component({
  selector: 'app-syndics-prestataires',
  templateUrl: './syndics-prestataires.component.html',
  styleUrls: ['./syndics-prestataires.component.scss']
})

export class SyndicsPrestatairesComponent implements OnInit {

  syndicId:string;
  index:number;

  onEvent(event) {
    event.stopPropagation();
  }

  confirmation(event) {
    let i : any;
    let id = event.srcElement.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.id
    if(id.length == 0) {
      id = event.srcElement.parentNode.parentNode.parentNode.parentNode.parentNode.id
    }
    if(confirm("Confirmer la suppresion ?")) {
    for( i in this.listCopro)
      {
        if(this.listCopro[i].id_el == id)
        {
         this.listCopro.splice(i, 1)
        }
      }
    }
  }

  confirmation2(event) {
    let i : any;
    let id = event.srcElement.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.id
    if(id.length == 0) {
      id = event.srcElement.parentNode.parentNode.parentNode.parentNode.parentNode.id
    }
    if(confirm("Confirmer la suppresion ?")) {
    for( i in this.listCopro2)
      {
        if(this.listCopro2[i].id_el == id)
        {
         this.listCopro2.splice(i, 1)
        }
      }
    }
  }

  PrestatairesDetailsArray;
  searchVal: string;
  resultSearch:Section[] = [];
  listCopro:Section[] = [];

  searchVal2: string;
  resultSearch2:Section[] = [];
  listCopro2:Section[] = [];

  id =  this.activatedRoute.snapshot.params['id'];

  constructor(public DataService: DataService, private activatedRoute: ActivatedRoute) {
    this.DataService.sharedMessage.subscribe(id => this.syndicId = id);
    this.DataService.sharedIndex.subscribe(index => this.index = parseInt(index));
    console.log(this.index);
  }

  sendIdToChild(id) {
    this.DataService.fillMessage(id);
  }

  devis = [];
  devisPDF = [];

  ngOnInit(): void {
    let prestataireId = localStorage.getItem('id');
    this.DataService.api_get_devis({prestataireId},
      (data) => {
        for (let i in data.list) {
          if (data.list[i].demandeDevis)
            this.devis.push(data.list[i].coproId);
          if (data.list[i].devisPDF)
            this.devisPDF.push(data.list[i].coproId);
        }
      },
      (error) => {
        console.log(error)
      });

    this.DataService.api_get_copro_syndic(
      {syndicId: this.id},
      (data) => {
        for (let key in data.copros) {
          this.listCopro[key] = {
            src_img: data.copros[key].batiments[0]?.image?.facadeRue[0] ? `https://copro-back.cantem.fr/uploads/batiment/${data.copros[key].batiments[0].image.facadeRue[0]}` : "../assets/img/photo/immeuble-base.jpg",
            name: data.copros[key].nomCopro,
            id_el: data.copros[key]._id,
            address: data.copros[key].address,
            codePostal: data.copros[key].codePostal,
            ville: data.copros[key].ville,
            nbEval: 0,
            nbDevis: 0
          }
          if (this.devis.includes(data.copros[key]._id)) {
            let nbEval = 0;
            let nbDevis = 0;
            for (let i in this.devis) {
              if (this.devis[i] === data.copros[key]._id)
                nbEval += 1;
            }
            for (let i in this.devisPDF) {
              if (this.devisPDF[i] === data.copros[key]._id)
                nbDevis += 1;
            }
            this.listCopro[key].nbEval = nbEval;
            this.listCopro[key].nbDevis = nbDevis;
            this.listCopro2.push(this.listCopro[key]);
          }
        }
      },
      (error) => {
        console.log("error: ", error)
      });

    this.resultSearch = this.listCopro;
    this.resultSearch2 = this.listCopro2;


  }

  searchEngine() {
    this.resultSearch = [];

    for(let key in this.listCopro) {
      if(
        this.listCopro[key].address.toLowerCase().indexOf(this.searchVal.toLowerCase())>-1 && this.resultSearch.length<4 ||
        this.listCopro[key].codePostal.toLowerCase().indexOf(this.searchVal.toLowerCase())>-1 && this.resultSearch.length<4 ||
        this.listCopro[key].name.toLowerCase().indexOf(this.searchVal.toLowerCase())>-1 && this.resultSearch.length<4 ||
        this.listCopro[key].ville.toLowerCase().indexOf(this.searchVal.toLowerCase())>-1 && this.resultSearch.length<4
        ){
        this.resultSearch.push(this.listCopro[key]);
      }
    }
  }

  searchEngine2() {
    this.resultSearch2 = [];

    for(let key in this.listCopro2) {
      if(
        this.listCopro2[key].address.toLowerCase().indexOf(this.searchVal2.toLowerCase())>-1 && this.resultSearch2.length<4 ||
        this.listCopro2[key].codePostal.toLowerCase().indexOf(this.searchVal2.toLowerCase())>-1 && this.resultSearch2.length<4 ||
        this.listCopro2[key].name.toLowerCase().indexOf(this.searchVal2.toLowerCase())>-1 && this.resultSearch2.length<4 ||
        this.listCopro2[key].ville.toLowerCase().indexOf(this.searchVal2.toLowerCase())>-1 && this.resultSearch2.length<4
        ){
        this.resultSearch2.push(this.listCopro2[key]);
      }
    }
  }
}
