<div class="padding-card padding-card-pos">
  <mat-card>
    <mat-card-content class="content-pos txt_medium">
      <div fxLayout="row wrap" *ngIf="info?.faiteLe == undefined">
        <div fxLayout="row wrap" fxFlex="50" fxFlex.xs="100" fxLayoutAlign="center">
          <div  fxFlex="100" fxLayoutAlign="center" class="titre_gras top10 imgFirst" style="margin-left: 50px;">
            <form [formGroup]="formParcelle" class="infixUpload" enctype="multipart/form-data">
              <mat-form-field appearance="outline" class="iconWidth">
                <mat-label style="font-size: 12px; position: relative; top: -5px;">Importer une photo</mat-label>
                <ngx-mat-file-input [accept]="'image/*'" [(ngModel)]="dataParcelle" formControlName="fileParcelle" (change)="uploadFileParcelle('ParcelleCadastrale')">
                  <mat-icon class="iconFileTop"  ngxMatFileInputIcon>add_a_photo</mat-icon>
                </ngx-mat-file-input>
              </mat-form-field>
            </form>
          </div>
          <div fxFlex="100" fxLayoutAlign="center" class="titre_gras top10 imgFirstTop">Parcelle Cadastrale</div>
        </div>
        <div fxLayout="row wrap" fxFlex="50" fxFlex.xs="100" fxLayoutAlign="center" *ngIf="info?.faiteLe == undefined">
          <div  fxFlex="100" fxLayoutAlign="center" class="titre_gras top10 imgFirst" style="margin-left: 50px;">
            <form [formGroup]="formGoogle" class="infixUpload" enctype="multipart/form-data">
              <mat-form-field appearance="outline" class="iconWidth">
                <mat-label style="font-size: 12px; position: relative; top: -5px;">Importer une photo</mat-label>
                <ngx-mat-file-input [accept]="'image/*'" [(ngModel)]="dataGoogle" formControlName="fileGoogle" (change)="uploadFileGoogle('VueGenGoogle')">
                  <mat-icon class="iconFileTop"  ngxMatFileInputIcon>add_a_photo</mat-icon>
                </ngx-mat-file-input>
              </mat-form-field>
            </form>
          </div>
          <div fxFlex="100" fxLayoutAlign="center" class="titre_gras top10 imgFirstTop">Vue générale Google</div>
        </div>
      </div>
      <div fxLayout="row wrap" *ngIf="info?.faiteLe" style="display: flex; justify-content: space-between; flex-direction: row;">
        <div fxLayout="row wrap" fxFlex="50" fxFlex.xs="50" fxLayoutAlign="center">
          <div fxFlex="100" fxLayoutAlign="center" class="titre_gras top10 imgFirstTop" *ngIf="resultEnvoyee[0].image.ParcelleCadastrale[0]">Parcelle Cadastrale</div>
          <span class="" gallerize="ParcelleCadastrale{{resultEnvoyee[0]._id}}">
            <span fxLayoutAlign="start" *ngIf="resultEnvoyee[0].image.ParcelleCadastrale">
              <img *ngIf="resultEnvoyee[0].image.ParcelleCadastrale[0]" [src]="'https://copro-back.cantem.fr/uploads/batiment/'+resultEnvoyee[0].image.ParcelleCadastrale[0]" style="width: 50px; height: 50px; margin-right: 5px;" alt="">
            </span>
          </span>
        </div>
        <div fxLayout="row wrap" fxFlex="50" fxFlex.xs="50" fxLayoutAlign="center">
          <div fxFlex="100" fxLayoutAlign="center" class="titre_gras top10 imgFirstTop" *ngIf="resultEnvoyee[0].image.VueGenGoogle[0]">Vue Générale Google</div>
          <span class="" gallerize="VueGenGoogle{{resultEnvoyee[0]._id}}">
            <span fxLayoutAlign="start" *ngIf="resultEnvoyee[0].image.VueGenGoogle">
              <img *ngIf="resultEnvoyee[0].image.VueGenGoogle[0]" [src]="'https://copro-back.cantem.fr/uploads/batiment/'+resultEnvoyee[0].image.VueGenGoogle[0]" style="width: 50px; height: 50px; margin-right: 5px;">
            </span>
          </span>
        </div>
      </div>

      <!-- <input-file-test (files)="fileChanged2($event)"
      [accept]="'image/*'"
      #input2></input-file-test> -->

      <!--Formulaire Visites -->

      <div fxLayout="row wrap">
        <div fxFlex="60" fxFlex.xs="100" fxFlex.sm="100" class="padding-card padding-card-pos">


          <div *ngIf="info?.faiteLe == undefined" fxLayout="row wrap" class="padding-card padding-card-pos">
            <div fxFlex="100">
              <span fxLayout="row wrap" fxLayoutAlign="start" style="text-align: left;">
                <div fxFlex="100" class="pad_num">
                  <span class="marge_r10">Nombre de bâtiments</span>
                  <mat-form-field appearance="outline">
                    <input matInput type="number" [(ngModel)]="FormService.nbrbat" min="1" placeholder="Ex: 5" (change)="batiments()" [disabled]="FormService.envoi">
                  </mat-form-field>
                </div>
              </span>

              <span *ngFor="let item of FormService.batiments; let y = index;">
                <div class="padd_xs"  class="pad10_0" style="text-align: left;">
                  <div class="pad10_0"><label><span class="titre_batiment">Bâtiment {{y+1}} :</span></label> </div>
                </div>
                <!---------------- INTRO ----------------------->

                                    <div>
                                        <span fxLayoutAlign="start" *ngFor="let intro of FormService.formIntro[y]" style="text-align: left;">
                                            <!-- OUI/NON  -->
                                            <section class="padd_xs"  *ngIf="intro.type == 'ouinon'" class="pad10_0">
                                                <label><span [ngClass]="intro.classes">{{intro.label}}</span></label>
                                                <mat-checkbox class="checkpos checksize" (click)="this.FormService.ouinon($event, intro, true)" [checked]="intro.oui" [disabled]="true">Oui</mat-checkbox>
                                                <mat-checkbox class="checkpos checksize" (click)="this.FormService.ouinon($event, intro, false)" [checked]="intro.non" [disabled]="true">Non</mat-checkbox>
                                                <span class="upload_pos" *ngIf="intro.img_upload == 'avec'"><mat-button-toggle><mat-icon aria-hidden="false" aria-label="Ajouter une photo" class="addPhoto">add_a_photo</mat-icon><span class="imp_imgtxt">Importer une image</span></mat-button-toggle></span><br>
                                            </section>
                                          <!--textarea-->
                                            <div class="padd_xs"  *ngIf="intro.type == 'textarea'  && parseConditionsForm(intro.condition, null, y)" class="pad10_0">
                                                <div class="pad10_0"><label><span [ngClass]="intro.classes">{{intro.label}}</span></label>
                                                    <span class="upload_pos" *ngIf="intro.img_upload == 'avec'"><mat-button-toggle><mat-icon aria-hidden="false" aria-label="Ajouter une photo" class="addPhoto">add_a_photo</mat-icon><span class="imp_imgtxt">Importer une image</span></mat-button-toggle></span><br>
                                                </div>
                                                <textarea [(ngModel)]="intro.value" [disabled]="FormService.envoi"></textarea>
                                            </div>

                                          <!-- select_list valeurs de Thor-->
                                            <div *ngIf="intro.type == 'list_select'  && this.parseConditionsForm(intro.condition, null, y)" class="pad10_0 sans_shadow labelPosSelect">
                                                <span class="marge_r10 pos_labelselect"><span [ngClass]="intro.classes">{{intro.label}}</span></span>
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Séléctionner une valeur</mat-label>
                                                    <select matNativeControl [(ngModel)]="intro.value" [disabled]="FormService.envoi">
                                                    <option  *ngFor="let option of intro.list" value="{{option.toLowerCase()}}">{{option}}</option>
                                                    </select>
                                                </mat-form-field>
                                                <span class="upload_pos_list" *ngIf="intro.img_upload == 'avec'"><mat-button-toggle><mat-icon aria-hidden="false" aria-label="Ajouter une photo" class="addPhoto">add_a_photo</mat-icon><span class="imp_imgtxt">Importer une image</span></mat-button-toggle></span><br>
                                            </div>
                                          <!-- select_list_2 valeurs de Thor-->
                                            <div *ngIf="intro.type == 'list_select_2'  && this.parseConditionsForm(intro.condition, null, y)" class="pad10_0 sans_shadow labelPosSelect">
                                                <span class="marge_r10 pos_labelselect"><span [ngClass]="intro.classes">{{intro.label}}</span></span>
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Séléctionner une valeur</mat-label>
                                                    <select matNativeControl [(ngModel)]="intro.value" [disabled]="FormService.envoi">
                                                    <option  *ngFor="let option of intro.list" value="{{option}}">{{option}}</option>
                                                    </select>
                                                </mat-form-field>
                                                <span class="upload_pos_list" *ngIf="intro.img_upload == 'avec'"><mat-button-toggle><mat-icon aria-hidden="false" aria-label="Ajouter une photo" class="addPhoto">add_a_photo</mat-icon><span class="imp_imgtxt">Importer une image</span></mat-button-toggle></span><br>
                                            </div>
                                          <!--txt-->
                                            <div class="padd_xs" fxLayout="row wrap"  *ngIf="intro.type == 'txt'  && this.parseConditionsForm(intro.condition, null, y)" class="pad10_0">
                                                <div fxLayoutFlex="50" class="pad10_0"><label><span [ngClass]="intro.classes">{{intro.label}}</span></label></div>
                                                <span fxLayoutFlex="50" class="upload_pos importImg" *ngIf="intro.img_upload === 'facadeRue'">
                                                    <form [formGroup]="formRue"  class="infixUpload entreeImage" enctype="multipart/form-data">
                                                        <mat-form-field appearance="outline" class="iconWidth formFacadeRue">
                                                            <mat-label style="display: flex; align-self: start; margin-left: 32px;" >Importer une photo</mat-label>
                                                            <ngx-mat-file-input style="position: absolute; top: 0;" class="entreeImage" [accept]="'image/*'" [(ngModel)]="dataRue" formControlName="fileRue" multiple (change)="uploadFileRue(intro.img_upload, y)">
                                                                <mat-icon class="iconFile"  ngxMatFileInputIcon>add_a_photo</mat-icon>
                                                            </ngx-mat-file-input>
                                                            <div *ngFor="let file of this.OriginalImage[y][intro.img_upload]; let i = index"
                                                                 [attr.data-index]="i"
                                                                 style="flex-direction: row;display:flex;margin-left: 10px;"
                                                            >
                                                              <div style="margin-right: 10px;">{{file.name}}</div>
                                                                <div
                                                                  type="button"
                                                                  style="justify-self: center; display: flex; height: 12px; width: 12px; position: static; cursor: pointer;"
                                                                  (click)="deleteImage(i, intro.img_upload, 'dataRue', 0, y)">
                                                                  <mat-icon style="transform: scale(0.70);">
                                                                    clear
                                                                  </mat-icon>
                                                                </div>
                                                            </div>
                                                        </mat-form-field>
                                                    </form>
                                                </span><br>
                                                <span fxLayoutFlex="50" class="upload_pos importImg" *ngIf="intro.img_upload === 'facadeArriere'">
                                                    <form [formGroup]="formArriere"  class="infixUpload entreeImage" enctype="multipart/form-data">
                                                        <mat-form-field appearance="outline" class="iconWidth formFacadeRue">
                                                            <mat-label style="display: flex; align-self: start; margin-left: 32px;" >Importer une photo</mat-label>
                                                            <ngx-mat-file-input style="position: absolute; top: 0;" class="entreeImage" [accept]="'image/*'" [(ngModel)]="dataArriere" formControlName="fileArriere" multiple (change)="uploadFileArriere(intro.img_upload, y)">
                                                                <mat-icon class="iconFile"  ngxMatFileInputIcon>add_a_photo</mat-icon>
                                                            </ngx-mat-file-input>
                                                            <div *ngFor="let file of this.OriginalImage[y][intro.img_upload]; let i = index"
                                                                 [attr.data-index]="i"
                                                                 style="flex-direction: row;display:flex;margin-left: 10px;"
                                                            >
                                                              <div style="margin-right: 10px;">{{file.name}}</div>
                                                                <div
                                                                  type="button"
                                                                  style="justify-self: center; display: flex; height: 12px; width: 12px; position: static; cursor: pointer;"
                                                                  (click)="deleteImage(i, intro.img_upload, 'dataArriere', 0, y)">
                                                                  <mat-icon style="transform: scale(0.70);">
                                                                    clear
                                                                  </mat-icon>
                                                                </div>
                                                            </div>
                                                        </mat-form-field>
                                                    </form>
                                                </span><br>
                                            </div>
                                        </span>
                                    </div>
                <!-------------------- FIN INTRO ----------------->
                                    <span *ngFor="let elem of item" fxLayoutAlign="start" style="text-align: left;">
                                        <!-- OUI/NON  -->
                                        <section class="padd_xs"  *ngIf="elem.type == 'ouinon' && elem.value !== 'desactive' && this.parseConditionsForm(elem.condition, true, y)" class="pad10_0">
                                            <label><span [ngClass]="elem.classes">{{elem.label}}</span></label>
                                            <mat-checkbox class="checkpos checksize" (click)="this.FormService.ouinon($event, elem, true)" [checked]="elem.oui" [disabled]="true">Oui</mat-checkbox>
                                            <mat-checkbox class="checkpos checksize" (click)="this.FormService.ouinon($event, elem, false)" [checked]="elem.non" [disabled]="true">Non</mat-checkbox>
                                            <span class="upload_pos" *ngIf="elem.img_upload == 'avec'"><mat-button-toggle><mat-icon aria-hidden="false" aria-label="Ajouter une photo" class="addPhoto">add_a_photo</mat-icon><span class="imp_imgtxt">Importer une image</span></mat-button-toggle></span><br>
                                        </section>

                                      <!-- Input -->
                                        <div *ngIf="elem.type == 'inputNormal' && FormService.nbrbat !== 1 && this.parseConditionsForm(elem.condition, true, y)" class="padInputNormal">
                                            <span  class="marge_r10" [ngClass]="elem.classes">{{elem.label}}</span>
                                            <mat-form-field appearance="outline">
                                                <input [(ngModel)]="elem.value" placeholder="{{elem.placeholder}}"  matInput [disabled]="FormService.envoi">
                                            </mat-form-field>
                                        </div>
                                      <!-- InputReadOnly -->
                                        <div *ngIf="elem.type == 'inputReadOnly' && this.parseConditionsForm(elem.condition, true, y)" class="padInputNormal">
                                            <span  class="marge_r10" [ngClass]="elem.classes">{{elem.label}}</span>
                                            <mat-form-field appearance="outline">
                                                <input [(ngModel)]="elem.value" placeholder="{{this.refVisite}}" matInput disabled>
                                            </mat-form-field>
                                        </div>
                                      <!-- List_number -->
                                        <div *ngIf="elem.type == 'list_num' && elem.value !== 'desactive' && this.parseConditionsForm(elem.condition, true, y)" class="pad_num">
                                            <span class="marge_r10" [ngClass]="elem.classes">{{elem.label}}</span>
                                            <mat-form-field appearance="outline">
                                                <input matInput type="number" [(ngModel)]="elem.value" placeholder="Ex: 5" [disabled]="FormService.envoi">
                                            </mat-form-field>
                                            <span class="upload_pos" *ngIf="elem.img_upload == 'avec'"><mat-button-toggle><mat-icon aria-hidden="false" aria-label="Ajouter une photo" class="addPhoto">add_a_photo</mat-icon><span class="imp_imgtxt">Importer une image</span></mat-button-toggle></span><br>
                                        </div>

                                      <!-- List_number m2 -->
                                        <div *ngIf="elem.type == 'list_numm2' && elem.value !== 'desactive' && this.parseConditionsForm(elem.condition, true, y)" class="padInputNormal">
                                            <span class="marge_r10" [ngClass]="elem.classes">{{elem.label}}</span>
                                            <mat-form-field appearance="outline">
                                                <input matInput type="number" [(ngModel)]="elem.value" placeholder="Ex: 500" [disabled]="FormService.envoi">
                                            </mat-form-field>
                                            <span class="upload_pos" *ngIf="elem.img_upload == 'avec'"><mat-button-toggle><mat-icon aria-hidden="false" aria-label="Ajouter une photo" class="addPhoto">add_a_photo</mat-icon><span class="imp_imgtxt">Importer une image</span></mat-button-toggle></span><br>
                                        </div>

                                      <!--textarea-->
                                        <div class="padd_xs"  *ngIf="elem.type == 'textarea' && elem.value !== 'desactive' && this.parseConditionsForm(elem.condition, true, y)" class="pad10_0">
                                            <div class="pad10_0"><label><span [ngClass]="elem.classes">{{elem.label}}</span></label>
                                                <span class="upload_pos" *ngIf="elem.img_upload == 'avec'"><mat-button-toggle><mat-icon aria-hidden="false" aria-label="Ajouter une photo" class="addPhoto">add_a_photo</mat-icon><span class="imp_imgtxt">Importer une image</span></mat-button-toggle></span><br>
                                            </div>
                                            <textarea [(ngModel)]="elem.value" [disabled]="FormService.envoi"></textarea>
                                        </div>
                                      <!--datePicker-->
                                        <div class="padd_xs"  *ngIf="elem.type == 'datePicker' && elem.value !== 'desactive' && this.parseConditionsForm(elem.condition, true, y)" class="pad10_0">
                                            <mat-form-field class="example-full-width" appearance="outline">
                                                <mat-label>{{elem.label}}</mat-label>
                                                <input matInput [(ngModel)]="elem.value" [matDatepicker]="picker" disabled>
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker disabled="false" [disabled]="FormService.envoi"></mat-datepicker>
                                            </mat-form-field>
                                        </div>

                                      <!-- select_list valeurs de Thor-->
                                        <div fxLayout="row wrap" *ngIf="elem.type == 'list_select' && elem.value !== 'desactive' && this.parseConditionsForm(elem.condition, true, y)" class="pad10_0 sans_shadow labelPosSelect">
                                            <span fxLayoutFlex="50">
                                                <span class="marge_r10 pos_labelselect"><span [ngClass]="elem.classes">{{elem.label}}</span></span>
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Séléctionner une valeur</mat-label>
                                                        <select matNativeControl [(ngModel)]="elem.value" [disabled]="FormService.envoi">
                                                        <option  *ngFor="let option of elem.list" value="{{option.toLowerCase() === 'sans objet' ? 'sansObjet' : option.toLowerCase()}}">{{option}}</option>
                                                    </select>
                                                </mat-form-field>
                                            </span>
                                            <span fxLayoutFlex="50" class="upload_pos importImg" *ngIf="elem.img_upload && elem.name === 'cave_encombrement'">
                                                <form [formGroup]="formCave" class="infixUpload entreeImage" enctype="multipart/form-data">
                                                    <mat-form-field appearance="outline" class="iconWidth formAscenseur">
                                                        <mat-label style="display: flex; align-self: start; margin-left: 32px;" >Importer une photo</mat-label>
                                                        <ngx-mat-file-input style="position: absolute; top: -25px;" class="entreeImage" [accept]="'image/*'" [(ngModel)]="dataCave" formControlName="fileCave" multiple (change)="uploadFileCave(elem.img_upload, y)">
                                                            <mat-icon class="iconFile"  ngxMatFileInputIcon>add_a_photo</mat-icon>
                                                        </ngx-mat-file-input>
                                                        <div *ngFor="let file of this.OriginalImage[y][elem.img_upload]; let i = index"
                                                             [attr.data-index]="i"
                                                             style="flex-direction: row;display:flex;margin-left: 10px;"
                                                        >
                                                          <div style="margin-right: 10px;">{{file.name}} - {{file.type}}</div>
                                                                <div
                                                                  type="button"
                                                                  style="justify-self: center; display: flex; height: 12px; width: 12px; position: static; cursor: pointer;"
                                                                  (click)="deleteImage(i, elem.img_upload, 'dataCave', 0, 0)"
                                                                >
                                                            <mat-icon style="transform: scale(0.70);">
                                                              clear
                                                            </mat-icon>
                                                          </div>
                                                        </div>
                                                    </mat-form-field>
                                                </form>
                                            </span><br>
                                        </div>
                                      <!-- select_list_2 valeurs de Thor-->
                                        <div *ngIf="elem.type == 'list_select_2' && (!elem.condition || FormService.batiments[y][elem.condition.pos].value === elem.condition.value)" class="pad10_0 sans_shadow labelPosSelect">
                                            <span class="marge_r10 pos_labelselect"><span [ngClass]="elem.classes">{{elem.label}}</span></span>
                                            <mat-form-field appearance="outline">
                                                <mat-label>Séléctionner une valeur</mat-label>
                                                <select matNativeControl [(ngModel)]="elem.value" [disabled]="FormService.envoi">
                                                <option  *ngFor="let option of elem.list" value="{{option}}">{{option}}</option>
                                                </select>
                                            </mat-form-field>
                                            <span class="upload_pos_list" *ngIf="elem.img_upload == 'avec'"><mat-button-toggle><mat-icon aria-hidden="false" aria-label="Ajouter une photo" class="addPhoto">add_a_photo</mat-icon><span class="imp_imgtxt">Importer une image</span></mat-button-toggle></span><br>
                                        </div>
                                       <div *ngIf="elem.type == 'list_select_3' && (!elem.condition || FormService.batiments[y][elem.condition.pos].value === elem.condition.value)" class="pad10_0 sans_shadow labelPosSelect">
                                            <span class="marge_r10 pos_labelselect"><span [ngClass]="elem.classes">{{elem.label}}</span></span>
                                            <mat-form-field appearance="outline">
                                                <mat-label>Séléctionner une valeur</mat-label>
                                                <select matNativeControl [(ngModel)]="elem.value" [disabled]="FormService.envoi">
                                                <option  *ngFor="let option of elem.list" [ngValue]="option === 'Collective' ? true : false">{{option}}</option>
                                                </select>
                                            </mat-form-field>
                                        </div>
                                      <!--txt-->
                                        <div class="padd_xs" fxLayout="row wrap"  *ngIf="elem.type == 'txt' && elem.value !== 'desactive' && (!elem.condition || FormService.batiments[y][elem.condition.pos].value === elem.condition.value)" class="pad10_0">
                                            <div fxLayoutFlex="50" class="pad10_0"><label><span [ngClass]="elem.classes">{{elem.label}}</span></label></div>
                                            <span fxLayoutFlex="50" class="upload_pos importImg" *ngIf="elem.img_upload && elem.name === 'environnement'">
                                                <form [formGroup]="formEnvi" style="margin-top: 10px;" class="infixUpload entreeImage" enctype="multipart/form-data">
                                                    <mat-form-field appearance="outline" class="iconWidth formBottomImage">
                                                        <mat-label style="display: flex; align-self: start; margin-left: 32px;">Importer une photo</mat-label>
                                                        <ngx-mat-file-input style="position: absolute; top: -10px;" class="entreeImage" [accept]="'image/*'" [(ngModel)]="dataEnvi" formControlName="fileEnvi" multiple (change)="uploadFileEnvi(elem.img_upload, y)">
                                                            <mat-icon class="iconFile"  ngxMatFileInputIcon>add_a_photo</mat-icon>
                                                        </ngx-mat-file-input>
                                                        <div *ngFor="let file of this.OriginalImage[y][elem.img_upload]; let i = index;"
                                                             [attr.data-index]="i"
                                                             style="flex-direction: row;display:flex;margin-left: 10px;">
                                                          <div style="margin-right: 10px;">{{file.name}} </div>
                                                                <div
                                                                  type="button"
                                                                  style="justify-self: center; display: flex; height: 12px; width: 12px; position: static; cursor: pointer;"
                                                                  (click)="deleteImage(i, elem.img_upload, 'dataEnvi', 0, 0)"
                                                                >
                                                            <mat-icon style="transform: scale(0.70);">
                                                              clear
                                                            </mat-icon>
                                                          </div>
                                                        </div>
                                                    </mat-form-field>
                                                </form>
                                            </span><br>
                                            <span fxLayoutFlex="50" class="upload_pos importImg" *ngIf="elem.img_upload && elem.name === 'contiguite'">
                                                <form [formGroup]="formConti" style="margin-top: 10px;" class="infixUpload entreeImage" enctype="multipart/form-data" >
                                                    <mat-form-field appearance="outline" class="iconWidth formBottomImage">
                                                        <mat-label style="display: flex; align-self: start; margin-left: 32px;">Importer une photo</mat-label>
                                                        <ngx-mat-file-input style="position: absolute; top: -10px;" class="entreeImage"  [accept]="'image/*'" [(ngModel)]="dataConti" formControlName="fileConti" multiple (change)="uploadFileConti(elem.img_upload, y)">
                                                            <mat-icon class="iconFile"  ngxMatFileInputIcon>add_a_photo</mat-icon>
                                                        </ngx-mat-file-input>
                                                        <div
                                                          *ngFor="let file of this.OriginalImage[y][elem.img_upload]; let i = index"
                                                          [attr.data-index]="i"
                                                          style="flex-direction: row;display:flex;margin-left: 10px;"
                                                        >
                                                          <div style="margin-right: 10px;">{{file.name}}</div>
                                                            <div
                                                              type="button"
                                                              style="justify-self: center; display: flex; height: 12px; width: 12px; position: static; cursor: pointer;"
                                                              (click)="deleteImage(i, elem.img_upload, 'dataConti', 0, 0)"
                                                            >
                                                              <mat-icon style="transform: scale(0.70);">
                                                                clear
                                                              </mat-icon>
                                                            </div>
                                                        </div>
                                                    </mat-form-field>
                                                </form>
                                            </span><br>
                                            <span fxLayoutFlex="50" class="upload_pos importImg" *ngIf="elem.img_upload && elem.name === 'parkingST_img'">
                                                <form [formGroup]="formParking" class="infixUpload entreeImage" enctype="multipart/form-data">
                                                    <mat-form-field appearance="outline" class="iconWidth formAscenseur">
                                                        <mat-label style="display: flex; align-self: start; margin-left: 32px;">Importer une photo</mat-label>
                                                        <ngx-mat-file-input style="position: absolute; top: -20px;" class="entreeImage" [accept]="'image/*'" [(ngModel)]="dataParking" formControlName="fileParking" multiple (change)="uploadFileParking(elem.img_upload, y)">
                                                            <mat-icon class="iconFile"  ngxMatFileInputIcon>add_a_photo</mat-icon>
                                                        </ngx-mat-file-input>
                                                        <div
                                                          *ngFor="let file of this.OriginalImage[y][elem.img_upload]; let i = index"
                                                          [attr.data-index]="i"
                                                          style="flex-direction: row;display:flex;margin-left: 10px;"
                                                        >
                                                          <div style="margin-right: 10px;">{{file.name}}</div>
                                                                <div
                                                                  type="button"
                                                                  style="justify-self: center; display: flex; height: 12px; width: 12px; position: static; cursor: pointer;"
                                                                  (click)="deleteImage(i, elem.img_upload, 'dataParking', 0, 0)"
                                                                >
                                                            <mat-icon style="transform: scale(0.70);">
                                                              clear
                                                            </mat-icon>
                                                          </div>
                                                        </div>
                                                    </mat-form-field>
                                                </form>
                                            </span><br>
                                        </div>

                                      <!--nbentrees-->
                                        <div *ngIf="elem.type == 'nbentrees'" class="pad_num">
                                            <span class="marge_r10" [ngClass]="elem.classes">{{elem.label}}</span>
                                            <mat-form-field appearance="outline">
                                                <input matInput type="number" [(ngModel)]="elem.value" placeholder="Ex: 5" (change)="entrees(y)" [disabled]="FormService.envoi">
                                            </mat-form-field>
                                        </div>
                                      <!--entrees-->
                                        <div *ngIf="elem.type == 'entrees'">
                                            <span *ngFor="let x of FormService.batiments[y][3].tab; let i = index;">
                                                <div class="padd_xs"  class="pad10_0">
                                                    <div class="pad10_0"><label><span class="titre_gras">Entrée {{i+1}} :</span></label> </div>
                                                </div>
                                            <span fxLayoutAlign="start" *ngFor="let entree of FormService.batiments[y][3].tab[i]" style="text-align: left;">

                                                <!-- OUI/NON  -->
                                                <section class="padd_xs"
                                                         [ngStyle]="{'margin-top': (entree.name=='porteVitree' || entree.name === 'porteVitreeSecond') ? '-28px' : 'inherit', 'margin-bottom': (entree.name=='porteVitree') ? '25px' : 'inherit'}"
                                                         *ngIf="entree.type == 'ouinon' && entree.value !== 'desactive'&& (!entree.condition || FormService.batiments[y][3].tab[i][entree.condition.pos].value === entree.condition.value)"
                                                         class="pad10_0" style="min-width: 120%">
                                                    <label><span [ngClass]="entree.classes">{{entree.label}}</span></label>
                                                    <mat-checkbox class="checkpos checksize" (click)="this.FormService.ouinon($event, entree, true)" [checked]="entree.oui" [disabled]="true">Oui</mat-checkbox>
                                                    <mat-checkbox class="checkpos checksize" (click)="this.FormService.ouinon($event, entree, false)" [checked]="entree.non" [disabled]="true">Non</mat-checkbox>
                                                    <span class="upload_pos" *ngIf="entree.img_upload == 'avec'"><mat-button-toggle><mat-icon aria-hidden="false" aria-label="Ajouter une photo" class="addPhoto">add_a_photo</mat-icon><span class="imp_imgtxt">Importer une image</span></mat-button-toggle></span><br>
                                                </section>

                                              <!-- Input -->

                                                <div *ngIf="entree.type == 'inputNormal' && (!entree.condition || FormService.batiments[y][3].tab[i][entree.condition.pos].value === entree.condition.value)" class="padInputNormal">
                                                    <span  class="marge_r10" [ngClass]="entree.classes">{{entree.label}}</span>
                                                    <mat-form-field appearance="outline">
                                                        <input [(ngModel)]="entree.value" placeholder="{{entree.placeholder}}"  matInput [disabled]="FormService.envoi">
                                                    </mat-form-field>
                                                </div>

                                              <!-- List_number -->
                                                <div *ngIf="entree.type == 'list_num' && entree.value !== 'desactive' && (!entree.condition || FormService.batiments[y][3].tab[i][entree.condition.pos].value === entree.condition.value)" class="pad_num">
                                                    <span class="marge_r10" [ngClass]="entree.classes">{{entree.label}}</span>
                                                    <mat-form-field appearance="outline">
                                                        <input matInput type="number" [(ngModel)]="entree.value" placeholder="Ex: 5" [disabled]="FormService.envoi">
                                                    </mat-form-field>
                                                    <span class="upload_pos" *ngIf="entree.img_upload == 'avec'"><mat-button-toggle><mat-icon aria-hidden="false" aria-label="Ajouter une photo" class="addPhoto">add_a_photo</mat-icon><span class="imp_imgtxt">Importer une image</span></mat-button-toggle></span><br>
                                                </div>

                                              <!-- List_number m2 -->
                                                <div *ngIf="entree.type == 'list_numm2' && entree.value !== 'desactive' && (!entree.condition || FormService.batiments[y][3].tab[i][entree.condition.pos].value === entree.condition.value)" class="padInputNormal">
                                                    <span class="marge_r10" [ngClass]="entree.classes">{{entree.label}}</span>
                                                    <mat-form-field appearance="outline">
                                                        <input matInput type="number" [(ngModel)]="entree.value" placeholder="Ex: 500" [disabled]="FormService.envoi">
                                                    </mat-form-field>
                                                    <span class="upload_pos" *ngIf="entree.img_upload == 'avec'"><mat-button-toggle><mat-icon aria-hidden="false" aria-label="Ajouter une photo" class="addPhoto">add_a_photo</mat-icon><span class="imp_imgtxt">Importer une image</span></mat-button-toggle></span><br>
                                                </div>

                                              <!--textarea-->
                                                <div class="padd_xs"  *ngIf="entree.type == 'textarea' && entree.value !== 'desactive' && (!entree.condition || FormService.batiments[y][3].tab[i][entree.condition.pos].value === entree.condition.value)" class="pad10_0">
                                                    <div class="pad10_0"><label><span [ngClass]="entree.classes">{{entree.label}}</span></label>
                                                        <span class="upload_pos" *ngIf="entree.img_upload == 'avec'"><mat-button-toggle><mat-icon aria-hidden="false" aria-label="Ajouter une photo" class="addPhoto">add_a_photo</mat-icon><span class="imp_imgtxt">Importer une image</span></mat-button-toggle></span><br>
                                                    </div>
                                                    <textarea [(ngModel)]="entree.value" [disabled]="FormService.envoi"></textarea>
                                                </div>

                                              <!-- select_list valeurs de Thor-->
                                                <div fxLayout="row wrap" *ngIf="entree.type == 'list_select' && entree.value !== 'desactive' && (!entree.condition || FormService.batiments[y][3].tab[i][entree.condition.pos].value === entree.condition.value)" class="pad10_0 sans_shadow labelPosSelect">
                                                    <span fxLayoutFlex="70">
                                                        <span class="marge_r10 pos_labelselect"><span [ngClass]="entree.classes">{{entree.label}}</span></span>
                                                        <mat-form-field appearance="outline">
                                                            <mat-label>Séléctionner une valeur</mat-label>
                                                            <select matNativeControl [(ngModel)]="entree.value" [disabled]="FormService.envoi">
                                                            <option  *ngFor="let option of entree.list" value="{{option.toLowerCase()}}">{{option}}</option>
                                                            </select>
                                                        </mat-form-field>
                                                    </span>

                                                    <span fxLayoutFlex="30" class="upload_pos importImg" *ngIf="entree.img_upload && entree.name === 'etatAscenceur'">
                                                        <form [formGroup]="formAscenceur" class="infixUpload entreeImage" enctype="multipart/form-data">
                                                            <mat-form-field appearance="outline" class="iconWidth formAscenseur">
                                                                <mat-label style="display: flex; align-self: start; margin-left: 32px;">Importer une photo</mat-label>
                                                                <ngx-mat-file-input style="position: absolute; top: -25px;" class="entreeImage" [accept]="'image/*'" [(ngModel)]="dataAscenceur" formControlName="fileAscenceur" multiple (change)="uploadFileAscenceur('ascenseur', i, y)">
                                                                    <mat-icon class="iconFile"  ngxMatFileInputIcon>add_a_photo</mat-icon>
                                                                </ngx-mat-file-input>
                                                                <div
                                                                  *ngFor="let file of this.OriginalImage[y]['ascenseur'][i]; let imageAscenseurIndex = index;"
                                                                  [attr.data-index]="imageAscenseurIndex"
                                                                  style="flex-direction: row;display:flex;margin-left: 10px;"
                                                                >
                                                                  <div style="margin-right: 10px;">{{file.name}}</div>
                                                                        <div
                                                                          type="button"
                                                                          style="justify-self: center; display: flex; height: 12px; width: 12px; position: static; cursor: pointer;"
                                                                          (click)="deleteImage(imageAscenseurIndex, 'ascenseur', 'dataAscenceur', i, y)"
                                                                        >
                                                                    <mat-icon style="transform: scale(0.70);">
                                                                      clear
                                                                    </mat-icon>
                                                                  </div>
                                                                </div>
                                                            </mat-form-field>
                                                        </form>
                                                    </span><br>
                                                </div>
                                              <!-- select_list_2 valeurs de Thor-->
                                                <div *ngIf="entree.type == 'list_select_2' && (!entree.condition || FormService.batiments[y][3].tab[i][entree.condition.pos].value === entree.condition.value)" class="pad10_0 sans_shadow labelPosSelect">
                                                    <span class="marge_r10 pos_labelselect"><span [ngClass]="entree.classes">{{entree.label}}</span></span>
                                                    <mat-form-field appearance="outline">
                                                        <mat-label>Séléctionner une valeur</mat-label>
                                                        <select matNativeControl [(ngModel)]="entree.value" [disabled]="FormService.envoi">
                                                        <option  *ngFor="let option of entree.list" value="{{option}}">{{option}}</option>
                                                        </select>
                                                    </mat-form-field>
                                                    <span class="upload_pos_list"  *ngIf="entree.img_upload == 'avec'"><mat-button-toggle><mat-icon aria-hidden="false" aria-label="Ajouter une photo" class="addPhoto">add_a_photo</mat-icon><span class="imp_imgtxt">Importer une image</span></mat-button-toggle></span><br>
                                                </div>
                                              <!--txt-->
                                                <div class="padd_xs" fxLayout="row wrap"  *ngIf="entree.type == 'txt' && entree.value !== 'desactive' && (!entree.condition || FormService.batiments[y][3].tab[i][entree.condition.pos].value === entree.condition.value)" class="pad10_0">
                                                    <div fxLayoutFlex="50" class="pad10_0">
                                                      <label>
                                                        <span [ngClass]="entree.classes">
                                                          {{entree.label}}
                                                        </span>
                                                      </label>
                                                    </div>
                                                    <span fxLayoutFlex="50" class="upload_pos importImg" *ngIf="entree.img_upload && entree.name === 'hallEntree'">
                                                        <form [formGroup]="formEntree" style="margin-top: 25px;" class="infixUpload entreeImage" enctype="multipart/form-data">
                                                            <mat-form-field appearance="outline" class="iconWidth formAscenseur">
                                                                <mat-label style="display: flex; align-self: start; margin-left: 32px;">Importer une photo</mat-label>
                                                                <ngx-mat-file-input style="position: absolute; top: -20px;" class="entreeImage" [accept]="'image/*'" [(ngModel)]="dataEntree" multiple formControlName="fileEntree" (change)="uploadFileEntree('entrees', i, y)">
                                                                    <mat-icon class="iconFile"  ngxMatFileInputIcon>add_a_photo</mat-icon>
                                                                </ngx-mat-file-input>
                                                                <div *ngFor="let file of this.OriginalImage[y]['entrees'][i]; let imageEntreeIndex = index"
                                                                     [attr.data-index]="imageEntreeIndex"
                                                                     style="flex-direction: row;display:flex;margin-left: 10px;"
                                                                >
                                                                  <div style="margin-right: 10px;">{{file.name}}</div>
                                                                        <div
                                                                          type="button"
                                                                          style="justify-self: center; display: flex; height: 12px; width: 12px; position: static; cursor: pointer;"
                                                                          (click)="deleteImage(imageEntreeIndex, 'entrees', 'dataEntree', i, y)"
                                                                        >
                                                                    <mat-icon style="transform: scale(0.70);">
                                                                      clear
                                                                    </mat-icon>
                                                                  </div>
                                                                </div>
                                                            </mat-form-field>
                                                        </form>
                                                    </span><br>
                                                </div>
                                            </span>
                                            </span>
                                        </div>
                                    </span>
                                </span>
            </div>
            <mat-button-toggle fxLayoutAlign="start" class="btn" (click)="sendFormVisite()" *ngIf="!FormService.envoi" style="margin-top: 15px;">
              Envoyer
            </mat-button-toggle>
            <div class="alert_msg padTB10 " *ngIf="msgErrVisit"> {{ msgErrVisit }}</div>
          </div>


          <!--Si visite déjà faite-->
          <div *ngIf="info?.faiteLe" fxLayout="row wrap" class="padding-card padding-card-pos">
            <div fxFlex="100" class="dejaVisite">
              <span class="max300"> Une visite à déjà été envoyée. </span>
            </div>

            <div fxFlex="100" *ngFor="let detail of resultEnvoyee; let batIndex = index;" fxLayout="row wrap" class="marge_tab bloc_travaux">
              <div fxFlex="70" fxFlex.xs="100" style="text-align: left;">

                <div fxLayout="row wrap">
                  <div>
                    <span class="font_content">Fait le :</span>
                    <span class="font_resultat_tab  data_dynamic_done resultat_marge_left">{{detail.faitLe | date: 'dd/MM/yyyy'}}</span>
                  </div>

                </div>
                <div fxLayout="row wrap">
                  <div fxFlex="100" class="title_descr font_immeuble">Façade avant</div>
                  <div class="txt_medium500" fxFlex="90" *ngIf="detail.facadeRue">
                    <div fxLayout="row wrap"><span fxFlex="70">Etat général</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ detail.facadeRue.etatGen }}</span></div>
                    <div fxLayout="row wrap"><span fxFlex="70">Commerces</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.facadeRue.commerces ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap" style="padding-left: 5px;" *ngIf="detail.facadeRue.commerces"><span fxFlex="70">Nature des commerces</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ detail.facadeRue.natCommerce }}</span></div>
                    <div fxLayout="row wrap"><span fxFlex="70">Commerces contigus</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.facadeRue.commercesContiguite ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap" style="padding-left: 5px;" *ngIf="detail.facadeRue.commercesContiguite"><span fxFlex="70">Nature des commerces</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ detail.facadeRue.natCommerceContiguite }}</span></div>
                  </div>
                </div>
                <div fxLayout="row wrap">
                  <div fxFlex="100" class="title_descr font_immeuble">Façade arrière</div>
                  <div class="txt_medium500" fxFlex="90" *ngIf="detail.facadeRue">
                    <div fxLayout="row wrap"><span fxFlex="70">Accès</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.facadeRue.access ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap"><span fxFlex="70">Etat général</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ detail.facadeRue.etatGen }}</span></div>
                  </div>
                </div>

                <div fxLayout="row wrap">
                  <div class="txt_medium500" fxFlex="90">
                    <div  class="title_descr font_immeuble" fxLayout="row wrap"><span fxFlex="70">Nombre d'étages</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.nbEtages}}</span></div>
                  </div>
                </div>

                <div fxLayout="row wrap" *ngFor="let entree of resultEntree">
                  <div fxFlex="100" class="title_descr font_immeuble">Entrée</div>
                  <div class="txt_medium500" fxFlex="90">

                    <div fxLayout="row wrap"><span fxFlex="70">Porte d'entrée avec :</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{entree?.specAccess2 === 'autre(précisez)' ? entree?.specAutre2 : entree?.specAccess2}}</span></div>
                    <div fxLayout="row wrap"  style="padding-left: 5px;"><span fxFlex="70">Porte vitrée</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{entree.porteVitree ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap"><span fxFlex="70">Second contrôle accès</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{entree.isAccess2 ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap"><span fxFlex="70">Porte d'entrée avec </span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{entree?.specAccess2 === 'autre(précisez)' ? entree?.specAutre2 : entree?.specAccess2}}</span></div>
                    <div fxLayout="row wrap"  style="padding-left: 5px;"><span fxFlex="70">Porte vitrée</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{entree.porteVitreeSecond ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap"><span fxFlex="70">Ascenseur</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{entree.Ascenceur ? 'Oui' : 'Non'}} </span></div>
                    <div fxLayout="row wrap" *ngIf="entree.Ascenceur"><span fxFlex="70">Etat global des portes et de la cabine d'ascenseur :</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ entree.etatAscenceur ? entree.etatAscenceur : 'Pas indiqué'}}</span></div>
                    <div fxLayout="row wrap"><span fxFlex="70">Alarme incendie</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{entree.alarmeIncendie ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap"><span fxFlex="70">Caméra de surveillance</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{entree.cameraVideo ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap"><span fxFlex="70">Commande de désenfumage</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{entree.cmdDesenfumage ? 'oui' : 'non'}}</span></div><br>

                    <div fxLayout="row wrap" *ngIf="entree.escalier"><span fxFlex="70">Escalier</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">oui</span></div>

                    <div fxLayout="row wrap" *ngIf="entree.escalier"><span fxFlex="70">Etat général des escalier</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ entree.escalier.etatGenEscaliers }}</span></div>
                    <div fxLayout="row wrap" *ngIf="entree.escalier"><span fxFlex="70">Etat général des murs</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ entree.escalier.etatGenMurs }}</span></div>
                    <div fxLayout="row wrap" *ngIf="entree.escalier"><span fxFlex="70">Etat général du plafond</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ entree.escalier.etatGenPlafonds }}</span></div>
                    <div fxLayout="row wrap" *ngIf="entree.escalier"><span fxFlex="70">Nature des marches</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ entree.escalier.natureMarches }}</span></div>
                    <div fxLayout="row wrap" *ngIf="entree.escalier"><span fxFlex="70">Nature du palier</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ entree.escalier.naturePaliers }}</span></div>
                    <div fxLayout="row wrap" *ngIf="entree.escalier"><span fxFlex="70">Extincteurs</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{entree.escalier.extincteurs ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap" *ngIf="entree.escalier && entree.escalier.extincteurs" style="padding-left: 5px;"><span fxFlex="70">Visite moins de 12 mois</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{entree.escalier.visite12mois ? 'oui' : 'non'}}</span></div><br>

                  </div>
                </div>
                <div fxLayout="row wrap">
                  <div fxFlex="60" style="white-space: nowrap;" class="title_descr font_immeuble">Cave</div> <div *ngIf="detail.cave === undefined || detail.cave.presence === false" fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">non</div>
                  <div class="txt_medium500" fxFlex="90" *ngIf="detail.cave && detail.cave.presence">
                    <div fxLayout="row wrap"><span fxFlex="70">Présence de caves</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.cave.presence ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap"><span fxFlex="70">Caves accessibles</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.cave.accessible ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap"><span fxFlex="70">Encombrement des caves</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ detail.cave.encombrement }}</span></div>
                    <div fxLayout="row wrap"><span fxFlex="70">Extincteur</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.cave.extincteurs ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap"  *ngIf="detail.cave.extincteurs" style="padding-left: 5px;"><span fxFlex="70">Visite moins de 12 mois</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.cave.visite12mois ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap"><span fxFlex="70">Nombre de niveaux</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ detail.cave.nbSousSol }}</span></div>
                  </div>
                </div>
                <div fxLayout="row wrap">
                  <div fxFlex="60" style="white-space: nowrap;" class="title_descr font_immeuble">Chaufferie</div> <div *ngIf="detail.chaufferie === undefined" fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">non</div>
                  <div class="txt_medium500" fxFlex="90" *ngIf="detail.chaufferie">
                    <div fxLayout="row wrap"  *ngIf="detail.chaufferie.collective"><span fxFlex="70">Chaufferie collective</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.chaufferie.collective ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap"  *ngIf="detail.chaufferie.collective"><span fxFlex="70">Avez-vous eu accès ?</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.chaufferie.Access ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap"  *ngIf="detail.chaufferie.collective" style="padding-left: 5px;"><span fxFlex="70">Extincteur extérieur</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.chaufferie.exitincteursExt ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap" *ngIf="detail.chaufferie.collective && detail.chaufferie.exitincteursExt" style="padding-left: 10px;"><span fxFlex="70"> Visite moins de 12 mois</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.chaufferie.visite12moisCollecExt ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap" *ngIf="detail.chaufferie.Access && detail.chaufferie.collective" style="padding-left: 5px;"><span fxFlex="70">Extincteur intérieur</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.chaufferie.exitincteursInt ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap" *ngIf="detail.chaufferie.Access && detail.chaufferie.collective && detail.chaufferie.exitincteursInt" style="padding-left: 10px;"><span fxFlex="70"> Visite moins de 12 mois</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.chaufferie.visite12moisCollecInt ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap" *ngIf="detail.chaufferie.Access && detail.chaufferie.collective" style="padding-left: 5px;"><span fxFlex="70">Présence de carnet d'entretien</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.chaufferie.carnet ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap" *ngIf="detail.chaufferie.Access && detail.chaufferie.collective" style="padding-left: 5px;"><span fxFlex="70">Date de la dernière visite</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.chaufferie.dateLastVisite | date: 'dd/MM/yyyy'}}</span></div>
                    <div fxLayout="row wrap"><span fxFlex="70" *ngIf="detail.chaufferie.collective && detail.chaufferie.genre">Type de chaufferie</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ detail.chaufferie.genre }}</span></div>

                    <div fxLayout="row wrap" *ngIf="!detail.chaufferie.collective"><span fxFlex="70">Chaufferie individuelle</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">oui</span></div>
                    <div fxLayout="row wrap" style="padding-left: 5px;" *ngIf="!detail.chaufferie.collective"><span fxFlex="70">Extincteur extérieur</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.chaufferie.exitincteursIndivExt ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap" style="padding-left: 10px;" *ngIf="!detail.chaufferie.collective && detail.chaufferie.exitincteursIndivExt"><span fxFlex="70"> Visite moins de 12 mois</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.chaufferie.visite12moisIndivExt ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap"><span fxFlex="70" *ngIf="!detail.chaufferie.collective && detail.chaufferie.genreIndiv">Type de chaufferie</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{ detail.chaufferie.genreIndiv }}</span></div>
                  </div>
                </div>
                <div fxLayout="row wrap">
                  <div fxFlex="60" style="white-space: nowrap;" class="title_descr font_immeuble">Parking souterrain</div> <div *ngIf="detail.parkingST === undefined || detail.parkingST && detail.parkingST.presence" fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">non</div>
                  <div class="txt_medium500" fxFlex="90" *ngIf="detail.parkingST && detail.parkingST.presence">
                    <div fxLayout="row wrap"><span fxFlex="70">Présence de parking souterrain</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.parkingST.presence ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap"><span fxFlex="70">Avez-vous eu accès ?</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.parkingST.access ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap"><span fxFlex="70">Contrôl d'accès</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.parkingST.controlAccess ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap"><span fxFlex="70">Etat de la porte</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.parkingST.etatPorte === 'sansObjet' ? "Sans objet" : detail.parkingST.etatPorte}}</span></div>
                    <div fxLayout="row wrap"><span fxFlex="70">Nombre de niveaux</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.parkingST.nbNiveaux}}</span></div>
                    <div fxLayout="row wrap"><span fxFlex="70">Extincteurs</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.parkingST.extincteurs ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap" *ngIf="detail.parkingST.extincteurs"><span fxFlex="70">Visite moins de 12 mois</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.parkingST.visite12mois ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap"><span fxFlex="70">Plan d'évacuation</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.parkingST.planEvac ? 'oui' : 'non'}}</span></div>
                    <div fxLayout="row wrap"><span fxFlex="70">Présence d'un bac à sable</span><span fxFlex="30" fxLayoutAlign="end center" class="data_dynamic txt_small">{{detail.parkingST.bacSable ? 'oui' : 'non'}}</span></div>
                  </div>
                </div>
              </div>
              <div fxFlex="30" fxFlex.xs="100">
                <div fxLayout="row wrap" class="marge_add">
                  <div class="title_descr txt_medium" fxLayoutAlign="start" fxFlex="100">Façades Rue</div>
                  <div fxFlex="100">
                    <div fxFlex="100">
                      <span class="" gallerize="facadeRue{{detail._id}}">
                          <span fxLayoutAlign="start" *ngIf="detail.image.facadeRue">
                              <img *ngIf="detail.image.facadeRue[0]" [src]="'https://copro-back.cantem.fr/uploads/batiment/'+detail.image.facadeRue[0]" style="width: 50px; height: 50px; margin-right: 5px;">
                              <img *ngIf="detail.image.facadeRue[1]" [src]="'https://copro-back.cantem.fr/uploads/batiment/'+detail.image.facadeRue[1]" style="width: 50px; height: 50px; margin-right: 5px;">
                              <img *ngIf="detail.image.facadeRue[2]" [src]="'https://copro-back.cantem.fr/uploads/batiment/'+detail.image.facadeRue[2]" style="width: 50px; height: 50px; margin-right: 5px;">
                          </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div fxLayout="row wrap">
                  <div class="title_descr txt_medium" fxLayoutAlign="start" fxFlex="100%">Façade arrière</div>
                  <div fxFlex="100">
                    <div fxFlex="100">
                      <span class="" gallerize="facadeArriere{{detail._id}}">
                          <span fxLayoutAlign="start" *ngIf="detail.image.facadeArriere">
                              <img *ngIf="detail.image.facadeArriere[0]" [src]="'https://copro-back.cantem.fr/uploads/batiment/'+detail.image.facadeArriere[0]" style="width: 50px; height: 50px; margin-right: 5px;">
                              <img *ngIf="detail.image.facadeArriere[1]" [src]="'https://copro-back.cantem.fr/uploads/batiment/'+detail.image.facadeArriere[1]" style="width: 50px; height: 50px; margin-right: 5px;">
                              <img *ngIf="detail.image.facadeArriere[2]" [src]="'https://copro-back.cantem.fr/uploads/batiment/'+detail.image.facadeArriere[2]" style="width: 50px; height: 50px; margin-right: 5px;">
                          </span>
                      </span>
                    </div>
                  </div>
                </div>
                <!--div fxLayout="row wrap">
                    <div class="title_descr txt_medium" fxLayoutAlign="start" fxFlex="100%">Entrée</div>
                    <div fxFlex="100">
                        <div fxFlex="100">
                            <span class="" gallerize="facadeEntree{{detail._id}}">
                                <span fxLayoutAlign="start" *ngIf="detail.image.entrees">
                                    <img *ngIf="detail.image.entrees[0]" [src]="'https://copro-back.cantem.fr/uploads/batiment/'+detail.image.entrees[0]" style="width: 50px; height: 50px; margin-right: 5px;">
                                    <img *ngIf="detail.image.entrees[1]" [src]="'https://copro-back.cantem.fr/uploads/batiment/'+detail.image.entrees[1]" style="width: 50px; height: 50px; margin-right: 5px;">
                                    <img *ngIf="detail.image.entrees[2]" [src]="'https://copro-back.cantem.fr/uploads/batiment/'+detail.image.entrees[2]" style="width: 50px; height: 50px; margin-right: 5px;">
                                </span>
                            </span>
                        </div>
                    </div>
                </div-->
                <div fxLayout="row wrap">
                  <div fxLayout="row wrap">
                    <div class="title_descr txt_medium" fxLayoutAlign="start" fxFlex="100%">Entrée{{resultEntree?.length > 1 ? 's' : ''}}</div>
                    <div fxFlex="100" fxLayout="row wrap" style="display: flex; justify-content: space-between;">
                      <div *ngFor="let entree of detail.entrees; let indexPhotoEntree = index;" fxLayout="row wrap" >
                        <div
                          style="margin-bottom: 5px;"
                          *ngIf="entree.photosEntree && entree.photosEntree.length || entree.photosAscenseur && entree.photosAscenseur.length">
                          <span *ngIf="resultEntree?.length > 1"  fxLayoutAlign="start" style="text-align: left; font-size: 12px;margin-bottom: 5px;">Entrée {{indexPhotoEntree + 1}}</span>
                          <div *ngIf="entree.photosEntree && entree.photosEntree.length">
                            <span fxLayoutAlign="start" style="; font-size: 11px; margin: 0 0 3px 10px">Photos de l'entrée</span>
                          </div>
                          <span class="" gallerize="gallery_e_{{batIndex}}_{{indexPhotoEntree}}">
                            <span  *ngFor="let photoEntreeUrl of entree.photosEntree; let i = index;" >
                                <img [src]="'https://copro-back.cantem.fr/uploads/batiment/'+ photoEntreeUrl" style="width: 50px; height: 50px; margin-right: 5px;">
                            </span>
                          </span>

                          <div *ngIf="entree.photosAscenseur && entree.photosAscenseur.length">
                            <span fxLayoutAlign="start" style="; font-size: 11px; margin: 0 0 3px 10px;" >Photos de l'ascenseur</span>
                          </div>
                          <span class="" gallerize="gallery_ea_{{batIndex}}_{{indexPhotoEntree}}">
                            <span  *ngFor="let photoAscenseurUrl of entree.photosAscenseur">
                              <img [src]="'https://copro-back.cantem.fr/uploads/batiment/' + photoAscenseurUrl" style="width: 50px; height: 50px; margin-right: 5px;">
                            </span>
                          </span>
                        </div>
                      </div>
                      <!-- <span fxLayoutAlign="start" *ngFor="let entree of FormService.batiments[y][3].tab[i]" style="text-align: left;"> -->
                    </div>
                    <!-------------->
                  </div>
                </div>
                  <!--div fxLayout="row wrap">
                      <div class="title_descr txt_medium" fxLayoutAlign="start" fxFlex="100%">Etages</div>
                      <div fxFlex="100">
                          <div fxFlex="100">
                              <span class="" gallerize="facadeEtages{{detail._id}}">
                                  <span fxLayoutAlign="start" *ngIf="detail.image.etages">
                                      <img *ngIf="detail.image.etages[0]" [src]="'https://copro-back.cantem.fr/uploads/batiment/'+detail.image.etages[0]" style="width: 50px; height: 50px; margin-right: 5px;">
                                      <img *ngIf="detail.image.etages[1]" [src]="'https://copro-back.cantem.fr/uploads/batiment/'+detail.image.etages[1]" style="width: 50px; height: 50px; margin-right: 5px;">
                                      <img *ngIf="detail.image.etages[2]" [src]="'https://copro-back.cantem.fr/uploads/batiment/'+detail.image.etages[2]" style="width: 50px; height: 50px; margin-right: 5px;">
                                  </span>
                              </span>
                          </div>
                      </div>
                  </div-->
                  <div fxLayout="row wrap">
                    <div class="title_descr txt_medium" fxLayoutAlign="start" fxFlex="100%">Parking</div>
                    <div fxFlex="100">
                      <div fxFlex="100">
                                                <span class="" gallerize="facadeParking{{detail._id}}">
                                                    <span fxLayoutAlign="start" *ngIf="detail.image.parking">
                                                        <img *ngIf="detail.image.parking[0]" [src]="'https://copro-back.cantem.fr/uploads/batiment/'+detail.image.parking[0]" style="width: 50px; height: 50px; margin-right: 5px;">
                                                        <img *ngIf="detail.image.parking[1]" [src]="'https://copro-back.cantem.fr/uploads/batiment/'+detail.image.parking[1]" style="width: 50px; height: 50px; margin-right: 5px;">
                                                        <img *ngIf="detail.image.parking[2]" [src]="'https://copro-back.cantem.fr/uploads/batiment/'+detail.image.parking[2]" style="width: 50px; height: 50px; margin-right: 5px;">
                                                    </span>
                                                </span>
                      </div>
                    </div>
                  </div>
                  <div fxLayout="row wrap">
                    <div class="title_descr txt_medium" fxLayoutAlign="start" fxFlex="100%">Caves</div>
                    <div fxFlex="100">
                      <div fxFlex="100">
                                                <span class="" gallerize="facadeCaves{{detail._id}}">
                                                    <span fxLayoutAlign="start" *ngIf="detail.image.caves">
                                                        <img *ngIf="detail.image.caves[0]" [src]="'https://copro-back.cantem.fr/uploads/batiment/'+detail.image.caves[0]" style="width: 50px; height: 50px; margin-right: 5px;">
                                                        <img *ngIf="detail.image.caves[1]" [src]="'https://copro-back.cantem.fr/uploads/batiment/'+detail.image.caves[1]" style="width: 50px; height: 50px; margin-right: 5px;">
                                                        <img *ngIf="detail.image.caves[2]" [src]="'https://copro-back.cantem.fr/uploads/batiment/'+detail.image.caves[2]" style="width: 50px; height: 50px; margin-right: 5px;">
                                                    </span>
                                                </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--Fin résumé visite-->
          </div>



          <!-- Formulaire désordres -->

          <div fxFlex="40" fxFlex.xs="100" fxFlex.sm="100" class="padding-card padding-card-pos">
            <mat-card *ngIf="show">
              <div style="position: fixed; bottom: 15px; right: 42px;" class="incidentButton">
                <button mat-icon-button (click)="toggle()" style="background-color: transparent;">
                  <img class="plotOnImage" src="../../../../../assets/img/icones/plot.png">
                </button>
              </div>
              <div fxLayout="row wrap"  class="incidentCard">
                <div fxFlex="90" class="title-pos">
                  <div>
                    <div fxLayoutAlign="start center" class="input_titre titre_plot">Désordres</div>
                    <input [(ngModel)]="desordre" class="border-input input_plot"/>
                  </div>
                  <div>
                    <div fxLayoutAlign="start center" class="input_titre titre_plot">Situation</div>
                    <input [(ngModel)]="situation" class="border-input input_plot"/>
                  </div>
                  <div>
                    <div fxLayoutAlign="start center" class="input_titre titre_plot">Descriptif</div>
                    <input [(ngModel)]="description" class="border-input input_plot"/>
                  </div>
                  <div>
                    <div fxLayoutAlign="start center" class="input_titre titre_plot">Métrages</div>
                    <input [(ngModel)]="metrages" (keypress)="numericOnly($event)" class="border-input input_plot"/>
                  </div>
                  <div fxLayout="row wrap" class="inputpos titre esp_bot" fxLayoutAlign="start">
                    <span fxLayoutAlign="start" fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" class="titre_plot">Corps d'état</span>                                        <span fxLayoutAlign="start" fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" class="plot">

                                            <div class="corpsEtats" style="width: 100%; margin-top: 10px;">
                                                <mat-form-field appearance="outline" style="width: 100%">
                                                    <mat-label>Choix multiple</mat-label>
                                                    <mat-select [formControl]="toppings" [(ngModel)]="corpsEtat" multiple>
                                                    <mat-select-trigger>
                                                        {{toppings.value ? toppings.value[0] : ''}}
                                                      <span *ngIf="toppings.value?.length > 1" class="example-additional-selection">
                                                        (+{{toppings.value.length - 1}} {{toppings.value?.length === 2 ? 'autre' : 'autres'}})
                                                        </span>
                                                    </mat-select-trigger>
                                                    <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>

                                        </span>
                  </div>
                  <div class="plot input_plot">
                    <div fxLayoutAlign="start center" class="input_titre titre_plot">Commentaire</div>
                    <textarea [(ngModel)]="commentaire"></textarea>
                  </div>
                  <div class="upload_pos importImg">
                    <form [formGroup]="formFiles" class="infixUpload" enctype="multipart/form-data">
                      <!-- <mat-form-field appearance="outline" class="iconWidth">
                          <mat-label>Importer une photo</mat-label>
                          <ngx-mat-file-input [accept]="'image/*'" (change)="getFileDetails($event)" multiple #file formControlName="multiplefile">
                              <mat-icon class="iconFile" ngxMatFileInputIcon>add_a_photo</mat-icon>
                          </ngx-mat-file-input>
                      </mat-form-field> -->
                      <mat-button-toggle style="margin-top: 25px;" (click)="uploaderIncidents.click()">
                                                <span>
                                                    <mat-icon class="iconFile" class="imgIncidents" ngxMatFileInputIcon>add_a_photo</mat-icon>
                                                    <span>Importer des photos (3 max)</span>
                                                </span>
                      </mat-button-toggle>
                      <div
                        *ngFor="let file of myFiles; let i = index"
                        [attr.data-index]="i"
                        style="flex-direction: row;display:flex;margin-left: 10px;"
                      >
                        <div style="margin-right: 10px;">{{file.name}}</div>
                        <div
                          type="button"
                          style="justify-self: center; display: flex; height: 12px; width: 12px; position: static; cursor: pointer;"
                          (click)="deleteIncident(i)"
                        >
                          <mat-icon style="transform: scale(0.70);">
                            clear
                          </mat-icon>
                        </div>
                      </div>
                      <div style="display: none;">
                        <ng-container>
                          <input [accept]="'image/*'" type="file" id="file" multiple #uploaderIncidents
                                 (change)="getFileDetails($event)">
                        </ng-container>
                      </div>
                    </form>
                  </div>
                  <div class="alert_msg padTB10" style="margin-bottom: 4px" *ngIf="msgErreur"> {{ msgErreur }}</div>
                  <div class="btnDesordres" *ngIf="!loader" style="margin-bottom: 18px;">
                    <mat-button-toggle
                      (click)="addIncident2(desordre, situation, description, metrages, commentaire, syndicId, courtierId, gestionnaireId, idVisite, idCopro)"
                      class="btn">Valider</mat-button-toggle>
                  </div>
                  <div class="btnDesordres" *ngIf="loader"><div class="loader" ></div></div>
                </div>
              </div>
            </mat-card>
            <div *ngIf="!(show)" style="position: fixed; bottom: 15px; right: 42px;" class="incidentButton2">
                            <span>
                              <button mat-icon-button (click)="toggle()">
                                <img  src="../../../../../assets/img/icones/plot_hover.png">
                              </button>
                            </span>
            </div>

            <!-- Résultat des désordres si il y'en a -->
            <div style="margin-top: 50px; position:relative;">
              <mat-accordion *ngFor="let detail of resultDesordres" class="example-headers-align" multi style="display: flex; align-items: center; justify-content: center;">
                <mat-expansion-panel fxFlex="90">
                  <mat-expansion-panel-header>
                    <mat-panel-description>
                      <span>N° </span>
                      <span>{{detail.refDesordre}}</span>
                      <span>Fait le:</span>
                      <span>{{detail.date | date: 'dd/MM/yyyy'}}</span>
                      <mat-icon>warning</mat-icon>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div style="text-align: left;" class="bloc_travaux">
                    <div class="font_immeuble marge_devis">
                      <span>Le</span>
                      <span class="data_dynamic resultat_marge_left">{{detail.date | date: 'dd/MM/yyyy'}}</span>
                    </div>
                    <div>
                      <span class="font_content">Désordre :</span>
                      <span class="font_resultat_tab  data_dynamic_done resultat_marge_left">{{ detail.desordre }}</span>
                    </div>
                    <div>
                      <span class="font_content">Situation :</span>
                      <span class="font_resultat_tab  data_dynamic_done resultat_marge_left">{{ detail.situation }}</span>
                    </div>
                    <div class="bloc-right maxWidth350">
                      <span class="font_content">Descriptif :</span>
                      <span class="font_resultat_tab  data_dynamic_done resultat_marge_left">{{ detail.description }}</span>
                    </div>
                    <div>
                      <span class="font_content">Métrages :</span>
                      <span class="font_resultat_tab  data_dynamic_done resultat_marge_left"></span>{{ detail.metrages }}<span class="resultat_marge_left">m²</span>
                    </div>
                    <div>
                      <span class="font_content">Corps d'état : </span>
                      <span class="font_resultat_tab  data_dynamic_done resultat_marge_left">{{ detail.corpsEtat }}</span>
                    </div>
                    <div>
                      <span class="font_content">Commentaire :</span>
                      <span class="font_resultat_tab  data_dynamic_done resultat_marge_left"></span>
                      <span class="font_resultat_tab  data_dynamic_done resultat_marge_left">{{ detail.commentaire }}</span>
                    </div>

                    <div fxLayout="row wrap" class="marge_add" *ngIf="detail._id">
                      <span class="font_content">Photos :</span>
                      <div fxFlex="100">
                        <div fxFlex="100">
                                                        <span class="" gallerize="gallery{{ detail._id }}">
                                                            <span *ngIf="detail.images">
                                                                <img *ngIf="detail.images[0]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+detail.images[0]" style="width: 50px; height: 50px; margin-right: 5px;">
                                                                <img *ngIf="detail.images[1]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+detail.images[1]" style="width: 50px; height: 50px; margin-right: 5px;">
                                                                <img *ngIf="detail.images[2]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+detail.images[2]" style="width: 50px; height: 50px; margin-right: 5px;">
                                                            </span>
                                                        </span>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="bloc-right">
                        <span class="font_content">Photo :</span>
                        <span class="font_resultat_tab  data_dynamic_done resultat_marge_left">3</span>
                    </div> -->
                    <!------Gallery Images-------->
                    <!-- <div class="" style="margin:15px 0">
                        <span class="" gallerize="gallery_1">
                            <img class="imgMignature" *ngFor="let img of GalleryComponent.imageEvaluation" [src]="img.srcUrl">
                        </span>
                    </div> -->
                    <!-------------->
                    <!-- <div>
                        <span class="font_content">Montant en TTC :</span>
                        <span class="font_resultat_tab  data_dynamic_done resultat_marge_left"></span>
                    </div> -->
                  </div>
                </mat-expansion-panel>
                <button mat-icon-button	  (click)="requestDeleteIncident(detail)">
                  <mat-icon>close</mat-icon>
                </button>

              </mat-accordion>

            </div>
            <!-- Fin des résultat désordres -->
          </div>
        </div>
    </mat-card-content>
  </mat-card>
</div>
