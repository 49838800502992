<div fxLayout="row wrap" fxLayoutAlign="center center" class="padding-card padding-card-pos">
    <mat-card fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">
         <mat-card-content class="content-pos txt_medium">                     
                
                <div fxLayout="row wrap" fxLayoutAlign="center">
                    <div>
                        <form class="form_connexion">
                            <div fxLayoutAlign="center">
                                <h2>Connexion</h2>
                            </div>
                            <div fxLayoutAlign="start" class="connexionMarge">
                                <mat-form-field appearance="outline" class="input350">
                                    <mat-label>Votre email</mat-label>
                                    <input [(ngModel)]="login" matInput [formControl]="email_input" required>
                                    <mat-error *ngIf="email_input.invalid">{{getErrorMessage()}}</mat-error>
                                </mat-form-field> 
                            </div>  
                            <div fxLayoutAlign="start" class="password_input connexionMarge">
                                <mat-form-field appearance="outline" class="input350">
                                    <mat-label>Mot de passe</mat-label>
                                    <input [(ngModel)]="password" matInput [ngModelOptions]="{standalone: true}" [type]="hide ? 'password' : 'text'">
                                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </button>
                                  </mat-form-field>
                            </div>     
                            <div fxLayoutAlign="start" class="button_connexion">
                                <mat-button-toggle (click)="connexion(login, password)" class="btn" [disabled]="login == null || password == null || login.length < 5 || password.length < 1  ">
                                    Se connecter
                                </mat-button-toggle>
                            </div>   
                            <p class="alert_msg" *ngIf="msgErreur"> {{ msgErreur }}</p>                     
                        </form>
                    </div>  
                </div>
         </mat-card-content> 
    </mat-card>
</div>
