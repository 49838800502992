<div class="padding-card padding-card-pos">
    <mat-button-toggle (click)="goBack()" class="pad10">
        <span fxLayoutAlign="center center" fxFlex="15" class="icon_arr"><img class="img_retour" src="../../../../assets/img/icones/retour-arr.png"></span>
        <div fxLayoutAlign="center center" fxFlex="85"><span class="retour_parc">Retour à Mes Evaluations</span></div>
    </mat-button-toggle>
</div>


<div fxLayout="row wrap" fxLayoutAlign="center">

<!------------------- BLOC LEFT -------------------------->

    <div fxFlex="25" fxFlex.xs="100" fxFlex.md="40" fxFlex.sm="100" fxFlex.lg="30" class="padding-card padding-card-pos">
        <mat-card fxFlex="100">
            <mat-card-content class="content-40">
                <div fxLayoutAlign="center">
                    <img width="90%" class="res_img" src="{{src_img}}"> <!-- [src]="MonParc.src_img_profil" -->
                </div>
                <div fxLayout="row wrap" style="margin: 30px 0;" fxLayoutAlign="center">
                    <div fxFlex="90">
                      <mat-card>
                        <div fxLayout="column" class="avec_shadow pad_box_shadow">
                          <div fxLayoutAlign="center" class="info_courtier"><b>Gestionnaire:</b></div>
                          <div fxLayoutAlign="center" class="info_courtier">{{gestionnaireId?.lastName || ' - '}}</div>
                          <div fxLayoutAlign="center" class="info_courtier">{{gestionnaireId?.email || ' - '}}</div>
                          <div fxLayoutAlign="center" class="info_courtier info_mid">{{ gestionnaireId?.phone || ' - ' }}</div>
                          <hr style="border: .5vm solid; width: 100%"/>
                          <div fxLayoutAlign="center" class="info_courtier"><b>Président du conseil:</b></div>
                          <div fxLayoutAlign="center" class="info_courtier">{{nomPCS || ' - '}}</div>
                          <div fxLayoutAlign="center" class="info_courtier">{{emailPCS || ' - '}}</div>
                          <div fxLayoutAlign="center" class="info_courtier">{{phonePCS || ' - '}}</div>
                        </div>
                      </mat-card>
                    </div>
                </div>
                <!--div fxLayoutAlign="center">
                    <mat-button-toggle class="btn-white">Télécharger Stat sinistre (PDF)</mat-button-toggle>
                </div-->
            </mat-card-content>
        </mat-card>
    </div>

<!------------------- BLOC RIGHT --------------------------->

    <div fxFlex="75" fxFlex.xs="100" fxFlex.md="60" fxFlex.sm="100" fxFlex.lg="70" class="padding-card padding-card-pos">
        <mat-card fxFlex="95%;">
            <mat-card-content class="remove-border-bottom">
                <mat-tab-group>



<!------------------- TAB 1 À VALIDER -------------------------->

                    <mat-tab label="À valider">
                        <div fxLayout="row wrap" class="marge_tab" *ngIf="aFaire.length === 0">
                          <span class="font_content">Aucune évalutation à faire pour le moment</span>
                        </div>
                        <div fxLayout="row wrap" class="marge_tab" *ngFor="let incident of aFaire; let i = index">
                            <div fxFlex="15" fxFlex.xs="20" fxFlex.sm="20">
                                <!--div fxLayoutAlign="center" class="marge_icon"><mat-button-toggle><img src="../../../../assets/img/icones/croix.jpg"></mat-button-toggle></div>
                                <div fxLayoutAlign="center" class="marge_icon"><mat-button-toggle><img src="../../../../assets/img/icones/cloche.jpg"></mat-button-toggle></div-->
                            </div>
                            <div fxLayout="row wrap" fxFlex="85" fxFlex.xs="80" fxFlex.sm="80">
                                <div fxFlex="50" fxFlex.xs="100">
                                    <div class="title_devis">{{incident.desordre}}</div>
                                    <div class="marge_devis title_devis">
                                        <span>Le :</span>
                                        <span class="data_dynamic resultat_marge_left">{{incident.date | date: 'dd/MM/yyyy'}}</span>
                                    </div>
                                    <div class="marge_devis txt_devis">
                                        <span class="txt_medium">Demande d'évaluation faite le {{incident.date | date: 'dd/MM/yyyy'}}</span>
                                    </div>
                                    <div class="marge_devis txt_devis">
                                        <span class="txt_medium">Descriptif :</span>
                                        <span class="data_dynamic txt_small resultat_marge_left">{{incident.description}}</span>
                                    </div>
                                    <div class="txt_devis">
                                        <span class="txt_medium">Corps d'etat :</span>
                                        <span class="data_dynamic txt_small resultat_marge_left">{{incident.corpsEtat}}</span>
                                    </div>
                                    <div class="txt_devis">
                                        <span class="txt_medium">Situation :</span>
                                        <span class="data_dynamic txt_small resultat_marge_left">{{incident.situation}}</span>
                                    </div>
                                    <div class="txt_devis">
                                        <span class="txt_medium">Métrage :</span>
                                        <span class="data_dynamic txt_small resultat_marge_left" *ngIf="!abonnement">*Abonnement Premium requis*</span>
                                        <span class="data_dynamic txt_small resultat_marge_left" *ngIf="abonnement">{{incident.metrages}} ㎡</span>
                                    </div>
                                    <div class="marge_devis txt_devis">
                                        <span class="txt_medium">Commentaire architecte:</span>
                                        <span class="data_dynamic txt_small resultat_marge_left">{{incident.commentaire}}</span>
                                    </div>
                                </div>
                                <div fxFlex="50" fxFlex.xs="100" class="bouton_ref" *ngIf="incident?.refDesordre">
                                    <mat-button-toggle [disabled]="true" class="btn-ref">N° {{incident?.refDesordre}}</mat-button-toggle>
                                </div>
                                <div fxFlex="50" fxFlex.xs="100">
                                    <!--div class="marge_devis txt_devis">
                                        <span class="txt_medium">Corps d'etat évalués :</span>
                                        <span class="data_dynamic txt_small resultat_marge_left">Peinture, maçonnerie</span>
                                    </div-->
                                </div>
                                <div fxFlex="50" fxFlex.xs="100"></div>
                                <div fxFlex="50" fxFlex.xs="100">
                                    <div fxLayout="row wrap" class="marge_add">
                                        <div fxFlex="100" class="txt_medium margebot10">Photos désordres: {{incident.images.length}} </div>
                                        <div fxFlex="100">
                                            <!------Gallery Images-------->
                                            <div fxLayout="row">
                                                <span class="data_dynamic txt_small resultat_marge_left" *ngIf="!abonnement">*Abonnement requis*</span>
                                                <span gallerize="gallery{{ incident?._id }}" *ngIf="abonnement">
                                                    <span *ngIf="incident.images">
                                                      <img *ngIf="incident.images[0]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+incident.images[0]" style="width: 50px; height: 50px; margin-right: 5px;">
                                                      <img *ngIf="incident.images[1]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+incident.images[1]" style="width: 50px; height: 50px; margin-right: 5px;">
                                                      <img *ngIf="incident.images[2]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+incident.images[2]" style="width: 50px; height: 50px; margin-right: 5px;">
                                                    </span>
                                                </span>
                                            </div>
                                            <!-------------->
                                        </div>
                                    </div>
                                </div>
                                <div fxFlex="50" fxFlex.xs="100">

                                </div>
                                <div fxFlex="100" fxFlex.xs="100" style="margin-bottom: 10%;">
                                  <div class="txt_devis marge_devis">
                                    <span class="txt_medium">Evalutation TTC :</span>
                                    <span class="resultat_marge_left"><input type="number" min="0" [(ngModel)]="evaluationTTC[i]" class="border-input-mini">€</span>
                                  </div>
                                  <div class="txt_medium">Ajouter un commentaire :</div>
                                  <textarea style="width: 70%; height: 100%" [(ngModel)]="comEval"></textarea>
                                </div>
                              <div class="btnDesordres" *ngIf="this.loaderEval[incident._id]"><div class="loader" ></div></div>
                                <div *ngIf="!this.loaderEval[incident._id]" fxFlex="100" fxFlex.xs="100" fxFlexAlign="right" style="margin-top: 2%">
                                    <mat-button-toggle class="btn send_button" (click)="doEvaluation(incident._id, evaluationTTC[i], incident.metrages, incident.coproId, pcsId?._id, incident.syndicId, incident.visiteId, incident.courtierId, incident.architecteId, incident.gestionnaireId, comEval, incident.desordre, incident.description, incident.situation, incident.corpsEtat, incident.images)">Envoyer</mat-button-toggle>
                                </div>
                            </div>
                         </div>
                    </mat-tab>

<!------------------- TAB 1 FAIT EN ATTENTE -------------------------->

                    <mat-tab label="Fait, en attente">
                        <div fxLayout="row wrap" class="marge_tab" *ngFor="let incident of enAttente; let i = index">
                            <div fxFlex="15" fxFlex.xs="20" fxFlex.sm="20">
                                <!--div fxLayoutAlign="center" class="marge_icon"><mat-button-toggle><img src="../../../../assets/img/icones/croix.jpg"></mat-button-toggle></div-->
                            </div>
                            <div fxLayout="row wrap" fxFlex="85" fxFlex.xs="80" fxFlex.sm="80">
                                <div fxFlex="50" fxFlex.xs="100">
                                    <div class="title_devis">{{incident.desordre}}</div>
                                    <div class="marge_devis title_devis">
                                        <span>Le :</span>
                                        <span class="data_dynamic resultat_marge_left">{{incident.date | date: 'dd/MM/yyyy'}}</span>
                                    </div>
                                    <div class="marge_devis txt_devis">
                                        <span class="txt_medium">Descriptif :</span>
                                        <span class="data_dynamic txt_small resultat_marge_left">{{incident.description}}</span>
                                    </div>
                                    <div class="txt_devis">
                                        <span class="txt_medium">Corps d'etat :</span>
                                        <span class="data_dynamic txt_small resultat_marge_left">{{incident.corpsEtat}}</span>
                                    </div>
                                    <div class="txt_devis">
                                      <span class="txt_medium">Situation :</span>
                                      <span class="data_dynamic txt_small resultat_marge_left">{{incident.situation}}</span>
                                    </div>
                                    <div class="txt_devis">
                                        <span class="txt_medium">Metrage :</span>
                                        <span class="data_dynamic txt_small resultat_marge_left" *ngIf="!abonnement">*Abonnement Premium requis*</span>
                                        <span class="data_dynamic txt_small resultat_marge_left" *ngIf="abonnement">{{incident.metrages}} ㎡</span>
                                    </div>
                                    <div class="marge_devis txt_devis">
                                      <span class="txt_medium">Commentaire architecte:</span>
                                      <span class="data_dynamic txt_small resultat_marge_left">{{incident.commentaire}}</span>
                                    </div>
                                </div>
                                <div fxFlex="50" fxFlex.xs="100" class="bouton_ref" *ngIf="incident?.refDesordre">
                                  <mat-button-toggle [disabled]="true" class="btn-ref">N° {{incident?.refDesordre}}</mat-button-toggle>
                                </div>

                                <div fxFlex="50" fxFlex.xs="100">
                                  <div fxLayout="row wrap" class="marge_add">
                                    <div fxFlex="100" class="txt_medium margebot10">Photos désordres: {{incident.images.length}} </div>
                                    <div fxFlex="100">
                                      <!------Gallery Images-------->
                                      <div fxLayout="row">
                                        <span class="data_dynamic txt_small resultat_marge_left" *ngIf="!abonnement">*Abonnement Premium requis*</span>
                                        <span gallerize *ngIf="abonnement">
                                          <span *ngIf="incident.images">
                                            <img *ngIf="incident.images[0]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+incident.images[0]" style="width: 50px; height: 50px; margin-right: 5px;">
                                            <img *ngIf="incident.images[1]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+incident.images[1]" style="width: 50px; height: 50px; margin-right: 5px;">
                                            <img *ngIf="incident.images[2]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+incident.images[2]" style="width: 50px; height: 50px; margin-right: 5px;">
                                          </span>
                                        </span>
                                      </div>
                                      <!-------------->
                                    </div>
                                  </div>
                                </div>
                                <div fxFlex="50" fxFlex.xs="100">

                                </div>
                                <div fxFlex="50" fxFlex.xs="100">
                                    <div class="txt_devis">
                                        <span class="txt_medium">Montant TTC :</span>
                                        <span class="data_dynamic txt_small resultat_marge_left">{{incident.evaluationTTC}}€</span>
                                    </div>
                                    <div class="marge_devis txt_devis">
                                        <span class="txt_medium">Commentaire Travaux :</span>
                                        <span class="data_dynamic txt_small resultat_marge_left">{{incident.comEval}}</span>
                                    </div>
                                </div>
                            </div>
                         </div>
                    </mat-tab>

                </mat-tab-group>
            </mat-card-content>
        </mat-card>
    </div>
</div>

