import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../../data.service'
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';

export interface Section {
  image: string;
  //toggle: string;
  nom_syndic: string;
  id: string;
  //class:string;
}

@Component({
  selector: 'app-gerer-courtiers',
  templateUrl: './gerer-courtiers.component.html',
  styleUrls: ['./gerer-courtiers.component.scss']
})

export class GererCourtiersComponent implements OnInit {

  onEvent(event) {
    event.stopPropagation();
  }

  color_line(event){
    if(event.currentTarget.parentElement.classList.contains("droits")) {
      event.currentTarget.parentElement.classList.remove("droits");
      event.currentTarget.parentElement.classList.add("droits_selected");

    } else if (event.currentTarget.parentElement.classList.contains('droits_selected')) {
      event.currentTarget.parentElement.classList.remove('droits_selected');
      event.currentTarget.parentElement.classList.add('droits');
    }
  }

  move_item_colonne_gauche_to_droite_by_id(id) {
    let i;
    for( i in this.listSyndicsCourtier) {
      if(this.listSyndicsCourtier[i].id === id) {
        this.listSyndicsDisponibles.push(this.listSyndicsCourtier[i]);
        this.listSyndicsCourtier.splice(i, 1);
      }
    }
  }

  move_item_colonne_droite_to_gauche_by_id(id) {
    let i;
    for( i in this.listSyndicsDisponibles) {
      if (this.listSyndicsDisponibles[i].id === id) {
        this.listSyndicsCourtier.push(this.listSyndicsDisponibles[i]);
        this.listSyndicsDisponibles.splice(i, 1);
      }
    }
  }


  echange_line(event){
    if (confirm("Voulez-vous enlever ces syndicats de votre selection?")) {
      let el = document.querySelectorAll(".colonne_gauche.droits_selected");
      let syndics = [];
      for (let i in el) {
        if (el[i].id)
          syndics.push(el[i].id)
      }
      this.activatedRoute.params.subscribe(params => {
        const id = params['id'];
        this.DataService.apiAssignCourtierToSyndic(
          {syndics, courtier: id, option: false},
          (data) => {
            console.log(data);
            for(let i in data.successId) {
              this.move_item_colonne_gauche_to_droite_by_id(data.successId[i])
            }
            window.location.reload();
          }, (error) => {
            console.log("erreur: ", error)
          });
      });
    }
  }

  echange_line_2(event){
    if (confirm("Voulez-vous ajouter ces syndicats à votre selection?")) {
      var el = document.querySelectorAll(".colonne_droite.droits_selected");
      let syndics = [];
      for (let i in el) {
        if (el[i].id)
          syndics.push(el[i].id)
      }

      this.activatedRoute.params.subscribe(params => {
        const id = params['id'];
        this.DataService.apiAssignCourtierToSyndic({syndics, courtier: id, option: true},
          (data) => {
            console.log(data);
            for(let i in data.successId) {
              this.move_item_colonne_droite_to_gauche_by_id(data.successId[i])
            }
            window.location.reload();
          }, (error) => {
            console.log("erreur: ", error)
          });
      });
    }
  }

    listSyndicsCourtier: Section[] = [];

    listSyndicsDisponibles: Section[] = [];

    resultCourtier;
    idCourtier;

    constructor(private http: HttpClient, private activatedRoute: ActivatedRoute, public DataService: DataService) { }

    ngOnInit(): void {

      this.activatedRoute.params.subscribe(params => {
        const id = params['id'];
        this.idCourtier = id
        this.DataService.api_single_courtier({_id:id},
          (data)=>{
            console.log("Courtier: ", data.courtier);
            this.resultCourtier = data.courtier;
          },
          (error)=>{
            console.log("erreur")
          }
        );
      });

      this.DataService.api_get_syndic(
        (data) => {
          console.log("All syndics: ", data.syndics)
          for (let key in data.syndics) {
            if (data.syndics[key].courtiers.find(id => id === this.idCourtier)) {
              this.listSyndicsCourtier[key] = {
                image: data.syndics[key].image?? '../../../../assets/img/icones/silouette.jpg',
                nom_syndic: data.syndics[key].nomSyndic,
                id: data.syndics[key]._id
              };
            } else {
              this.listSyndicsDisponibles[key] = {
                image: data.syndics[key].image?? '../../../../assets/img/icones/silouette.jpg',
                nom_syndic: data.syndics[key].nomSyndic,
                id: data.syndics[key]._id
              };
            }
          }
          console.log("syndic courtier: ", this.listSyndicsCourtier);
          console.log("syndic disponible: ", this.listSyndicsDisponibles);
        },
        (error) => {
          console.log("erreur")
        }
      )

    }

  }
