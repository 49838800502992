import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service'

@Component({
  selector: 'app-gestionnaire-assign-dialog',
  templateUrl: './gestionnaire-assign-dialog.component.html',
  styleUrls: ['./gestionnaire-assign-dialog.component.scss']
})
export class GestionnaireAssignDialogComponent implements OnInit {

  constructor(public DataService: DataService) { }

  retourFormCourtier() {
    window.location.reload();
  }

  ngOnInit(): void {
  }

}
