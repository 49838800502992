<div fxLayout="row wrap" class="padding-card padding-card-pos">
    <mat-card>
        <div fxLayout="row wrap">
            <div fxFlex="33" fxFlex.xs="100" fxFlex.sm="100" fxLayoutAlign="left center" fxLayoutAlign.xs="center center" class="title title-pos">Ajout Prestataire</div>
        </div>
        <mat-card-content class="title-pos input_outline">
            <form fxLayout="row wrap" [formGroup]="formPresta" class="formCreation">
                <div fxLayout="row wrap" class="pos_input">
                    <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
                        <div class="input-gestion">
                            <mat-label>Email du compte :</mat-label>
                        </div>
                        <mat-form-field appearance="outline" class="input350">
                            <mat-label>Email du compte</mat-label>
                            <input [(ngModel)]="email" formControlName="emailForm" matInput required>
                            <mat-error class="ErrorInline" *ngIf="formCtrl.emailForm.hasError('required')">Le champ email est requis</mat-error>
                            <mat-error class="ErrorInline" *ngIf="formCtrl.emailForm.hasError('email')">Format de mail invalide.</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
                        <div class="input-gestion">
                            <mat-label>Numéro de contact du prestataire :</mat-label>
                        </div>
                        <mat-form-field appearance="outline" class="input350">
                            <mat-label>Numéro de téléphone</mat-label>
                            <input [(ngModel)]="phone" formControlName="phoneForm" matInput (keypress)="numericOnly($event)" required minlength="10" maxlength="10">
                            <mat-error class="ErrorInline" *ngIf="formCtrl.phoneForm.hasError('required')">Le champ téléphone est requis</mat-error>
                            <mat-error class="ErrorInline" *ngIf="formCtrl.phoneForm.hasError('minlength')">Il doit comporter <b>10 chiffres</b></mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
                        <div class="input-gestion">
                            <mat-label>Adresse (n° et nom de rue) du siege social :</mat-label>
                        </div>
                        <mat-form-field appearance="outline" class="input350">
                            <mat-label>Adresse du siege social</mat-label>
                            <input [(ngModel)]="address" formControlName="addressForm" matInput required minlength="5" maxlength="200">
                            <mat-error class="ErrorInline" *ngIf="formCtrl.addressForm.hasError('required')">Le champ adresse est requis</mat-error>
                            <mat-error class="ErrorInline" *ngIf="formCtrl.addressForm.hasError('minlength')">Minimum <b>5 caractères</b>, maximum <b>200</b>.</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
                        <div class="input-gestion">
                            <mat-label>Code postal du siège social :</mat-label>
                        </div>
                        <mat-form-field appearance="outline" class="input350">
                            <mat-label>Code postal</mat-label>
                            <input [(ngModel)]="codePostal" formControlName="codePostalForm" matInput (keypress)="numericOnly($event)" required minlength="5" maxlength="5">
                            <mat-error class="ErrorInline" *ngIf="formCtrl.codePostalForm.hasError('required')">Le champ code postal est requis</mat-error>
                            <mat-error class="ErrorInline" *ngIf="formCtrl.codePostalForm.hasError('minlength')">Il doit comporter <b>5 chiffres</b></mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
                        <div class="input-gestion">
                            <mat-label>Nom/raison sociale de l'entreprise :</mat-label>
                        </div>
                        <mat-form-field appearance="outline" class="input350">
                            <mat-label>Nom/raison sociale</mat-label>
                            <input [(ngModel)]="company" formControlName="companyForm" matInput  required minlength="3" maxlength="50">
                            <mat-error class="ErrorInline" *ngIf="formCtrl.companyForm.hasError('required')">Le champ Nom/Raison sociale est requis</mat-error>
                            <mat-error class="ErrorInline" *ngIf="formCtrl.companyForm.hasError('minlength')">Minimum <b>3 caractères</b>, maximum <b>50</b>.</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
                        <div class="input-gestion">
                            <mat-label>Nom du représentant :</mat-label>
                        </div>
                        <mat-form-field appearance="outline" class="input350">
                            <mat-label>Nom</mat-label>
                            <input [(ngModel)]="lastName" formControlName="lastNameForm" matInput required minlength="3" maxlength="50">
                            <mat-error class="ErrorInline" *ngIf="formCtrl.lastNameForm.hasError('required')">Le champ nom est requis</mat-error>
                            <mat-error class="ErrorInline" *ngIf="formCtrl.lastNameForm.hasError('minlength')">Minimum <b>3 caractères</b>, maximum <b>50</b>.</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
                        <div class="input-gestion">
                            <mat-label>Numéro SIRET de l'entreprise :</mat-label>
                        </div>
                        <mat-form-field appearance="outline" class="input350">
                            <mat-label>Numéro SIRET</mat-label>
                            <input [(ngModel)]="siret" formControlName="sirenForm" matInput (keypress)="numericOnly($event)" required minlength="14" maxlength="14">
                            <mat-error class="ErrorInline" *ngIf="formCtrl.sirenForm.hasError('required')">Le champ SIRET est requis</mat-error>
                            <mat-error class="ErrorInline" *ngIf="formCtrl.sirenForm.hasError('minlength')">Il doit comporter <b>14 chiffres</b>.</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
                        <div class="input-gestion">
                            <mat-label>Nombre de salariés dans l'entreprise:</mat-label>
                        </div>
                        <mat-form-field appearance="outline" class="input350">
                            <mat-label>Nombre de salariés</mat-label>
                            <input [(ngModel)]="nbrSalaries" formControlName="nbrSalariesForm" matInput (keypress)="numericOnly($event)" required minlength="1" maxlength="6">
                            <mat-error class="ErrorInline" *ngIf="formCtrl.nbrSalariesForm.hasError('required')">Le champ nombre de salariés est requis</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <mat-card fxFlex="100">
                  <mat-card-title class="title title-pos">Corps d'états</mat-card-title>
                  <mat-card-content class="title-pos" fxLayout="row wrap">
                    <div fxFlex="33" fxFlex.xs="100" fxFlex.sm="50" class="etatCheckbox" *ngFor="let etats of etatsArray">
                      <mat-checkbox (change)="showOptions($event)" name="{{etats}}"> {{ etats }} </mat-checkbox>
                    </div>
                  </mat-card-content>
                </mat-card>

                <div class="button_connexion marge15T">
                    <mat-button-toggle
                    [disabled]="!formPresta.valid"
                    (click)="addPrestataire(email, phone, address, codePostal, company, lastName, siret, nbrSalaries, corpsEtat)"
                    class="btn btn-txt">
                      Soumettre a Coprovisit
                    </mat-button-toggle>
                    <div class="champsAlert" *ngIf="!formPresta.valid">* Remplissez tous les champs obligatoires pour créer le prestataire</div>
                    <div class="champsOk" *ngIf="formPresta.valid">* Vous pouvez créer le prestataire</div>
                </div>
                <p *ngIf="DataService.error"> {{ DataService.error }}</p>
            </form>
        </mat-card-content>
    </mat-card>
</div>

<!--div fxLayout="row wrap" class="padding-card padding-card-pos">
    <mat-card fxFlex="100">
        <mat-card-title class="title title-pos">Corps etats</mat-card-title>
        <mat-card-content class="title-pos" fxLayout="row wrap">
            <div fxFlex="33" fxFlex.xs="100" fxFlex.sm="50" class="etatCheckbox" *ngFor="let etats of etatsArray">
                <mat-checkbox> {{ etats }} </mat-checkbox>
            </div>
        </mat-card-content>
    </mat-card>
</div-->

