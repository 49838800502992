<!--app-navigation-admin></app-navigation-admin-->

<div fxLayout="row wrap" class="padding-card padding-card-pos">
    <mat-card>
        <mat-card-title class="title title-pos">Ajout syndic</mat-card-title>
        <mat-card-content fxLayout="row wrap" class="title-pos input_outline">
            <form fxLayout="row wrap" [formGroup]="formSyndic" class="formCreation">
                <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
                    <div class="input-gestion">
                        <mat-label>Email du compte :</mat-label>
                    </div>
                    <mat-form-field appearance="outline" class="input350">
                        <mat-label>Email du compte</mat-label>
                        <input [(ngModel)]="email" formControlName="emailForm" matInput required>
                        <mat-error class="ErrorInline" *ngIf="formCtrl.emailForm.hasError('required')">Le champ email est requis</mat-error>
                        <mat-error class="ErrorInline" *ngIf="formCtrl.emailForm.hasError('email')">Format de mail invalide.</mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
                    <div class="input-gestion">
                        <mat-label>Nom/raison sociale du Syndic :</mat-label>
                    </div>
                    <mat-form-field appearance="outline" class="input350">
                        <mat-label>Nom/raison sociale</mat-label>
                        <input [(ngModel)]="company" formControlName="companyForm" matInput  required minlength="3" maxlength="50">
                        <mat-error class="ErrorInline" *ngIf="formCtrl.companyForm.hasError('required')">Le champ Nom/Raison sociale est requis</mat-error>
                        <mat-error class="ErrorInline" *ngIf="formCtrl.companyForm.hasError('minlength')">Minimum <b>3 caractères</b>, maximum <b>50</b>.</mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
                    <div class="input-gestion">
                        <mat-label>Nom de l'utilisateur :</mat-label>
                    </div>
                    <mat-form-field appearance="outline" class="input350">
                        <mat-label>Nom</mat-label>
                        <input [(ngModel)]="lastName" formControlName="lastNameForm" matInput required minlength="3" maxlength="50">
                        <mat-error class="ErrorInline" *ngIf="formCtrl.lastNameForm.hasError('required')">Le champ nom est requis</mat-error>
                        <mat-error class="ErrorInline" *ngIf="formCtrl.lastNameForm.hasError('minlength')">Minimum <b>3 caractères</b>, maximum <b>50</b>.</mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
                    <div class="input-gestion">
                        <mat-label>Adresse (n° et nom de rue) du siege social :</mat-label>
                    </div>
                    <mat-form-field appearance="outline" class="input350">
                        <mat-label>Adresse du siege social</mat-label>
                        <input [(ngModel)]="address" formControlName="addressForm" matInput required minlength="5" maxlength="200">
                        <mat-error class="ErrorInline" *ngIf="formCtrl.addressForm.hasError('required')">Le champ adresse est requis</mat-error>
                        <mat-error class="ErrorInline" *ngIf="formCtrl.addressForm.hasError('minlength')">Minimum <b>5 caractères</b>, maximum <b>200</b>.</mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
                    <div class="input-gestion">
                        <mat-label>Prénom de l'utilisateur :</mat-label>
                    </div>
                    <mat-form-field appearance="outline" class="input350">
                        <mat-label>Prénom</mat-label>
                        <input [(ngModel)]="firstName" formControlName="firstNameForm" matInput required minlength="3" maxlength="100">
                        <mat-error class="ErrorInline" *ngIf="formCtrl.firstNameForm.hasError('required')">Le champ prénom est requis</mat-error>
                        <mat-error class="ErrorInline" *ngIf="formCtrl.firstNameForm.hasError('minlength')">Minimum <b>3 caractères</b>, maximum <b>100</b>.</mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
                    <div class="input-gestion">
                        <mat-label>Ville du siege social :</mat-label>
                    </div>
                    <mat-form-field appearance="outline" class="input350">
                        <mat-label>Ville</mat-label>
                        <input [(ngModel)]="ville" formControlName="villeForm" matInput required minlength="3" maxlength="100">
                        <mat-error class="ErrorInline" *ngIf="formCtrl.villeForm.hasError('required')">Le champ ville est requis</mat-error>
                        <mat-error class="ErrorInline" *ngIf="formCtrl.villeForm.hasError('minlength')">Il doit comporter <b>3 caractères</b> minimum</mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
                    <div class="input-gestion">
                        <mat-label>Numéro de contact du syndic :</mat-label>
                    </div>
                    <mat-form-field appearance="outline" class="input350">
                        <mat-label>Numéro de téléphone</mat-label>
                        <input [(ngModel)]="phone" formControlName="phoneForm" matInput (keypress)="numericOnly($event)" required minlength="10" maxlength="10">
                        <mat-error class="ErrorInline" *ngIf="formCtrl.phoneForm.hasError('required')">Le champ téléphone est requis</mat-error>
                        <mat-error class="ErrorInline" *ngIf="formCtrl.phoneForm.hasError('minlength')">Il doit comporter <b>10 chiffres</b></mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
                    <div class="input-gestion">
                        <mat-label>Code postal du siège social :</mat-label>
                    </div>
                    <mat-form-field appearance="outline" class="input350">
                        <mat-label>Code postal</mat-label>
                        <input [(ngModel)]="codePostal" formControlName="codePostalForm" matInput (keypress)="numericOnly($event)" required minlength="5" maxlength="5">
                        <mat-error class="ErrorInline" *ngIf="formCtrl.codePostalForm.hasError('required')">Le champ code postal est requis</mat-error>
                        <mat-error class="ErrorInline" *ngIf="formCtrl.codePostalForm.hasError('minlength')">Il doit comporter <b>5 chiffres</b></mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">

                </div>
                <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
                    <div class="input-gestion">
                        <mat-label>Numéro SIREN du syndic :</mat-label>
                    </div>
                    <mat-form-field appearance="outline" class="input350">
                        <mat-label>Numéro SIREN</mat-label>
                        <input [(ngModel)]="siren" formControlName="sirenForm" matInput (keypress)="numericOnly($event)" required minlength="9" maxlength="9">
                        <mat-error class="ErrorInline" *ngIf="formCtrl.sirenForm.hasError('required')">Le champ siren est requis</mat-error>
                        <mat-error class="ErrorInline" *ngIf="formCtrl.sirenForm.hasError('minlength')">Il doit comporter <b>9 chiffres</b>.</mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row wrap" fxFlex="100" class="button_connexion marge15T">
                    <div fxFlex="100">
                        <span>
                            <mat-button-toggle
                            [disabled]="!formSyndic.valid"
                            (click)="addSyndic(email, firstName, lastName, company, siren, address, codePostal, ville, phone)"
                            class="btn">
                                Créer le syndic
                            </mat-button-toggle>
                        </span>
                        <span class="alert_msg margeL10" *ngIf="msgErreur"> {{ msgErreur }}</span>
                    </div>

                    <div class="champsAlert" *ngIf="!formSyndic.valid">* Remplissez tous les champs obligatoires pour créer le syndic</div>
                    <div class="champsOk" *ngIf="formSyndic.valid">* Vous pouvez créer le syndic</div>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
