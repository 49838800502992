import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DataService } from '../../../data.service'

@Component({
  selector: 'app-ajouter-un-parc-en-cours',
  templateUrl: './ajouter-un-parc-en-cours.component.html',
  styleUrls: ['./ajouter-un-parc-en-cours.component.scss']
})
export class AjouterUnParcEnCoursComponent implements OnInit {
  accept: string = ".xls,.xlsx";
  data;

  form = new FormGroup({
    file: new FormControl()
 });

  constructor(public DataService: DataService) {}

  ngOnInit(){}
   
  addParc(data) {
    this.DataService.apiParc('/create/multi-copros', data,
    (res)=>{
      console.log("Yipi :", res)    
    },
    (error) => {
       console.log (error)
       if (error.error.message)
       {
         this.DataService.error= error.error.message 
       }
    })
  }    
}
