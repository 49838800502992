
<div class="padding-card padding-card-pos">
    <mat-card>
         <mat-card-title class="title title-pos">Ajout collaborateur</mat-card-title>
         <mat-card-content class="content-pos" style="text-align: center;">
                <mat-button-toggle class="btn btn-margin btn-txt btn_collabo" value="underline" routerLink="../form-gestionnaires">Gestionnaires</mat-button-toggle>
                <mat-button-toggle class="btn btn-margin btn-txt btn_collabo" value="underline" routerLink="../form-prestataires">Prestataires</mat-button-toggle>
                <mat-button-toggle class="btn btn-margin btn-txt btn_collabo" value="underline" routerLink="../form-courtiers">Courtiers</mat-button-toggle>
         </mat-card-content>
    </mat-card>
</div>

<div class="padding-card padding-card-pos">
    <mat-card>
        <mat-card-title class="title title-pos">Gestionnaires</mat-card-title>
         <mat-card-content>
                <div fxLayout="row wrap" class="font-normal">
                    <div fxFlex="33" fxFlex.xs="100"  fxFlex.sm="100" fxFlex.md="50" *ngFor="let detail of resultSearch; let i = index">
                        <div fxLayout="row wrap" fxLayoutAlign="center">
                            <div fxFlex="80" fxFlex.xs="100" fxFlex.sm="100" id={{detail.id_el}} class="box_shadow pad_colab">

                                <div fxLayoutAlign="center">
                                    <div *ngIf="detail.src_img == !null" mat-card-avatar class="imgs marge_photo" [ngStyle]="{ 'background-position': 'center', ' background-color': '#F9F9F9', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-image': 'url(' + detail.src_img + ')'}"></div>
                                    <div *ngIf="detail.src_img == null" mat-card-avatar class="imgs marge_photo ProfilSansImg"></div>
                                </div>

                                <div fxLayoutAlign="center" class="marge_name name_collabo">{{ detail.name }}</div>
                                <div fxLayoutAlign="center" class="marge_colab"><mat-button-toggle class="btn-gris btn-text droit_collabo" routerLink="../changer-droits/{{detail.id_el}}">Gérer Gestionnaire</mat-button-toggle></div>
                                <div fxLayoutAlign="center" class="delet_icon"><mat-button-toggle (click)="onEvent($event)" (click)="confirmation($event)"><img src="../../../../assets/img/icones/croix-medium-black.png"></mat-button-toggle></div>

                            </div>
                        </div>
                    </div>
                    <!--<div fxFlex="33" fxFlex.xs="100" fxFlex.sm="50" fxFlex.md="50" height="100" fxLayoutAlign="center center" class="pad40_0">
                        <mat-button-toggle><img src="../../../../assets/img/icones/next-medium.png"></mat-button-toggle>
                    </div>-->
                </div>
         </mat-card-content>
    </mat-card>
</div>
