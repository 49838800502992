//import { ConnectionService } from './connection.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {Component, HostListener, OnInit} from '@angular/core';
import {CourtierDemandeDialogComponent} from "../../../../dialog/courtier-demande-dialog/courtier-demande-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {DataService} from "../../../../data.service";

@Component({
  selector: 'app-dashboard-contact',
  templateUrl: './dashboard-contact.component.html',
  styleUrls: ['./dashboard-contact.component.scss']
})
export class DashboardContactComponent implements OnInit {

  objet; message; msgErreur;

  formData = this.fb.group({
    objetForm: ['', [Validators.required]],
    messageForm: ['', [Validators.required, Validators.minLength(3), Validators.pattern('[a-zA-Z0-9 ]*')]],
  });

  sendMessage() {
    if (this.formData.valid) {
      this.objet = this.formData.value.objetForm;
      this.message = this.formData.value.messageForm;
      this.DataService.api('/gestion/contact', {objet: this.objet, message: this.message},
        (data) => {
          alert(data.message);
        },
        (error) => {
          this.msgErreur = error.error.message;
        });
    } else {
      this.msgErreur = "Vérifiez les informations du formulaire"
    }
  }

  constructor(private fb: FormBuilder, public DataService : DataService) { }

  ngOnInit(): void {}

}
