import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../../data.service'
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';

export interface Section {
  image: string;
  toggle: string;
  num_copro: string;
  address: string;
  demandeLe: string;
  codePostal: string;
  id: number;
  class:string;
  surface: number;
}

@Component({
  selector: 'app-gerer-architectes',
  templateUrl: './gerer-architectes.component.html',
  styleUrls: ['./gerer-architectes.component.scss']
})

export class GererArchitectesComponent implements OnInit {

  onEvent(event) {
    event.stopPropagation();
  }

  color_line(event){
    if(event.currentTarget.parentElement.classList.contains("droits")) {
      event.currentTarget.parentElement.classList.remove("droits");
      event.currentTarget.parentElement.classList.add("droits_selected");

    } else if (event.currentTarget.parentElement.classList.contains('droits_selected')) {
      event.currentTarget.parentElement.classList.remove('droits_selected');
      event.currentTarget.parentElement.classList.add('droits');
    }
  }

  move_item_colonne_gauche_to_droite_by_id(i) {

    let params = {
      visites : i,
      architecteId  : this.architecte._id
    }

    this.api_unassign_visit(params, (res) => {
        for (let id in i) {
            for (let j in this.typesOfSyndics) {
                if (i[id] == this.typesOfSyndics[j].id) {
                  this.typesOfDisponibles.push(this.typesOfSyndics[j])
                  this.typesOfSyndics.splice(parseInt(j), 1);
                }
            }
        }
    }, (error) => {
      console.log("error: ", error)
    })
  }

  move_item_colonne_droite_to_gauche_by_id(i) {
      let params = {
        visites : i,
        architecteId  : this.architecte._id
      }

      this.api_assign_visit(params, (res) => {
          for (let id in i) {
              for (let j in this.typesOfDisponibles) {
                  if (i[id] == this.typesOfDisponibles[j].id) {
                    this.typesOfSyndics.push(this.typesOfDisponibles[j])
                    this.typesOfDisponibles.splice(parseInt(j), 1);
                  }
              }
          }
      }, (error) => {
        console.log("error: ", error)
      })
  }



  get_colonnedroite(id) {
    let i;
    for ( i in this.typesOfDisponibles) {
        if (this.typesOfDisponibles[i].id == id) {
            return this.typesOfDisponibles[i].id;
        }
    }
  }

  get_colonnegauche(id) {
    let i
    for ( i in this.typesOfSyndics) {
        if (this.typesOfSyndics[i].id == id) {
            return this.typesOfSyndics[i].id;
        }
    }
  }



  echange_line(event) {
      if (confirm("Voulez-vous enlever ces syndicats de votre selection?")) {
          let el = document.querySelectorAll(".colonne_gauche.droits_selected");

          let to_move = [];
          if (el.length == 1) {
            let tmp_id = this.get_colonnegauche(el[0].id)
            to_move.push(tmp_id)
          } else {
              for (let i in el) {
                if (el[i].id)
                  to_move.push(this.get_colonnegauche(el[i].id))
              }
              //for (let i in el) {
                this.move_item_colonne_gauche_to_droite_by_id(to_move)
              //}
              return;
          }
          //for (let i in to_move) {
              this.move_item_colonne_gauche_to_droite_by_id(to_move)
          //}
      }
  }

  echange_line_2(event) {
    if (confirm("Voulez-vous ajouter ces syndicats à votre selection?")) {
        let el = document.querySelectorAll(".colonne_droite.droits_selected");

        let to_move = [];
        if (el.length == 1) {
          let tmp_id = this.get_colonnedroite(el[0].id)
          to_move.push(tmp_id)
        } else {
            for (let i in el) {
              if (el[i].id)
                to_move.push(this.get_colonnedroite(el[i].id));
            }

            //for (let i in el) {
              this.move_item_colonne_droite_to_gauche_by_id(to_move)
            //}
            return;
        }

        //for (let i in to_move) {
            this.move_item_colonne_droite_to_gauche_by_id(to_move)
        //}
    }
  }


  api_assign_visit(params, callback_success, callback_error){
    this.http.post("https://copro-back.cantem.fr/gestion/assign-visite", params).subscribe(
      (response) => {
            callback_success(response)
      },
      (error) => {
           callback_error(error)
    });
  }

  api_unassign_visit(params, callback_success, callback_error){
    console.warn(params)
    this.http.post("https://copro-back.cantem.fr/gestion/unassign-visite", params).subscribe(
      (response) => {
            callback_success(response)
      },
      (error) => {
           callback_error(error)
    });
  }

  api_post_visite_architect(params, callback_success, callback_error){
    this.http.post("https://copro-back.cantem.fr/retrieve/visite-archi", params).subscribe(
      (response) => {
            callback_success(response)
      },
      (error) => {
           callback_error(error)
    });
  }
  api_get_uvisite_architect(callback_success, callback_error){
    this.http.get("https://copro-back.cantem.fr/retrieve/visite-unassigned").subscribe(
      (response) => {
            callback_success(response)
      },
      (error) => {
           callback_error(error)
    });
  }

  api_get_un_architect(params, callback_success, callback_error){
    this.http.get("https://copro-back.cantem.fr/retrieve/architecte", params).subscribe(
      (response) => {
            callback_success(response)
      },
      (error) => {
           callback_error(error)
    });
  }

    /*api_post_visite_architect(params, callback_success, callback_error){
    this.http.post("https://copro-back.cantem.fr/retrieve/visite", params).subscribe(
      (response) => {
            callback_success(response)
      },
      (error) => {
           callback_error(error)
    });
  }*/
  /*api_get_uvisite_architect(callback_success, callback_error){
    this.http.get("https://copro-back.cantem.fr/retrieve/visite").subscribe(
      (response) => {
            callback_success(response)
      },
      (error) => {
           callback_error(error)
    });
  }*/

    typesOfSyndics: Section[] = [];

    typesOfDisponibles: Section[] = [];

    architecte;
    resultArchi;
    idArchi;
    surface = 0;


    constructor(private http: HttpClient, private activatedRoute: ActivatedRoute, public DataService: DataService) { }
    ngOnInit() {
      this.activatedRoute.params.subscribe(params => {
        const id = params['id'];
        this.idArchi = id
        this.DataService.api_single_architecte({_id:id},
          (data)=>{
            this.architecte = data.architecte
            // for(let item in data.architecte){
            //   this.architecte[item]=
            //   {address: data.architecte[item].address}
            //    console.log(data.architecte[item].address)
            // }
            // console.log("this.architecte adresse", this.architecte.address)

            this.api_post_visite_architect({_id: this.architecte._id},
              (data) => {
                for (let key in data.visites) {
                  this.typesOfSyndics[key] = {
                    num_copro: data.visites[key].nomCopro,
                    id: data.visites[key]._id,
                    codePostal: data.visites[key].coproId.codePostal,
                    demandeLe: data.visites[key].demandeLe,
                    nomCabinet: data.visites[key].nomCabinet,
                    address: data.visites[key].coproId.address,
                    surface: data.visites[key].coproId.surface
                  };
                  this.surface += data.visites[key].coproId.surface;
                }
              },
              (error)=>{
                console.log(error.status)
              }
            );

            this.api_get_uvisite_architect(
              (data)=>{
                for(let key in data.visites)
                  this.typesOfDisponibles[key] = {
                    num_copro: data.visites[key].nomCopro,
                    id: data.visites[key]._id,
                    address: data.visites[key].coproId.address,
                    codePostal: data.visites[key].coproId.codePostal ,
                    demandeLe: data.visites[key].demandeLe,
                    surface: data.visites[key].coproId.surface
                };
              },
              (error)=>{
                console.log(error.status)
              }
            );

            console.log(this.typesOfDisponibles)
            this.api_get_un_architect({_id: this.architecte._id},
              (data) => {
                this.resultArchi = data.architectes;
              },
              (error)=>{
                console.log(error.status)
              }
            );
          },
          (error)=>{
            console.log("erreur")
            // this.architecte = error.err.architecte
          }
        );
        // console.log('Url Id: ',id);
      });
    }

  }
