import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-connexion',
  templateUrl: './connexion.component.html',
  styleUrls: ['./connexion.component.scss']
})
export class ConnexionComponent implements OnInit {

  hide = true;

  email_input = new FormControl('', [Validators.required, Validators.email]);

  getErrorMessage() {
    if (this.email_input.hasError('required')) {
      return 'Le champ email est vide';
    }

    return this.email_input.hasError('email') ? 'Email non valide' : '';
  }

  login;
  password;
  email;
  msgErreur;

  taken = JSON.parse(localStorage.getItem('taken'));

  connexion(login, password) {
    this.DataService.error = ""
    this.DataService.api('/auth/login', {email : login, password : password},
    (res)=>{
      if(res.token) {
        this.DataService.token=res.token;
        console.log(res)
        res.user.token = res.token
        // document.cookie = JSON.stringify(res.user)
        localStorage.setItem('token', res.token)
        localStorage.setItem('role', res.user.role)
        localStorage.setItem('id', res.user._id)
        this.DataService.user = res.user;
        this.DataService.user.token = res.token;
        if(res.user.role==="admin") {
          // document.cookie="";
          // document.cookie="{\"account_type\":\"admin\"}";
          this.DataService.router.navigate(['admin-copro']);
        }
        if (res.user.role==="syndic") {
          // document.cookie="";
          // document.cookie="{\"account_type\":\"syndic\"}";
          this.DataService.api('/retrieve/credit', {},
            (data) => {
              localStorage.setItem('credit', data.credit);
            },
            (error) => {
              console.log(error);
            });
          this.DataService.router.navigate(['ajout-collaborateurs']);
        }
        if (res.user.role==="gestionnaire") {
          // document.cookie="";
          // document.cookie="{\"account_type\":\"gestionnaire\"}";
          this.DataService.api('/retrieve/credit', {},
            (data) => {
              localStorage.setItem('credit', data.credit);
            },
            (error) => {
              console.log(error);
            })
          this.DataService.router.navigate(['mon-parc']);
        }
        if(res.user.role==="courtier") {
          // document.cookie="";
          // document.cookie="{\"account_type\":\"courtier\"}";
          this.DataService.router.navigate(['mes-syndics']);
        }
        if(res.user.role==="architecte") {
          // document.cookie="";
          // document.cookie="{\"account_type\":\"architecte\"}";
          this.DataService.router.navigate(['visites-a-faire']);
        }
        if(res.user.role==="prestataire") {
          this.DataService.router.navigate(['mes-syndics']);
        }
        if(res.user.role==="pcs") {
          this.DataService.router.navigate(['mon-immeuble']);
        }
      }
    },
    (error) => {
      console.log (error)
        if (error.error.message)
        {
          this.DataService.error= error.error.message;
          this.msgErreur = this.DataService.error;
        }
    })
}

  constructor(public DataService : DataService) { }

  ngOnInit(): void {
  }

}
