import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataService } from '../../../../data.service';
import {ActivatedRoute} from '@angular/router';

export interface Section {
  address: string;
  imgProfil: string;
  src_img: string;
  nomCopro: string;
  ville: string;
  id_el: string;
  reference: string;
  codePostal: string;
  surface: number;
  compagnie: object;
  dateDemandeVisite: string;
  dateVisite: string;
  pcs: object;
  gest: object;
  display_global: string;
}

@Component({
  selector: 'app-dashboard-a-etudier',
  templateUrl: './dashboard-a-etudier.component.html',
  styleUrls: ['./dashboard-a-etudier.component.scss']
})

export class DashboardAEtudierComponent implements OnInit {

  ids = [];

  constructor(public DataService: DataService, private activatedRoute: ActivatedRoute) {
    this.syndicId =  this.activatedRoute.snapshot.params['id'];
    let isParc = false;

    this.DataService.api_get_copro_syndic(
      {syndicId: this.syndicId, isParc},
      (data) => {

        data.etudes.reverse();
        for (const key in data.etudes) {
          this.ids.push(data.etudes[key]._id);
          if (data.etudes[key].batiments[0]) {
            this.listCopro[key] = {
              nomCopro: data.etudes[key].nomCopro,
              address: data.etudes[key].address,
              imgProfil : data.etudes[key].batiments[0],
              src_img : `https://copro-back.cantem.fr/uploads/batiment/${data.etudes[key].batiments[0].image.facadeRue[0]}`,
              ville: data.etudes[key].ville,
              id_el: data.etudes[key]._id,
              reference: data.etudes[key].reference,
              codePostal: data.etudes[key].codePostal,
              surface: data.etudes[key].surface,
              compagnie: data.etudes[key].compagnie,
              pcs: data.etudes[key].pcs,
              gest: data.etudes[key].gestionnaire,
              dateDemandeVisite: data.etudes[key].dateDemandeVisite,
              dateVisite: data.etudes[key].dateVisite,
              display_global: 'display_none'
            };
          } else {
            this.listCopro[key] = {
              nomCopro: data.etudes[key].nomCopro,
              address: data.etudes[key].address,
              imgProfil : data.etudes[key].batiments[0],
              src_img : '/assets/img/photo/immeuble-base.jpg',
              ville: data.etudes[key].ville,
              id_el: data.etudes[key]._id,
              reference: data.etudes[key].reference,
              codePostal: data.etudes[key].codePostal,
              surface: data.etudes[key].surface,
              compagnie: data.etudes[key].compagnie,
              pcs: data.etudes[key].pcs,
              gest: data.etudes[key].gestionnaire,
              dateDemandeVisite: data.etudes[key].dateDemandeVisite,
              dateVisite: data.etudes[key].dateVisite,
              display_global: 'display_none'
            };
          }
        }

        this.DataService.api_get_visite_courtier({copros: this.ids},
          (data) => {
            for (let i in data.visites) {
              for (let j in this.listCopro) {
                if (data.visites[i].coproId === this.listCopro[j].id_el) {
                  this.listCopro[j].pcs = {
                    lastName: data.visites[i].nomPCS,
                    email: data.visites[i].emailPCS,
                    phone: data.visites[i].phonePCS,
                  }
                }
              }
            }
          },
          (error) => {
            console.log(error)
          });

        this.resultSearch = this.listCopro;
      },
      (error) => {
        console.log(error);
      }
    );
    this.resultSearch = this.listCopro;
  }

  PrestatairesDetailsArray;
  searchVal: string;
  resultSearch = [];
  listCopro: Section [] = [];

  syndicId;

  onEvent(event) {
    event.stopPropagation();
  }


  toggle_info(event) {
    console.log (event);
    let i;
    const id = event.srcElement.parentNode.parentNode.parentNode.parentNode.parentNode.nextElementSibling.id;

    if (document.getElementById(id).className === 'display_none')
    {
      document.getElementById(id).classList.remove('display_none');
      document.getElementById(id).className = 'display_block';
    }
    else if (document.getElementById(id).className === 'display_block')
    {
      document.getElementById(id).classList.remove('display_block');
      document.getElementById(id).className = 'display_none';
    }
    else
    {
      return;
    }
  }
  ngOnInit(): void {
  }
  deassignCoproEtude(selectedCopro) {
    this.DataService.apiUnassignEtudeToCourtier(
      {coproId: selectedCopro.id_el},
      (data, data2) => {
        alert('Immeuble supprimé avec succès')
        this.resultSearch = this.resultSearch.filter(e => e.id_el !== selectedCopro.id_el);
        this.listCopro = this.listCopro.filter(e => e.id_el !== selectedCopro.id_el);

      },
      (err, err2) => {
        alert("une erreur est survenue");
        console.log('err,', err, err2)
      });
  }
  searchEngine() {
    this.resultSearch = [];
    // if(this.searchVal)
    for (const key in this.listCopro) {
      if (
        this.listCopro[key].nomCopro.toLowerCase().indexOf(this.searchVal.toLowerCase()) > -1 && this.resultSearch.length < 4 ||
        this.listCopro[key].address.toLowerCase().indexOf(this.searchVal.toLowerCase()) > -1 && this.resultSearch.length < 4 ||
        this.listCopro[key].codePostal.toLowerCase().indexOf(this.searchVal.toLowerCase()) > -1 && this.resultSearch.length < 4 ||
        this.listCopro[key].reference.toLowerCase().indexOf(this.searchVal.toLowerCase()) > -1 && this.resultSearch.length < 4
      ){
        this.resultSearch.push(this.listCopro[key]);
      }
    }
  }
}
