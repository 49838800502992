import {Component, OnInit, ViewChild} from '@angular/core';
import {MatAccordion} from '@angular/material/expansion';
import {DataService} from '../../../../data.service';
import {FormService} from '../../../../services/form.service'
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {DesordresDialogComponent} from '../../../../dialog/desordres-dialog/desordres-dialog.component';
import {MatDialog} from '@angular/material/dialog';
//import { Router } from '@angular/router';
//import { Location } from '@angular/common';

@Component({
  selector: 'app-visites-formulaire',
  templateUrl: './visites-formulaire.component.html',
  styleUrls: ['./visites-formulaire.component.scss'],
  providers : [
    {provide: MAT_DATE_LOCALE, useValue: 'fr'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class VisitesFormulaireComponent implements OnInit {

  @ViewChild(MatAccordion) accordion: MatAccordion;

  form = new FormGroup({
    file: new FormControl()
  });

  formRue = new FormGroup({
    fileRue: new FormControl()
  });

  formArriere = new FormGroup({
    fileArriere: new FormControl()
  });

  formCave = new FormGroup({
    fileCave: new FormControl()
  });

  formEnvi = new FormGroup({
    fileEnvi: new FormControl()
  });

  formConti = new FormGroup({
    fileConti: new FormControl()
  });

  formParking = new FormGroup({
    fileParking: new FormControl()
  });

  formAscenceur = new FormGroup({
    fileAscenceur: new FormControl()
  });

  formEntree = new FormGroup({
    fileEntree: new FormControl()
  });

  formParcelle = new FormGroup({
    fileParcelle: new FormControl()
  });

  formGoogle = new FormGroup({
    fileGoogle: new FormControl()
  });

  formFiles = new FormGroup({
    multiplefile: new FormControl()
  });

  toppings = new FormControl();

  toppingList: string[] = [
    'Chauffagiste',
    'Contrôle d’accès',
    'Cordiste',
    'Couvreur',
    'Electricien',
    'Façadier',
    'Infiltrations',
    'Maçon',
    'Menuisier',
    'Peintre',
    'Plombier',
    'Porte de garage',
    'Ravalement',
    'Recherche de fuite',
    'Sécurité incendie',
    'Serrurier',
    'Vitrier',
  ];

  data;

  formVisite = this.fb.group({ });
  get formCtrl() { return this.formVisite.controls; }

  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    return patt.test(event.key);
  }
/*
  save() {
    console.log (JSON.stringify (this.FormService.formulaire))
  }
*/
  key; value; /*value1; value2; value3; value4; value5;*/ classes; /*img_upload; condition;*/ name; label; type;
  show = false;
  toggle() { this.show = !this.show };
  imgBat; ParcelleCadastrale; VueGenGoogle;
  //Image = {ascenseur: [[[]]], entrees: [[[]]], caves: [[]]};
  //OriginalImage = {ascenseur: [[[]]], entrees: [[[]]], caves: [[]]};

  Image = [{ParcelleCadastrale: [], VueGenGoogle: [], ascenseur: [[]], entrees: [[]], facadeRue: [], facadeArriere: [], caves: [], parking: [], environnement: [], contiguite: []}];
  OriginalImage = [{ParcelleCadastrale: [], VueGenGoogle: [], ascenseur: [[]], entrees: [[]], facadeRue: [], facadeArriere: [], caves: [], parking: [], environnement: [], contiguite: []}];

  parseConditionsForm(conditions, thirdDimension, y) {
    if (!conditions) {
      return true;
    }
    if (thirdDimension) {
      if (conditions.length) {
        // @ts-ignore
        const conditionValidity = conditions.map(condition => this.FormService.batiments[y][condition.pos].value === condition.value)
        return !conditionValidity.some(e => !e);
        // @ts-ignore
      } else return this.FormService.batiments[y][conditions.pos].value === conditions.value;
    } else {
      //console.log('forms', this.FormService, conditions)
      if (conditions.length) {
        // @ts-ignore
        const conditionValidity = conditions.map(condition => this.FormService?.formIntro[y][condition.pos]?.value === condition.value)
        return !conditionValidity.some(e => !e);
        // @ts-ignore
      } else return this.FormService?.formIntro[y][conditions.pos].value === conditions.value;
    }
  }
  /*
  uploadFile(ou) {
    this.DataService.apiImgBat('/create/batImage', this.idCopro, this.data,
      (res)=>{
        //console.log("Yipi :", res)
        if(!this.Image[ou])
          this.Image[ou]=new Array()
        this.Image[ou].push(res.image)
        //console.log("nom de l'image",res.image)
      },
      (error) => {
        console.log (error)
      })
  }
  */

  dataRue;
  uploadFileRue(ou, indexBat) {
    this.dataRue.map(dataRue => {
      this.DataService.apiImgBat('/create/batImage', this.idCopro, dataRue,
        (res) => {
          //console.log("Yipi Rue :", res)
          if (!this.Image[indexBat][ou])
            this.Image[indexBat][ou] = [];
          if (!this.OriginalImage[indexBat][ou])
            this.OriginalImage[indexBat][ou] = [];
          this.OriginalImage[indexBat][ou].push(dataRue);
          this.Image[indexBat][ou].push(res.image);
          //console.log("nom de l'image", res.image)
        },
        (error) => {
          console.log(error)
        })
    });
  }

  dataArriere;
  uploadFileArriere(ou, indexBat) {
    this.dataArriere.map(dataArriere => {
      this.DataService.apiImgBat('/create/batImage', this.idCopro, dataArriere,
        (res) => {
          //console.log("Yipi Rue :", res)
          if (!this.Image[indexBat][ou])
            this.Image[indexBat][ou] = [];
          if (!this.OriginalImage[indexBat][ou])
            this.OriginalImage[indexBat][ou] = [];
          this.OriginalImage[indexBat][ou].push(dataArriere);
          this.Image[indexBat][ou].push(res.image);
          //console.log("nom de l'image", res.image)
        },
        (error) => {
          console.log(error)
        })
    });
  }

  dataCave;
  uploadFileCave(ou, indexBat) {
    this.dataCave.map(cave => {
      this.DataService.apiImgBat('/create/batImage', this.idCopro, cave,
        (res) => {
          if (!this.Image[indexBat][ou])
            this.Image[indexBat][ou] = [];
          if (!this.OriginalImage[indexBat][ou])
            this.OriginalImage[indexBat][ou] = [];
          this.OriginalImage[indexBat][ou].push(cave);
          this.Image[indexBat][ou].push(res.image);
          //console.log("Yipi cave:", res)
          //console.log('dans: ', ou);
          /*
          if (!this.Image[ou])
            this.Image[ou] = new Array()
          if (!this.OriginalImage[ou])
            this.OriginalImage[ou] = new Array()
          this.OriginalImage[ou].push(cave)
          this.Image[ou].push(res.image)
          //console.log("nom de l'image", res.image)
          */
        },
        (error) => {
          console.log(error)
        })
    })
  }

  dataEnvi;
  uploadFileEnvi(ou, indexBat) {
    this.dataEnvi.map(environment => {
      this.DataService.apiImgBat('/create/batImage', this.idCopro, environment,
        (res) => {
          //console.log("Yipi environnement:", res)
          //console.log('dans: ', ou);
          if (!this.Image[indexBat][ou])
            this.Image[indexBat][ou] = [];
          if (!this.OriginalImage[indexBat][ou])
            this.OriginalImage[indexBat][ou] = [];
          this.OriginalImage[indexBat][ou].push(environment);
          this.Image[indexBat][ou].push(res.image);
          //console.log("nom de l'image", res.image)
        },
        (error) => {
          console.log(error);
        });
    });
  }

  dataConti;
  uploadFileConti(ou, indexBat) {
    this.dataConti.map(contiguite => {
      this.DataService.apiImgBat('/create/batImage', this.idCopro, contiguite,
        (res) => {
          //console.log("Yipi Contiguite:", this.Image)
          //console.log('dans: ', ou);
          if (!this.Image[indexBat][ou])
            this.Image[indexBat][ou] = [];
          if (!this.OriginalImage[indexBat][ou])
            this.OriginalImage[indexBat][ou] = [];
          this.OriginalImage[indexBat][ou].push(contiguite);
          this.Image[indexBat][ou].push(res.image);
          //console.log("nom de l'image", res.image)
        },
        (error) => {
          console.log(error)
        })
    });
  }

  dataParking;
  uploadFileParking(ou, indexBat) {
    this.dataParking.map(parking => {
      this.DataService.apiImgBat('/create/batImage', this.idCopro, parking,
        (res) => {
          //console.log("Yipi Parking:", res)
          //console.log('dans: ', ou);
          if (!this.Image[indexBat][ou])
            this.Image[indexBat][ou] = [];
          if (!this.OriginalImage[indexBat][ou])
            this.OriginalImage[indexBat][ou] = [];
          this.OriginalImage[indexBat][ou].push(parking);
          this.Image[indexBat][ou].push(res.image);
          //console.log("nom de l'image", res.image)
        },
        (error) => {
          console.log(error)
        })
    });
  }

  dataParcelle;
  uploadFileParcelle(ou) {
    this.DataService.apiImgBat('/create/batImage', this.idCopro, this.dataParcelle,
      (res)=>{
        //console.log("Yipi parcelle:", res)
        //console.log('dans: ', ou);
        if(!this.Image[0][ou])
          this.Image[0][ou] = [];
        if(!this.OriginalImage[0][ou])
          this.OriginalImage[0][ou] = [];
        this.OriginalImage[0][ou].push(this.dataParcelle);
        this.Image[0][ou].push(res.image);
        //console.log("nom de l'image",res.image)
      },
      (error) => {
        console.log (error)
      })
  }

  dataGoogle;
  uploadFileGoogle(ou) {
    this.DataService.apiImgBat('/create/batImage', this.idCopro, this.dataGoogle,
      (res)=>{
        //console.log("Yipi vue google:", res)
        //console.log('dans: ', ou);
        if(!this.Image[0][ou])
          this.Image[0][ou] = [];
        if(!this.OriginalImage[0][ou])
          this.OriginalImage[0][ou] = [];
        this.OriginalImage[0][ou].push(this.dataGoogle);
        this.Image[0][ou].push(res.image);
        //console.log("nom de l'image",res.image)
      },
      (error) => {
        console.log (error)
      })
  }

  dataAscenceur;
  uploadFileAscenceur(ou, indexEntree, indexBat) {
    this.dataAscenceur.map(ascenseur => {
      this.DataService.apiImgBat('/create/batImage', this.idCopro, ascenseur,
        (res) => {
          //console.log("Yipi ascenceur:", ou)
          //console.log('dans: ', ou);
          if (!this.Image[indexBat][ou][indexEntree])
            this.Image[indexBat][ou][indexEntree] = [];
          if (!this.OriginalImage[indexBat][ou][indexEntree])
            this.OriginalImage[indexBat][ou][indexEntree] = [];
          this.OriginalImage[indexBat][ou][indexEntree].push(ascenseur);
          this.Image[indexBat][ou][indexEntree].push(res.image);
          //console.log("nom de l'image", res.image)
        },
        (error) => {
          console.log(error)
        })
    })
  }

  dataEntree;
  uploadFileEntree(ou, indexEntree, indexBat) {
    this.dataEntree.map(entree => {
      this.DataService.apiImgBat('/create/batImage', this.idCopro, entree,
        (res)=>{
          //console.log("Yipi entree:", res)
          //console.log('dans: ', ou);
          if(!this.Image[indexBat][ou][indexEntree])
            this.Image[indexBat][ou][indexEntree] = [];
          if (!this.OriginalImage[indexBat][ou][indexEntree])
            this.OriginalImage[indexBat][ou][indexEntree] = [];

          this.OriginalImage[indexBat][ou][indexEntree].push(entree);
          this.Image[indexBat][ou][indexEntree].push(res.image);
          //console.log("nom de l'image",res.image)
        },
        (error) => {
          console.log (error)
        })
    })
  }

  deleteImage(index, imageCategory, formName, indexEntree, indexBat ) {
    if (imageCategory === 'ascenseur' || imageCategory === 'entrees') {
      this.Image[indexBat][imageCategory][indexEntree].splice(index, 1)
      this.OriginalImage[indexBat][imageCategory][indexEntree].splice(index, 1)
      if (!this.Image[indexBat][imageCategory][indexEntree].length) {
        this[formName] = null;
      }
    } else {
      this.Image[indexBat][imageCategory].splice(index, 1)
      this.OriginalImage[indexBat][imageCategory].splice(index, 1)
      if (!this.Image[indexBat][imageCategory].length) {
        this.Image[indexBat][imageCategory] = null;
        this.OriginalImage[indexBat][imageCategory] = null;
        this[formName] = null;
      }
    }
  }

  /*this.DataService.apiImgBat('/create/batImage', this.idCopro, this.dataEntree,
  (res)=>{
    //console.log("Yipi :", res)
    if(!this.Image[ou])
    this.Image[ou]=new Array()
    this.Image[ou].push(res.image)
    //console.log("nom de l'image",res.image)
  },
  (error) => {
    console.log (error)
  })*/


  myFiles:string [] = [];
  //sMsg:string = '';
  deleteIncident (index) {
    this.myFiles.splice(index, 1);
  }
  requestDeleteIncident(incident) {
    const confirmed = confirm("Voulez-vous supprimer cet incident ?")
    if (confirmed) {
      this.DataService.apiDeleteIncident({incidentId: incident._id}, () => {
        this.resultDesordres = this.resultDesordres.filter(e => e?._id !== incident?._id);
        alert('Incident supprimé')
      }, (err) => {
        console.log('err', err)
        if (err?.error?.message)
          alert(err.error.message)
      })
    }
  }
  getFileDetails (e) {
    //console.log (e.target.files);
    for (let i = 0; i < e.target.files.length; i++) {
      if ( this.myFiles.length < 3) {
        this.myFiles.push(e.target.files[i]);
      } else {
        alert("3 images max")
      }
    }
  }
/*
  uploadFiles () {
    const data = new FormData();

    for (var i = 0; i < this.myFiles.length; i++) {
      data.append("images", this.myFiles[i][name]);
    }
  }
*/
  incidents; desordre; situation; description; metrages; corpsEtat = []; commentaire; syndicId; courtierId; gestionnaireId;
  images;
  coproId;
  msgErreur;
  msgErrVisit;
  architecteId = localStorage.getItem('id')

  loader = false;

  addIncident2(desordre, situation, description, metrages, commentaire, syndicId, courtierId, gestionnaireId, visiteId, idCopro) {
    //this.loader = true;
    if (!desordre)
      this.msgErreur = "Veuillez renseigner le champ 'Désordres'";
    else if (!situation)
      this.msgErreur = "Veuillez renseigner le champ 'Situation'";
    else if (!description)
      this.msgErreur = "Veuillez renseigner le champ 'Description'";
    else if (!metrages)
      this.msgErreur = "Veuillez renseigner le champ 'Métrages'";
    else if (this.toppings.value.length === 0)
      this.msgErreur = "Veuillez renseigner le ou les corps d'état";
    else if (this.myFiles?.length === 0)
      this.msgErreur = "Veuillez ajouter des photos du désordre";
    else {
      this.loader = true;
      const data = new FormData();
      this.incidents = data;
      for (let i = 0; i < this.myFiles.length; i++) {
        data.append("images", this.myFiles[i]);
      }
      data.append('desordre', desordre);
      data.append('situation', situation)
      data.append('description', description)
      data.append('metrages', metrages)
      data.append('commentaire', commentaire)

      for (let file of this.toppings.value) {
        data.append('corpsEtat[]', file);
      }
      data.append('syndicId', syndicId)
      data.append('courtierId', courtierId)
      data.append('architecteId', this.architecteId)
      data.append('gestionnaireId', gestionnaireId)
      data.append('coproId', idCopro)
      data.append('visiteId', visiteId)

      this.DataService.apiIncident('/create/incident', data,
        ()=>{
          //console.log("Yipi :", res)
          this.DataService.api_single_copro({_id: this.idCopro},
            (data)=>{
              this.copro = data.copro
              this.resultDesordres= this.copro.incidentId;
              this.resultEnvoyee = this.copro.batiments;
              if (this.copro.batiments[0] !== undefined){
                this.resultEntree = this.copro.batiments[0]['entrees'];
              }
              // cache le formulaire
              this.show = !this.show;
              // clear input
              this.desordre = '';
              this.situation = '';
              this.description = '';
              this.commentaire = '';
              this.corpsEtat = [];
              this.metrages = '';
              this.msgErreur = '';
              this.myFiles = [];
              // message d'alerte
              this.dialog.open(DesordresDialogComponent, {
                height: 'auto',
                width: '100%',
                maxWidth: '450px',
              });
              this.loader = false;
            },
            (error)=>{
              console.log(error)
              this.copro = error.err.copro
              this.loader = false;
            }
          );
        },
        (error) => {
          console.log (error)
          if (error.error.message)
          {
            this.DataService.error= error.error.message;
            this.msgErreur = this.DataService.error;
          }
        })
    }
  }
/*
  addIncident(data) {
    data.coproId = this.idCopro
    data.visiteId = this.idVisite
    var to_send = {coproId: this.idCopro}
    console.error(to_send)
    this.DataService.apiIncident('/create/incident', to_send,
      (res)=>{
        //console.log("Yipi :", res)
      },
      (error) => {
        console.log (error)
        if (error.error.message)
        {
          this.DataService.error= error.error.message
        }
      })
  }
*/
  sendFormVisite(){
    this.FormService.envoi = true;
    let params = {};
    params["batiments"] = [];
    for(let bat in this.FormService.batiments){
      let tmp = {};
      for(let field in this.FormService.batiments[bat]){
        let name = this.FormService.batiments[bat][field].name;
        if(name != "" && this.FormService.batiments[bat][field].type != "txt" && (this.FormService.batiments[bat][field].value || name=="entrees")){
          let list = name.split("_");
          if (list[0] !== 'entrees') {
            if (list.length > 1){
              if (tmp[list[0]] === undefined) {
                tmp[list[0]] = {};
              }
              tmp[list[0]][list[1]] = this.FormService.batiments[bat][field].value;
            } else {
              tmp[name] = this.FormService.batiments[bat][field].value;
            }
          } else {
            if(!tmp[name])tmp[name] = [];
            for(let i=0; i<this.FormService.batiments[bat][3].tab.length; i++){
              if(tmp[name][i]===undefined){
                tmp[name][i] = {};
              }
              tmp[name][i]['photosEntree'] = this.Image[bat]['entrees'][i]
              tmp[name][i]['photosAscenseur'] = this.Image[bat]['ascenseur'][i]
              for(let cle in this.FormService.batiments[bat][3].tab[i]){
                let nom = this.FormService.batiments[bat][3].tab[i][cle].name;
                if(nom != "" && this.FormService.batiments[bat][3].tab[i][cle].type != "txt" && this.FormService.batiments[bat][3].tab[i][cle].value){
                  let list = nom.split("_");
                  if(list.length>1){
                    if(tmp[name][i][list[0]]===undefined){
                      tmp[name][i][list[0]] = {};
                    }
                    tmp[name][i][list[0]][list[1]] = this.FormService.batiments[bat][3].tab[i][cle].value;
                  } else {
                    tmp[name][i][nom] = this.FormService.batiments[bat][3].tab[i][cle].value;
                  }
                }
              }
            }
          }
        }
      }
      tmp["image"] = this.Image[bat];

      for(let key in this.FormService.formIntro[bat]){
        if(this.FormService.formIntro[bat][key].value){
          let name = this.FormService.formIntro[bat][key].name;
          let list = name.split("_");
          if (tmp[list[0]] === undefined) {
            tmp[list[0]] = {};
          }
          tmp[list[0]][list[1]] = this.FormService.formIntro[bat][key].value;
        }
      }
      params["batiments"].push(tmp);
    }
    params["ParcelleCadastrale"] = this.Image[0].ParcelleCadastrale[0];
    params["VueGenGoogle"] = this.Image[0].VueGenGoogle[0];
    params["coproId"] = this.idCopro;
    params["reference"] = this.refVisite;
    //console.log("paramètres",params);

    this.DataService.api("/create/batiment", params,
      ()=>
      {
        alert ("la visite a bien été envoyée");
      },
      (err)=>
      {
        console.log(err)
        if (err.error.message)
        {
          console.log ("précision erreur", err.error["failed"][0].message)
          alert (err.error["failed"][0].message)
          this.FormService.envoi=false
        }
      })
  }

  entrees(y){
    while(this.FormService.batiments[y][3].tab.length !== this.FormService.batiments[y][2].value){
      if (this.FormService.batiments[y][3].tab.length < this.FormService.batiments[y][2].value){
        this.FormService.batiments[y][3].tab.push(JSON.parse(JSON.stringify(this.FormService.toctoctoc)))
        this.Image[y].ascenseur.push([]);
        this.Image[y].entrees.push([]);
        this.OriginalImage[y].ascenseur.push([]);
        this.OriginalImage[y].entrees.push([]);
      }
      if (this.FormService.batiments[y][3].tab.length > this.FormService.batiments[y][2].value){
        this.FormService.batiments[y][3].tab.splice(-1,1)
        this.Image[y].ascenseur.splice(-1, 1);
        this.Image[y].entrees.splice(-1, 1);
        this.OriginalImage[y].ascenseur.splice(-1, 1);
        this.OriginalImage[y].entrees.splice(-1, 1);
      }
    }
  }

  batiments(){
    while(this.FormService.batiments.length !== this.FormService.nbrbat){
      if (this.FormService.batiments.length < this.FormService.nbrbat){
        this.FormService.batiments.push(JSON.parse(JSON.stringify(this.FormService.formulaire)))

        this.Image.push({ParcelleCadastrale: [], VueGenGoogle: [], ascenseur: [[]], entrees: [[]], facadeRue: [], facadeArriere: [], caves: [], parking: [], environnement: [], contiguite: []})
        //this.Image.ascenseur.push([])
        //this.Image.entrees.push([])
        this.OriginalImage.push({ParcelleCadastrale: [], VueGenGoogle: [], ascenseur: [[]], entrees: [[]], facadeRue: [], facadeArriere: [], caves: [], parking: [], environnement: [], contiguite: []})
        //this.OriginalImage.ascenseur.push([])
        //this.OriginalImage.entrees.push([])
        this.FormService.formIntro.push([
          /** FACADE RUE **/
          {"name":"facaderue","type":"txt","label":"Façade avant :","classes":"titre_gras", "img_upload":"facadeRue", file: null},
          {"name":"facadeRue_etatGen","type":"list_select","label":"Etat général de la façade : ","value":"","list":["Bon","Moyen","Mauvais","Dangereux"]},
          //conditionForm /** /*Façade avant */
          {"name":"facadeRue_commerces","type":"ouinon","label":"Commerces : ","value": null,"classes":"red","oui":false,"non":false },
          {"name":"facadeRue_natCommerce","type":"textarea","condition":{pos:2,value:true},"label":"Nature des commerces, et situation :","value":"","classes":"green"},

          //conditionForm /** /*façade arrière */
          {"name":"facadeArriere_access","type":"ouinon","label":"Accès à la façade arrière : ","value":null,"classes":"red","oui":false,"non":false},
          {"name":"facadeArriere_etatGen","type":"list_select","condition":{pos:4,value:true},"label":"Etat global :","value":"","classes":"green","list":["Bon","Moyen","Mauvais"]},
          {"name":"facadeArriere","type":"txt","label":"", "condition":{pos:4,value:true},"classes":"titre_gras", "img_upload":"facadeArriere", file: null},
        ]);
      }
      if (this.FormService.batiments.length > this.FormService.nbrbat){
        this.FormService.batiments.splice(-1,1)
        this.Image.splice(-1, 1);
        //this.Image.ascenseur.splice(-1, 1);
        //this.Image.entrees.splice(-1, 1);
        this.OriginalImage.splice(-1, 1);
        //this.OriginalImage.ascenseur.splice(-1, 1);
        //this.OriginalImage.entrees.splice(-1, 1);
        this.FormService.formIntro.splice(-1, 1);
      }
    }
  }
  resultVisite; refVisite; visite ;idCopro; idVisite; resultDesordres; resultEnvoyee; resultEntree; copro;

  constructor(/*public _router: Router, public _location: Location, */private fb: FormBuilder, public DataService:DataService, private activatedRoute: ActivatedRoute, public FormService: FormService, public dialog: MatDialog) {}
/*
  refresh(): void {
    this._router.navigateByUrl("/visites-formulaire/:id/:idCopro", {skipLocationChange:true}).then(()=>{
      console.log(decodeURI(this._location.path()))
      this._router.navigate([decodeURI(this._location.path())]);
    });
  }
*/
  info;
  ngOnInit(): void {
    window.addEventListener("beforeunload", function (e) {
      let confirmationMessage = "\o/";
      e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
      return confirmationMessage;              // Gecko, WebKit, Chrome <34
    });
    this.activatedRoute.params.subscribe(params => {
      this.idVisite = params['id'];
      this.idCopro = params['idCopro'];
      this.FormService.api_post_single_visite({_id: this.idVisite},
        (data) => {
          console.log("data: ", data)
          this.resultVisite = data.visite
          this.resultVisite = {
            reference: data.visite.reference,
            faiteLe: data.visite.faiteLe,
          }
          this.info = this.resultVisite;
          this.refVisite = data.visite.reference;
          this.FormService.reference = this.refVisite;
          this.syndicId = data.visite.syndicId;
        },
        (error)=>{
          console.log("erreur", error)
        }
      );
      this.DataService.api_single_copro({_id: this.idCopro},
        (data)=>{
          console.log("copro: ", data)
          this.copro = data.copro;
          this.gestionnaireId = data.copro.gestionnaire?._id ?? null;
          this.courtierId = data.copro.courtier?._id ?? null;

          this.resultDesordres= this.copro.incidentId;
          this.resultEnvoyee = this.copro.batiments;
          if (this.copro.batiments[0] !== undefined){
            this.resultEntree = this.copro.batiments[0]['entrees'];
          }
        },
        (error)=>{
          console.log(error)
          this.copro = error.err.copro
        }
      );
    });
  }
}

