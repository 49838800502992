<div fxLayout="row wrap" fxLayoutAlign="center">
    <!------------------- no Pre-Reception -------------------->

        <div *ngIf="!devis" fxFlex="100" fxFlex.xs="100" fxFlex.md="40" fxFlex.sm="100" fxFlex.lg="50" class="padding-card padding-card-pos">
          <mat-card fxFlex="100">
            <span>devis déposé, pas de visite pré-réception pour l'instant...</span>
          </mat-card>
        </div>

    <!------------------- BLOC LEFT --------------------------->

        <div *ngIf="devis" fxFlex="30" fxFlex.xs="100" fxFlex.md="40" fxFlex.sm="100" fxFlex.lg="30" class="padding-card padding-card-pos">
            <mat-card fxFlex="100">
                <mat-card-content class="content-40">
                    <div fxLayoutAlign="center" *ngIf="!src_img">
                        <img width="90%" class="res_img" src="../../../../../assets/img/photo/immeuble-base.jpg"> <!-- [src]="MonParc.src_img_profil" -->
                    </div>
                  <div fxLayoutAlign="center" *ngIf="src_img">
                    <img width="90%" class="res_img" src="{{'https://copro-back.cantem.fr/uploads/batiment/' + src_img}}">
                  </div>
                  <div fxLayout="row wrap" style="margin: 30px 0;" fxLayoutAlign="center">
                    <div fxFlex="90">
                      <mat-card>
                        <div fxLayout="column" class="avec_shadow pad_box_shadow">
                          <div fxLayoutAlign="center" class="info_courtier">Gestionnaire: {{gest?.name || ' - '}}</div>
                          <div fxLayoutAlign="center" class="info_courtier">Email: {{gest?.email || ' - '}}</div>
                          <div fxLayoutAlign="center" class="info_courtier info_mid">Port: {{ gest?.port || ' - ' }}</div>
                          <hr style="border: .5vm solid; width: 100%"/>
                          <div fxLayoutAlign="center" class="info_courtier">Président du conseil: {{pcs?.name || ' - '}}</div>
                          <div fxLayoutAlign="center" class="info_courtier">Email: {{pcs?.email || ' - '}}</div>
                          <div fxLayoutAlign="center" class="info_courtier">Port: {{pcs?.port || ' - '}}</div>
                        </div>
                      </mat-card>
                    </div>
                  </div>
                    <!--<div fxLayoutAlign="center">
                        <mat-button-toggle class="btn-white">Télécharger Stat sinistre (PDF)</mat-button-toggle>
                    </div>-->
                </mat-card-content>
            </mat-card>
        </div>

    <!------------------- BLOC RIGHT --------------------------->

        <div *ngFor="let reception of devis; let i = index" fxFlex="70" fxFlex.xs="100" fxFlex.md="60" fxFlex.sm="100" fxFlex.lg="70" class="padding-card padding-card-pos">
            <mat-card fxFlex="95">
                <mat-card-content class="remove-border-bottom padding-card padding-card-pos">
                    <div fxLayout="row wrap">
                        <div fxFlex="50" fxFlex.xs="100">
                            <div class="title_devis" *ngIf="reception?.refDesordre">N° {{reception?.refDesordre}} </div>
                            <div class="title_devis">{{reception?.desordre}} </div>
                            <div class="marge_devis title_devis">
                                <span>Le :</span>
                                <span class="data_dynamic resultat_marge_left">{{reception?.date | date: 'dd/MM/yyyy'}}</span>
                            </div>
                            <div class="marge_devis txt_devis maxWidth350">
                                <span class="txt_medium">Descriptif :</span>
                                <span class="data_dynamic txt_small resultat_marge_left">{{reception?.description}}</span>
                            </div>
                            <div class="txt_devis">
                                <span class="txt_medium">Corps d'état :</span>
                                <span class="data_dynamic txt_small resultat_marge_left">{{reception?.corpsEtat}}</span>
                            </div>
                            <div class="txt_devis">
                                <span class="txt_medium">Situation :</span>
                                <span class="data_dynamic txt_small resultat_marge_left">{{reception?.situation}}</span>
                            </div>
                            <div class="txt_devis">
                                <span class="txt_medium">Métrage :</span>
                                <span class="data_dynamic txt_small resultat_marge_left">{{reception?.metrages}} ㎡</span>
                            </div>
                            <div class="txt_devis">
                              <span class="txt_medium">Commentaire Architecte :</span>
                              <span class="data_dynamic txt_small resultat_marge_left">{{reception?.comArchi}}</span>
                            </div>
                            <div class="marge_devis txt_devis">
                                <span class="txt_medium">Devis prestataire :</span>
                              <a [href]="'https://copro-back.cantem.fr/uploads/devis/' + reception?.devisPDF" target="_blank">
                                <span class="resultat_marge_left"><mat-button-toggle><img src="../../../../../assets/img/icones/upload.png"></mat-button-toggle></span>
                              </a>
                            </div>
                            <div class="txt_devis">
                              <span class="txt_medium">Commentaire Prestataire :</span>
                              <span class="data_dynamic txt_small resultat_marge_left">{{reception?.comPrest}}</span>
                            </div>
                            <div class="marge_devis txt_devis">
                                <span class="txt_medium">Facture prestataire :</span>
                              <a [href]="'https://copro-back.cantem.fr/uploads/devis/' + reception?.facturePDF" target="_blank">
                                <span class="resultat_marge_left"><mat-button-toggle><img src="../../../../../assets/img/icones/upload.png"></mat-button-toggle></span>
                              </a>
                            </div>
                            <div class="marge_devis txt_devis">
                                <span class="txt_medium">Photo avant ({{reception?.images_bf.length}}):</span>
                            </div>
                            <div fxLayout="row wrap" class="marge_add">
                                <div fxFlex="100">
                                    <!------Gallery Images-------->
                                    <div fxLayout="row">
                                        <span gallerize>
                                          <span *ngIf="reception?.images_bf">
                                            <img *ngIf="reception?.images_bf[0]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+reception?.images_bf[0]" style="width: 50px; height: 50px; margin-right: 5px;">
                                            <img *ngIf="reception?.images_bf[1]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+reception?.images_bf[1]" style="width: 50px; height: 50px; margin-right: 5px;">
                                            <img *ngIf="reception?.images_bf[2]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+reception?.images_bf[2]" style="width: 50px; height: 50px; margin-right: 5px;">
                                          </span>
                                        </span>
                                    </div>
                                    <!-------------->
                                </div>
                            </div>
                        </div>
    <!--------------------------------------- BLOC 2 ------------------------------------>

                        <div fxFlex="50" fxFlex.xs="100" style="background-color: #f9f9f9;" *ngIf="!reception?.receptionDone?.id_el">
                            <div fxLayout="row wrap" class="padding-card padding-card-pos txt_devis">
                                <div fxFlex="100" class="marge_devis txt_medium">Conformité des travaux réalisés: </div>
                                <section fxLayoutAlign="left" class="padd_xs marge_devis txt_small">
                                    <mat-checkbox class="checkpos checksize" (click)="conformiteOuiNon(true, i)" [checked]="reception?.oui" [disabled]="true">Oui</mat-checkbox>
                                    <mat-checkbox class="checkpos checksize" (click)="conformiteOuiNon(false, i)" [checked]="reception?.non" [disabled]="true">Non</mat-checkbox>
                                </section>
                                <div fxFlex="100" class="marge_devis txt_medium">Qualité des travaux réalisés :</div>
                                <div fxFlex="100" class="marge_devis">
                                    <star-rating value="{{reception.rate}}" (rate)="rating($event, i)" checkedcolor="gold" uncheckedcolor="gray" totalstars="{{totalstar}}" readonly="false"></star-rating>
                                </div>
                                <div fxFlex="100" class="marge_devis txt_medium">Remarques</div>
                                <div fxFlex="100" class="marge_devis"><textarea [(ngModel)]="reception.remarque"></textarea></div>
                                <div fxFlex="100" class="marge_devis txt_medium" style="color: red;" *ngIf="errMessageRemarque">{{errMessageRemarque}}</div>
                                <div fxFlex="100">

                                    <form class="infixUpload" enctype="multipart/form-data">
                                      <mat-button-toggle style="margin-top: 25px;" (click)="uploaderAvisTravaux.click()">
                                        <span>
                                          <mat-icon class="iconFile" class="imgIncidents" ngxMatFileInputIcon>add_a_photo</mat-icon>
                                          <span>Ajouter des images (3 max)</span>
                                        </span>
                                      </mat-button-toggle>
                                        <div
                                          *ngFor="let file of photo_af; let j = index"
                                          [attr.data-index]="j"
                                          style="flex-direction: row;display:flex;margin-left: 10px;"
                                        >
                                          <div style="margin-right: 10px;">{{file.name}}</div>
                                          <div
                                            type="button"
                                            style="justify-self: center; display: flex; height: 12px; width: 12px; position: static; cursor: pointer;"
                                            (click)="deletePhotoAf(j)"
                                          >
                                            <mat-icon style="transform: scale(0.70);">
                                              clear
                                            </mat-icon>
                                          </div>
                                        </div>
                                      <div style="display: none;">
                                        <ng-container>
                                          <input [accept]="'image/*'" type="file" id="file" multiple #uploaderAvisTravaux
                                                 (change)="getFileDetails($event)">
                                        </ng-container>
                                      </div>
                                    </form>

                                    <div fxFlex="100" class="marge_devis txt_medium" style="color: red;" *ngIf="errMessagePhoto_af">{{errMessagePhoto_af}}</div>
                                </div>
                                <div class="btnDesordres" *ngIf="loaderImage[reception.id_el]"><div class="loader" ></div></div>
                                <mat-button-toggle *ngIf="!loaderImage[reception.id_el]" class="btn send_btn" (click)="sendReception(reception.id_el, i)">Envoyer</mat-button-toggle>
                            </div>
                        </div>
                        <div fxFlex="50" fxFlex.xs="100" style="background-color: #f9f9f9;" *ngIf="reception?.receptionDone?.id_el">
                          <mat-icon aria-hidden="false" style="background-color: white; color: green; font-size: 30px; margin-right: 19px; position: relative; top: -5px;">check_circle</mat-icon>
                          <div fxLayout="row wrap" class="padding-card padding-card-pos txt_devis">
                            <div fxFlex="100" class="marge_devis txt_medium">Conformité des travaux réalisés: {{reception?.receptionDone?.conformite ? 'oui' : 'non'}}</div>

                            <div fxFlex="100" class="marge_devis txt_medium">Qualité des travaux réalisés :</div>
                            <div fxFlex="100" class="marge_devis">
                              <star-rating value="{{reception?.receptionDone.rate}}" checkedcolor="gold" uncheckedcolor="gray" totalstars="{{totalstar}}" readonly="true"></star-rating>
                            </div>
                            <div fxFlex="100" class="marge_devis txt_medium">Remarques: </div>
                            <div fxFlex="100" class="marge_devis"><textarea [value]="reception?.receptionDone?.remarque" readonly="true"></textarea></div>

                            <div fxFlex="100">
                              <div class="marge_devis txt_devis">
                                <span class="txt_medium">Photo après ({{reception?.receptionDone?.images_af?.length}}):</span>
                              </div>
                              <div fxLayout="row wrap" class="marge_add">
                                <div fxFlex="100">
                                  <!------Gallery Images-------->
                                  <div fxLayout="row">
                                    <span gallerize>
                                      <span *ngIf="reception?.receptionDone?.images_af">
                                        <img *ngIf="reception?.receptionDone?.images_af[0]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+reception?.receptionDone?.images_af[0]" style="width: 50px; height: 50px; margin-right: 5px;">
                                        <img *ngIf="reception?.receptionDone?.images_af[1]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+reception?.receptionDone?.images_af[1]" style="width: 50px; height: 50px; margin-right: 5px;">
                                        <img *ngIf="reception?.receptionDone?.images_af[2]" [src]="'https://copro-back.cantem.fr/uploads/incidents/'+reception?.receptionDone?.images_af[2]" style="width: 50px; height: 50px; margin-right: 5px;">
                                      </span>
                                    </span>
                                  </div>
                                  <!-------------->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
