import { Injectable } from '@angular/core';
import {io} from 'socket.io-client';
@Injectable({
  providedIn: 'root'
})
export class SocketioService {
  socket;
  constructor() {   }
  emitSocketTest() {
    this.socket.emit('notifyTest', function(data) {
      console.log('ok')
    })

  }
  setupSocketConnection(fetchNotif) {
    this.socket = io('https://copro-back.cantem.fr', {query: {'auth_token': `${localStorage.getItem('token')}`}});
    this.socket.on('error', function(err) {
      throw new Error(err);
    });
    // Connection succeeded
    this.socket.on('success', async function(data) {
    })
    this.socket.on('notify', function(data) {
      fetchNotif();
      if (Notification.requestPermission())
        new Notification(data.title,
          {
            image: 'https://static.lpnt.fr/images/2019/09/25/19403277lpw-19403339-article-chat-etude-felin-jpg_6528763_1250x625.jpg',
            body: data.message
          }
        );
    })
  }
}
