<div class="title-pos">
    <mat-card>
        <mat-card-title class="title title-pos">Ajout d'un immeuble</mat-card-title>
         <mat-card-content class="txt_medium title-pos">
                <div fxLayout="row wrap">
                    <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" >
                        <div fxLayout="row wrap">
                            <div fxFlex="100">
                                <form [formGroup]="formPresta" fxLayout="row wrap"><!--Formulaire -->
                                    <span fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap" fxLayoutAlign="start" *ngFor="let elem of FormService.form_AjoutImmeuble" style="text-align: left;">
                                        <!-- OUI/NON  -->
                                        <section class="padd_xs pad10_0" fxFlex.xs="100" *ngIf="elem.type == 'ouinon' && elem.value !== 'desactive' && elem.condition == null">
                                            <label><span [ngClass]="elem.classes">{{elem.label}}</span></label>
                                            <mat-checkbox class="checkpos checksize" (click)="this.DataService.ouinon($event, elem, true)" [checked]="elem.oui" [disabled]="true">Oui</mat-checkbox>
                                            <mat-checkbox class="checkpos checksize" (click)="this.DataService.ouinon($event, elem, false)" [checked]="elem.non" [disabled]="true">Non</mat-checkbox>
                                            <span class="upload_pos" *ngIf="elem.img_upload == 'true'"><mat-button-toggle><img src="../../../../../assets/img/icones/upload.png"><span class="imp_imgtxt">Importer une image</span></mat-button-toggle></span><br>
                                        </section>

                                        <!--conditions-->
                                        <div *ngIf="elem.type == 'ouinon' && elem.value !== 'desactive' && elem.condition !== null">
                                            <section class="padd_xs padOuiNon" fxFlex.xs="100">
                                                <span [ngClass]="elem.classes">
                                                    <label><span>{{elem.label}}</span></label>
                                                    <mat-checkbox class="checkpos checksize" (click)="this.DataService.ouinon($event, elem, true)" [checked]="elem.oui" (click)="this.disable()" [disabled]="true">Oui</mat-checkbox>
                                                    <mat-checkbox class="checkpos checksize" (click)="this.DataService.ouinon($event, elem, false)" [checked]="elem.non" (click)="this.disable()" [disabled]="true">Non</mat-checkbox>
                                                </span>
                                                <div *ngIf="elem.oui !== false" style="line-height: 20px;">
                                                    <div fxLayoutAlign="start" *ngFor="let key of elem.liste">
                                                        <!-- ouinon --->
                                                        <div *ngIf="key.type == 'ouinon' && key.condition_enfant == elem.condition" class="pad10_0">
                                                            <label><span [ngClass]="key.classes">{{key.label}}</span></label>
                                                            <mat-checkbox class="checkpos checksize" (click)="this.DataService.ouinon($event, key, true)" [checked]="key.oui" [disabled]="true">Oui</mat-checkbox>
                                                            <mat-checkbox class="checkpos checksize" (click)="this.DataService.ouinon($event, key, false)" [checked]="key.non" [disabled]="true">Non</mat-checkbox>
                                                        </div>
                                                        <!--Input border #000 -->
                                                        <div *ngIf="key.type == 'refInput' && key.condition_enfant == elem.condition" class="pad10_0">
                                                            <div class="padLabel"><label><span [ngClass]="key.classes">{{key.label}}</span></label></div>
                                                            <div><input class="border-input-noir" type="text" placeholder="Azf52"></div>
                                                        </div>
                                                        <!-- toggle -->
                                                        <div *ngIf="key.type == 'toggleInput' && key.condition_enfant == elem.condition" class="pad10_0">
                                                            <div  class="padLabel"><label><span [ngClass]="key.classes">{{key.label}}</span></label></div>
                                                            <div><span class="pad7"><mat-slide-toggle></mat-slide-toggle></span></div>
                                                        </div>
                                                        <!-- list_select --->
                                                        <div *ngIf="key.type == 'list_select' && key.condition_enfant == elem.condition" class="pad10_0 sans_shadow">
                                                            <div class="marge_r10 pos_labelselect padLabel"><span [ngClass]="key.classes">{{key.label}}</span></div>
                                                            <mat-form-field appearance="outline" >
                                                              <select matNativeControl [(ngModel)]="courtier" [ngModelOptions]="{standalone: true}">
                                                                <option value="{{null}}">{{key.value1}}</option>
                                                                <option *ngFor="let courtier of key.values" value="{{courtier.id}}">
                                                                  {{courtier.name}}
                                                                </option>
                                                              </select>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>

                                        <!-- FIN CONDITION OUI/NON -->

                                        <!--Input border #f8d57e-->
                                        <div *ngIf="elem.type == 'inputNormal' && elem.value !== 'desactive'" fxFlex="100" fxLayoutAlign="center">
                                            <div fxFlex="100">
                                                <div class="margin_input">
                                                    <div class="marge_r10 padLabel input-title" [ngClass]="elem.classes">{{elem.label}}</div>
                                                    <div><input class="border-input"/></div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Input error -->

                                        <div *ngIf="elem.type == 'inputNomCopro' && elem.value !== 'desactive'" fxFlex="100" class="pad15R">
                                            <mat-label fxLayout="row wrap" class="marge_r10 padLabel input-title">{{elem.label}}</mat-label>
                                            <mat-form-field appearance="outline" class="input350">
                                                <input [(ngModel)]="nomCopro" [(formControlName)]="elem.controlForm" matInput required minlength="3" maxlength="200">
                                                <mat-error class="ErrorInline" *ngIf="formCtrl.nomCoproForm.hasError('required')">Le champ {{elem.model}} est requis</mat-error>
                                                <mat-error class="ErrorInline" *ngIf="formCtrl.nomCoproForm.hasError('minlength')">Minimum <b>3 caractères</b>, maximum <b>200</b></mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="elem.type == 'inputReference' && elem.value !== 'desactive'" fxFlex="100" class="pad15R">
                                            <mat-label fxLayout="row wrap" class="marge_r10 padLabel input-title">{{elem.label}}</mat-label>
                                            <mat-form-field appearance="outline" class="input350">
                                                <input [(ngModel)]="reference" [(formControlName)]="elem.controlForm" placeholder="{{!lectureSeule ? 'veuillez renseigner un N° interne' : null}}" matInput required minlength="3" maxlength="200" [readOnly]="lectureSeule">
                                                <mat-error class="ErrorInline" *ngIf="formCtrl.referenceForm.hasError('required')">Le champ {{elem.model}} est requis</mat-error>
                                                <mat-error class="ErrorInline" *ngIf="formCtrl.referenceForm.hasError('minlength')">Minimum <b>3 caractères</b>, maximum <b>200</b></mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="elem.type == 'inputAdresse' && elem.value !== 'desactive'" fxFlex="100" class="pad15R">
                                            <mat-label fxLayout="row wrap" class="marge_r10 padLabel input-title">{{elem.label}}</mat-label>
                                            <mat-form-field appearance="outline" class="input350">
                                                <input [(ngModel)]="address" [(formControlName)]="elem.controlForm" matInput required minlength="5" maxlength="200">
                                                <mat-error class="ErrorInline" *ngIf="formCtrl.addressForm.hasError('required')">Le champ {{elem.model}} est requis</mat-error>
                                                <mat-error class="ErrorInline" *ngIf="formCtrl.addressForm.hasError('minlength')">Minimum <b>5 caractères</b>, maximum <b>200</b></mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="elem.type == 'inputVille' && elem.value !== 'desactive'" fxFlex="100" class="pad15R">
                                            <mat-label fxLayout="row wrap" class="marge_r10 padLabel input-title">{{elem.label}}</mat-label>
                                            <mat-form-field appearance="outline" class="input350">
                                                <input [(ngModel)]="ville" [(formControlName)]="elem.controlForm" matInput required minlength="3" maxlength="200">
                                                <mat-error class="ErrorInline" *ngIf="formCtrl.villeForm.hasError('required')">Le champ {{elem.model}} est requis</mat-error>
                                                <mat-error class="ErrorInline" *ngIf="formCtrl.villeForm.hasError('minlength')">Minimum <b>3 caractères</b>, maximum <b>200</b></mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="elem.type == 'inputCodePostal' && elem.value !== 'desactive'" fxFlex="100" class="pad15R">
                                            <mat-label fxLayout="row wrap" class="marge_r10 padLabel input-title">{{elem.label}}</mat-label>
                                            <mat-form-field appearance="outline" class="input350">
                                                <input [(ngModel)]="codePostal" [(formControlName)]="elem.controlForm" matInput (keypress)="numericOnly($event)" required minlength="5" maxlength="5">
                                                <mat-error class="ErrorInline" *ngIf="formCtrl.codePostalForm.hasError('required')">Le champ {{elem.model}} est requis</mat-error>
                                                <mat-error class="ErrorInline" *ngIf="formCtrl.codePostalForm.hasError('minlength')">Le champ {{ elem.model }} doit contenir <b>5 chiffres</b></mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="elem.type == 'inputNbBatiments' && elem.value !== 'desactive'" fxFlex="100" class="pad15R">
                                            <mat-label fxLayout="row wrap" class="marge_r10 padLabel input-title">{{elem.label}}</mat-label>
                                            <mat-form-field appearance="outline" class="input350">
                                                <input [(ngModel)]="nbBatiments" [(formControlName)]="elem.controlForm" matInput required type="number" (keypress)="numericOnly($event)" minlength="1" maxlength="3">
                                                <mat-error class="ErrorInline" *ngIf="formCtrl.nbBatimentsForm.hasError('required')">Le champ {{elem.model}} est requis</mat-error>
                                                <mat-error class="ErrorInline" *ngIf="formCtrl.nbBatimentsForm.hasError('minlength')">Le champ {{ elem.model }} doit contenir au moins <b>1 chiffre</b></mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="elem.type == 'inputSurface' && elem.value !== 'desactive'" fxFlex="100" class="pad15R">
                                            <mat-label fxLayout="row wrap" class="marge_r10 padLabel input-title">{{elem.label}}</mat-label>
                                            <mat-form-field appearance="outline" class="input350">
                                                <input [(ngModel)]="surface" [(formControlName)]="elem.controlForm" matInput required type="number" (keypress)="numericOnly($event)" minlength="1" maxlength="10">
                                                <mat-error class="ErrorInline" *ngIf="formCtrl.surfaceForm.hasError('required')">Le champ {{elem.model}} est requis</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <!-- FIN Input error --->
                                    </span>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row wrap" class="button_connexion marge15T">
                    <div fxFlex="100">
                        <span>
                            <mat-button-toggle
                            (click)="addImmeuble(nomCopro, reference, address, codePostal, ville, nbBatiments, surface)"
                            [disabled]="!formPresta.valid"
                            class="btn btn-txt">
                                Ajouter l'immeuble
                            </mat-button-toggle>
                        </span>
                        <span class="alert_msg margeL10" *ngIf="msgErreur"> {{ msgErreur }}</span>
                    </div>

                    <div class="champsAlert" *ngIf="!formPresta.valid">* Remplissez tous les champs obligatoires pour créer l'immeuble</div>
                    <div class="champsOk" *ngIf="formPresta.valid">* Vous pouvez créer l'immeuble'</div>
                </div>
         </mat-card-content>
    </mat-card>
</div>
