import { Component, OnInit } from '@angular/core';
import { GalleryComponent } from '../../../../gallery/gallery.component';
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {DataService} from "../../../../data.service";

@Component({
  selector: 'app-a-etudier-details',
  templateUrl: './a-etudier-details.component.html',
  styleUrls: ['./a-etudier-details.component.scss'],

})
export class AEtudierDetailsComponent implements OnInit {

  reference;surface;multiDevis;maxTravaux;moisAG;compagnie;echeance;courtier;

  copro=this.DataService.copro;

  resultInfo;
  _id;
  imgIncident

  dataFacades = []
  dataAscenseur = []
  dataEntree = []
  dataCaves = []
  dataParking = []
  dataEnvironnement = []
  dataContiguite = []
  dataIncidents = []
  dataParcelle = []
  dataVueGG = []

  resultDescription;resultEntree;resultCave;resultCarnet;resultCopro;resultTravaux;imgBat;imgInfo;

  constructor(private http: HttpClient,
              public GalleryComponent: GalleryComponent,
              private activatedRoute: ActivatedRoute,
              public DataService: DataService,
              public dialog: MatDialog) {

    console.log('hello')
    const id =  this.activatedRoute.snapshot.params['id'];

    this.DataService.api_single_copro({_id:id},
      (data)=>{
        console.log("data: ", data);
        this.copro = data.copro;

        if (this.copro !== "undefined") {
          this.resultDescription = this.copro.batiments;
          this.resultEntree = this.copro.batiments[0]['entrees'];
          this.resultCave = this.copro.batiments[0].cave;
          this.resultCarnet = this.copro.incidentId;
          this.resultCopro = this.copro;

          this.resultTravaux = this.resultCarnet.length
          this.imgBat = 'https://copro-back.cantem.fr/uploads/batiment/'+this.copro.batiments[0].image.facadeRue[0]
          //console.log("resultat incident",this.copro)
          //console.log('img bat', this.imgBat)
          this.imgInfo = this.copro.batiments[0].image.facadeRue[0]

          // this.imgIncident = 'https://copro-back.cantem.fr/uploads/batiment/'+this.copro.incidentId[2].images[0]
          // //console.log ("image incident", this.imgIncident)
        }
        else {
          this.resultDescription = this.DataService.copro.batiments;
          this.resultEntree = this.DataService.copro.batiments[0]['entrees'];
          this.resultCave = this.DataService.copro.batiments[0].cave;
          this.resultCarnet = this.DataService.copro.incidentId;
          this.resultCopro = this.DataService.copro
          this.resultTravaux = this.resultCarnet.length
          this.imgBat = 'https://copro-back.cantem.fr/uploads/batiment/'+this.copro.batiments[0].image.facadeRue[0]
          this.imgInfo = this.copro.batiments[0].image.facadeRue[0]

          // this.imgIncident = 'https://copro-back.cantem.fr/uploads/batiment/'+this.copro.incidentId[2].images[0]
          // //console.log ("image incident", this.imgIncident)
        }

        this.dataParcelle = [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.ParcelleCadastrale[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.ParcelleCadastrale[0]),
          }
        ];
        this.dataVueGG = [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.VueGenGoogle[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.VueGenGoogle[0]),
          }
        ];
        this.dataFacades = [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[0]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[1]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[1]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[2]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[2]),
          },
        ];
        this.dataAscenseur= [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[0]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[1]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[1]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[2]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[2]),
          },
        ];
        this.dataEntree= [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[0]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[1]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[1]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[2]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[2]),
          },
        ];
        this.dataCaves= [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[0]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[1]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[1]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[2]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[2]),
          },
        ];
        this.dataParking= [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[0]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[1]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[1]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[2]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[2]),
          },
        ];
        this.dataEnvironnement = [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[0]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[1]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[1]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[2]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[2]),
          },
        ];
        this.dataContiguite = [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[0]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[1]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[1]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[2]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[2]),
          },
        ];
      },
      (error)=>{
        console.log("error: ", error)
        this.copro = error.err.copro
      }
    );

    this._id = id

    /** Bloc left mon parc **/
    this.DataService.api_post_mon_parc({_id:id},
      (data)=>{
        console.log("data: ",data)
        this.resultInfo = data.copro
        this.reference = this.resultInfo.reference;
        this.surface = this.resultInfo.surface;
        this.multiDevis = this.resultInfo.multiDevis;
        this.maxTravaux = this.resultInfo.maxTravaux;
        this.moisAG = this.resultInfo.moisAG;
        this.compagnie = this.resultInfo.compagnie?.assurance?? null;
        this.echeance = this.resultInfo.compagnie?.echeance?? null;
        this.courtier = this.resultInfo.courtier?.lastName?? 'Sans Courtier';
      },
      (error)=>{
        console.log(error)
        this.copro = error.err.copro
      }
    );
  }

  ngOnInit() {}

}
