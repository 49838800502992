import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mon-profil',
  templateUrl: './mon-profil.component.html',
  styleUrls: ['./mon-profil.component.scss']
})
export class MonProfilComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
