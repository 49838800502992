import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { GalleryComponent } from '../../../gallery/gallery.component';
import { DataService } from "../../../data.service";
import {filter} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-devis-prestataires',
  templateUrl: './devis-prestataires.component.html',
  styleUrls: ['./devis-prestataires.component.scss']
})

export class DevisPrestatairesComponent implements OnInit {
  coproId: string;
  syndicId;
  src_img;
  gestionnaireId;
  pcsId;
  incidents;
  accessCode;
  gardien;
  cleCabinet;
  commentaire;
  abonnement = false;
  corpsEtat: [] = [];
  loaderDevis = {};
  loaderFacture = {};
  evaluationTTC = [];
  comEval = [];

  imageError: string;

  id =  this.activatedRoute.snapshot.params['id'];

  constructor(public GalleryComponent: GalleryComponent, private DataService: DataService, private activatedRoute: ActivatedRoute, public router: Router) {
    this.DataService.sharedMessage.subscribe(id => this.coproId = id);
  }

  sendIdToChild(syndicId) {
    this.DataService.fillMessage(syndicId);
  }

  async goBack() {
    await this.DataService.fillIndex('1');
    await this.router.navigate([`./mes-syndics-prestataires/${this.syndicId}`], { relativeTo: this.activatedRoute.parent });
  }

  aFaire = [];
  enAttente = [];
  done = [];

  evaluation = [];

  depotDevis = [];
  depotFacture = [];
  released = []

  nomPCS = '';
  emailPCS = '';
  phonePCS = '';

  accept: string = ".pdf,.PDF,.png,.PNG,.jpg,.JPG,.jpeg,.JPEG";

  /*
  uploadDevis(e, devisId, index) {
    const data = new FormData();
    data.append('devisId', devisId);
    data.append('images', e.target.files)

      this.DataService.api_depot_devis(data,
        (response) => {
          this.aFaire[index].dateDepotDevis = response.dateDepotDevis;
          this.enAttente.push(this.aFaire[index]);
          this.aFaire = this.aFaire.filter(el => el._id !== this.aFaire[index]._id);

          alert("devis envoyé!");
        },
        (error) => {
          alert('une erreur est survenue');
          console.log("erreur: ", error);
        }
      );
  }

  uploadFacture(e, devisId, index) {
    const data = new FormData();
    data.append('devisId', devisId);
    data.append('image', e.target.files)

    this.DataService.api_depot_facture(data,
      (response) => {
        this.enAttente[index].dateDepotFacture = response.dateDepotFacture;
        this.released.push(this.enAttente[index]);
        this.enAttente = this.enAttente.filter(el => el._id !== this.enAttente[index]._id);
        alert("facture envoyée!");},
        (error) => {
        console.log("erreur: ", error);
      })
  }
  */


  ngOnInit() {
    let prestataireId = localStorage.getItem('id');
    this.DataService.api_get_devis({prestataireId},
      (data) => {
        for (let i in data.list) {
          if (data.list[i].demandeDevis)
            this.evaluation.push(data.list[i]);
          if (data.list[i].demandeDevis && !data.list[i].dateDepotDevis)
            this.depotDevis.push(data.list[i].incidentId);
          if (data.list[i].demandeDevis && data.list[i].dateDepotDevis && !data.list[i].dateDepotFacture)
            this.depotFacture.push(data.list[i].incidentId);
          if (data.list[i].dateDepotDevis && data.list[i].dateDepotFacture)
            this.released.push(data.list[i].incidentId)
        }
      },
      (error) => {
        console.log(error)
      });
    this.DataService.apiIncident(
      '/retrieve/incident-list',
      {coproId: this.id},
      async (data) => {
        this.src_img = data.copro.batiments[0]?.image?.facadeRue[0] ? `https://copro-back.cantem.fr/uploads/batiment/${data.copro.batiments[0].image.facadeRue[0]}` : "../assets/img/photo/immeuble-base.jpg";
        this.gestionnaireId = data.copro.gestionnaire;
        this.pcsId = data.copro.pcs;
        this.incidents = await data.incidents;
        this.abonnement = data.abonnements.includes(data.incidents[0].syndicId);
        this.aFaire = this.incidents?.filter(inc => {
          const checkDepot = this.depotDevis.includes(inc._id);
          if (checkDepot)
            this.loaderDevis[inc._id] = false;
          return checkDepot;
        }) ?? [];
        this.enAttente = this.incidents?.filter(inc => {
          const checkDepotFacture = this.depotFacture.includes(inc._id);
          if (checkDepotFacture)
            this.loaderFacture[inc._id] = false;
          return checkDepotFacture;
        }) ?? [];
        this.done = this.incidents?.filter(inc => this.released.includes(inc._id)) ?? [];

        for (let key in this.aFaire) {
          let item = this.evaluation.filter(el => this.aFaire[key]._id === el.incidentId);
          if (item[0].dateDepotDevis)
            this.aFaire = this.aFaire.filter(el => el._id !== this.aFaire[key]._id);
          else
            this.aFaire[key] = {
              ...this.aFaire[key],
              evaluationTTC: item[0]?.evaluationTTC,
              devisTTC: item[0]?.evaluationTTC,
              comEval: item[0]?.commentaire,
              devisId: item[0]?._id,
            };
        }
        for (let key in this.enAttente) {
          if (this.enAttente[key]) {
            let item = this.evaluation.filter(el => this.enAttente[key]._id === el.incidentId);

            if (item[0].dateDepotFacture || !item[0].dateDepotDevis)
              this.enAttente = this.enAttente.filter(el => el._id !== this.enAttente[key]._id);
            else
              this.enAttente[key] = {
                ...this.enAttente[key],
                evaluationTTC: item[0]?.evaluationTTC,
                devisTTC: item[0]?.devisTTC,
                factureTTC: item[0]?.devisTTC,
                comEval: item[0]?.commentaire,
                dateDepotDevis: item[0]?.dateDepotDevis,
                devisId: item[0]?._id,
              };
          }
        }
        for (let key in this.done) {
          let item = this.evaluation.filter(el => this.done[key]._id === el.incidentId);
          this.done[key] = {
            ...this.done[key],
            evaluationTTC: item[0]?.evaluationTTC,
            devisTTC: item[0]?.devisTTC,
            factureTTC: item[0]?.factureTTC,
            comEval: item[0]?.commentaire,
            dateDepotDevis: item[0]?.dateDepotDevis,
            dateDepotFacture: item[0]?.dateDepotFacture,
            devisId: item[0]?._id,
            receptionDone: item[0]?.receptionDone,
            note: item[0]?.note
          };
        }

        if (data.incidents[data.incidents.length - 1].visiteId)
          this.DataService.api_post_single_visite(
            {_id: data.incidents[data.incidents.length - 1].visiteId},
            (data) => {
              this.accessCode = data.visite.accessCode;
              this.gardien = data.visite.gardien;
              this.cleCabinet = data.visite.cleCabinet;
              this.commentaire = data.visite.commentaire;
              this.syndicId = data.visite.syndicId;
              this.nomPCS = data.visite.nomPCS;
              this.emailPCS = data.visite.emailPCS;
              this.phonePCS = data.visite.phonePCS;
            },
            (error) => {
              console.log(error)
            });
      },
      (error) => {
        console.log(error)
      }
    )
  }

  fileChangeEvent(fileInput: any, route, devisId, TTC, index, incidentId) {
    this.imageError = null;
    if (route === '/create/devis-pdf')
      this.loaderDevis[incidentId] = true;
    else if (route === '/create/facture-pdf')
      this.loaderFacture[incidentId] = true;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg', 'application/pdf'];

      if (fileInput.target.files[0].size > max_size) {
        this.imageError =
          'taille maximum autorisée ' + max_size / 1000 + 'Mb';
        this.loaderDevis[incidentId] = false;
        this.loaderFacture[incidentId] = false;
        return false;
      }

      if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
        this.imageError = 'fichiers autorisés ( JPG | PNG | PDF )';
        this.loaderDevis[incidentId] = false;
        this.loaderFacture[incidentId] = false;
        return false;
      }

      const reader = new FileReader();
      const data = new FormData();
      data.append('devisId', devisId);
      if (route === '/create/devis-pdf')
        data.append('devisTTC', TTC)
      else if (route === '/create/facture-pdf')
        data.append('factureTTC', TTC)
      data.append('files', fileInput.target.files[0])

      this.DataService.api(route, data,
        (response) => {
          if (response.dateDepotDevis) {
            this.loaderDevis[incidentId] = false;
            setTimeout(() => {
              this.aFaire[index].dateDepotDevis = response.dateDepotDevis;
              this.enAttente.push(this.aFaire[index]);
              this.aFaire = this.aFaire.filter(el => el._id !== this.aFaire[index]._id);
              alert("devis envoyé!");
            }, 100)

          } else if (response.dateDepotFacture) {
            this.loaderFacture[incidentId] = false;
            setTimeout(() => {
              this.enAttente[index].dateDepotFacture = response.dateDepotFacture;
              this.done.push(this.enAttente[index]);
              this.enAttente = this.enAttente.filter(el => el._id !== this.enAttente[index]._id);
              alert("facture envoyée!");
            }, 100);
          }
        },
        (error) => {
          this.loaderDevis[incidentId] = false;
          this.loaderFacture[incidentId] = false;
          setTimeout(() => {
          alert(error.error.message);
          console.log("erreur: ", error);
          }, 100)
        }
      );

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }
}
