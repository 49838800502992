import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { AcceptValidator, MaxSizeValidator } from '@angular-material-components/file-input';

import { DataService } from "../../../../data.service";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";

const presetFiles = [new File([], "file 1"), new File([], "file 2")];
const presetFile = new File([], "file 1");

export interface CodePostal {
  name: string;
}

@Component({
  selector: 'app-dashbord-mon-profil',
  templateUrl: './dashbord-mon-profil.component.html',
  styleUrls: ['./dashbord-mon-profil.component.scss']
})

export class DashbordMonProfilComponent implements OnInit {
  getAccountType = () => {
    if (localStorage.role.length > 0) {
      let ck = localStorage.role
      return ck
    }
  }

  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }
  formDecennal = new FormGroup({
    rcDecennal: new FormControl()
  });
  formProfessionnelle = new FormGroup({
    rcProfessionnelle: new FormControl()
  });
  color: ThemePalette = 'primary';
  disabled: boolean = false;
  multiple: boolean = false;
  accept: string;

  hide: boolean = true;

  fileControl: FormControl;

  errMessage = '';
  errType = '';

  /*****************/
  /*| Prestataire |*/
  /*****************/

  prestataire: object;

  etatsArray: String[] = [];
  corpsEtat: String[] = [];

  prestEmail;
  prestPassword;

  prestCompany;
  prestAddress;
  prestCodePostal;
  prestVille;
  prestSiret;

  prestRepresentantFirstName;
  prestRepresentantLastName;
  prestRepresentantPhone;
  prestRepresentantEmail;

  prestNbSalaries;

  prestRCPfile;
  prestRCDfile;
  RCPdetails = {compagnie: '', echeanceAnnuelle: null};
  originalRCPdetailsEcheance;
  RCDdetails = {compagnie: '', echeanceAnnuelle: null};
  originalRCDdetailsEcheance;
  rcProfessionnelle: any;
  rcDecennale: any;
  RCPLoader = false;
  RCDLoader = false
  includesEtat(etat) {
    return this.corpsEtat.includes(etat);
  }

  modifyCorpsEtat(event): void {
    if (event.checked)
      this.corpsEtat.push(event.source.name);
    else
      this.corpsEtat = this.corpsEtat.filter(e => e !== event.source.name);
  }

  updateAccessPrest() {
    this.errType = "";
    if (!this.prestEmail) {
      this.errMessage = "Veuillez renseigner un email valid";
      this.errType = "prestEmail";
    } else {
      this.DataService.update('/credentials', {email: this.prestEmail, password: this.prestPassword},
        (data) => {
          alert("Les identifiants d'accès ont été mis à jour");
        },
        (error) => {
          alert(error.error.message);
        });
    }
  }

  RCInput(RCType, fieldName, event) {
    this[RCType][fieldName] = event.target.value;
  }

  updateResponsability(RCType) {
    this.errType = "";
    this.errMessage = "";
    const data = new FormData();
    try {
      if (RCType === 'rcProfessionnelle') {
        this.RCPLoader = true;
        if (this.rcProfessionnelle) {
          data.append('rcProfessionnelle', this.rcProfessionnelle);
        } else {
          this.RCPLoader = false;
          this.errType = "rcProfessionnelle";
          this.errMessage = "Veuillez selectionner votre RC Professionnelle";
        }
        if (this.RCPdetails?.echeanceAnnuelle) {
          data.append('RCPdetailsEcheanceAnnuelle', this.RCPdetails?.echeanceAnnuelle);
        } else {
          this.RCPLoader = false;
          this.errType = "RCPdetailsEcheanceAnnuelle";
          this.errMessage = "Veuillez renseigner l'écheance de votre RC Professionnelle";
        }
        if (this.RCPdetails?.compagnie?.length > 0) {
          data.append('RCPdetailsCompagnie', this.RCPdetails?.compagnie);
        } else {
          this.RCPLoader = false;
          this.errType = "RCPdetailsCompagnie";
          this.errMessage = "Veuillez renseigner le nom de la compagnie d'assurance";
        }
      } else {
        this.RCDLoader = true;
        if (this.rcDecennale) {
          data.append('RCDecennale', this.rcDecennale);
        } else {
          this.RCDLoader = false;
          this.errType = "RCDecennale";
          this.errMessage = "Veuillez selectionner votre RC Decennale";
        }
        if (this.RCDdetails?.echeanceAnnuelle) {
          data.append('RCDdetailsEcheanceAnnuelle', this.RCDdetails?.echeanceAnnuelle);
        } else {
          this.RCDLoader = false;
          this.errType = "RCDdetailsEcheanceAnnuelle";
          this.errMessage = "Veuillez renseigner l'écheance de votre RC Decennale";
        }
        if (this.RCDdetails?.compagnie?.length > 0) {
          data.append('RCDdetailsCompagnie', this.RCDdetails?.compagnie);
        } else {
          this.RCDLoader = false;
          this.errType = "RCDdetailsCompagnie";
          this.errMessage = "Veuillez renseigner le nom de la compagnie d'assurance";
        }
      }
      if (!this.errType) {
        this.DataService.update('/prestataire-rcfiles', data,
          (data) => {
            alert(data.message);
            window.location.reload();
            this.RCPLoader = false;
            this.RCDLoader = false;
          }, (data, data2) => {
            if (RCType === 'rcProfessionnelle')
              this.errType = "errRCP";
            else
              this.errType = "errRCD";
            this.errMessage = "une erreur est survenue";
            this.RCPLoader = false;
            this.RCDLoader = false;
          })
      }
    } catch(err) {
      if (RCType === 'rcProfessionnelle')
        this.errType = "errRCP";
      else
        this.errType = "errRCD";
      this.errMessage = "une erreur est survenue";
      this.RCPLoader = false;
      this.RCDLoader = false;
    }
  }

  updateInfosPrest() {
    this.DataService.update('/prestataire-infos',
      {
        "representant.email": this.prestRepresentantEmail,
        "representant.phone": this.prestRepresentantPhone,
        "representant.firstName": this.prestRepresentantFirstName,
        "representant.lastName": this.prestRepresentantLastName,
        company: this.prestCompany,
        address: this.prestAddress,
        codePostal: this.prestCodePostal,
        ville: this.prestVille,
        siret: this.prestSiret,
        nbSalaries: this.prestNbSalaries
      },
      (data) => {
        alert("Les informations ont été mise à jour");
      },
      (error) => {
        alert(error.error.message);
      });
  }

  updateCorpsEtasPrest() {
    if (this.corpsEtat.length) {
      this.errMessage = "Au moins un corps d'état doit être sélectionné";
      this.errType = "prestCorpsEtat";
    }
    this.DataService.update('/prestataire-infos', {corpsEtat: this.corpsEtat},
      (data) => {
        alert("Les informations ont été mise à jour");
      },
      (error) => {
        alert(error.error.message);
      });
  }

  updateFilesPrest() {

  }

  /****************/
  /*| Architecte |*/
  /****************/

  periode_debut;
  periode_fin;

  archiEmail;
  archiPassword;

  archiListVisites = [];
  archiListDesordre = [];
  archiListAvis = [];

  archiNbVisites = 0;
  archiNbDesordre = 0;
  archiNbAvis = 0;
  archiSurfTot = 0;

  archiHonoAnnList = [];
  archiHonoAvisList = [];
  archiHonoAnnHT = 0;
  archiHonoAvisHT = 0;
  TotalHT = 0;

  archiZoneInter: CodePostal[] = [];
  zoneInter;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  updateAccessArchi() {
    this.errType = "";
    if (!this.archiEmail) {
      this.errMessage = "Veuillez renseigner un email valid";
      this.errType = "archiEmail";
    } else {
      this.DataService.update('/credentials', {email: this.archiEmail, password: this.archiPassword},
        (data) => {
          alert("Les identifiants d'accès ont été mis à jour");
        },
        (error) => {
          alert(error.error.message);
        });
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our code postal
    if ((value || '').trim()) {
      this.archiZoneInter.push({name: value.trim()});
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(codepostal: CodePostal): void {
    const index = this.archiZoneInter.indexOf(codepostal);

    if (index >= 0) {
      this.archiZoneInter.splice(index, 1);
    }
  }

  updateInfosArchi() {
    let codePostaux = [];
    for (let i in this.archiZoneInter)
      codePostaux.push(this.archiZoneInter[i].name);
    if (codePostaux.length > 0)
      this.DataService.update('/architecte-infos',
        {zoneInter: codePostaux},
        (data) => {
          alert("Les informations ont été mise à jour");
        },
        (error) => {
          console.log(error)
          alert(error.error.message);
        });
    else
      alert("Veuillez renseigner au moins un code postal !!!");
  }

  /**************/
  /*| Courtier |*/
  /**************/

  courtEmail;
  courtPassword;

  updateAccessCourt() {
    this.errType = "";
    if (!this.courtEmail) {
      this.errMessage = "Veuillez renseigner un email valid";
      this.errType = "courtEmail";
    } else {
      this.DataService.update('/credentials', {email: this.courtEmail, password: this.courtPassword},
        (data) => {
          alert("Les identifiants d'accès ont été mis à jour");
        },
        (error) => {
          alert(error.error.message);
        });
    }
  }

  /************/
  /*| Syndic |*/
  /************/

  syndEmail;
  syndPassword;

  updateAccessSynd() {
    this.errType = "";
    if (!this.syndEmail) {
      this.errMessage = "Veuillez renseigner un email valid";
      this.errType = "syndEmail";
    } else {
      this.DataService.update('/credentials', {email: this.syndEmail, password: this.syndPassword},
        (data) => {
          alert("Les identifiants d'accès ont été mis à jour");
        },
        (error) => {
          alert(error.error.message);
        });
    }
  }

  /******************/
  /*| Gestionnaire |*/
  /******************/

  gestEmail;
  gestPassword;

  updateAccessGest() {
    this.errType = "";
    if (!this.gestEmail) {
      this.errMessage = "Veuillez renseigner un email valid";
      this.errType = "gestEmail";
    } else {
      this.DataService.update('/credentials', {email: this.gestEmail, password: this.gestPassword},
        (data) => {
          alert("Les identifiants d'accès ont été mis à jour");
        },
        (error) => {
          alert(error.error.message);
        });
    }
  }

  /*********/
  /*| PCS |*/
  /*********/

  pcsEmail;
  pcsPassword;

  updateAccessPCS() {
    this.errType = "";
    if (!this.pcsEmail) {
      this.errMessage = "Veuillez renseigner un email valid";
      this.errType = "pcsEmail";
    } else {
      this.DataService.update('/credentials', {email: this.pcsEmail, password: this.pcsPassword},
        (data) => {
          alert("Les identifiants d'accès ont été mis à jour");
        },
        (error) => {
          alert(error.error.message);
        });
    }
  }

  constructor(public DataService : DataService,) {
    if (localStorage.role === 'pcs') {
      this.DataService.api('/retrieve/pcs',{_id: localStorage.id},
        (data) => {
          this.pcsEmail = data.pcs.email;
        },
        (error) => {
          console.log(error)
        });
    } else if (localStorage.role === 'syndic') {
      this.DataService.api_post_syndic({_id: localStorage.id},
        (data) => {
          this.syndEmail = data.syndic.email;
        },
        (error) => {
          console.log(error)
        });
    } else if (localStorage.role === 'gestionnaire') {
      this.DataService.api_get_single_gestionnaire({_id: localStorage.id},
        (data) => {
          this.gestEmail = data.gestionnaire.email
        },
        (error) => {
          console.log(error)
        });
    } else if (localStorage.role === 'courtier') {
      this.DataService.api_single_courtier({_id: localStorage.id},
        (data) => {
          this.courtEmail = data.courtier.email;
        },
        (error) => {
          console.log(error);
        })
    } else if (localStorage.role === 'prestataire') {
      this.etatsArray = ['Chauffagiste', 'Contrôle d’accès', 'Cordiste', 'Couvreur', 'Electricien', 'Façadier', 'Infiltrations', 'Maçon', 'Menuisier', 'Peintre', 'Plombier', 'Porte de garage', 'Ravalement', 'Recherche de fuite', 'Sécurité incendie', 'Serrurier', 'Vitrier'];
      this.formDecennal.get('rcDecennal').valueChanges.subscribe(newFile =>  {
        this.rcDecennale = newFile
      });
      this.formProfessionnelle.get('rcProfessionnelle').valueChanges.subscribe(newFile =>  {
        this.rcProfessionnelle = newFile
      });
      this.DataService.api_single_prestataire({_id: localStorage.id},
        (data) => {
          this.corpsEtat = data.prestataire.corpsEtat;

          this.prestEmail = data.prestataire.email;

          this.prestCompany = data.prestataire.company;
          this.prestAddress = data.prestataire.address;
          this.prestCodePostal = data.prestataire.codePostal;
          this.prestVille = data.prestataire.ville;
          this.prestSiret = data.prestataire.siret;

          this.prestRepresentantFirstName = data.prestataire.representant.firstName;
          this.prestRepresentantLastName = data.prestataire.representant.lastName;
          this.prestRepresentantPhone = data.prestataire.representant.phone;
          this.prestRepresentantEmail = data.prestataire.representant.email;
          this.prestNbSalaries = data.prestataire.nbSalaries;
          this.prestRCPfile = data.prestataire.RCProfessionnelle;
          this.prestRCDfile = data.prestataire.RCDecennale;
          this.RCPdetails = data.prestataire.RCPdetails;
          this.RCDdetails = data.prestataire.RCDdetails;
          this.originalRCDdetailsEcheance = data.prestataire.RCDdetails?.echeanceAnnuelle;
          this.originalRCPdetailsEcheance = data.prestataire.RCPdetails?.echeanceAnnuelle;

          if (this.RCPdetails?.echeanceAnnuelle) {
            this.RCPdetails.echeanceAnnuelle = new Date(this.RCPdetails.echeanceAnnuelle).getFullYear() + "-" + new Date(this.RCPdetails.echeanceAnnuelle).getMonth() + "-" + new Date(this.RCPdetails.echeanceAnnuelle).getDay();
            this.originalRCPdetailsEcheance = new Date(this.RCPdetails.echeanceAnnuelle).getFullYear()
          }
          if (this.RCDdetails?.echeanceAnnuelle)
            this.RCDdetails.echeanceAnnuelle = new Date(this.RCDdetails.echeanceAnnuelle).getFullYear() + "-" + new Date(this.RCDdetails.echeanceAnnuelle).getMonth() + "-" + new Date(this.RCDdetails.echeanceAnnuelle).getDay()
            this.originalRCDdetailsEcheance = new Date(this.RCDdetails.echeanceAnnuelle).getFullYear()
        },
        (error) => {
          alert(error.error.message);
        });
    } else if (localStorage.role === 'architecte') {
      this.periode_debut = new Date();
      this.periode_debut.setHours(0,0,0,0);
      this.periode_fin = new Date();
      this.periode_fin.setDate(this.periode_fin.getDate() + 30);
      this.periode_fin.setHours(0,0,0,0);
      this.DataService.api_single_architecte({_id: localStorage.id},
        async (data) => {
          this.archiEmail = data.architecte.email;
          for (let i in data.architecte.zoneInter) {
            this.archiZoneInter.push({name: data.architecte.zoneInter[i]});
          }
          this.archiHonoAnnList = data.architecte.honnorairesVisites;
          this.archiHonoAvisList = data.architecte.honnorairesAvis;
          for (let i in data.architecte.honnorairesVisites) {
            if (new Date(data.architecte.honnorairesVisites[i]?.date)?.getTime() >= this.periode_debut && new Date(data.architecte.honnorairesVisites[i]?.date)?.getTime() <= this.periode_fin) {
              this.archiHonoAnnHT += data.architecte.honnorairesVisites[i]?.amount;
            }
          }
          for (let i in data.architecte.honnorairesAvis) {
            if (new Date(data.architecte.honnorairesAvis[i]?.date)?.getTime() >= this.periode_debut.getTime() && new Date(data.architecte.honnorairesAvis[i]?.date)?.getTime() <= this.periode_fin.getTime()) {
              this.archiHonoAvisHT += data.architecte.honnorairesAvis[i]?.amount;
            }
          }
          await this.archiZoneInter.sort(function(a, b) {return a.name.localeCompare(b.name)});
        },
        (error) => {
          console.log(error)
        });
      this.DataService.api_get_visite(
        (data) => {
          for (let i in data.visites) {
            if (data.visites[i].faiteLe) {
              this.archiListVisites.push(data.visites[i]);
              if (new Date(data.visites[i].faiteLe)?.getTime() >= this.periode_debut.getTime() && new Date(data.visites[i].faiteLe)?.getTime() <= this.periode_fin.getTime()) {
                this.archiSurfTot += data.visites[i].coproId.surface;
                this.archiNbVisites += 1;
              }
            }
            if (data.visites[i].faiteLe && data.visites[i].receptionDone) {
              this.archiListAvis.push(data.visites[i]);
              if (new Date(data.visites[i].faiteLe)?.getTime() >= this.periode_debut.getTime() && new Date(data.visites[i].faiteLe)?.getTime() <= this.periode_fin.getTime()) {
                this.archiNbAvis += 1;
              }
            }
          }
        },
        (error) => {
          console.log(error)
        });
      this.DataService.apiIncident('/retrieve/incident-list', {architecteId: localStorage.id},
        (data) => {
          this.archiListDesordre = data.incidents;
          for (let i in data.incidents) {
            if (new Date(data.incidents[i]?.date)?.getTime() >= this.periode_debut.getTime() && new Date(data.incidents[i]?.date)?.getTime() <= this.periode_fin.getTime()) {
              this.archiNbDesordre += 1;
            }
          }
        },
        (error) => {
          console.log(error);
        })
    }
  }

  ngOnInit(): void {}

  changePeriod() {
    if (this.periode_debut.getTime() > this.periode_fin.getTime()) {
      alert("La date de debut doit être antérieur à celle de fin");
      this.periode_debut = new Date();
      this.periode_debut.setHours(0,0,0,0);
      this.periode_fin = new Date();
      this.periode_fin.setDate(this.periode_fin.getDate() + 30);
      this.periode_fin.setHours(0,0,0,0);
    } else {
      this.archiNbVisites = 0;
      this.archiSurfTot = 0;
      for (let i in this.archiListVisites) {
        if (new Date(this.archiListVisites[i].faiteLe).getTime() >= this.periode_debut.getTime() && new Date(this.archiListVisites[i].faiteLe).getTime() <= this.periode_fin.getTime()) {
          this.archiSurfTot += this.archiListVisites[i].coproId.surface;
          this.archiNbVisites += 1;
        }
      }
      this.archiNbDesordre = 0;
      for (let i in this.archiListDesordre) {
        if (new Date(this.archiListDesordre[i]?.date)?.getTime() >= this.periode_debut.getTime() && new Date(this.archiListDesordre[i]?.date)?.getTime() <= this.periode_fin.getTime()) {
          this.archiNbDesordre += 1;
        }
      }
      this.archiNbAvis = 0;
      for (let i in this.archiListAvis) {
        if (new Date(this.archiListAvis[i].faiteLe).getTime() >= this.periode_debut.getTime() && new Date(this.archiListAvis[i].faiteLe).getTime() <= this.periode_fin.getTime()) {
          this.archiSurfTot += this.archiListAvis[i].coproId.surface;
          this.archiNbAvis += 1;
        }
      }
      this.archiHonoAnnHT = 0;
      for (let i in this.archiHonoAnnList) {
        if (new Date(this.archiHonoAnnList[i]?.date)?.getTime() >= this.periode_debut.getTime() && new Date(this.archiHonoAnnList[i]?.date)?.getTime() <= this.periode_fin.getTime()) {
          this.archiHonoAnnHT += this.archiHonoAnnList[i]?.amount;
        }
      }
      this.archiHonoAvisHT = 0;
      for (let i in this.archiHonoAvisList) {
        if (new Date(this.archiHonoAvisList[i]?.date)?.getTime() >= this.periode_debut.getTime() && new Date(this.archiHonoAvisList[i]?.date)?.getTime() <= this.periode_fin.getTime()) {
          this.archiHonoAvisHT += this.archiHonoAvisList[i]?.amount;
        }
      }
    }
  }
}
