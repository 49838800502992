import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service'

@Component({
  selector: 'app-syndic-dialog',
  templateUrl: './syndic-dialog.component.html',
  styleUrls: ['./syndic-dialog.component.scss']
})
export class SyndicDialogComponent implements OnInit {

  constructor(public DataService: DataService) { }

  retourFormSyndic() {
    this.DataService.router.navigate(['admin-copro/form-syndic']);
  }

  ngOnInit(): void {
  }

}
