import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../data.service';
import { FormService } from '../../../../services/form.service'
import { Validators, FormBuilder } from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { AjoutImmeubleDialogComponent } from '../../../../dialog/ajout-immeuble-dialog/ajout-immeuble-dialog.component';

export interface SectionCourtier {
  id: string,
  name: string,
  company: string
}

@Component({
  selector: 'app-ajouter-un-immeuble',
  templateUrl: './ajouter-un-immeuble.component.html',
  styleUrls: ['./ajouter-un-immeuble.component.scss']
})

export class AjouterUnImmeubleComponent implements OnInit {

  formPresta = this.fb.group({
    nomCoproForm: ['', [Validators.required, Validators.minLength(3)]],
    addressForm: ['', [Validators.required, Validators.minLength(5)]],
    referenceForm: ['', [Validators.required, Validators.minLength(1)]],
    villeForm: ['', [Validators.required, Validators.minLength(3)]],
    codePostalForm: ['', [Validators.required, Validators.minLength(5)]],
    nbBatimentsForm: ['', [Validators.required, Validators.minLength(1)]],
    surfaceForm: ['', [Validators.required]],
  });

  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  nomCopro; reference = 'non assignée!'; address; codePostal; ville; nbBatiments; surface; courtier; checkedOui;

  msgErreur;

  addImmeuble(nomCopro, reference, address, codePostal, ville, nbBatiments, surface) {
    let copro = {
      nomCopro: nomCopro,
      reference: reference,
      address: address,
      codePostal: codePostal,
      ville: ville,
      nbBatiments: nbBatiments,
      surface: surface,
      checkedOui: this.FormService.form_AjoutImmeuble[7].value,
      courtier: this.courtier,
      syndicEnCours:"",
      syndicNominated:""
    }

    this.checkedOui = this.FormService.form_AjoutImmeuble[7].value

    if (this.checkedOui == true) {
        copro.syndicNominated = localStorage.getItem('id')
        delete copro.syndicEnCours;
    } else {
        copro.syndicEnCours = localStorage.getItem('id')
        delete copro.syndicNominated;
    }

    if (this.formPresta.valid) {
      this.DataService.api('/create/copro', copro,
      (res)=>{
          this.dialog.open(AjoutImmeubleDialogComponent, {
            height: 'auto',
            width: '100%',
            maxWidth: '450px',
          });
           if (this.checkedOui) {
             this.DataService.router.navigate(['mon-parc/dashboard']);
           } else {
             this.DataService.router.navigate(['en-cours-selection/dashboard']);
           }
      },
      (error) => {
         if (error.error.message) {
          this.DataService.error= error.error.message;
          this.msgErreur = this.DataService.error;
         }
      })
    } else {
      this.msgErreur = 'Vérifiez les informations du formulaire';
    }
  }

  get formCtrl() { return this.formPresta.controls; }

  listCourtiers: SectionCourtier [] = [];

  constructor(public DataService: DataService,
              private fb: FormBuilder,
              public dialog: MatDialog,
              public FormService: FormService) {
    this.DataService.api_get_courtier(
      (data) => {
        for (let key in data.courtiers)
          this.listCourtiers[key] = {
            id: data.courtiers[key]._id,
            name: data.courtiers[key].lastName,
            company: data.courtiers[key].company
          }
        this.FormService.form_AjoutImmeuble[7].liste[0].values = this.listCourtiers;
      },
      (error) => {
        console.log(error)
      }
    )
  }

  lectureSeule = true;

  disable() {
    this.lectureSeule = !this.lectureSeule;
    if (this.lectureSeule)
      this.reference = 'non assignée!';
    else
      this.reference = '';
  }

  ngOnInit(): void {
    this.lectureSeule = true;
    this.FormService.form_AjoutImmeuble[7].value = true;
    this.FormService.form_AjoutImmeuble[7].oui = true;
    this.FormService.form_AjoutImmeuble[7].non = false;
  }

}
