import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataService } from '../../../../data.service'

export interface Section {
  src_img: string;
  name: string;
  id_el: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  onEvent(event) {
    event.stopPropagation();
  }

  confirmation(event) {
    var i : any;
    var id = event.srcElement.parentNode.parentNode.parentNode.parentNode.parentNode.id
    if(id.length == 0) {
      id = event.srcElement.parentNode.parentNode.parentNode.parentNode.id
    }
    if(confirm("Confirmer la suppresion ?")) {
    for( i in this.listCopro)
      {
        if(this.listCopro[i].id_el == id)
        {
         this.listCopro.splice(i, 1)
        }
      }
    }
  }

  PrestatairesDetailsArray;
  searchVal: string;
  resultSearch = [];
  listCopro:Section []  = [  /*
    {
      "src_img": "../assets/img/photo/homme.jpg",
      "name": "Chris",
      "id_el":  "zz1",
    },
    {
      "src_img": "../assets/img/photo/photo_droits.png",
      "name": "Marc",
      "id_el":  "zz2",
    }, {
      "src_img": "../assets/img/photo/homme.jpg",
      "name": "Chris",
      "id_el":  "zz3",
    },
    {
      "src_img": "../assets/img/photo/photo_droits.png",
      "name": "Marc",
      "id_el":  "zz4",
    }
    */
  ];

  constructor(public DataService: DataService) {
    this.DataService.api_get_gestionnaire(
      (data)=>{
        console.log(data)
        console.log(data.gestionnaires);
        for(var key in data.gestionnaires)
          this.listCopro[key]={src_img: data.gestionnaires[key].img, name: data.gestionnaires[key].lastName, id_el: data.gestionnaires[key]._id};

        //this.listCopro4=data.syndics;

        this.resultSearch = this.listCopro;
      },
      (error)=>{
        console.log(error.status)
      }
    );
    this.resultSearch = this.listCopro;
  }


  ngOnInit(): void {
  }

  searchEngine() {
    this.resultSearch = [];
    //if(this.searchVal)
    for(var key in this.listCopro) {
      if(this.listCopro[key].name.toLowerCase().indexOf(this.searchVal.toLowerCase())>-1 && this.resultSearch.length<4){
        this.resultSearch.push(this.listCopro[key]);
      }
    }
    console.log(this.resultSearch)
  }


}
