<!--app-navigation-admin></app-navigation-admin-->
<span *ngFor="let detail of resultArchi">
    <span *ngIf="detail._id == idArchi">
        <div class="padding-card padding-card-pos" >
            <mat-card>
                <mat-card-content>
                    <div fxLayout="row wrap"  fxLayoutAlign="center" class="title title-pos">
                        <div fxFlex="100" style="margin-left: -35px">
                            <!-- <div fxLayoutAlign="center"><img class="imgs"  mat-card-avatar src="../../../../assets/img/photo/photo_droits.png"></div> -->
                          <div fxLayoutAlign="center">
                              <div mat-card-avatar class="imgs marge_photo ProfilSansImg"></div>
                          </div>
                          <div fxLayoutAlign="center" class="nom_droits">{{ detail.nomCabinet }}</div>
                        </div>
                        <div fxFlex="60" fxLayoutAlign="center" fxLayout="row wrap" class="pad30_0">
                            <div fxFlex="33" fxLayoutAlign="center">
                                <img src="../../../../assets/img/icones/square-gold-medium.jpg">
                                <div fxLayoutAlign="center center" class="marge_details">{{surface}} m²</div>
                            </div>
                            <div fxFlex="33" fxLayoutAlign="center">
                                <img src="../../../../assets/img/icones/calcule.jpg">
                                <div fxLayoutAlign="center center" class="marge_details">0 €</div>
                            </div>
                        </div>
                        <div fxFlex="40" fxLayoutAlign="center" fxLayout="row wrap" class="pad30_0">
                          <div fxFlex="33" fxLayoutAlign="center" >
                                  <img src="../../../../assets/img/icones/doc.jpg">
                                  <div fxLayoutAlign="center center" class="marge_details">{{ detail.zoneInter }}</div>
                              </div>
                        </div>
                    </div>

                    <div fxLayout="row wrap" class="title title-pos">
                        <!-- TITRES DU TABLEAU-->

                        <div fxFlex="40" fxFlex.xs="100" fxFlex.sm="100">
                            <div class="title_droits full-width title_tab">Immeubles liés à <span>{{ detail.nomCabinet }}</span></div>
                            <div class="padding-card">
                                <div fxLayout="row wrap" class="padd_list" style="width: 100%;">
                                    <div fxFlex="10"><img src="../../../../assets/img/photo/user-test.png"></div>
                                    <div fxFlex="20" fxLayoutAlign="center">surface</div>
                                    <div fxFlex="40" fxLayoutAlign="center">Adresse</div>
                                    <div fxFlex="30" fxLayoutAlign="center">Demandé Le</div>
                                </div>

                                <div fxLayout="row wrap" *ngFor="let syndic of typesOfSyndics">
                                    <div fxFlex="100">
                                        <div id="{{ syndic.id }}" class="colonne_gauche droits padd_list">
                                            <div fxLayout="row wrap" (click)="color_line($event)" class="pointer">
                                                <div fxFlex="10">
                                                    <div *ngIf="syndic.image == !null" mat-card-avatar class="icon_list" [ngStyle]="{ 'background-position': 'center', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-image': 'url(' + syndic.image + ')'}"></div>
                                                    <div *ngIf="syndic.image == null" mat-card-avatar class="icon_list" [ngStyle]="{ 'background-position': 'center', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-image': 'url(../../../../assets/img/icones/building.png)'}"></div>
                                                </div>
                                                <div fxFlex="20" fxLayoutAlign="center center" class="date_presta fontNormal"> {{ syndic.surface}} m²</div>
                                                <div fxFlex="40" fxLayoutAlign="center" class="list_height fontNormal">{{syndic.address}} - {{ syndic.codePostal }}</div>
                                                <div fxFlex="30" fxLayoutAlign="center center" class="date_presta fontNormal"> {{ syndic.demandeLe | date: 'dd/MM/yyyy'}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- ECHANGE -->

                        <div fxFlex="20" fxFlex.xs="100" fxFlex.sm="100" fxLayoutAlign="center center" fxLayoutAlign.xs="center center" fxLayoutAlign.sm="center center" class="echange_box">
                            <div class="box_shadow box_size position_echange">
                                <div fxLayoutAlign="center" class="fleche txt_center echange">Retirer Immeuble(s)</div>
                                <div fxLayoutAlign="center"><mat-button-toggle (click)="echange_line($event)"><img src="../../../../assets/img/icones/arrow-droite.png"></mat-button-toggle></div>
                                <div fxLayoutAlign="center"><mat-button-toggle (click)="echange_line_2($event)"><img src="../../../../assets/img/icones/arrow-gauche.png"></mat-button-toggle></div>
                                <div fxLayoutAlign="center" class="fleche txt_center echange">Ajouter Immeuble(s)</div>
                            </div>
                        </div>

                        <!-- IMMEUBLES DISPONIBLES-->

                        <!-- TITRES DU TABLEAU-->

                        <div fxFlex="40" fxFlex.xs="100" fxFlex.sm="100">
                            <div class="title_droits full-width title_tab">Immeubles en demande de visite</div>
                            <div class="padding-card padding-card-list">
                                <div fxLayout="row wrap" class="padd_list" style="width: 100%;">
                                    <div fxFlex="10"><img src="../../../../assets/img/photo/user-test.png"></div>
                                    <div fxFlex="20" fxLayoutAlign="center">surface</div>
                                    <div fxFlex="40" fxLayoutAlign="center">Adresse</div>
                                    <div fxFlex="30" fxLayoutAlign="center">Date</div>
                                </div>

                                <div fxLayout="row wrap" *ngFor="let disponible of typesOfDisponibles">
                                    <div fxFlex="100">
                                        <div id="{{ disponible.id }}" class="colonne_droite droits padd_list">
                                            <div fxLayout="row wrap" (click)="color_line($event)" class="pointer">
                                                <div fxFlex="10">
                                                    <div *ngIf="disponible.image == !null" mat-card-avatar class="icon_list" [ngStyle]="{ 'background-position': 'center', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-image': 'url(' + disponible.image + ')'}"></div>
                                                    <div *ngIf="disponible.image == null" mat-card-avatar class="icon_list" [ngStyle]="{ 'background-position': 'center', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-image': 'url(../../../../assets/img/icones/building.png)'}"></div>
                                                </div>
                                                <div fxFlex="20" fxLayoutAlign="center center" class="date_presta fontNormal"> {{ disponible.surface}} m²</div>
                                                <div fxFlex="40" fxLayoutAlign="center" class="list_height fontNormal">{{disponible.address}} - {{ disponible.codePostal }} </div>
                                                <div fxFlex="30" fxLayoutAlign="center center" class="date_presta fontNormal"> {{ disponible.demandeLe | date: 'dd/MM/yyyy'}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </span>
</span>
