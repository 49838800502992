import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mes-prestataires',
  templateUrl: './mes-prestataires.component.html',
  styleUrls: ['./mes-prestataires.component.scss']
})
export class MesPrestatairesComponent implements OnInit {


  constructor() {
  }

  ngOnInit(): void {
  }
}