import { Component, OnInit } from '@angular/core';
import { GalleryComponent } from '../../../../gallery/gallery.component';
import { StarRatingComponent } from 'ng-starrating';
import { DataService } from "../../../../data.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

export interface SectionLeftBloc {
  id_el : string,
  name  : string,
  email : string,
  port  : string
}

export interface SectionDevis {
  id_el           : string,
  src_img         : string,
  factureTTC,
  metrages,
  pcsId           : string,
  coproId         : string,
  syndicId        : string,
  visiteId        : string,
  courtierId      : string,
  incidentId      : string,
  architecteId    : string,
  prestataireId   : string,
  gestionnaireId  : string,
  comArchi        : string,
  comPrest        : string,
  desordre        : string,
  description     : string,
  situation       : string,
  corpsEtat       : [string],
  images_bf       : [string],
  images_af       : [string],
  date,
  devisPDF        : string,
  dateDepotDevis,
  facturePDF      : string,
  dateDepotFacture,
  receptionDone   : SectionAvis,
  conformite,
  remarque        : string,
  rate,
  oui             : boolean,
  non             : boolean,
  refDesordre     : string,
}

export interface SectionAvis {
  id_el           : string,
  images_af       : [],
  conformite      : boolean,
  rate            : number,
  remarque        : string,
  refDesordre     : string
}

@Component({
  selector: 'app-pre-reception-details',
  templateUrl: './pre-reception-details.component.html',
  styleUrls: ['./pre-reception-details.component.scss']
})

export class PreReceptionDetailsComponent implements OnInit {

  totalstar = 5;
  starsValue = 5;
  currentRate = 5;

  remarque: string;

  visiteId;

  src_img: string;
  gest: SectionLeftBloc;
  pcs : SectionLeftBloc;
  devis: SectionDevis[] = [];

  conformiteOuiNon(value, index) {
    this.devis[index].conformite = value;
    if (value == true) {
      this.devis[index].oui = true;
      this.devis[index].non = false;
    } else {
      this.devis[index].oui = false;
      this.devis[index].non = true;
    }
  }

  rating($event:{oldValue:number, newValue:number, starRating:StarRatingComponent}, index) {
    this.currentRate = $event.newValue;
    this.devis[index].rate = $event.newValue;
  }

  photo_af = [];

  getFileDetails (e) {
    this.errMessagePhoto_af = '';
    for (var i = 0; i < e.target.files.length; i++) {
      if ( this.photo_af.length < 3) {
        this.photo_af.push(e.target.files[i]);
      } else {
        alert("3 images max");
      }
    }
  }

  deletePhotoAf (index) {
    this.photo_af.splice(index, 1);
  }

  errMessageRemarque;
  errMessagePhoto_af;

  sendReception(devisId, index) {
    this.loaderImage[devisId] = true;
    this.errMessageRemarque = '';
    if (!this.devis[index].remarque || this.remarque?.length < 3) {
      this.errMessageRemarque = 'Veuillez renseigner vos remarques (3 caractères minimum)';
      this.loaderImage[devisId] = false;
    } else if (this.photo_af.length === 0) {
      this.errMessagePhoto_af = 'Veuillez ajouter des photos "après travaux"';
      this.loaderImage[devisId] = false;
    } else {
      const data = new FormData();
      for (let i in this.photo_af)
        data.append("images_af[]", this.photo_af[i]);
      for (let i in this.devis[index].images_bf)
        data.append("images_bf[]", this.devis[index].images_bf[i]);
      for (let i in this.devis[index].corpsEtat)
        data.append("corpsEtat[]", this.devis[index].corpsEtat[i])

      data.append("src_img", this.devis[index].src_img);
      data.append("factureTTC", this.devis[index].factureTTC);
      data.append("metrages", this.devis[index].metrages);
      data.append("comArchi", this.devis[index].comArchi);
      data.append("comPrest", this.devis[index].comPrest);
      data.append("desordre", this.devis[index].desordre);
      data.append("description", this.devis[index].description);
      data.append("situation", this.devis[index].situation);
      data.append("date", this.devis[index].date);
      data.append("conformite", this.devis[index].conformite);
      data.append("rate", this.devis[index].rate);
      data.append("remarque", this.devis[index].remarque);
      data.append("incidentId", this.devis[index].incidentId);
      data.append("coproId", this.devis[index].coproId);
      data.append("pcsId", this.devis[index].pcsId);
      data.append("devisId", this.devis[index].id_el);
      data.append("syndicId", this.devis[index].syndicId);
      data.append("visiteId", this.devis[index].visiteId);
      data.append("courtierId", this.devis[index].courtierId);
      data.append("architecteId", localStorage.getItem('id'));
      data.append("prestataireId", this.devis[index].prestataireId);
      data.append("gestionnaireId", this.devis[index].gestionnaireId);
      data.append("devisPDF", this.devis[index].devisPDF);
      data.append("dateDepotDevis", this.devis[index].dateDepotDevis);
      data.append("facturePDF", this.devis[index].facturePDF);
      data.append("dateDepotFacture", this.devis[index].dateDepotFacture);
      data.append("refDesordre", this.devis[index].refDesordre);

      this.DataService.api('/create/reception', data,
        (resp) => {
          this.loaderImage[devisId] = false;
          setTimeout(() => {
            alert("Avis Travaux enregistré !");
            this.devis[index].receptionDone = {
              id_el           : resp.receptionDone._id,
              images_af       : resp.receptionDone.images_af,
              conformite      : resp.receptionDone.conformite,
              rate            : resp.receptionDone.rate,
              remarque        : resp.receptionDone.remarque,
              refDesordre     : resp.receptionDone.refDesordre
            };
            this.photo_af = [];
          }, 100);
        },
        (error) => {
          this.loaderImage[devisId] = false;
        console.log("error: ", error);
        })
    }
  }

  constructor(public GalleryComponent: GalleryComponent, public DataService: DataService, private fb: FormBuilder) {
    this.DataService.sharedMessage.subscribe(id => this.visiteId = id);
  }
  loaderImage = {};
  ngOnInit() {
    if (this.visiteId) {
      this.DataService.api_get_devis({visiteId: this.visiteId},
        (data) => {
          console.log("data: ", data);
          this.gest = {
            id_el: data.devis[0]?.gestionnaireId?._id,
            name: data.devis[0]?.gestionnaireId?.firstName && data.devis[0]?.gestionnaireId?.lastName ? data.devis[0]?.gestionnaireId?.firstName + ' ' + data.devis[0]?.gestionnaireId?.lastName : null,
            email: data.devis[0]?.gestionnaireId?.email,
            port: data.devis[0]?.gestionnaireId?.phone
          };
          /*
          this.pcs = {
            id_el: data.devis[0]?.visiteId._id,
            name: data.devis[0]?.visiteId.nomPCS,
            email: data.devis[0]?.visiteId.emailPCS,
            port: data.devis[0]?.visiteId.phonePCS
          };
          */
          this.src_img = data.devis[0].visiteId.img;

          for (let key in data.devis) {
            if (!data.devis[key]?.receptionDone?._id) {
              this.pcs = {
                id_el: data.devis[key]?.visiteId._id,
                name: data.devis[key]?.visiteId.nomPCS,
                email: data.devis[key]?.visiteId.emailPCS,
                port: data.devis[key]?.visiteId.phonePCS
              };
            }
            this.devis[key] = {
              id_el: data.devis[key]?._id,
              src_img: data.devis[key].visiteId.img,
              factureTTC: data.devis[key].factureTTC,
              metrages: data.devis[key].metrages,
              comArchi: data.devis[key].visiteId.commentaire,
              comPrest: data.devis[key].commentaire,
              desordre: data.devis[key].desordre,
              description: data.devis[key].description,
              situation: data.devis[key].situation,
              corpsEtat: data.devis[key].corpsEtat,
              images_bf: data.devis[key].images,
              images_af: ['\0'],
              date: data.devis[key].date,
              conformite: true,
              rate: 5,
              remarque: '',
              incidentId: data.devis[key].incidentId,
              coproId: data.devis[key].coproId,
              pcsId: data.devis[key].pcsId,
              syndicId: data.devis[key].syndicId,
              visiteId: data.devis[key].visiteId._id,
              courtierId: data.devis[key].courtierId,
              architecteId: data.devis[key].architecteId,
              prestataireId: data.devis[key].prestataireId,
              gestionnaireId: data.devis[key].gestionnaireId?._id,
              demandeDevis: data.devis[key].demandeDevis,
              devisPDF: data.devis[key].devisPDF,
              dateDepotDevis: data.devis[key].dateDepotDevis,
              facturePDF: data.devis[key].facturePDF,
              dateDepotFacture: data.devis[key].dateDepotFacture,
              refDesordre: data.devis[key].refDesordre,
              oui: true,
              non: false,
              receptionDone: {
                id_el           : data.devis[key]?.receptionDone?._id ?? null,
                images_af       : data.devis[key]?.receptionDone?.images_af ?? [],
                conformite      : data.devis[key]?.receptionDone?.conformite ?? true,
                rate            : data.devis[key]?.receptionDone?.rate ?? 5,
                remarque        : data.devis[key]?.receptionDone?.remarque ?? '',
                refDesordre     : data.devis[key]?.receptionDone?.refDesordre ?? '',
              }
            }
          }
        },
        (error) => {
          this.devis = null;
          console.log(error);
        })
    } else
      this.devis = null;
  }
}

