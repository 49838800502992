import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service'

@Component({
  selector: 'app-gestionnaire-dialog',
  templateUrl: './gestionnaire-dialog.component.html',
  styleUrls: ['./gestionnaire-dialog.component.scss']
})
export class GestionnaireDialogComponent implements OnInit {

  constructor(public DataService: DataService) { }

  retourFormGestionnaire() {
    this.DataService.router.navigate(['ajout-collaborateurs/form-gestionnaires']);
  }

  ngOnInit(): void {
  }

}
