import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service'

@Component({
  selector: 'app-courtier-dialog',
  templateUrl: './courtier-dialog.component.html',
  styleUrls: ['./courtier-dialog.component.scss']
})
export class CourtierDialogComponent implements OnInit {

  constructor(public DataService: DataService) { }

  retourFormCourtier() {
    this.DataService.router.navigate(['admin-copro/form-courtiers-admin']);
  }

  ngOnInit(): void {
  }

} 
