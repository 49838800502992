import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DataService {

error;
token;
user;
copro;

  private message = new BehaviorSubject('');
  private index = new BehaviorSubject('0');
  sharedMessage = this.message.asObservable();
  sharedIndex = this.index.asObservable();

  public fillMessage(id: string) {
    let message = new BehaviorSubject(id);
    this.sharedMessage = message.asObservable();
  }

  public fillIndex(ind: string) {
    let index = new BehaviorSubject(ind);
    this.sharedIndex = index.asObservable();
  }

// generic Api POST Call
public api(route, params, callback_success, callback_error){
    this.http.post("https://copro-back.cantem.fr"+route, params).subscribe(
      (response) => {
            callback_success(response)
      },
      (error) => {
           callback_error(error)
    });
}

public update(route, params, callback_success, callback_error) {
   this.http.put("https://copro-back.cantem.fr/update"+route, params).subscribe(
     (response) => {
       callback_success(response)
     },
     (error) => {
       callback_error(error)
     });
}

public api_post_unseen_notifications(params, callback_success, callback_error){
    this.http.post("https://copro-back.cantem.fr/gestion/unseen-notif", params).subscribe(
      (response) => {
        callback_success(response)
      },
      (error) => {
        callback_error(error)
      });
}

public api_post_unseen_notif_copro(params, callback_success, callback_error){
    this.http.post("https://copro-back.cantem.fr/gestion/unseen-notif-copro", params).subscribe(
      (response) => {
        callback_success(response)
      },
      (error) => {
        callback_error(error)
      });
}

public api_post_del_notification(params, callback_success, callback_error){
  this.http.post("https://copro-back.cantem.fr/gestion/del-notif", params).subscribe(
    (response) => {
      callback_success(response)
    },
    (error) => {
      callback_error(error)
    });
}

public api_post_mes_notifications(params, callback_success, callback_error){
  this.http.post("https://copro-back.cantem.fr/retrieve/notifications", params).subscribe(
    (response) => {
      callback_success(response)
    },
    (error) => {
      callback_error(error)
    });
}

public api_post_notifs_by_copro(params, callback_success, callback_error){
    this.http.post("https://copro-back.cantem.fr/retrieve/notifs-corpos", params).subscribe(
      (response) => {
        callback_success(response)
      },
      (error) => {
        callback_error(error)
      });
}

public api_get_mon_parc(callback_success, callback_error){
  this.http.get("https://copro-back.cantem.fr/retrieve/copro").subscribe(
    (response) => {
          callback_success(response)
    },
    (error) => {
         callback_error(error)
  });
}

public api_post_mon_parc(params, callback_success, callback_error){
  console.warn(params)
  this.http.post("https://copro-back.cantem.fr/retrieve/copro", params).subscribe(
    (response) => {
          callback_success(response)
    },
    (error) => {
         callback_error(error)
  });
}

public api_get_syndic(callback_success, callback_error){
  this.http.get("https://copro-back.cantem.fr/retrieve/syndic").subscribe(
    (response) => {
          callback_success(response)
    },
    (error) => {
         callback_error(error)
  });
}

public api_post_syndic(params, callback_success, callback_error){
  this.http.post("https://copro-back.cantem.fr/retrieve/syndic", params).subscribe(
    (response) => {
          callback_success(response)
    },
    (error) => {
         callback_error(error)
  });
}

public api_get_architecte(callback_success, callback_error){
  this.http.get("https://copro-back.cantem.fr/retrieve/architecte").subscribe(
    (response) => {
          callback_success(response)
    },
    (error) => {
         callback_error(error)
  });
}

public api_single_architecte(params, callback_success, callback_error){
  console.warn(params)
  this.http.post("https://copro-back.cantem.fr/retrieve/architecte", params).subscribe(
    (response) => {
          callback_success(response)
    },
    (error) => {
         callback_error(error)
  });
}

public api_single_courtier(params, callback_success, callback_error){

  this.http.post("https://copro-back.cantem.fr/retrieve/courtier", params).subscribe(
    (response) => {
          callback_success(response)
    },
    (error) => {
         callback_error(error)
  });
}

public api_get_un_courtier(params, callback_success, callback_error){
  this.http.get("https://copro-back.cantem.fr/retrieve/courtier", params).subscribe(
    (response) => {
          callback_success(response)
    },
    (error) => {
         callback_error(error)
  });
}

public api_get_encours(callback_success, callback_error){
  this.http.get("https://copro-back.cantem.fr/retrieve/encours-list").subscribe(
    (response) => {
          callback_success(response)
    },
    (error) => {
         callback_error(error)
  });
}

public api_single_copro(params, callback_success, callback_error){
  this.http.post("https://copro-back.cantem.fr/retrieve/copro", params).subscribe(
    (response) => {
          callback_success(response)
    },
    (error) => {
         callback_error(error)
  });
}

public api_get_prestataire(callback_success, callback_error){
  this.http.get("https://copro-back.cantem.fr/retrieve/prestataire-list").subscribe(
    (response) => {
          callback_success(response)
    },
    (error) => {
         callback_error(error)
  });
}

public api_single_prestataire(params, callback_success, callback_error) {
  this.http.post("https://copro-back.cantem.fr/retrieve/prestataire", params).subscribe(
    (response) => {
      callback_success(response)
    },
    (error) => {
      callback_error(error)
    });
}

public api_get_courtier(callback_success, callback_error){
  this.http.get("https://copro-back.cantem.fr/retrieve/courtier").subscribe(
    (response) => {
          callback_success(response)
    },
    (error) => {
         callback_error(error)
  });
}

public api_get_gestionnaire(callback_success, callback_error){
  this.http.get("https://copro-back.cantem.fr/retrieve/gestionnaire").subscribe(
    (response) => {
          callback_success(response)
    },
    (error) => {
         callback_error(error)
  });
}

public api_get_single_gestionnaire(params, callback_success, callback_error){
  this.http.post("https://copro-back.cantem.fr/retrieve/gestionnaire", params).subscribe(
    (response) => {
      callback_success(response)
    },
    (error) => {
      callback_error(error)
    });
}

public api_get_copro(callback_success, callback_error){
  this.http.get("https://copro-back.cantem.fr/retrieve/copro").subscribe(
    (response) => {
          callback_success(response)
    },
    (error) => {
         callback_error(error)
  });
}

public api_get_devis(params, callback_success, callback_error) {
  this.http.post("https://copro-back.cantem.fr/retrieve/devis-list", params).subscribe(
    (response) => {
      callback_success(response)
    },
    (error) => {
      callback_error(error)
    });
}

public api_get_one_reception(params, callback_success, callback_error) {
  this.http.post("https://copro-back.cantem.fr/retrieve/one-avis", params).subscribe(
    (response) => {
      callback_success(response)
    },
    (error) => {
      callback_error(error)
    });
}

public api_get_all_receptions(params, callback_success, callback_error) {
    this.http.post("https://copro-back.cantem.fr/retrieve/all-avis", params).subscribe(
  (response) => {
    callback_success(response)
  },
  (error) => {
    callback_error(error)
  });
}

public api_get_devis_copro(params, callback_success, callback_error) {
  this.http.post("https://copro-back.cantem.fr/retrieve/devis-copro", params).subscribe(
     (response) => {
      callback_success(response)
    },
     (error) => {
      callback_error(error)
    });
}

public api_depot_devis(file, callback_success, callback_error) {
  this.http.post("https://copro-back.cantem.fr/create/devis-pdf", file).subscribe(
    (response) => {
      callback_success(response)
    },
    (error) => {
      callback_error(error)
    });
}

public api_depot_facture(file, callback_success, callback_error) {
  this.http.post("https://copro-back.cantem.fr/create/facture-pdf", file).subscribe(
    (response) => {
      callback_success(response)
    },
    (error) => {
      callback_error(error)
    });
}

public api_demande_devis(params, callback_success, callback_error) {
  this.http.post("https://copro-back.cantem.fr/gestion/demande-devis", params).subscribe(
    (response) => {
      callback_success(response)
    },
    (error) => {
      callback_error(error)
    });
}

public api_get_copro_syndic(params, callback_success, callback_error){
  this.http.post("https://copro-back.cantem.fr/retrieve/copro-by-syndic", params).subscribe(
    (response) => {
      callback_success(response)
    },
    (error) => {
      callback_error(error)
    });
}

public api_get_encourslist(callback_success, callback_error){
  this.http.get("https://copro-back.cantem.fr/retrieve/encours-list").subscribe(
    (response) => {
          callback_success(response)
    },
    (error) => {
         callback_error(error)
  });
}

public apiSendToEtude(params, callback_success, callback_error) {
  this.http.post("https://copro-back.cantem.fr/gestion/etude", params).subscribe(
    (response) => {
      callback_success(response)
    },
    (error) => {
      callback_error(error)
    });
}

public api_get_visite(callback_success, callback_error){
  this.http.get("https://copro-back.cantem.fr/retrieve/visite-all").subscribe(
    (response) => {
          callback_success(response)
    },
    (error) => {
         callback_error(error)
  });
}

public api_get_visite_courtier(params, callback_success, callback_error) {
  this.http.post("https://copro-back.cantem.fr/retrieve/visites-courtier", params).subscribe(
    (response) => {
      callback_success(response)
    },
    (error) => {
      callback_error(error)
    });
}

public api_post_single_visite(params, callback_success, callback_error){

  //console.log("Request on URL:", "https://copro-back.cantem.fr/retrieve/visite-one", "with params:", params)
  this.http.post("https://copro-back.cantem.fr/retrieve/visite-one", params).subscribe(
    (response) => {
          callback_success(response)
    },
    (error) => {
         callback_error(error)
  });
}


public apiParc(route, file, callback_success, callback_error){

  let formData = new FormData();
  formData.append('data', file);

  this.http.post("https://copro-back.cantem.fr"+route, formData).subscribe(

    (response) => {
          callback_success(response)
    },
    (error) => {
         callback_error(error)
  });
}

public apiIncident(route, file, callback_success, callback_error){

  //let formData = new FormData();
  //formData.append('data', file);

  this.http.post("https://copro-back.cantem.fr"+route, file).subscribe(

    (response) => {
          callback_success(response)
    },
    (error) => {
         callback_error(error)
  });
}

public api_evaluation(params, callback_success, callback_error) {
  this.http.post("https://copro-back.cantem.fr/create/evaluation", params).subscribe(
    (response) => {
      callback_success(response)
    },
    (error) => {
      callback_error(error)
    });
}

public api_del_eval(params, callback_success, callback_error) {
  this.http.post("https://copro-back.cantem.fr/gestion/del-eval", params).subscribe(
    (response) => {
      callback_success(response)
    },
    (error) => {
      callback_error(error)
    });
}

public apiImgBat(route, id, file, callback_success, callback_error){
  let formData = new FormData();
      formData.append('coproId', id);
      formData.append('image', file);

  this.http.post("https://copro-back.cantem.fr"+route, formData).subscribe(

    (response) => {
          callback_success(response)
    },
    (error) => {
         callback_error(error)
  });
}

public api_change_droits(params, callback_success, callback_error) {
    this.http.post('https://copro-back.cantem.fr/gestion/changer-droits', params).subscribe(
      (response) => {
        callback_success(response)
      },
      (error) => {
        callback_error(error)
      })
}

public apiFormCopro(route, file, callback_success, callback_error){

  //let formData = new FormData();
  //formData.append('data', file);

  this.http.post("https://copro-back.cantem.fr"+route, file).subscribe(

    (response) => {
          callback_success(response)
    },
    (error) => {
         callback_error(error)
  });
}

public apiFormCoproPut(route, file, callback_success, callback_error){

  //let formData = new FormData();
  //formData.append('data', file);

  this.http.put("https://copro-back.cantem.fr"+route, file).subscribe(

    (response) => {
          callback_success(response)
    },
    (error) => {
         callback_error(error)
  });
}

public api_delete_syndic(params, callback_success, callback_error) {
  this.http.post("https://copro-back.cantem.fr/gestion/delete-syndic", params).subscribe(
    (response) => {
      callback_success(response)
    },
    (error) => {
      callback_error(error)
    })
}

public apiCoproDelete(params, callback_success, callback_error) {
  this.http.post("https://copro-back.cantem.fr/gestion/delete-copro", params).subscribe(
    (response) => {
      callback_success(response)
    },
    (error) => {
      callback_error(error)
    })
}

public apiCancelVisit(params, callback_success, callback_error) {
  this.http.post("https://copro-back.cantem.fr/gestion/annuler-visite", params).subscribe(
    (response) => {
      callback_success(response)
    },
    (error) => {
      callback_error(error)
    })
}

public apiAssignCourtierToSyndic(params, callback_success, callback_error) {
  this.http.post("https://copro-back.cantem.fr/gestion/syndic-courtier", params).subscribe(
    (response) => {
      callback_success(response)
    },
    (error) => {
      callback_error(error)
    })
}

public apiAssignCourtierToCopro(params, callback_success, callback_error) {
  this.http.post("https://copro-back.cantem.fr/gestion/copro-courtier", params).subscribe(
    (response) => {
      callback_success(response)
    },
    (error) => {
      callback_error(error)
    })
}

public apiAssignGestionnaireToCopro(params, callback_success, callback_error) {
  this.http.post("https://copro-back.cantem.fr/gestion/assign-gestionnaire", params).subscribe(
    (response) => {
      callback_success(response)
    },
    (error) => {
      callback_error(error)
    })
}

public apiUnassignGestionnaireToCopro(params, callback_success, callback_error) {
    this.http.post("https://copro-back.cantem.fr/gestion/unassign-gestionnaire", params).subscribe(
  (response) => {
      callback_success(response)
    },
  (error) => {
      callback_error(error)
    });
}

public apiAssignPrestataireToSyndic(params, callback_success, callback_error) {
  this.http.post("https://copro-back.cantem.fr/gestion/syndic-prestataire", params).subscribe(
    (response) => {
      callback_success(response)
    },
    (error) => {
      callback_error(error)
    })
}
public apiUnassignEtudeToCourtier(params, callback_success, callback_error) {
  this.http.post("https://copro-back.cantem.fr/gestion/unassign-etude-courtier", params).subscribe(
    (response) => {
      callback_success(response)
    },
    (error) => {
      callback_error(error)
    })
}

public apiChangeStatusCopro(coproId, isParc, callback_success, callback_error) {
  this.http.post("https://copro-back.cantem.fr/gestion/nomination", {coproId, isParc}).subscribe(
    (response) => {
      callback_success(response);
    },
    (error) => {
      callback_error(error)
    }
  )
}

public apiAboPrestaToSyndic(params, callback_success, callback_error) {
  this.http.post("https://copro-back.cantem.fr/gestion/abo-presta", params).subscribe(
    (response) => {
      callback_success(response);
    },
    (error) => {
      callback_error(error)
    }
  )
}
public apiDeleteIncident(params, callback_success, callback_error) {
  this.http.post("https://copro-back.cantem.fr/gestion/delete-incident", params).subscribe(
    (response) => {
      callback_success(response);
    },
    (error) => {
      callback_error(error)
    }
  )
}

public getCredit(callback_success, callback_error) {
    this.http.get("https://copro-back.cantem.fr/retrieve/credit").subscribe(
      (response) => {
        callback_success(response);
      },
      (error) => {
        callback_error(error)
      }
    )
}


  constructor(private http: HttpClient, private activedRoute: ActivatedRoute, public router: Router) {}

  public ouinon($event, elem, value){
    elem.value = value;
    if(value == true)
    {
      elem.oui = true;
      elem.non = false;
    }
    else {
      elem.oui = false;
      elem.non = true;
    }
  }

  loggedIn() {
    return localStorage.getItem('token')
  }

  getToken() {
    return !!localStorage.getItem('token')
  }

  //helper regex input

  public isValidEmail(email) {
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
  }
}
