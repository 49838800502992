<!--app-navigation-admin></app-navigation-admin-->

<span *ngIf="resultPresta && resultPresta._id == idPresta">
<div class="padding-card padding-card-pos">
    <mat-card>
        <mat-card-content>
            <div fxLayout="row wrap" class="title title-pos">

                <div fxFlex="100%" style="margin-left: -35px">
                    <!--div fxLayoutAlign="center"><img class="imgs"  mat-card-avatar src="../../../../../assets/img/photo/photo_droits.png"></div-->
                    <div fxLayoutAlign="center">
                      <div mat-card-avatar class="imgs marge_photo ProfilSansImg"></div>
                    </div>
                    <div fxLayoutAlign="center" class="nom_droits">{{resultPresta.company}}</div>
                </div>

                <!-- TITRES DU TABLEAU-->

                <div fxFlex="40%" fxFlex.xs="100%" fxFlex.sm="100%">
                    <div class="title_droits full-width title_tab">Syndics liés à {{resultPresta.representant.firstName}} {{resultPresta.representant.lastName}}</div>
                    <div class="padding-card">
                        <div fxLayout="row wrap" class="padd_list" style="width: 100%;">
                            <div fxFlex="30"><img src="../../../../../assets/img/icones/user.png"></div>
                            <div fxFlex="40">Nom</div>
                            <div fxFlex="30">Abonnement</div>
                        </div>

                        <div fxLayout="row wrap" *ngFor="let syndic of listSyndicsPresta">
                            <div fxFlex="100">
                                <div *ngIf="syndic?.id" id="{{ syndic.id }}" class="colonne_gauche droits padd_list">
                                    <div fxLayout="row wrap" (click)="color_line($event)" class="pointer">
                                        <div fxFlex="30">
                                            <div mat-card-avatar class="icon_list" [ngStyle]="{ 'background-position': 'center', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-image': 'url(' + syndic.image + ')'}"></div>
                                        </div>
                                        <div fxFlex="40" class="list_height">{{syndic.nom_syndic}}</div>
                                        <div fxFlex="30"><mat-slide-toggle style="align-self: center" (click)="onEvent($event, syndic.abo, syndic.id)" [checked]="syndic.abo" class="list_height" fxFlex="10"></mat-slide-toggle></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ECHANGE -->

                <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="100%" fxLayoutAlign="center center" fxLayoutAlign.xs="center center" fxLayoutAlign.sm="center center" class="echange_box">
                    <div class="box_shadow box_size position_echange">
                        <div fxLayoutAlign="center" class="fleche txt_center echange">Retirer syndic(s)</div>
                        <div fxLayoutAlign="center"><mat-button-toggle (click)="echange_line($event)"><img src="../../../../../assets/img/icones/arrow-droite.png"></mat-button-toggle></div>
                        <div fxLayoutAlign="center"><mat-button-toggle (click)="echange_line_2($event)"><img src="../../../../../assets/img/icones/arrow-gauche.png"></mat-button-toggle></div>
                        <div fxLayoutAlign="center" class="fleche txt_center echange">Ajouter syndic(s)</div>
                    </div>
                </div>

                <!-- IMMEUBLES DISPONIBLES-->

                <!-- TITRES DU TABLEAU-->

                <div fxFlex="40%" fxFlex.xs="100%" fxFlex.sm="100%">
                    <div class="title_droits full-width title_tab">Syndics disponibles</div>
                    <div class="padding-card padding-card-list">
                        <div fxLayout="row wrap" class="padd_list" style="width: 100%;">
                            <div fxFlex="40"><img src="../../../../../assets/img/icones/user.png"></div>
                            <div fxFlex="40">Nom</div>
                            <div fxFlex="20">Code Postal</div>
                        </div>

                        <div fxLayout="row wrap" *ngFor="let disponible of listSyndicsDisponibles">
                            <div fxFlex="100">
                                <div *ngIf="disponible?.id" id="{{ disponible.id }}" class="colonne_droite droits padd_list">
                                    <div fxLayout="row wrap" (click)="color_line($event)" class="pointer">
                                        <div fxFlex="40">
                                            <div mat-card-avatar class="icon_list" [ngStyle]="{ 'background-position': 'center', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-image': 'url(' + disponible.image + ')'}"></div>
                                        </div>
                                        <div fxFlex="40" class="list_height">{{disponible.nom_syndic}}</div>
                                        <div fxFlex="20" class="list_height">{{disponible.codePostal}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
</span>
