<div fxLayout="row wrap" class="padding-card padding-card-pos">
    <mat-card fxFlex="95">
        <mat-card-content class="remove-border-bottom">
            <mat-tab-group [selectedIndex]="index">

<!------------------- TAB 1 EVALUTATION -------------------------->

                <mat-tab label="Evalutation">

                    <!-- IMMEUBLE -->

                    <div fxLayout="row wrap" class="marge_row">
                      <span fxFlex="100" fxLayoutAlign="center" class="title res_name marge25 data_dynamic"><b>Mes Evaluations</b></span>

                        <div fxFlex="100" class="padding-card padding-card-pos-search">
                            <mat-card-content>
                                <mat-form-field fxLayoutAlign="left center" class="input_search" appearance="outline">
                                    <mat-label>N° immeuble, rue ...</mat-label>
                                    <input matInput [(ngModel)]="searchVal" (keyup)="searchEngine()"/>
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                            </mat-card-content>
                        </div>


                        <div routerLink="../evaluation-prestataires/{{detail.id_el}}" id={{detail.id_el}} fxLayoutAlign.xs="center" fxLayoutAlign.sm="center" fxFlex="33" fxFlex.xs="100" fxFlex.md="33" fxFlex.sm="50" fxFlex.lg="33" fxFlex.gt-lg="25" style="padding:5px; margin-bottom: 45px;" class="padding-card pointer" *ngFor="let detail of resultSearch; let i = index">
                            <mat-card fxFlex="100" class="remove_border" (click)="sendIdToChild(detail.id_el)">
                                <div class="ic_right"><mat-button-toggle (click)="onEvent($event)"  (click)="confirmation($event)" class="ic_croix"><img src="../../../../../assets/img/icones/croix.png"></mat-button-toggle></div>
                                <div class="ic_right"><mat-button-toggle (click)="onEvent($event)"  class="ic_cloche ic_cloche_pos"><img src="../../../../../assets/img/icones/cloche.png"></mat-button-toggle></div>
                                <mat-card-content class="content-140 contentxs font-normal">
                                    <div fxLayoutAlign="center">
                                        <div width="70%" class="max_width img_virtuelle res_img" [ngStyle]="{ 'background-size': 'cover', 'background-image': 'url(' + detail.src_img + ')'}"></div>
                                    </div>
                                    <div fxLayout="row wrap" fxLayoutAlign="center" class="marge25">
                                        <div fxFlex="100" fxLayoutAlign="center" class="title res_name marge25 data_dynamic"><b>{{ detail.name }}</b></div>
                                        <div fxFlex="100" fxLayoutAlign="center">
                                            <div class="res_adresse adresse">
                                                <div><span class="data_dynamic">{{detail.address}}</span> </div>
                                                <div><span class="data_dynamic">{{detail.codePostal}} {{detail.ville}}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div fxLayout="row wrap" fxLayoutAlign="center" class="icon_pos">
                                        <div fxFlex="70">
                                            <div fxLayoutAlign="center" class="icon_height"><img src="../../../../../assets/img/icones/recherche.jpg"></div>
                                            <div class="txt_marge txt_medium data_dynamic" fxLayoutAlign="center">{{detail?.nbEval}}</div>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                </mat-tab>

<!------------------- TAB 2 DEVIS -------------------------->

                <mat-tab label="Devis">

                    <!-- IMMEUBLE -->

                    <div fxLayout="row wrap" class="marge_row">
                      <span fxFlex="100" fxLayoutAlign="center" class="title res_name marge25 data_dynamic"><b>Mes Devis</b></span>

                        <div fxFlex="100" class="padding-card padding-card-pos-search">
                            <mat-card-content>
                                <mat-form-field fxLayoutAlign="left center" class="input_search" appearance="outline">
                                    <mat-label>N° immeuble, rue ...</mat-label>
                                    <input matInput [(ngModel)]="searchVal2" (keyup)="searchEngine2()"/>
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                            </mat-card-content>
                        </div>

                        <div routerLink="../devis-prestataires/{{detail2.id_el}}" id={{detail2.id_el}} fxLayoutAlign.xs="center" fxLayoutAlign.sm="center" fxFlex="33" fxFlex.xs="100" fxFlex.md="33" fxFlex.sm="50" fxFlex.lg="33" fxFlex.gt-lg="25" style="padding:5px; margin-bottom: 45px;" class="padding-card pointer" *ngFor="let detail2 of resultSearch2; let i = index">
                            <mat-card fxFlex="100" class="remove_border" (click)="sendIdToChild(detail2.id_el)">
                                <div class="ic_right"><mat-button-toggle (click)="onEvent($event)" (click)="confirmation2($event)" class="ic_croix"><img src="../../../../../assets/img/icones/croix.png"></mat-button-toggle></div>
                                <div class="ic_right"><mat-button-toggle (click)="onEvent($event)" class="ic_cloche ic_cloche_pos"><img src="../../../../../assets/img/icones/cloche.png"></mat-button-toggle></div>
                                <mat-card-content class="content-140 contentxs font-normal">
                                    <div fxLayoutAlign="center">
                                        <div width="70%" class="max_width img_virtuelle res_img" [ngStyle]="{ 'background-size': 'cover', 'background-image': 'url(' + detail2.src_img + ')'}"></div>
                                    </div>
                                    <div fxLayout="row wrap" fxLayoutAlign="center" class="marge25">
                                        <div fxFlex="100" fxLayoutAlign="center" class="title res_name marge25 data_dynamic"><b>{{ detail2.name }}</b></div>
                                        <div fxFlex="100" fxLayoutAlign="center">
                                            <div class="res_adresse adresse">
                                                <div><span class="data_dynamic">{{ detail2.address }}</span> </div>
                                                <div><span class="data_dynamic">{{detail2.codePostal}} {{ detail2.ville }}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div fxLayout="row wrap" fxLayoutAlign="center" class="icon_pos">
                                        <div fxFlex="70">
                                            <div fxLayoutAlign="center" class="icon_height"><img src="../../../../../assets/img/icones/calcule.jpg"></div>
                                            <div class="txt_marge txt_medium data_dynamic" fxLayoutAlign="center">{{detail2?.nbDevis}}</div>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
    </mat-card>
</div>
