import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataService } from "../../../../data.service";

export interface SectionCopro {
  src_img   : string;
  address   : string;
  codePostal: string;
  ville     : string;
  surface   : number;
  id_select : string;
  reference : string;
  compagnie : {
    assurance : string,
    echeance  : string
  }
}

@Component({
  selector: 'app-mes-courtiers-selection',
  templateUrl: './mes-courtiers-selection.component.html',
  styleUrls: ['./mes-courtiers-selection.component.scss']
})

export class MesCourtiersSelectionComponent implements OnInit {

  selected = 'option2';

  searchVal: string;
  resultSearch = [];
  listCopro:SectionCopro [] = [];

  constructor(public DataService: DataService, private activatedRoute: ActivatedRoute) {


    this.activatedRoute.params.subscribe(params => {
      let id = params.id;
      this.DataService.api_get_copro(
        (data) => {
          console.log(data.parc)
            for (let key in data.parc) {
              if (
                (!data.parc[key].courtier && id === '0') ||
                (data.parc[key].courtier && data.parc[key].courtier._id === id)
              )
                this.listCopro.push({
                  src_img   : data.parc[key].batiments[0]?.image?.facadeRue[0]?`https://copro-back.cantem.fr/uploads/batiment/${data.parc[key].batiments[0]?.image?.facadeRue[0]}`:'../../../../../assets/img/photo/immeuble-base.jpg',
                  address   : data.parc[key].address,
                  codePostal: data.parc[key].codePostal,
                  ville     : data.parc[key].ville,
                  surface   : data.parc[key].surface,
                  id_select : data.parc[key]._id,
                  reference : data.parc[key].reference,
                  compagnie : {
                    assurance : data.parc[key].compagnie.assurance,
                    echeance  : data.parc[key].compagnie.echeance
                  }
                });
            }
          },
        (error) => {
          console.log(error)
        });

      this.DataService.api_get_encourslist(
        (data) => {
          console.log(data.enCours)
          for (let key in data.enCours) {
            if (
              (!data.enCours[key].courtier && id === '0') ||
              (data.enCours[key].courtier && data.enCours[key].courtier === id)
            )
              this.listCopro.push({
                src_img   : data.enCours[key].batiments[0]?.image?.facadeRue[0]?`https://copro-back.cantem.fr/uploads/batiment/${data.enCours[key].batiments[0]?.image?.facadeRue[0]}`:'../../../../../assets/img/photo/immeuble-base.jpg',
                address   : data.enCours[key].address,
                codePostal: data.enCours[key].codePostal,
                ville     : data.enCours[key].ville,
                surface   : data.enCours[key].surface,
                id_select : data.enCours[key]._id,
                reference : data.enCours[key].reference,
                compagnie : {
                  assurance : data.enCours[key].compagnie.assurance,
                  echeance  : data.enCours[key].compagnie.echeance
                }
              })
          }
        },
        (error) => {
          console.log(error)
        });

      this.resultSearch = this.listCopro;
    });
  }

  ngOnInit(): void {
  }

  searchEngine() {
    this.resultSearch = [];
    //if(this.searchVal)
    for(var key in this.listCopro) {
      if(this.listCopro[key].reference.toLowerCase().indexOf(this.searchVal.toLowerCase())>-1 || this.listCopro[key].address.toLowerCase().indexOf(this.searchVal.toLowerCase())>-1 || this.listCopro[key].codePostal.toLowerCase().indexOf(this.searchVal.toLowerCase())>-1){
        this.resultSearch.push(this.listCopro[key]);
      }
    }
  }
}
