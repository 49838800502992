<!-- Profil standard -->

<div *ngIf="getAccountType() == 'pcs'" fxLayout="row wrap" class="padding-card padding-card-pos">
  <div fxFlex="100" fxLayoutAlign="start" class="title margetop15">
    <div fxLayout="row wrap">
      <div fxFlex="100" class="margetop15">Changer l'email du compte</div>
      <div fxFlex="100" class="margetop15">
        <input class="box_shadow input_profil" type="text" placeholder="Email" [(ngModel)]="pcsEmail">
        <div class="data_dynamic_done" style="color: red; text-align: left; margin-top: 1px;" *ngIf="errType === 'pcsEmail'">{{errMessage}}</div>
      </div>
      <div fxFlex="100" class="margetop15">Changer le mot de passe</div>
      <div fxFlex="100" class="margetop15">
        <input class="box_shadow input_profil" type="text" placeholder="Mot de passe" [(ngModel)]="pcsPassword">
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </div>

      <mat-button-toggle class="btn top15 btn-txt" value="underline" (click)="updateAccessPCS()">Enregistrer</mat-button-toggle>
    </div>
  </div>
</div>

<div *ngIf="getAccountType() == 'gestionnaire'" fxLayout="row wrap" class="padding-card padding-card-pos">
    <div fxFlex="100" fxLayoutAlign="start" class="title margetop15">
        <div fxLayout="row wrap">
            <div fxFlex="100" class="margetop15">Changer l'email du compte</div>
            <div fxFlex="100" class="margetop15">
              <input class="box_shadow input_profil" type="text" placeholder="Email" [(ngModel)]="gestEmail">
              <div class="data_dynamic_done" style="color: red; text-align: left; margin-top: 1px;" *ngIf="errType === 'gestEmail'">{{errMessage}}</div>
            </div>
            <div fxFlex="100" class="margetop15">Changer le mot de passe</div>
            <div fxFlex="100" class="margetop15">
              <input class="box_shadow input_profil" type="text" placeholder="Mot de passe" [(ngModel)]="gestPassword">
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
            </div>

            <mat-button-toggle class="btn top15 btn-txt" value="underline" (click)="updateAccessGest()">Enregistrer</mat-button-toggle>
        </div>
    </div>
</div>

<div *ngIf="getAccountType() == 'syndic'" fxLayout="row wrap" class="padding-card padding-card-pos">
    <div fxFlex="100" fxLayoutAlign="start" class="title margetop15">
        <div fxLayout="row wrap">
            <div fxFlex="100" class="margetop15">Changer l'email du compte</div>
            <div fxFlex="100" class="margetop15">
              <input class="box_shadow input_profil" type="text" placeholder="Email" [(ngModel)]="syndEmail">
              <div class="data_dynamic_done" style="color: red; text-align: left; margin-top: 1px;" *ngIf="errType === 'syndEmail'">{{errMessage}}</div>
            </div>
            <div fxFlex="100" class="margetop15">Changer le mot de passe</div>
            <div fxFlex="100" class="margetop15">
              <input class="box_shadow input_profil" [type]="hide ? 'password' : 'text'" placeholder="Mot de passe" [(ngModel)]="syndPassword">
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
            </div>

            <mat-button-toggle class="btn top15 btn-txt" value="underline" (click)="updateAccessSynd()">Enregistrer</mat-button-toggle>
        </div>
    </div>
</div>

<div *ngIf="getAccountType() == 'courtier'" fxLayout="row wrap" class="padding-card padding-card-pos">
    <div fxFlex="100" fxLayoutAlign="start" class="title margetop15">
        <div fxLayout="row wrap">
            <div fxFlex="100" class="margetop15">Changer l'email du compte</div>
            <div fxFlex="100" class="margetop15">
              <input class="box_shadow input_profil" type="text" placeholder="Email" [(ngModel)]="courtEmail">
              <div class="data_dynamic_done" style="color: red; text-align: left; margin-top: 1px;" *ngIf="errType === 'courtEmail'">{{errMessage}}</div>
            </div>
            <div fxFlex="100" class="margetop15">Changer le mot de passe</div>
            <div fxFlex="100" class="margetop15">
              <input class="box_shadow input_profil" [type]="hide ? 'password' : 'text'" placeholder="Mot de passe" [(ngModel)]="courtPassword">
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
            </div>

            <mat-button-toggle fxLayoutAlign="start" class="btn top15 btn-txt" value="underline" (click)="updateAccessCourt()">Enregistrer</mat-button-toggle>
        </div>
    </div>
</div>

<!-- Architectes -->

<div *ngIf="getAccountType() == 'architecte'" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center" class="padding-card padding-card-pos" style="margin-bottom: 50px;">
    <div fxFlex="100" style="text-align: center;"><mat-card-title>Mon compte</mat-card-title></div>
    <mat-card class="resultat_abo" fxFlex="90" style="margin-top: 5%;">
      <div class="padding-card padding-card-pos">
        <div fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayoutAlign="left" class="title margetop15">
          <div fxLayout="row wrap">
            <div fxFlex="100">Changer l'email du compte</div>
            <div fxFlex="100" class="margetop15">
              <input class="box_shadow input_profil" type="email" placeholder="Email" [(ngModel)]="archiEmail">
              <div class="data_dynamic_done" style="color: red; text-align: center; margin-top: 1px;" *ngIf="errType === 'archiEmail'">{{errMessage}}</div>
            </div>
          </div>
        </div>
        <div fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayoutAlign="left" class="title margetop15">
            <div fxLayout="row wrap">
              <div fxFlex="100">Changer votre mot de passe</div>
              <div fxFlex="100" class="margetop15">
                <input class="box_shadow input_profil" [type]="hide ? 'password' : 'text'" placeholder="Mot de passe" [(ngModel)]="archiPassword">
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </div>
            </div>
        </div>

        <div fxFlex="30">
            <div style="max-width: 200px; text-align: right;">
              <mat-button-toggle fxLayoutAlign="start" class="btn top15 btn-txt modif_btn" value="underline" (click)="updateAccessArchi()">Enregistrer</mat-button-toggle>
            </div>
        </div>
      </div>
    </mat-card>
    <div fxFlex="100" style="text-align: center; margin-top: 5%; margin-bottom: 5%;">
      <mat-card>
        Période du
        <mat-form-field appearance="outline">
          <mat-label></mat-label>
          <input matInput (dateChange)="changePeriod()" [matDatepicker]="picker1" [(ngModel)]="periode_debut" dataformatas="dd/MM/yyyy">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        au
        <mat-form-field appearance="outline">
          <mat-label></mat-label>
          <input matInput (dateChange)="changePeriod()" [matDatepicker]="picker2" [(ngModel)]="periode_fin">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </mat-card>
    </div>
    <div fxFlex="50" fxFlex.xs="100" fxFlex.md="50" fxFlex.sm="50" fxFlex.lg="50" class="marge_bloc">
        <mat-card class="resultat_abo" fxFlex="90">
            <div class="padding-card padding-card-pos">
                <div class="txt_profil">
                    <div fxLayoutAlign="center" class="title marge_bloc">Visites annuelles</div>
                    <div fxLayoutAlign="center">Nombre de visite : {{archiNbVisites}}</div>
                    <div fxLayoutAlign="center">Total surface en m² : {{archiSurfTot}}</div>
                    <div fxLayoutAlign="center">Nombre de désordres notés : {{archiNbDesordre}}</div>
                    <div fxLayoutAlign="center">Mes honoraires HT : {{archiHonoAnnHT}}€</div>
                </div>
            </div>
        </mat-card>
    </div>
    <div fxFlex="50" fxFlex.xs="100" fxFlex.md="50" fxFlex.sm="50" fxFlex.lg="50" class="marge_bloc">
        <mat-card class="resultat_abo" fxFlex="90">
            <div class="padding-card padding-card-pos">
                <div fxLayoutAlign="center" class="title marge_bloc">Visites avis travaux</div>
                <div class="txt_profil">
                    <div fxLayoutAlign="center">Nombre de visite : {{archiNbAvis}}</div>
                    <div fxLayoutAlign="center">Mes honoraires HT : {{archiHonoAvisHT}}€</div>
                </div>
            </div>
        </mat-card>
    </div>
    <div fxFlex="100" fxFlex.xs="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.lg="100" class="marge_bloc">
      <mat-card class="resultat_abo" fxFlex="95">
        <div class="padding-card padding-card-pos">
          <div fxLayoutAlign="center" class="title marge_bloc">Total HT</div>
          <div class="txt_profil">
            <div fxLayoutAlign="center" class="marge30bot txt_total">{{archiHonoAnnHT + archiHonoAvisHT}}€</div>
          </div>
        </div>
      </mat-card>
    </div>
    <div fxFlex="100" fxFlex.xs="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.lg="100" class="marge_bloc">
        <mat-card class="resultat_abo" fxFlex="95">
            <!--mat-card-title class="title title-pos" fxLayoutAlign="center">Zones d'intervention</mat-card-title>
            <mat-card-content class="title-pos" fxLayout="row wrap">
              <div></div>
              <div fxFlex="10" fxFlex.xs="100" fxFlex.sm="100" fxLayoutAlign="center" class="etatCheckbox" style="border: 1px solid gray; margin: 2px; padding: 2px; border-radius: 10%;" *ngFor="let detail of archiZoneInter; let i = index;">
                {{detail}}
                <button mat-icon-button matSuffix (click)="modifyZoneInter(i, 'del')">
                  <mat-icon style="color: red;">delete_forever</mat-icon>
                </button>
              </div>
              <div fxFlex="100" class="modif_btn marge_bloc">
                <mat-button-toggle class="btn btn-margin btn-txt" value="underline" (click)="updateCorpsEtasPrest()">Enregistrer</mat-button-toggle>
              </div>
            </mat-card-content-->
          <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="pad15R" fxLayoutAlign="center">
            <mat-card-title class="title title-pos" fxLayoutAlign="center">Zones d'intervention</mat-card-title>
            <mat-form-field class="example-chip-list input350" appearance="outline">
              <mat-chip-list #chipList aria-label="CodePostal selection">
                <mat-chip *ngFor="let codepostal of archiZoneInter" [selectable]="selectable"
                          [removable]="removable" (removed)="remove(codepostal)">
                  {{codepostal.name}}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input [(ngModel)]="zoneInter" [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)" minlength="5" maxlength="5" placeholder="saisissez le ou les codes postaux..."
                       [matChipInputFor]="chipList"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                       [matChipInputAddOnBlur]="addOnBlur"
                       (matChipInputTokenEnd)="add($event)">
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div fxFlex="100" class="modif_btn marge_bloc">
            <mat-button-toggle class="btn btn-margin btn-txt" value="underline" (click)="updateInfosArchi()">Enregistrer</mat-button-toggle>
          </div>
            <!--div class="padding-card padding-card-pos">
                <div fxLayoutAlign="center" class="title marge_bloc">Zones d'intervention</div>
                <div class="txt_profil" *ngFor="let detail of archiZoneInter">
                    <div fxLayoutAlign="left center">
                      {{detail}}
                      <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>delete_forever</mat-icon>
                      </button>
                    </div>
                </div>
            </div-->
        </mat-card>
    </div>
</div>

<!-- Prestataires -->

<div *ngIf="getAccountType() == 'prestataire'">
    <div fxLayout="row wrap" class="padding-card padding-card-pos-archi">
      <div fxFlex="100">
        <mat-card fxFlex="100" class="resultat_abo">
          <!--div fxFlex="100" class="title margetop15">
              <div fxLayout="row wrap">
                  <div fxFlex="100">Changer l'image du profil</div>
                  <div fxFlex="100">
                      <mat-form-field class="icon_up">
                          <ngx-mat-file-input  placeholder="Image de profil" [formControl]="fileControl" [multiple]="multiple" [accept]="accept" [color]="color">
                          </ngx-mat-file-input>
                      </mat-form-field>
                  </div>
              </div>
          </div-->
          <div fxLayout="row wrap" class="pos_input">
            <div fxFlex="50" fxFlex.xs="100" fxFlex.sm="100">
              <div fxFlex="90" class="title-pos">
                <div>
                  <div fxLayoutAlign="left center" class="input_titre">Modifier email du compte</div>
                  <input class="border-input" placeholder="Email" type="text" [(ngModel)]="prestEmail"/>
                  <div class="data_dynamic_done" style="color: red; text-align: center; margin-top: 1px;" *ngIf="errType === 'prestEmail'">{{errMessage}}</div>
                </div>
              </div>
            </div>
            <div fxFlex="50" fxFlex.xs="100" fxFlex.sm="100">
              <div fxFlex="90" class="title-pos">
                <div>
                  <div fxLayoutAlign="left center" class="input_titre">Modifier votre mot de passe</div>
                  <input class="border-input" placeholder="Mot de passe" [type]="hide ? 'password' : 'text'" [(ngModel)]="prestPassword"/>
                  <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <div fxFlex="100" class="modif_btn marge_bloc">
              <mat-button-toggle class="btn btn-margin btn-txt" value="underline" (click)="updateAccessPrest()">Enregistrer</mat-button-toggle>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
    <div fxLayout="row wrap" class="padding-card padding-card-pos-archi">
        <div fxFlex="100">
            <mat-card fxFlex="100" class="resultat_abo">
                <div fxLayout="row wrap" class="pos_input">
                    <div fxFlex="50" fxFlex.xs="100" fxFlex.sm="100">
                        <div fxFlex="90" class="title-pos">
                            <div>
                                <div fxLayoutAlign="left center" class="input_titre">Nom/raison sociale de l'entreprise :</div>
                                <input class="border-input" [(ngModel)]="prestCompany"/>
                            </div>
                            <div>
                                <div fxLayoutAlign="left center" class="input_titre">Adresse (n° et nom de rue) :</div>
                                <input class="border-input" [(ngModel)]="prestAddress"/>
                            </div>
                            <div>
                                <div fxLayoutAlign="left center" class="input_titre">Code postal :</div>
                                <input class="border-input" [(ngModel)]="prestCodePostal"/>
                            </div>
                            <div>
                                <div fxLayoutAlign="left center" class="input_titre">Ville :</div>
                                <input class="border-input" [(ngModel)]="prestVille"/>
                            </div>
                            <div>
                                <div fxLayoutAlign="left center" class="input_titre">Siret :</div>
                                <input class="border-input" [(ngModel)]="prestSiret"/>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="50" fxFlex.xs="100" fxFlex.sm="100">
                        <div fxFlex="90" class="title-pos">
                            <div>
                                <div fxLayoutAlign="left center" class="input_titre">Nom Représentant:</div>
                                <input class="border-input" [(ngModel)]="prestRepresentantLastName"/>
                            </div>
                            <div>
                                <div fxLayoutAlign="left center" class="input_titre">Prénom Représentant:</div>
                                <input class="border-input" [(ngModel)]="prestRepresentantFirstName"/>
                            </div>
                            <div>
                                <div fxLayoutAlign="left center" class="input_titre">Telephone :</div>
                                <input class="border-input" [(ngModel)]="prestRepresentantPhone"/>
                            </div>
                            <div>
                                <div fxLayoutAlign="left center" class="input_titre">Email</div>
                                <input class="border-input" [(ngModel)]="prestRepresentantEmail"/>
                            </div>
                            <div>
                              <div fxLayoutAlign="left center" class="input_titre">Nombre de salariés :</div>
                              <input class="border-input" [(ngModel)]="prestNbSalaries"/>
                            </div>
                            <div>
                              <div fxLayoutAlign="left center" class="input_titre"></div>
                              <mat-button-toggle class="btn btn-margin btn-txt" value="underline" (click)="updateInfosPrest()">Enregistrer</mat-button-toggle>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
        <div fxLayout="row wrap" class="padding-card padding-card-pos-archi">
          <div fxFlex="100">
            <mat-card fxFlex="100" class="resultat_abo">
              <mat-card-title class="title title-pos">Corps d'états</mat-card-title>
              <mat-card-content class="title-pos" fxLayout="row wrap">
                <div fxFlex="33" fxFlex.xs="100" fxFlex.sm="50" class="etatCheckbox" *ngFor="let etats of etatsArray">
                  <mat-checkbox (change)="modifyCorpsEtat($event)" name="{{etats}}" [checked]="includesEtat(etats)"> {{ etats }} </mat-checkbox>
                </div>
                <div fxFlex="100" class="modif_btn marge_bloc">
                  <mat-button-toggle class="btn btn-margin btn-txt" value="underline" (click)="updateCorpsEtasPrest()">Enregistrer</mat-button-toggle>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>

        <div fxFlex="100">
          <mat-card fxFlex="100" class="resultat_abo">
            <div fxLayout="row wrap" class="pos_input">
              <div fxFlex="50" fxFlex.xs="100" fxFlex.sm="100">
                <div fxFlex="90" class="title-pos">
                  <div class="title margebot20">RC Professionnelle</div>
                  <div fxLayout="row wrap">
                    <div fxFlex="60" fxFlex.xs="100">
                      <div>
                        <div fxLayoutAlign="left center" class="input_titre">Nom de la compagnie</div>
                        <input class="border-input" (change)="RCInput('RCPdetails', 'compagnie', $event)" [(ngModel)]="RCPdetails.compagnie" placeholder="Renseignez la compagnie d'assurance"/>
                        <div class="data_dynamic_done" style="color: red; text-align: left; margin-top: 1px;" *ngIf="errType === 'RCPdetailsCompagnie'">{{errMessage}}</div>
                      </div>
                      <div>
                        <div fxLayoutAlign="left center" class="input_titre">Echéance annuelle</div>
                        <input class="border-input" placeholder="originalRCPdetailsEcheance" (change)="RCInput('RCPdetails', 'echeanceAnnuelle', $event)" type="date" [(ngModel)]="RCPdetails.echeanceAnnuelle" placeholder="Renseignez l'échéance annuelle"/>
                        <div class="data_dynamic_done" style="color: red; text-align: left; margin-top: 1px;" *ngIf="errType === 'RCPdetailsEcheanceAnnuelle'">{{errMessage}}</div>
                      </div>
                    </div>
                    <div fxFlex="40" fxFlex.xs="100" height="100%">
                      <div fxLayout="row wrap" fxFlexFill fxLayoutAlign="center center" class="attestation" *ngIf="prestRCPfile">
                        <div fxLayoutAlign="center" fxFlex="100" class="margebot20">Attestation en cours</div>
                        <div fxLayoutAlign="center" fxFlex="100"><a mat-button-toggle target="_blank" href="{{'https://copro-back.cantem.fr/uploads/RC-files/' +  prestRCPfile}}"><img src="../../../assets/img/icones/upload.png"></a></div>
                        <div fxLayoutAlign="center" fxFlex="100" class="margetop15">{{originalRCPdetailsEcheance}}</div>
                      </div>
                    </div>
                    <div fxFlex="40" fxFlex.xs="100" height="100%">
                      <br/>
                      <div fxLayout="row wrap" fxFlexFill fxLayoutAlign="center center" class="attestation">
                        <!--div fxLayoutAlign="center" fxFlex="100" >Uploader nouvelle attestation</div-->
                        <form fxFlex="100"   [formGroup]="formProfessionnelle" class="infixUpload" enctype="multipart/form-data" style="display: flex; flex-direction: row;">
                          <mat-form-field class="icon_up"  (click)="inputRcPro.openFilePicker()" style="cursor: pointer;" >
                            <ngx-mat-file-input
                              #inputRcPro
                              class="icon_download_attest"
                              placeholder="Enregistrer nouvelle attestation"
                              formControlName="rcProfessionnelle"
                              [color]="color"
                              style="cursor: pointer;"
                            >
                            </ngx-mat-file-input>
                            <mat-icon class="yellowCopro" style="z-index: 3;font-size: 24px;" matSuffix>attach_file</mat-icon>
                          </mat-form-field>
                          <div class="data_dynamic_done" style="color: red; text-align: left; margin-top: 1px;" *ngIf="errType === 'rcProfessionnelle'">{{errMessage}}</div>
                        </form>
                        <div fxFlex="100" class="modif_btn marge_bloc">
                          <mat-button-toggle class="littleButton" (click)="updateResponsability('rcProfessionnelle')" class="btn btn-margin btn-txt" value="underline">Enregistrer</mat-button-toggle>
                        </div>
                        <div class="data_dynamic_done" style="color: red; text-align: left; margin-top: 1px;" *ngIf="errType === 'errRCP'">{{errMessage}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div fxFlex="50" fxFlex.xs="100" fxFlex.sm="100">
                <div fxFlex="90" class="title-pos">
                  <div class="title margebot20">RC Décennale</div>
                  <div fxLayout="row wrap">
                    <div fxFlex="60" fxFlex.xs="100">
                      <div>
                        <div fxLayoutAlign="left center" class="input_titre">Nom de la compagnie</div>
                        <input class="border-input" (change)="RCInput('RCDdetails', 'compagnie', $event)" [(ngModel)]="RCDdetails.compagnie" placeholder="Renseignez la compagnie d'assurance"/>
                        <div class="data_dynamic_done" style="color: red; text-align: left; margin-top: 1px;" *ngIf="errType === 'RCDdetailsCompagnie'">{{errMessage}}</div>
                      </div>
                      <div>
                        <div fxLayoutAlign="left center" class="input_titre">Echéance annuelle</div>
                        <input class="border-input" (change)="RCInput('RCDdetails', 'echeanceAnnuelle', $event)" type="date" [(ngModel)]="RCDdetails.echeanceAnnuelle" placeholder="Renseignez l'échéance annuelle"/>
                        <div class="data_dynamic_done" style="color: red; text-align: left; margin-top: 1px;" *ngIf="errType === 'RCDdetailsEcheanceAnnuelle'">{{errMessage}}</div>
                      </div>
                    </div>
                    <div fxFlex="40" fxFlex.xs="100" height="100%">
                      <div fxLayout="row wrap" fxFlexFill fxLayoutAlign="center center" class="attestation" *ngIf="prestRCDfile">
                        <div fxLayoutAlign="center" fxFlex="100" class="margebot20">Attestation en cours</div>
                        <div fxLayoutAlign="center" fxFlex="100"><a mat-button-toggle target="_blank" href="{{'https://copro-back.cantem.fr/uploads/RC-files/' +  prestRCDfile}}"><img src="../../../assets/img/icones/upload.png"></a></div>
                        <div fxLayoutAlign="center" fxFlex="100" class="margetop15">{{originalRCDdetailsEcheance}}</div>
                      </div>
                    </div>
                    <div fxFlex="40" fxFlex.xs="100" height="100%">
                      <br/>
                      <div fxLayout="row wrap" fxFlexFill fxLayoutAlign="center center" class="attestation">
                        <!--div fxLayoutAlign="center" fxFlex="100" >Uploader nouvelle attestation</div-->
                        <form fxFlex="100"  [formGroup]="formDecennal" class="infixUpload" enctype="multipart/form-data" style="display: flex; flex-direction: row;">
                          <mat-form-field class="icon_up" (click)="inputRcDec.openFilePicker()" style="cursor: pointer;">
                            <ngx-mat-file-input
                              #inputRcDec
                              class="icon_download_attest"
                              placeholder="Enregistrer nouvelle attestation"
                              formControlName="rcDecennal"
                              [color]="color">
                            </ngx-mat-file-input>
                            <mat-icon class="yellowCopro" style="z-index: 3;font-size: 24px;" matSuffix>attach_file</mat-icon>
                          </mat-form-field>
                          <div class="data_dynamic_done" style="color: red; text-align: left; margin-top: 1px;" *ngIf="errType === 'RCDecennale'">{{errMessage}}</div>
                        </form>
                        <div fxFlex="100" class="modif_btn marge_bloc">
                          <mat-button-toggle class="littleButton" (click)="updateResponsability('rcDecennale')" class="btn btn-margin btn-txt" value="underline">Enregistrer</mat-button-toggle>
                        </div>
                        <div class="data_dynamic_done" style="color: red; text-align: left; margin-top: 1px;" *ngIf="errType === 'errRCD'">{{errMessage}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
    </div>
</div>
