<div class="padding-card padding-card-pos">
    <mat-card>
        <mat-card-title class="title title-pos">Ajout un parc d'immeubles</mat-card-title>
        <mat-card-content>

            <form [formGroup]="form" enctype="multipart/form-data">
                <mat-form-field appearance="standard" class="iconWidth">
                    <mat-label>Choisissez un fichier (.xls ou .xlsx)</mat-label>
                    <ngx-mat-file-input formControlName="file" [(ngModel)]="data" [accept]="accept">
                        <mat-icon class="iconFile" ngxMatFileInputIcon>folder_open</mat-icon>
                    </ngx-mat-file-input>
                </mat-form-field>
                <div fxLayout="row wrap" fxLayoutAlign="start">
                    <mat-button-toggle (click)="addParc(data)" type="submit" class="btn">Ajouter un parc d'immeuble</mat-button-toggle>
                </div>
            </form>
            
        </mat-card-content>
    </mat-card> 
</div>