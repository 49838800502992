<div fxLayout="row wrap">
    <div fxLayoutAlign.xs="center" fxFlex="33" fxFlex.xs="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.lg="40" class="padding-card padding-card-pos">
        <mat-button-toggle routerLink="../ajouter-un-immeuble"class="btn-shadow">
            <div fxLayout="row wrap" fxFlex="100" class="btn_add_padd">
                <div fxFlex="20" fxLayoutAlign="left center"><mat-icon class="icon_add_parc">add_box</mat-icon></div>
                <div fxFlex="80" fxLayoutAlign="left center"><span class="add-imm">Ajouter un immeuble</span></div>
            </div>
        </mat-button-toggle>
    </div>

    <div fxLayoutAlign.xs="center" fxFlex="66" fxFlex.xs="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.lg="60" class="padding-card padding-card-pos">
        <mat-card-content fxFlex="94">
            <mat-form-field fxLayoutAlign="right center" class="input_search" appearance="outline">
                <mat-label>N° immeuble, rue...</mat-label>
                <input matInput [(ngModel)]="searchVal" (keyup)="searchEngine()"/>
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </mat-card-content>
    </div>
</div>

<!-- IMMEUBLE -->

<div fxLayout="row wrap">
    <div fxFlex="70" fxFlex.sm="50" fxFlex.xs="100">
        <div fxLayout="row wrap">
            <div fxLayoutAlign.xs="center" fxLayoutAlign.sm="center" fxFlex="50" fxFlex.xs="100" fxFlex.md="50" fxFlex.sm="100" fxFlex.lg="50" fxFlex.gt-lg="33" class="padding-card padding-card-pos" *ngFor="let detail of resultSearch">
                <mat-card fxFlex="100">
                    <div class="ic_right"><mat-button-toggle routerLink="/home" class="ic_croix"><img src="../../../../assets/img/icones/croix.jpg"></mat-button-toggle></div>
                    <div class="ic_right"><mat-button-toggle routerLink="/home" class="ic_cloche"><img src="../../../../assets/img/icones/cloche.jpg"></mat-button-toggle></div>
                    <mat-card-content class="content-140 contentxs res_font">
                        <div fxLayoutAlign="center">
                            <div class="img_virtuelle res_img max_width" [ngStyle]="{ 'background-size': 'cover', 'background-image': 'url(' + detail.src_img + ')'}"></div>
                        </div>
                        <div fxLayout="row wrap" fxLayoutAlign="center">
                            <div fxFlex="70" fxFlex.xs="65" fxFlex.md="70" fxFlex.xs="51">
                                <div class="adress_line">
                                    <div><span class="data_dynamic_done">{{detail.adresse1}}</span> </div>
                                    <div><span class="data_dynamic_done">Adresse ligne 2</span></div>
                                    <div>N°<span class="data_dynamic_done resultat_marge_left">350</span></div>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="row wrap" fxLayoutAlign="center" class="icon_pos">
                            <div fxFlex="70" fxFlex.xs="65" fxFlex.md="70">
                                <div fxLayout="row wrap">
                                    <div fxFlex="25" fxFlex.xs="50">
                                        <div fxLayoutAlign="center" class="icon_height"><img src="../../../../assets/img/icones/recherche.jpg"></div>
                                        <div class="txt_marge data_dynamic_done txt_icon" fxLayoutAlign="center">10</div>
                                    </div>
                                    <div fxFlex="25" fxFlex.xs="50">
                                        <div fxLayoutAlign="center" class="icon_height"><img src="../../../../assets/img/icones/calcule.jpg"></div>
                                        <div class="txt_marge data_dynamic_done txt_icon" fxLayoutAlign="center">7</div>
                                    </div>
                                    <div fxFlex="25" fxFlex.xs="50">
                                        <div fxLayoutAlign="center" class="icon_height"><img src="../../../../assets/img/icones/doc.jpg"></div>
                                        <div class="txt_marge data_dynamic_done txt_icon" fxLayoutAlign="center">4</div>
                                    </div>
                                    <div fxFlex="25" fxFlex.xs="50">
                                        <div fxLayoutAlign="center" class="icon_height"><img src="../../../../assets/img/icones/silouette.jpg"></div>
                                        <div class="txt_marge data_dynamic_done txt_icon" fxLayoutAlign="center">FB</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
    <div fxFlex="25" fxFlex.sm="50" fxFlex.xs="100">
        <mat-card class="card_pos">
            <div fxLayout="row wrap">
                <div fxFlex="90" class="txt_visite">
                    <div>Faire la visite</div>
                    <div>
                        <div class="input_titre">Numéro interne</div>
                        <input class="border-input"/>
                    </div>
                    <div>
                        <div class="input_titre">Coordonnées PCS</div>
                        <input class="border-input"/>
                    </div>
                    <div>
                        <div class="input_titre">Code d'accès</div>
                        <input class="border-input"/>
                    </div>
                    <section class="checkpos">
                        <mat-checkbox labelPosition="before" class="checksize">Clé au cabinet ? </mat-checkbox>
                    </section>
                    <div class="marge_commentaires">
                        <div class="textarea_marge">Commentaires</div>
                        <textarea></textarea>
                    </div>
                    <div fxLayoutAlign="center" class="marge_commentaires">
                        <mat-button-toggle class="btn ">Envoyer</mat-button-toggle>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>

</div>
