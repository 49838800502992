import { Component, OnInit } from '@angular/core';
import { DataService } from "../../../../data.service";

export interface Section {
  src_img: string;
  name: string;
  id_el: string;
  abo: boolean;
}

@Component({
  selector: 'app-dashboard-abo',
  templateUrl: './dashboard-abo.component.html',
  styleUrls: ['./dashboard-abo.component.scss']
})

export class DashboardAboComponent implements OnInit {

  onEvent(event) {
    event.stopPropagation();
  }
/*
  toggle_abo(event) {
    console.log(event)
    var i
    var id = event.srcElement.parentNode.parentNode.id
    var id_show = event.srcElement.parentNode.parentNode.nextElementSibling.id
    for( i in this.listSyndics)
    {
      if(this.listSyndics[i].id_abo == id)
      {
        document.getElementById(id).style.display = 'none';
        document.getElementById(id_show).style.display = 'block';
      }
    }
  }

  toggle_desabo(event) {
    console.log(event)
    var i
    var id = event.srcElement.parentNode.parentNode.id
    var id_show = event.srcElement.parentNode.parentNode.previousElementSibling.id
    for( i in this.listSyndics)
    {
      if(this.listSyndics[i].id_desabo == id)
      {
        document.getElementById(id).style.display = 'none';
        document.getElementById(id_show).style.display = 'block';
      }
    }
  }

  confirmation(event) {
    var i : any;
    var id = event.srcElement.parentNode.parentElement.parentElement.parentElement.parentElement.parentElement.id
    if(id.length == 0) {
      id = event.srcElement.parentNode.parentElement.parentElement.parentElement.parentElement.id
    }
    if(confirm("Confirmer la suppresion ?")) {
    for( i in this.listSyndics)
      {
        if(this.listSyndics[i].id_el == id)
        {
         this.listSyndics.splice(i, 1)
        }
      }
    }
  }
*/
  resultPresta;
  searchVal: string;
  resultSearch = [];
  listSyndics:Section [] = [];
  prestaId = localStorage.getItem('id');

  constructor(public DataService: DataService) {
    this.DataService.api_get_syndic((result) => {
      for (let key in result.syndics)
        this.listSyndics[key] = {
          src_img: "../assets/img/icones/broker.png",
          name: result.syndics[key].nomSyndic,
          id_el: result.syndics[key]._id,
          abo: result.abonnements.includes(result.syndics[key]._id)
        }
    }, (error) => {
      console.log("erreur: ", error);
    })
    this.resultSearch = this.listSyndics;
  }

  ngOnInit(): void {}

  searchEngine() {
    this.resultSearch = [];
    //if(this.searchVal)
    for(var key in this.listSyndics) {
      if(this.listSyndics[key].name.toLowerCase().indexOf(this.searchVal.toLowerCase())>-1 && this.resultSearch.length<4){
        this.resultSearch.push(this.listSyndics[key]);
      }
    }
  }


}
