import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataService } from '../../../../data.service';
import {MatDialog} from '@angular/material/dialog';
import { FaireVisiteDialogComponent } from '../../../../dialog/faire-visite-dialog/faire-visite-dialog.component';
import { Router } from '@angular/router';
import {CourtierAssignDialogComponent} from "../../../../dialog/courtier-assign-dialog/courtier-assign-dialog.component";
import {GestionnaireAssignDialogComponent} from "../../../../dialog/gestionnaire-assign-dialog/gestionnaire-assign-dialog.component";


export interface Section {}

export interface SectionCourtier {
  id_el: string,
  lastName: string
  company: string
}

export interface SectionGestionnaire {
  id_el: string,
  firstName: string,
  lastName: string
}

@Component({
  selector: 'app-dashboard-mon-parc',
  templateUrl: './dashboard-mon-parc.component.html',
  styleUrls: ['./dashboard-mon-parc.component.scss']
})

export class DashboardMonParcPCSComponent implements OnInit {

  onEvent(event) {
    event.stopPropagation();
  }


  PrestatairesDetailsArray;
  searchVal: string;
  resultSearch = [];
  listCopro;

  listParc = [];

  listCourtiers: SectionCourtier [] = [];
  listGestionnaires: SectionGestionnaire [] = [];

  constructor(public DataService: DataService, public dialog: MatDialog, private router: Router) {
    this.resultSearch = this.listCopro;
  }

  info(id) {
    this.DataService.api_single_copro({_id:id},
      (data)=>{
        this.DataService.copro = data.copro
        //this.imgBat = 'https://copro-back.cantem.fr/uploads/batiment/'+this.copro.batiments[0]?.image.facadeRue[0]
        this.router.navigate(["/mon-immeuble/mon-parc-immeuble/"+id]);
      },
      (error)=>{
        console.log(error)
        this.DataService.copro = error.err.copro
      }
    );
  }

  courtier: string;
  gestionnaire: string;

  ids = [];

  ngOnInit(): void {
    this.DataService.api_get_copro(
      (data)=>{
        this.ids.push(data.copro._id);
        if (data.copro.batiments[0]) {
          this.listCopro = {
            id:data.copro._id,
            address: data.copro.address,
            imgProfil : data.copro.batiments[0],
            src_img : data.copro.batiments[0]?.image?.facadeRue[0],
            ville: data.copro.ville,
            id_el: data.copro._id,
            reference: data.copro.reference,
            codePostal: data.copro.codePostal,
            gestionnaire: data.copro.gestionnaire,
            dateDemandeVisite: data.copro.dateDemandeVisite,
            dateVisite: data.copro.dateVisite,
            visite: false,
            ajout: false,
            nbDevis: 0,
            nbEval: 0,
            nbVisite: [],
          };
        } else {
          this.listCopro = {
            id:data.copro._id,
            address: data.copro.address,
            imgProfil : data.copro.batiments[0],
            ville: data.copro.ville,
            id_el: data.copro._id,
            reference: data.copro.reference,
            codePostal: data.copro.codePostal,
            gestionnaire: data.copro.gestionnaire,
            dateDemandeVisite: data.copro.dateDemandeVisite,
            dateVisite: data.copro.dateVisite,
            visite: false,
            ajout: false,
            nbDevis: 0,
            nbEval: 0,
            nbVisite: [],
          };
        }

        this.DataService.api_get_devis_copro(
          {coproId: this.listCopro.id, option: true},
          async (data) => {
            this.listCopro.nbEval = data.list.length;
            for (let i in data.list) {
              if (data.list[i].devisPDF)
                this.listCopro.nbDevis += 1;
              if (!this.listCopro.nbVisite.includes(data.list[i].visiteId))
                this.listCopro.nbVisite.push(data.list[i].visiteId);
            }
          },
          (error) => {
            console.log(error)
          });

        //this.resultSearch.push(this.listCopro);
      },
      (error)=>{
        console.log(error)
      }
    );
  }
}
