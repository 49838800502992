import { Component, ChangeDetectionStrategy, OnInit, Injectable } from '@angular/core';
import { GalleryComponent } from '../../../../gallery/gallery.component';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from '../../../../data.service'
import { HttpClient } from '@angular/common/http';
import { Gallery, GalleryItem, ImageItem } from 'ng-gallery';
import {MajImmeubleDialogComponent} from '../../../../dialog/maj-immeuble/maj-immeuble-dialog.component';
import {MatDialog} from "@angular/material/dialog";
import {FormControl} from "@angular/forms";
import {CourtierEtudeDialogComponent} from '../../../../dialog/courtier-etude-dialog/courtier-etude-dialog.component';
import {CommentaireDialogComponent} from "../../../../dialog/commentaire-dialog/commentaire-dialog.component";


export interface SectionDevis {
  _id             : string,
  incidentId      : string,
  evaluationTTC   : number,
  devisTTC        : number,
  factureTTC      : number,
  metrages        : number,
  coproId    	    : string,
  pcsId           : string,
  syndicId        : string,
  visiteId        : string,
  courtierId      : string,
  architecteId    : string,
  prestataireId   : string,
  gestionnaireId  : string,
  commentaire     : string,
  desordre        : string,
  description     : string,
  situation       : string,
  corpsEtat       : [string],
  images          : [string],
  date            : string,
  demandeDevis    : boolean,
  devisPDF        : string,
  facturePDF      : string,
  refDesordre     : string
}

export interface SectionReception {
  numPreRecep : string;
  visiteFaitLe : string;
  logoPresta : string;
  notePresta : string;
  descriptif : string;
  montantTTCtravaux : string;
  natureTravauxRealises : string;
  conformiteTravaux : string;
  qualiteTravaux : string;
  remarques : string;
  dwlFacture : string;
  dwlDevis : string;
}

export interface SectionDescription {
  faitLe : string;
  habExclu : string;
  bureauxEclu : string;
  habPro : string;
  occupants : string;
  nbrNiveaux : string;
  caveOuiNon : string;
  caveAcces : string;
  nbrNiveauxCaves : string;
  parkingST : string;
  natureConstr : string;
  precisezConstr : string;
  etatFacadeCanal : string;
}

export interface SectionCarnet {
  images          : [string],
  date            : string,
  metrages        : number,
  desordre        : string,
  situation       : string,
  description     : string,
  corpsEtat       : [string],
  courtierId      : string,
  gestionnaireId  : string,
  architecteId    : string,
  visiteId        : string,
  syndicId        : string,
  coproId         : string,
  commentaire     : string,
  evaluationTTC   : number,
  devisTTC        : number,
  factureTTC      : number,
  devis           : [object],
  _id             : string,
  refDesordre     : string,
  notePresta      : number
}

export interface SectionAvis {
  _id             : string,
  src_img         : string,
  evaluationTTC   : number,
  metrages        : number,
  comArchi        : string,
  comPrest        : string,
  desordre        : string,
  description     : string,
  situation       : string,
  corpsEtat       : [],
  images_bf       : [],
  images_af       : [],
  date            : string,
  conformite      : boolean,
  rate            : number,
  remarque        : string,
  incidentId      : string,
  coproId    	    : string,
  pcsId           : string,
  devisId         : string,
  syndicId        : string,
  visiteId        : string,
  courtierId      : string,
  architecteId    : string,
  prestataireId   : string,
  gestionnaireId  : string,
  demandeDevis    : boolean,
  devisPDF        : string,
  dateDepotDevis  : string,
  facturePDF      : string,
  dateDepotFacture: string,
  refDesordre     : string,
  noteGlobale     : string
}

export interface SectionEtudes {
  lastName  : string,
  company   : string,
  date      : string
}

export interface SectionCourtier {
  id_el: string,
  lastName: string,
  company: string,
}

export interface SectionPresta {
  id_el: string,
  lastName: string,
  company: string,
  notePresta: number,
  corpsEtat: string[],
  incidents: string[],
  incidentId: string[]
}

@Component({
  selector: 'app-mon-parc-immeuble',
  templateUrl: './mon-parc-immeuble.component.html',
  styleUrls: ['./mon-parc-immeuble.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MonParcImmeubleComponent implements OnInit{

  accept: string = ".pdf,.PDF";

  resultCourtiers: SectionCourtier [] = [];

  resultDevis:SectionDevis [] = [];
  resultReception:SectionAvis [] = [];
  resultEvaluation:SectionDevis [] = [];
  resultPresta:SectionPresta [] = [];

  listEvaluationIds = [];
  listDevisIds = [];



  loaderSinistre = false;
  show = false;
  show2 = false;
  buttonName = 'Show';
  hide: any;

  toggle() {
    this.show = !this.show
    this.show2 = false;

    if(this.show) {
      this.buttonName = 'Hide'
    }
    else {
      this.buttonName = 'Show'
    }
  }

  toggle2() {
    this.show2 = !this.show2
    this.show = false

    if(this.show2) {
      this.buttonName = 'Hide'
    }
    else {
      this.buttonName = 'Show'
    }
  }

  toggle_info(event, com) {
    let dialogRef = this.dialog.open(CommentaireDialogComponent, {
      height: 'auto',
      width: '100%',
      maxWidth: '450px',
    });

    dialogRef.componentInstance.commentaire = com;
  }

  listCourtier: SectionCourtier[] = [];

  chooseCourtier(id, i) {
    if (!this.listCourtier.includes(id))
      this.listCourtier.push(id);
    else
      this.listCourtier.splice(i, 1);
  }

  alertLoad(message) {
    alert(message)
  }

  uploadStatSinistre(event, coproId) {
    const data = new FormData();
    this.loaderSinistre = true;
    data.append('coproId', coproId);
    data.append('files', event.target.files[0]);

    this.DataService.api('/gestion/stats-sinistres', data,
      (resp) => {
        this.loaderSinistre = false;
        setTimeout(() => {
          this.alertLoad("Stats Sinistres enregistrées");
          window.location.reload()
        }, 100)
      },
      (error) => {
        this.loaderSinistre = false;
        setTimeout(() => {
          this.alertLoad("Une erreur système est survenue");
          window.location.reload()
        }, 100)
        console.log("error: ", error);
      });
  }

  sendToEtude(coproId) {
    if (this.listCourtier.length > 0) {
      this.DataService.apiSendToEtude({coproId, courtiers: this.listCourtier},
        () => {
          this.dialog.open(CourtierEtudeDialogComponent, {
            height: 'auto',
            width: '100%',
            maxWidth: '450px',
          });
        },
        (error) => {
          console.log("error: ", error)
          alert(error.error.message);
        })
    } else {
      alert('Veuillez selectionner au moins un courtier')
    }
  }

  reference;surface;multiDevis;maxTravaux;moisAG;compagnie;echeance;courtier;
  update = {
    _id: null,
    reference: null,
    surface: null,
    multiDevis: null,
    maxTravaux: null,
    moisAG: null,
    "compagnie.assurance": null,
    "compagnie.echeance": null,
  };

  toppings = new FormControl();

  toppingList: object[];

  changeCoproInfo(reference,surface,multiDevis,maxTravaux,moisAG,compagnie,echeance) {
    this.update._id = this.copro._id;

    for (let key in this.update) {
      switch (key) {
        case 'reference': reference ? this.update[key] = reference : delete this.update[key]; break;
        case 'surface': surface ? this.update[key] = surface : delete this.update[key]; break;
        case 'multiDevis': multiDevis ? this.update[key] = multiDevis : delete this.update[key]; break;
        case 'maxTravaux': maxTravaux ? this.update[key] = maxTravaux : delete this.update[key]; break;
        case 'moisAG': moisAG ? this.update[key] = moisAG : delete this.update[key]; break;
        case 'compagnie.assurance': compagnie ? this.update[key] = compagnie : delete this.update[key]; break;
        case 'compagnie.echeance': echeance ? this.update[key] = echeance : delete this.update[key]; break;
        default: console.log('default: ', key); break;
      }
    }

    this.DataService.apiFormCoproPut('/update/copro', {update: this.update},
    (res) => {
      this.dialog.open(MajImmeubleDialogComponent, {
        height: 'auto',
        width: '100%',
        maxWidth: '450px',
      });
    },
    (error) => {
      if (error.error.message) {
        this.DataService.error= error.error.message
      }
    })
  }


  copro=this.DataService.copro;

  // resultDescription = this.DataService.copro.batiments;
  // resultEntree = this.DataService.copro.batiments[0]['entrees'];
  // resultCave = this.DataService.copro.batiments[0].cave;
  // resultCarnet = this.DataService.copro.incidentId;
  // resultCopro = this.DataService.copro
  // resultTravaux = this.resultCarnet.length
  // imgBat = 'https://copro-back.cantem.fr/uploads/batiment/'+this.copro.batiments[0].image.facadeRue[0]
  // imgInfo = this.copro.batiments[0].image.facadeRue[0]

  //resultInfo;
  _id;
  //urlImgLeft;
  imgIncident

  dataFacades = []
  dataAscenseur = []
  dataEntree = []
  dataCaves = []
  dataParking = []
  dataEnvironnement = []
  dataContiguite = []
  dataIncidents = []
  dataParcelle = []
  dataVueGG = []
  travaux = 0

/*
  itemsFacades: GalleryItem[];
  itemsAscenseur: GalleryItem[];
  itemsEntree: GalleryItem[];
  itemsCaves: GalleryItem[];
  itemsParking: GalleryItem[];
  itemsEnvironnement: GalleryItem[];
  itemsContiguite: GalleryItem[];
  itemParcelle: GalleryItem[];
  itemVueGG: GalleryItem[];
  itemsIncidents: GalleryItem[];
*/
  coproLoaded: Promise<boolean>;
  resultDescription;resultEntree;resultCave;resultCopro;resultTravaux;imgBat;imgInfo;
  resultCarnet: SectionCarnet [] = [];
  resultCarnetDevis: SectionCarnet [] = [];
  nbEval = 0;

  sendDemandeDevis(devisId, option, index) {
    option = !option;
    this.DataService.api_demande_devis(
      {devisId, option},
      (data) => {
        if (option)
          alert("demande de devis envoyée");
        else
          alert("demande de devis annulée")
        this.resultEvaluation[index].demandeDevis = option;
      },
      (error) => {
        console.log(error)
      }
    )
  }

  removeDemandeEval(prest_id, inc_id, index, list) {
    if(confirm("Voulez vous supprimer l'evaluation de ce prestataire ?")) {
      this.DataService.api_del_eval({prest_id, inc_id},
        (data) => {
          if (list === 1)
            this.resultEvaluation.splice(index, 1);
          else
            this.resultPresta.splice(index, 1);
        },
        (error) => {
          alert(error.error.message);
        });
    } else {
      console.log('aborted')
    }
  }

  removeForbidden() {
    alert("Suppression impossible car un devis a déjà été emis");
  }

  envoieEtude: SectionEtudes[] = [];

  roundVal(n) {
    return Math.ceil(n)
  }

  constructor(public gallery: Gallery,
              private http: HttpClient,
              public GalleryComponent: GalleryComponent,
              private activatedRoute: ActivatedRoute,
              public DataService: DataService,
              public dialog: MatDialog) {

    const id =  this.activatedRoute.snapshot.params['id'];

    this.DataService.api_get_devis_copro(
      {coproId: id, option: true},
      async (data) => {
        for (let key in data.list) {
          if (data.list[key].evaluationTTC && data.list[key].incidentId?._id) {
            this.nbEval += 1;
            this.listEvaluationIds.push({prestataireId: data.list[key].prestataireId._id, incidentId: data.list[key].incidentId?._id});
            this.resultEvaluation.push(data.list[key])
          } else if (!data.list[key].evaluationTTC && data.list[key].incidentId?._id) {
            //this.resultEvaluation.push(data.list[key])
          }
          if (data.list[key].devisPDF && data.list[key].incidentId?._id) {
            this.listDevisIds.push(data.list[key].incidentId._id);
            this.resultDevis.push(data.list[key]);
          }
        }
        this.DataService.api_get_prestataire(
          (data) => {
            for (let i in data.prestataires) {
              this.resultPresta[i] = {
                id_el: data.prestataires[i]._id,
                lastName: data.prestataires[i].representant.lastName,
                company: data.prestataires[i].company,
                corpsEtat: data.prestataires[i].corpsEtat,
                notePresta: Math.ceil(data.prestataires[i].noteGlobale),
                incidents: data.prestataires[i].incidentId,
                incidentId: []
              };
              for (let j in this.listEvaluationIds) {
                if (this.resultPresta[i].id_el === this.listEvaluationIds[j].prestataireId) {
                  this.resultPresta[i].incidentId.push(this.listEvaluationIds[j].incidentId);
                }
              }
            }
          },
          (error) => {
            console.log("erreur: ", error);
          }
        )
      },
      (error) => {
        console.log(error)
      }
    )

    this.DataService.api_single_copro({_id:id},
      async (data) => {
        this.copro = await data.copro;

        if (this.copro !== "undefined") {
          this.imgBat = 'https://copro-back.cantem.fr/uploads/batiment/'+this.copro.batiments[0].image.facadeRue[0]
          this.resultDescription = await this.copro.batiments;
          this.resultEntree = await this.copro.batiments[0].entrees;
          this.resultCave = await this.copro.batiments[0].cave;
          this.resultCarnet = await this.copro.incidentId;
          this.resultCopro = await this.copro;
          this.resultTravaux = this.resultCarnet.length;
          this.imgInfo = await this.copro.batiments[0].image.facadeRue[0];

          this.resultCarnetDevis = await this.copro.incidentId.filter(el => this.listDevisIds.includes(el?._id));

          this.reference = await this.copro.reference;
          this.surface = await this.copro.surface;
          this.multiDevis = await this.copro.multiDevis;
          this.maxTravaux = await this.copro.maxTravaux;
          this.moisAG = await this.copro.moisAG;
          this.compagnie = await this.copro.compagnie?.assurance?? null;
          this.echeance = await this.copro.compagnie?.echeance?? null;
          this.courtier = await this.copro.courtier?.lastName?? 'Sans Courtier';

          this.dataParcelle = [{
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.ParcelleCadastrale[0]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.ParcelleCadastrale[0]),
            }];
          this.dataVueGG = [
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.VueGenGoogle[0]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.VueGenGoogle[0]),
            }
          ];
          this.dataFacades = [
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[0]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[0]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[1]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[1]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[2]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[2]),
            },
          ];
          this.dataAscenseur= [
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[0]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[0]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[1]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[1]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[2]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[2]),
            },
          ];
          this.dataEntree= [
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[0]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[0]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[1]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[1]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[2]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[2]),
            },
          ];
          this.dataCaves= [
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[0]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[0]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[1]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[1]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[2]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[2]),
            },
          ];
          this.dataParking= [
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[0]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[0]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[1]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[1]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[2]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[2]),
            },
          ];
          this.dataEnvironnement = [
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[0]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[0]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[1]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[1]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[2]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[2]),
            },
          ];
          this.dataContiguite = [
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[0]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[0]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[1]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[1]),
            },
            {
              srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[2]),
              previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[2]),
            },
          ];
        }

        this.DataService.api_get_all_receptions({coproId: id},
          (data) => {
            for (let key in data.receptions) {
              this.resultReception[key] = data.receptions[key];
              this.resultReception[key].noteGlobale = Math.ceil(data.receptions[key].prestataireId.noteGlobale);
              this.travaux += data.receptions[key].factureTTC;
              this.resultCarnet = this.resultCarnet.filter(c => c._id !== data.receptions[key].incidentId);
              this.resultCarnetDevis = this.resultCarnetDevis.filter(c => c._id !== data.receptions[key].incidentId);
            }
          },
          (error) => {
            console.log(error)
          }
        )

        this.coproLoaded = Promise.resolve(true);
      },
      async (error)=>{
        console.log(error)
        this.copro = await error.err.copro
      }
    );

    this.DataService.api_get_courtier(
      (data) => {
        for (let i in data.courtiers) {
          this.resultCourtiers[i] = {
            id_el: data.courtiers[i]._id,
            lastName: data.courtiers[i].lastName,
            company: data.courtiers[i].company
          }
          for (let j in data.courtiers[i].dateEtudes) {
            if (data.courtiers[i]?.dateEtudes[j]?.coproId === id)
              this.envoieEtude.push({
                lastName: data.courtiers[i]?.lastName,
                company: data.courtiers[i]?.company,
                date: data.courtiers[i]?.dateEtudes[j]?.date
              });
          }
        }
      }, (error) => {
        this.resultCourtiers = [];
      });
  }

  ngOnInit() {}
}
