import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service'

@Component({
  selector: 'app-credit-alert-dialog',
  templateUrl: './credit-alert-dialog.component.html',
  styleUrls: ['./credit-alert-dialog.component.scss']
})
export class CreditAlertDialogComponent implements OnInit {

  credit = localStorage.getItem('credit');

  constructor(public DataService: DataService) {}

  retourFormCourtier() {}

  ngOnInit(): void {
  }

}
