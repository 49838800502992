<div fxLayout="row wrap" style="padding: 10px 0; font-size: 17px; font-weight: 500;">
  <div fxFlex="100" fxLayoutAlign="start">
    <mat-icon aria-hidden="false" style="color: green; font-size: 30px; margin-right: 19px; position: relative; top: -5px;">check_circle</mat-icon>
    L'immeuble a été envoyé en étude
  </div>
</div>

<div fxLayout="row wrap" style="padding: 10px 0;">
  <div fxFlex="100" fxLayoutAlign="center">
    <div mat-dialog-actions>
      <button (click)="retourFormCourtier()" mat-button mat-dialog-close class="btn">Fermer</button>
    </div>
  </div>
</div>
