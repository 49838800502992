import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { Validators, FormBuilder } from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { SyndicDialogComponent } from '../../../dialog/syndic-dialog/syndic-dialog.component';

@Component({
  selector: 'app-form-syndic',
  templateUrl: './form-syndic.component.html',
  styleUrls: ['./form-syndic.component.scss']
})

export class FormSyndicComponent implements OnInit {

  msgErreur;

  email; firstName; lastName; company; siren; address; codePostal; ville; phone;

  addSyndic(email, firstName, lastName, company, siren, address, codePostal, ville, phone) {
           
    this.DataService.api('/create/syndic', {
      email: email, firstName: firstName, //
      lastName: lastName, company: company, //
      siren: siren, address: address, //
      codePostal: codePostal, ville: ville, // 
      phone: phone //
    },

    (res)=>{        
      this.dialog.open(SyndicDialogComponent, {
        height: 'auto',
        width: '100%',
        maxWidth: '450px',
      }); 
       this.DataService.router.navigate(['admin-copro/dashboard']); 
    },
    (error) => {
       console.log (error)
       if (error.error.message) 
       {
        this.DataService.error= error.error.message;
        this.msgErreur = this.DataService.error;
       }
    })
  }

  formSyndic = this.fb.group({
    emailForm: ['', [Validators.required, Validators.email]],
    firstNameForm: ['', [Validators.required, Validators.minLength(3)]],
    lastNameForm: ['', [Validators.required, Validators.minLength(3)]],
    companyForm: ['', [Validators.required, Validators.minLength(3)]],
    sirenForm: ['', [Validators.required, Validators.minLength(9)]],
    addressForm: ['', [Validators.required, Validators.minLength(3)]],
    codePostalForm: ['', [Validators.required, Validators.minLength(5)]],
    villeForm: ['', [Validators.required, Validators.minLength(3)]],
    phoneForm: ['', [Validators.required, Validators.minLength(10)]],
  }); 

  numericOnly(event): boolean {    
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  get formCtrl() { return this.formSyndic.controls; }

  constructor(public DataService : DataService, private fb: FormBuilder, public dialog: MatDialog) { }


ngOnInit(): void { }

}  
