import { Component, OnInit } from '@angular/core';
import { DataService } from "../../../../data.service";
import { ActivatedRoute } from "@angular/router";

export interface Section {
  address: string,
  imgProfil : string,
  src_img : string,
  nomCopro: string,
  surface: number,
  ville: string,
  id_el: string,
  reference: string,
  codePostal: string,
  compagnie: object,
  dateDemandeVisite: string,
  dateVisite: string,
  pcs: object,
  gest: object,
  travaux: 0,
  display_global: string;
}

@Component({
  selector: 'app-syndics-courtiers',
  templateUrl: './syndics-courtiers.component.html',
  styleUrls: ['./syndics-courtiers.component.scss']
})

export class SyndicsCourtiersComponent implements OnInit {

  onEvent(event) {
    event.stopPropagation();
  }


  toggle_info(event) {
    console.log (event)
    let i
    let id = event.srcElement.parentNode.parentNode.parentNode.parentNode.parentNode.nextElementSibling.id

    if (document.getElementById(id).className === "display_none") {
      document.getElementById(id).classList.remove('display_none');
      document.getElementById(id).className = "display_block"
    } else if(document.getElementById(id).className === "display_block") {
      document.getElementById(id).classList.remove('display_block');
      document.getElementById(id).className = "display_none"
    } else {
      return
    }
  }

  PrestatairesDetailsArray;
  searchVal: string;
  resultSearch = [];
  listCopro:Section [] = [];

  syndicId;

  ids = [];

  constructor(public DataService: DataService, private activatedRoute: ActivatedRoute) {
    this.syndicId =  this.activatedRoute.snapshot.params['id'];
    let isParc = true;

    this.DataService.api_get_copro_syndic(
      {syndicId: this.syndicId, isParc},
      (data) => {
        data.parc.reverse();
        for (let key in data.parc) {
          this.ids.push(data.parc[key]._id);
          if (data.parc[key].batiments[0]) {
            this.listCopro[key] = {
              nomCopro: data.parc[key].nomCopro,
              address: data.parc[key].address,
              imgProfil: data.parc[key].batiments[0],
              src_img: `https://copro-back.cantem.fr/uploads/batiment/${data.parc[key].batiments[0].image.facadeRue[0]}`,
              ville: data.parc[key].ville,
              id_el: data.parc[key]._id,
              surface: data.parc[key].surface,
              reference: data.parc[key].reference,
              codePostal: data.parc[key].codePostal,
              compagnie: data.parc[key].compagnie,
              gest: data.parc[key].gestionnaire,
              dateDemandeVisite: data.parc[key].dateDemandeVisite,
              dateVisite: data.parc[key].dateVisite,
              travaux: 0,
              display_global: "display_none"
            };
          } else {
            this.listCopro[key] = {
              nomCopro: data.parc[key].nomCopro,
              address: data.parc[key].address,
              imgProfil: data.parc[key].batiments[0],
              src_img: "/assets/img/photo/immeuble-base.jpg",
              ville: data.parc[key].ville,
              id_el: data.parc[key]._id,
              surface: data.parc[key].surface,
              reference: data.parc[key].reference,
              codePostal: data.parc[key].codePostal,
              compagnie: data.parc[key].compagnie,
              gest: data.parc[key].gestionnaire,
              dateDemandeVisite: data.parc[key].dateDemandeVisite,
              dateVisite: data.parc[key].dateVisite,
              travaux: 0,
              display_global: "display_none"
            };
          }
        }

        this.DataService.api_get_visite_courtier({copros: this.ids},
          (data) => {
            for (let i in data.visites) {
              for (let j in this.listCopro) {
                if (data.visites[i].coproId === this.listCopro[j].id_el) {
                  this.listCopro[j].pcs = {
                    lastName: data.visites[i].nomPCS,
                    email: data.visites[i].emailPCS,
                    phone: data.visites[i].phonePCS,
                  }
                }
              }
            }
          },
          (error) => {
            console.log(error)
          });

        this.resultSearch = this.listCopro;

        this.DataService.api_get_devis({courtierId: localStorage.id},
          (data) => {
            for (let i in data.devis) {
              for (let j in this.resultSearch) {
                if (data.devis[i].coproId === this.resultSearch[j].id_el) {
                  this.resultSearch[j].travaux += data.devis[i].factureTTC;
                }
              }
            }
          },
          (error) => {
            alert("une erreur est survenue");
            console.log(error);
          });
      },
      (error)=>{
        console.log(error)
      }
    );
    //this.resultSearch = this.listCopro;
  }

  ngOnInit(): void {}

  searchEngine() {
    this.resultSearch = [];
    //if(this.searchVal)
    for(let key in this.listCopro) {
      if(
        this.listCopro[key].nomCopro.toLowerCase().indexOf(this.searchVal.toLowerCase())>-1 && this.resultSearch.length<4 ||
        this.listCopro[key].address.toLowerCase().indexOf(this.searchVal.toLowerCase())>-1 && this.resultSearch.length<4 ||
        this.listCopro[key].codePostal.toLowerCase().indexOf(this.searchVal.toLowerCase())>-1 && this.resultSearch.length<4 ||
        this.listCopro[key].reference.toLowerCase().indexOf(this.searchVal.toLowerCase())>-1 && this.resultSearch.length<4
        ){
        this.resultSearch.push(this.listCopro[key]);
      }
    }
  }
}
