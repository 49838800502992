import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataService } from '../../../../data.service';

export interface SectionSyndicCourtier {
  src_img: string;
  name: string;
  id_el: string;
  nbCopro: number;
  surface: number;
  travaux: number;
  unseen: number;
}

export interface SectionSyndicPresta {
  src_img: string;
  name: string;
  id_el: string;
  nbEval: number;
  nbDevis: number;
  nbFacture: number;
  unseen: number;
}

@Component({
  selector: 'app-dashboard-syndics',
  templateUrl: './dashboard-syndics.component.html',
  styleUrls: ['./dashboard-syndics.component.scss']
})

export class DashboardSyndicsComponent implements OnInit {

  id:string;

  onEvent(event) {
    event.stopPropagation();
  }

  confirmation(event) {
    let i : any;
    let id = event.srcElement.parentNode.parentNode.parentNode.parentNode.parentNode.id
    if(id.length == 0) {
      id = event.srcElement.parentNode.parentNode.parentNode.parentNode.id
    }
    if(confirm("Confirmer la suppresion ?")) {
    for( i in this.listSyndicCourtier)
      {
        if(this.listSyndicCourtier[i].id_el == id)
        {
         this.listSyndicCourtier.splice(i, 1)
        }
      }
    }
  }

  confirmation2(event) {
    let i : any;
    let id = event.srcElement.parentNode.parentNode.parentNode.parentNode.parentNode.id
    if (id.length == 0) {
      id = event.srcElement.parentNode.parentNode.parentNode.parentNode.id
    }
    if (confirm("Confirmer la suppresion ?")) {
      for ( i in this.listSyndicPresta) {
        if (this.listSyndicPresta[i].id_el == id) {
          this.listSyndicPresta.splice(i, 1)
        }
      }
    }
  }

  PrestatairesDetailsArray;
  searchVal: string;
  searchVal2: string;
  resultSearch = [];
  resultSearch2 = [];
  listSyndicCourtier:SectionSyndicCourtier [] = [];
  listSyndicPresta:SectionSyndicPresta [] = [];
  parc: [] = [];

  getAccountType = () => {
    if (localStorage.role.length > 0) {
      let ck = localStorage.role
        return ck
    }
  }

  constructor(public DataService: DataService) {
    this.DataService.api_get_syndic((result) => {
      for (let key in result.syndics) {
        if (localStorage.role === 'courtier') {
          let parc = result.parc.filter(obj => result.syndics[key].parc.includes(obj._id));
          let surface = 0;
          let ids = [];
          for (let i in parc) {
            surface += parc[i].surface;
            ids.push(parc[i]._id);
          }
          let unseen = 0;
          this.DataService.api_post_notifs_by_copro({copros: parc},
            (notifs) => {
              for (let i in notifs.notifications) {
                  if (ids.includes(notifs.notifications[i].coproId)) {
                    unseen += notifs.notifications[i].nbNotif;
                  }
              }
              this.listSyndicCourtier[key] = {
                src_img: "../assets/img/icones/broker.png",
                name: result.syndics[key].nomSyndic,
                id_el: result.syndics[key]._id,
                nbCopro: parc.length,
                surface: surface,
                travaux: 0,
                unseen: unseen
              }
            },
            (error) => {
              console.log(error)
            });
        } else {
          this.listSyndicPresta[key] = {
            src_img: "../assets/img/icones/broker.png",
            name: result.syndics[key].nomSyndic,
            id_el: result.syndics[key]._id,
            nbEval: 0,
            nbDevis: 0,
            nbFacture: 0,
          }
        }
      }
      let id = localStorage.getItem('id');
      if (localStorage.role === 'courtier')
        this.DataService.api_get_devis({courtierId: id},
          (data) => {
            for (let j in this.listSyndicCourtier) {
              for (let i in data.devis) {
                if (data.devis[i].syndicId === this.listSyndicCourtier[j].id_el) {
                  this.listSyndicCourtier[j].travaux += data.devis[i].factureTTC;
                }
              }
            }
          /*
            for (let i in data.devis) {
              for (let j in this.listSyndicCourtier) {
                if (data.devis[i].syndicId === this.listSyndicCourtier[j].id_el) {
                  this.listSyndicCourtier[j].travaux += data.devis[i].evaluationTTC;
                }
              }
            }
          */
          },
          (error) => {
            alert("une erreur est survenue");
            console.log(error);
          });
      if (localStorage.role === 'prestataire')
        this.DataService.api_get_devis({prestataireId: localStorage.getItem('id')},
          (data) => {
            for (let j in this.listSyndicPresta) {
              for (let i in data.list) {
                if (data.list[i].syndicId === this.listSyndicPresta[j].id_el) {
                  if (data.list[i].evaluationTTC && data.list[i].prestataireId === localStorage.id)
                    this.listSyndicPresta[j].nbEval += 1;
                  if (data.list[i].devisPDF && data.list[i].prestataireId === localStorage.id)
                    this.listSyndicPresta[j].nbDevis += 1;
                  if (data.list[i].facturePDF && data.list[i].prestataireId === localStorage.id)
                    this.listSyndicPresta[j].nbFacture += 1;
                }
              }
            }
          },
          (error) => {
            alert("une erreur est survenue");
            console.log(error);
          });
    }, (error) => {
      console.log("error: ", error)
    })
    if (localStorage.role === 'courtier') {
      this.resultSearch = this.listSyndicCourtier;
    } else
      this.resultSearch2 = this.listSyndicPresta;

  }

  sendIdToChild(id) {
    this.DataService.fillMessage(id);
  }

  ngOnInit(): void {}

  searchEngine() {
    this.resultSearch = [];

    for(let key in this.listSyndicCourtier) {
      if(this.listSyndicCourtier[key].name.toLowerCase().indexOf(this.searchVal.toLowerCase())>-1){
        this.resultSearch.push(this.listSyndicCourtier[key]);
      }
    }
  }

  searchEngine2() {
    this.resultSearch2 = [];

    for(let key in this.listSyndicPresta) {
      if(this.listSyndicPresta[key].name.toLowerCase().indexOf(this.searchVal2.toLowerCase())>-1){
        this.resultSearch2.push(this.listSyndicPresta[key]);
      }
    }
  }
}
