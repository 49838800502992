import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ConnexionComponent } from './connexion/connexion.component';

import { AjoutCollaborateursComponent} from './page/ajout-collaborateurs/ajout-collaborateurs.component';
  //child
  import { DashboardComponent } from './page/ajout-collaborateurs/dashboard/dashboard/dashboard.component';
  import { FormGestionnairesComponent } from './page/ajout-collaborateurs/formulaires/form-gestionnaires/form-gestionnaires.component';
  import { FormCourtiersComponent } from './page/ajout-collaborateurs/formulaires/form-courtiers/form-courtiers.component';
  import { FormPrestatairesComponent } from './page/ajout-collaborateurs/formulaires/form-prestataires/form-prestataires.component';
  import { ChangerDroitsComponent } from './page/ajout-collaborateurs/changer-droits/changer-droits/changer-droits.component';

import { MonParcComponent} from './page/mon-parc/mon-parc.component';
  //child
  import { DashboardMonParcComponent } from './page/mon-parc/dashboard/dashboard-mon-parc/dashboard-mon-parc.component';
  import { AjouterUnImmeubleComponent } from './page/mon-parc/ajouter-un-immeuble/ajouter-un-immeuble/ajouter-un-immeuble.component';
  import { AjouterUnParcComponent } from './page/mon-parc/ajouter-un-parc/ajouter-un-parc.component';
  import { MonParcImmeubleComponent } from './page/mon-parc/mon-parc-immeuble/mon-parc-immeuble/mon-parc-immeuble.component';

import { EnCoursSelectionComponent} from './page/en-cours-selection/en-cours-selection.component';
  //child
  import { DashboardEnCoursComponent } from './page/en-cours-selection/dashboard/dashboard-en-cours/dashboard-en-cours.component';
  import { AjouterUnImmeubleEnCoursComponent } from './page/en-cours-selection/ajouter-un-immeuble/ajouter-un-immeuble-en-cours/ajouter-un-immeuble-en-cours.component';
  import { AjouterUnParcEnCoursComponent } from './page/en-cours-selection/ajouter-un-parc-en-cours/ajouter-un-parc-en-cours.component';
  import { SelectionImmeubleComponent } from './page/en-cours-selection/selection-immeuble/selection-immeuble/selection-immeuble.component';
  import { VisiteEnCoursComponent } from './page/en-cours-selection/visite-en-cours/visite-en-cours.component';

import { MesCourtiersComponent} from './page/mes-courtiers/mes-courtiers.component';
  //child
  import { DashboardCourtiersComponent } from './page/mes-courtiers/dashboard/dashboard-courtiers/dashboard-courtiers.component';
  import { MesCourtiersSelectionComponent } from './page/mes-courtiers/mes-courtiers-selection/mes-courtiers-selection/mes-courtiers-selection.component';

import { MonAbonnementComponent} from './page/mon-abonnement/mon-abonnement.component';
  //child
  import { DashboardAboComponent } from './page/mon-abonnement/dashboard/dashboard-abo/dashboard-abo.component';


import { SyndicComponent} from './page/syndic/syndic.component';
  //child
  import { DashboardSyndicsComponent } from './page/syndic/dashboard/dashboard-syndics/dashboard-syndics.component';
  import { SyndicsCourtiersComponent } from './page/syndic/syndics-courtiers/syndics-courtiers/syndics-courtiers.component';
  import { SyndicsCourtiersDetailsComponent } from './page/syndic/syndics-courtiers-details/syndics-courtiers-details/syndics-courtiers-details.component';
  import { SyndicsPrestatairesComponent } from './page/syndic/syndics-prestataires/syndics-prestataires/syndics-prestataires.component';
  import { EvaluationPrestatairesComponent } from './page/syndic/evaluation-prestataires/evaluation-prestataires.component';
  import { DevisPrestatairesComponent } from './page/syndic/devis-prestataires/devis-prestataires.component';

import { VisitesAFairesComponent} from './page/visites-a-faires/visites-a-faires.component';
  //child
  import { DashboardVisitesComponent } from './page/visites-a-faires/dashboard/dashboard-visites/dashboard-visites.component';
  import { VisitesFormulaireComponent } from './page/visites-a-faires/visites-formulaire/visites-formulaire/visites-formulaire.component';

import { MesPrestatairesComponent} from './page/mes-prestataires/mes-prestataires.component';
  //child
  import { DashboardPrestaComponent } from './page/mes-prestataires/dashboard/dashboard-presta/dashboard-presta.component';

import { PreReceptionComponent} from './page/pre-reception/pre-reception.component';
  //child
  import { DashboardPreReceptionDetailsComponent } from './page/pre-reception/dashboard/dashboard-pre-reception-details/dashboard-pre-reception-details.component';
  import { PreReceptionDetailsComponent } from './page/pre-reception/pre-reception-details/pre-reception-details/pre-reception-details.component';

import { AEtudierComponent} from './page/a-etudier/a-etudier.component';
  //child
  import { DashboardAEtudierComponent } from './page/a-etudier/dashboard/dashboard-a-etudier/dashboard-a-etudier.component';
  import { AEtudierDetailsComponent } from './page/a-etudier/a-etudier-details/a-etudier-details/a-etudier-details.component';

import { AdminCoproComponent } from './admin-copro/admin-copro.component';
  //child
  import { DashboardAdminComponent } from './admin-copro/dashboard/dashboard-admin/dashboard-admin.component';
  import { FormSyndicComponent } from './admin-copro/form/form-syndic/form-syndic.component';
  import { FormArchitectesComponent } from './admin-copro/form/form-architectes/form-architectes.component';
  import { FormCourtiersAdminComponent } from './admin-copro/form/form-courtiers-admin/form-courtiers-admin.component';
  import { FormPrestatairesAdminComponent } from './admin-copro/form/form-prestataires-admin/form-prestataires-admin.component';
  import { ChangerDroitsAdminComponent } from './admin-copro/form/changer-droits/changer-droits-admin/changer-droits-admin.component';
  import { ProfilAdminComponent } from './admin-copro/form/profil-admin/profil-admin.component';
  import { ContactAdminComponent } from './admin-copro/form/contact-admin/contact-admin.component';
  import { GererCourtiersComponent } from './admin-copro/form/gerer-courtiers/gerer-courtiers.component';
  import { GererArchitectesComponent } from './admin-copro/form/gerer-architectes/gerer-architectes.component';
  import { GererSyndicsComponent } from './admin-copro/form/gerer-syndics/gerer-syndics.component';


import { MonProfilComponent } from './page/mon-profil/mon-profil.component';
  //child
  import { DashbordMonProfilComponent } from './page/mon-profil/dashboard/dashbord-mon-profil/dashbord-mon-profil.component';

import { ContactComponent } from './page/contact/contact.component';
  //child
  import { DashboardContactComponent } from './page/contact/dashboard/dashboard-contact/dashboard-contact.component';

import {MonParcPCSComponent} from "./page/pcs/mon-parc.component";
  //child
  import { DashboardMonParcPCSComponent } from "./page/pcs/dashboard/dashboard-mon-parc/dashboard-mon-parc.component";
  import { MonParcPCSImmeubleComponent } from "./page/pcs/mon-parc-immeuble/mon-parc-immeuble/mon-parc-immeuble.component";

import { AuthGuard } from './services/auth.guard';

const routes: Routes = [

  { path: '', component: ConnexionComponent },

  { path: 'connexion', component: ConnexionComponent},

  { path: 'contact',
  component: ContactComponent,
  children: [
    {
      path: '', redirectTo: 'dashboard', pathMatch: 'full'
    },
    {
      path: 'dashboard',
      component: DashboardContactComponent,
    },
  ] },

  { path: 'admin-copro',
  component: AdminCoproComponent,
  canActivate: [AuthGuard],
  children: [
    {
      path: '', redirectTo: 'dashboard', pathMatch: 'full'
    },
    {
      path: 'dashboard',
      component: DashboardAdminComponent,
    },
    {
      path: 'contact-admin',
      component: ContactAdminComponent,
    },
    {
      path: 'gerer-courtiers/:id',
      component: GererCourtiersComponent,
    },
    {
      path: 'gerer-architectes/:id',
      component: GererArchitectesComponent,
    },
    {
      path: 'gerer-syndics/:id',
      component: GererSyndicsComponent,
    },
    {
      path: 'profil-admin',
      component: ProfilAdminComponent,
    },
    {
      path: 'changer-droits-admin/:id',
      component: ChangerDroitsAdminComponent,
    },
    {
      path: 'form-syndic',
      component: FormSyndicComponent,
    },
    {
      path: 'form-architectes',
      component: FormArchitectesComponent,
    },
    {
      path: 'form-courtiers-admin',
      component: FormCourtiersAdminComponent,
    },
    {
      path: 'form-prestataires-admin',
      component: FormPrestatairesAdminComponent,
    },
  ] },

  { path: 'ajout-collaborateurs',
  component: AjoutCollaborateursComponent,
  canActivate: [AuthGuard],
  children: [
    {
      path: '', redirectTo: 'dashboard', pathMatch: 'full'
    },
    {
      path: 'dashboard',
      component: DashboardComponent,
    },
    {
      path: 'form-gestionnaires',
      component: FormGestionnairesComponent
    },
    {
      path: 'form-courtiers',
      component: FormCourtiersComponent
    },
    {
      path: 'form-prestataires',
      component: FormPrestatairesComponent
    },
    {
      path: 'changer-droits/:id',
      component: ChangerDroitsComponent
    },
  ] },
  { path: 'mon-parc',
  component: MonParcComponent,
  canActivate: [AuthGuard],
  children: [
    {
      path: '', redirectTo: 'dashboard', pathMatch: 'full'
    },
    {
      path: 'dashboard',
      component: DashboardMonParcComponent,
    },
    {
      path: 'ajouter-un-immeuble',
      component: AjouterUnImmeubleComponent,
    },
    {
      path: 'ajouter-un-parc',
      component: AjouterUnParcComponent,
    },
    {
      path: 'mon-parc-immeuble/:id',
      component: MonParcImmeubleComponent,
    },
  ] },
  { path: 'mon-immeuble',
    component: MonParcPCSComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '', redirectTo: 'dashboard', pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: DashboardMonParcPCSComponent,
      },
      {
        path: 'mon-parc-immeuble/:id',
        component: MonParcPCSImmeubleComponent,
      },
    ] },
  { path: 'en-cours-selection',
  component: EnCoursSelectionComponent,
  children: [
    {
      path: '', redirectTo: 'dashboard', pathMatch: 'full'
    },
    {
      path: 'dashboard',
      component: DashboardEnCoursComponent,
    },
    {
      path: 'ajouter-un-immeuble',
      component: AjouterUnImmeubleEnCoursComponent,
    },
    {
      path: 'ajouter-un-parc',
      component: AjouterUnParcEnCoursComponent,
    },
    {
      path: 'selection-immeuble/:id',
      component: SelectionImmeubleComponent,
    },
    {
      path: 'visite-en-cours',
      component: VisiteEnCoursComponent,
    },
  ] },
  { path: 'mes-courtiers',
  component: MesCourtiersComponent,
  children: [
    {
      path: '', redirectTo: 'dashboard', pathMatch: 'full'
    },
    {
      path: 'dashboard',
      component: DashboardCourtiersComponent,
    },
    {
      path: 'mes-courtiers-selection/:id',
      component: MesCourtiersSelectionComponent,
    },
  ] },
  { path: 'visites-a-faire',
  component: VisitesAFairesComponent,
  canActivate: [AuthGuard],
  children: [
    {
      path: '', redirectTo: 'dashboard', pathMatch: 'full'
    },
    {
      path: 'dashboard',
      component: DashboardVisitesComponent,
    },
    {
      path: 'visites-formulaire/:id/:idCopro',
      component: VisitesFormulaireComponent,
    },
  ] },
  { path: 'pre-reception',
  component: PreReceptionComponent,
  children: [
    {
      path: '', redirectTo: 'dashboard', pathMatch: 'full'
    },
    {
      path: 'dashboard',
      component: DashboardPreReceptionDetailsComponent,
    },
    {
      path: 'pre-reception-details',
      component: PreReceptionDetailsComponent,
    },
  ] },
  { path: 'a-etudier',
  component: AEtudierComponent,
  children: [
    {
      path: '', redirectTo: 'dashboard', pathMatch: 'full'
    },
    {
      path: 'dashboard',
      component: DashboardAEtudierComponent,
    },
    {
      path: 'a-etudier-details/:id',
      component: AEtudierDetailsComponent,
    },
  ] },
  { path: 'mes-syndics',
  component: SyndicComponent,
  canActivate: [AuthGuard],
  children: [
    {
      path: '', redirectTo: 'dashboard', pathMatch: 'full'
    },
    {
      path: 'dashboard',
      component: DashboardSyndicsComponent,
    },
    {
      path: 'mes-syndics-courtiers/:id',
      component: SyndicsCourtiersComponent,
    },
    {
      path: 'mes-syndics-courtiers/mes-syndics-courtiers-details/:id',
      component: SyndicsCourtiersDetailsComponent,
    },
    {
      path: 'mes-syndics-prestataires/:id',
      component: SyndicsPrestatairesComponent,
    },
    {
      path: 'mes-syndics-prestataires/evaluation-prestataires/:id',
      component: EvaluationPrestatairesComponent,
    },
    {
      path: 'mes-syndics-prestataires/devis-prestataires/:id',
      component: DevisPrestatairesComponent,
    },

  ] },

  { path: 'mon-abonnement',
  component: MonAbonnementComponent,
  children: [
    {
      path: '', redirectTo: 'dashboard', pathMatch: 'full'
    },
    {
      path: 'dashboard',
      component: DashboardAboComponent,
    },
  ]},

  { path: 'mes-prestataires',
  component: MesPrestatairesComponent,
  children: [
    {
      path: '', redirectTo: 'dashboard', pathMatch: 'full'
    },
    {
      path: 'dashboard',
      component: DashboardPrestaComponent,
    },
  ]},

  { path: 'mon-profil',
  component: MonProfilComponent,
  children: [
    {
      path: '', redirectTo: 'dashboard', pathMatch: 'full'
    },
    {
      path: 'dashboard',
      component: DashbordMonProfilComponent,
    },
  ]},

  { path: 'mes-syndics', component: SyndicComponent },

  { path: '**', component: AjoutCollaborateursComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes),
          RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
