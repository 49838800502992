import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service'

@Component({
  selector: 'app-credit-dialog',
  templateUrl: './credit-dialog.component.html',
  styleUrls: ['./credit-dialog.component.scss']
})
export class CreditDialogComponent implements OnInit {

  credit = localStorage.getItem('credit');

  checkCredit() {
    return (parseInt(this.credit) > 2800)
  }

  constructor(public DataService: DataService) {}

  retourFormCourtier() {}

  ngOnInit(): void {}
}
