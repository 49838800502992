<!--app-navigation-admin></app-navigation-admin-->

<div class="padding-card padding-card-pos">
    <mat-card>
        <mat-card-content>
            <div>
                <span fxLayout="row wrap" fxFlexAlign="center" *ngIf="resultSyndic?._id"  class="title title-pos" fxLayoutAlign="start">
                    <div fxFlex="30" fxFlexAlign="center" class="padBot20">
                      <div fxFlex="100" fxLayoutAlign="start" class="title margetop15">
                        <div fxLayout="row wrap">
                            <div fxFlex="100" class="margetop15">Crédit en m² Disponible</div>
                            <div fxFlex="100" class="margetop15">
                              <input class="box_shadow input_profil" type="number" min="0" style="text-align: center;" [(ngModel)]="creditDispo" readonly="true">m²
                            </div>
                            <div fxFlex="100" class="margetop15">Ajouter des crédits</div>
                            <div fxFlex="100" class="margetop15">
                              <input class="box_shadow input_profil" type="number" min="0" style="text-align: center;" [(ngModel)]="creditAjout" (keypress)="numericOnly($event)">m²
                            </div>
                            <mat-button-toggle class="btn marge_top30 btn-txt" value="underline" (click)="ajoutCredit(resultSyndic._id)">Ajouter</mat-button-toggle>
                        </div>
                      </div>
                    </div>
                    <div fxFlex="30" fxFlexAlign="center" class="padBot20">
                        <div fxLayoutAlign="center"><img class="imgs  ProfilSansImg"  mat-card-avatar src="../../../../assets/img/icones/person-sans-contour.png"></div>
                        <div fxLayoutAlign="center" class="nom_droits"><span style="text-transform: uppercase; margin-left: 4px;">{{resultSyndic.nomSyndic}}</span></div>
                    </div>
                    <div fxFlex="30" fxFlexAlign="center" class="InfosSyndic">
                        <div>
                            <div class="InlineInfos">Email : </div>
                            <div class="InlineInfos ResulatInfos">{{resultSyndic.email}}</div>
                        </div>
                        <div>
                            <div class="InlineInfos">Prénom : </div>
                            <div class="InlineInfos ResulatInfos">{{resultSyndic.firstName}}</div>
                        </div>
                        <div>
                            <div class="InlineInfos">Nom : </div>
                            <div class="InlineInfos ResulatInfos">{{resultSyndic.lastName}}</div>
                        </div>
                        <div>
                            <div class="InlineInfos">Entreprise : </div>
                            <div class="InlineInfos ResulatInfos">{{resultSyndic.nomSyndic}}</div>
                        </div>
                        <div>
                            <div class="InlineInfos">Siren : </div>
                            <div class="InlineInfos ResulatInfos">{{resultSyndic.siren}}</div>
                        </div>
                        <div>
                            <div class="InlineInfos">Adresse : </div>
                            <div class="InlineInfos ResulatInfos">{{resultSyndic.address}}</div>
                        </div>
                        <div>
                            <div class="InlineInfos">Code postal : </div>
                            <div class="InlineInfos ResulatInfos">{{resultSyndic.codePostal}}</div>
                        </div>
                        <div>
                            <div class="InlineInfos">Ville : </div>
                            <div class="InlineInfos ResulatInfos">{{resultSyndic.ville}}</div>
                        </div>
                        <div>
                            <div class="InlineInfos">Téléphone : </div>
                            <div class="InlineInfos ResulatInfos">{{resultSyndic.phone}}</div>
                        </div>
                    </div>
                </span>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-content>
        <div fxLayout="row wrap" class="title title-pos">
          <!-- TITRES DU TABLEAU-->

          <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100">
            <div class="title_droits full-width title_tab" fxLayoutAlign="center">Immeubles liés au Syndic (Mon Parc)</div>
            <div class="padding-card">
              <div fxLayout="row wrap" class="padd_list" style="width: 100%;">
                <div fxFlex="30" fxLayoutAlign="center">Statut</div>
                <div fxFlex="30" fxLayoutAlign="center">Surface totale</div>
                <div fxFlex="30" fxLayoutAlign="center">Nombre de Copro</div>
              </div>

              <div fxLayout="row wrap">
                <div fxFlex="100">
                  <div class="colonne_gauche droits padd_list">
                    <div fxLayout="row wrap" class="pointer" fxFlexAlign="center">
                      <div fxFlex="30" fxLayoutAlign="center center" class="date_presta fontNormal">Ajout immeuble</div>
                      <div fxFlex="30" fxLayoutAlign="center center" class="date_presta fontNormal">{{ajoutParcSurface}}m²</div>
                      <div fxFlex="30" fxLayoutAlign="center center" class="date_presta fontNormal">{{ajoutImmeubleParc}}</div>
                    </div>
                    <div fxLayout="row wrap" class="pointer" fxFlexAlign="center">
                      <div fxFlex="30" fxLayoutAlign="center center" class="date_presta fontNormal">Visites en cours</div>
                      <div fxFlex="30" fxLayoutAlign="center center" class="date_presta fontNormal">{{visteParcSurface}}m²</div>
                      <div fxFlex="30" fxLayoutAlign="center center" class="date_presta fontNormal">{{visitesEnCoursParc}}</div>
                    </div>
                    <div fxLayout="row wrap" class="pointer" fxFlexAlign="center">
                      <div fxFlex="30" fxLayoutAlign="center center" class="date_presta fontNormal">Immeubles visités</div>
                      <div fxFlex="30" fxLayoutAlign="center center" class="date_presta fontNormal">{{immubleParcSurface}}m²</div>
                      <div fxFlex="30" fxLayoutAlign="center center" class="date_presta fontNormal">{{immeubleVisiteParc}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-content>
        <div fxLayout="row wrap" class="title title-pos">
          <!-- TITRES DU TABLEAU-->

          <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100">
            <div class="title_droits full-width title_tab" fxLayoutAlign="center">Immeubles liés au Syndic (En cours sélection)</div>
            <div class="padding-card">
              <div fxLayout="row wrap" class="padd_list" style="width: 100%;">
                <div fxFlex="30" fxLayoutAlign="center">Statut</div>
                <div fxFlex="30" fxLayoutAlign="center">Surface totale</div>
                <div fxFlex="30" fxLayoutAlign="center">Nombre de Copro</div>
              </div>

              <div fxLayout="row wrap">
                <div fxFlex="100">
                  <div class="colonne_gauche droits padd_list">
                    <div fxLayout="row wrap" class="pointer" fxFlexAlign="center">
                      <div fxFlex="30" fxLayoutAlign="center center" class="date_presta fontNormal">Ajout immeuble</div>
                      <div fxFlex="30" fxLayoutAlign="center center" class="date_presta fontNormal">{{ajoutSelectSurface}}m²</div>
                      <div fxFlex="30" fxLayoutAlign="center center" class="date_presta fontNormal">{{ajoutImmeubleSelect}}</div>
                    </div>
                    <div fxLayout="row wrap" class="pointer" fxFlexAlign="center">
                      <div fxFlex="30" fxLayoutAlign="center center" class="date_presta fontNormal">Visites en cours</div>
                      <div fxFlex="30" fxLayoutAlign="center center" class="date_presta fontNormal">{{visteSelectSurface}}m²</div>
                      <div fxFlex="30" fxLayoutAlign="center center" class="date_presta fontNormal">{{visitesEnCoursSelect}}</div>
                    </div>
                    <div fxLayout="row wrap" class="pointer" fxFlexAlign="center">
                      <div fxFlex="30" fxLayoutAlign="center center" class="date_presta fontNormal">Immeubles visités</div>
                      <div fxFlex="30" fxLayoutAlign="center center" class="date_presta fontNormal">{{immubleSelectSurface}}m²</div>
                      <div fxFlex="30" fxLayoutAlign="center center" class="date_presta fontNormal">{{immeubleVisiteSelect}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-content>
        <div fxLayout="row wrap" class="title title-pos">
          <!-- TITRES DU TABLEAU-->

          <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100">
            <div class="title_droits full-width title_tab" fxLayoutAlign="center">Gestionnaires liés au Syndic</div>
            <div class="padding-card" *ngIf="resultGest?.length > 0">
              <div fxLayout="row wrap" class="padd_list" style="width: 100%;">
                <div fxFlex="25" fxLayoutAlign="center">Prenom</div>
                <div fxFlex="25" fxLayoutAlign="center">Nom</div>
                <div fxFlex="25" fxLayoutAlign="center">Email</div>
                <div fxFlex="25" fxLayoutAlign="center">Téléphone</div>
              </div>

              <div fxLayout="row wrap" *ngFor="let gest of resultGest">
                <div fxFlex="100">
                  <div id="{{ gest._id }}" class="colonne_gauche droits padd_list">
                    <div fxLayout="row wrap" class="pointer">
                      <div fxFlex="25" fxLayoutAlign="center center" class="date_presta fontNormal">{{gest.firstName}}</div>
                      <div fxFlex="25" fxLayoutAlign="center center" class="date_presta fontNormal">{{gest.lastName}}</div>
                      <div fxFlex="25" fxLayoutAlign="center center" class="date_presta fontNormal">{{gest.email}}</div>
                      <div fxFlex="25" fxLayoutAlign="center center" class="date_presta fontNormal">{{gest.phone}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="padding-card" *ngIf="resultGest?.length === 0">
              <div fxLayout="row wrap">
                <div fxFlex="100">
                  <div class="colonne_gauche droits padd_list">
                    <div fxLayout="row wrap" class="pointer">
                      <div fxFlex="100" fxLayoutAlign="center center" class="date_presta fontNormal">Aucun Gestionnaire enregistré</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-content>
        <div fxLayout="row wrap" class="title title-pos">
          <!-- TITRES DU TABLEAU-->

          <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100">
            <div class="title_droits full-width title_tab" fxLayoutAlign="center">Courtiers liés au Syndic</div>
            <div class="padding-card" *ngIf="resultCourt?.length > 0">
              <div fxLayout="row wrap" class="padd_list" style="width: 100%;">
                <div fxFlex="30" fxLayoutAlign="center">Entreprise</div>
                <div fxFlex="30" fxLayoutAlign="center">Surface totale</div>
                <div fxFlex="30" fxLayoutAlign="center">Nombre de Copro</div>
              </div>

              <div fxLayout="row wrap" *ngFor="let courtier of resultCourt">
                <div fxFlex="100">
                  <div id="{{ courtier._id }}" class="colonne_gauche droits padd_list">
                    <div fxLayout="row wrap" class="pointer" fxFlexAlign="center">
                      <div fxFlex="30" fxLayoutAlign="center center" class="date_presta fontNormal">{{courtier.company}}</div>
                      <div fxFlex="30" fxLayoutAlign="center center" class="date_presta fontNormal">{{courtier.surface}}m²</div>
                      <div fxFlex="30" fxLayoutAlign="center center" class="date_presta fontNormal">{{courtier.nbCopro}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="padding-card" *ngIf="resultCourt?.length === 0">
              <div fxLayout="row wrap">
                <div fxFlex="100">
                  <div class="colonne_gauche droits padd_list">
                    <div fxLayout="row wrap" class="pointer">
                      <div fxFlex="100" fxLayoutAlign="center center" class="date_presta fontNormal">Aucun Courtier lié</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-content>
        <div fxLayout="row wrap" class="title title-pos">
          <!-- TITRES DU TABLEAU-->

          <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100">
            <div class="title_droits full-width title_tab" fxLayoutAlign="center">Prestataires liés au Syndic</div>
            <div class="padding-card" *ngIf="resultPresta?.length > 0">
              <div fxLayout="row wrap" class="padd_list" style="width: 100%;">
                <div fxFlex="50" fxLayoutAlign="center">Entreprise</div>
                <div fxFlex="50" fxLayoutAlign="center">Abonnement</div>
              </div>

              <div fxLayout="row wrap" *ngFor="let presta of resultPresta">
                <div fxFlex="100">
                  <div id="{{ presta._id }}" class="colonne_gauche droits padd_list">
                    <div fxLayout="row wrap" class="pointer" fxFlexAlign="center">
                      <div fxFlex="50" fxLayoutAlign="center center" class="date_presta fontNormal">{{presta.company}}</div>
                      <div fxFlex="50" fxLayoutAlign="center center" class="date_presta fontNormal" style="color: green;" *ngIf="presta.abo">Premium</div>
                      <div fxFlex="50" fxLayoutAlign="center center" class="date_presta fontNormal" style="color: red;" *ngIf="!presta.abo">Basic</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="padding-card" *ngIf="resultPresta?.length === 0">
              <div fxLayout="row wrap">
                <div fxFlex="100">
                  <div class="colonne_gauche droits padd_list">
                    <div fxLayout="row wrap" class="pointer">
                      <div fxFlex="100" fxLayoutAlign="center center" class="date_presta fontNormal">Aucun Prestataire lié</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-content>
        <div fxLayout="row wrap" class="title title-pos" fxLayoutAlign="center">
          <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center">
            <div fxFlex="100" fxLayout="row wrap">
              <div fxFlex="100" fxLayoutAlign="start center">Sélectionnez le début de la période :</div>
              <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">
                <mat-form-field color="accent" style="width: 80%;">
                  <mat-label>Début de la période :</mat-label>
                  <input matInput [matDatepicker]="picker1" [(ngModel)]="debutPeriode">
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div fxFlex="100" fxLayout="row wrap">
              <div fxFlex="100" fxLayoutAlign="start center">Sélectionnez la fin de la période :</div>
              <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">
                <mat-form-field color="accent" style="width: 80%;">
                  <mat-label>Fin de la période</mat-label>
                  <input matInput [matDatepicker]="picker2" [(ngModel)]="finPeriode">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2 color="primary"></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center">
              <div fxFlex="100" fxLayout="row wrap">
                <mat-button-toggle class="btn marge_top30 btn-txt" value="underline" (click)="retrieveData()">Générer Excel</mat-button-toggle>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
</div>
