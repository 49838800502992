import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DataService } from '../../../data.service'

@Component({
  selector: 'app-ajouter-un-parc',
  templateUrl: './ajouter-un-parc.component.html',
  styleUrls: ['./ajouter-un-parc.component.scss']
})
export class AjouterUnParcComponent implements OnInit {

  accept: string = ".xls,.xlsx";
  data;

  form = new FormGroup({
    file: new FormControl()
 });

  constructor(public DataService: DataService) {}

  ngOnInit(){}

  addParc(data) {
    this.DataService.apiParc('/create/multi-copros', data,
    (res) => {
      alert("Le parc d'immeubles a bien été ajouté")
      this.DataService.router.navigate(['mon-parc/dashboard']);
    },
    (error) => {
       console.log (error)
       if (error.error.message) {
         this.DataService.error = error.error.message
       }
    })
  }
}

