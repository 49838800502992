import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { DataService } from '../data.service'

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  intercept(req, next) {
    let dataService = this.injector.get(DataService)
    let tokenizedReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${dataService.loggedIn()}`,
        credentials: 'include'
      }
    })
    return next.handle(tokenizedReq)
  }

  constructor(private injector: Injector) { }
}
