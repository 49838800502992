<div fxLayout="row wrap" class="padding-card padding-card-pos-search">
    <mat-card-content fxFlex="100">
        <mat-form-field fxLayoutAlign="left center" class="input_search" appearance="outline">
            <mat-label>Recherche...</mat-label>
            <input matInput [(ngModel)]="searchVal" (keyup)="searchEngine()"/>
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
    </mat-card-content>
</div>

<div class="padding-card padding-card-pos">
    <mat-card>
        <mat-card-content class="content-pos">
            <div fxLayout="row wrap">
                <div fxFlex="33" fxFlex.xs="100" fxFlex.md="50" fxFlex.sm="50" fxFlex.lg="33" fxFlex.gt-lg="25" fxLayoutAlign.xs="center" *ngFor="let detail of resultSearch; let i = index">
                    <div routerLink="../mes-courtiers-selection/{{detail?.id_el}}" fxFlex="90" class="result-box" style="padding: 16px;" id={{detail?.id_el}} class="box_shadow pointer marge_presta">
                        <div class="croix" *ngIf="permissions && permissions[1]">
                            <mat-button-toggle (click)="onEvent($event)" (click)="confirmation($event)" class="btn_without_bg">
                              <img src="../../../../../assets/img/icones/croix.jpg">
                            </mat-button-toggle>
                        </div>
                        <div class="nom_societe data_dynamic_done">{{detail?.company}}</div>
                        <div *ngIf="detail?.company !== 'Sans Courtier'" class="nom_societe data_dynamic_done">({{detail?.name}})</div>
                        <div fxLayout="row wrap">
                            <div fxLayoutAlign="center" fxFlex="100%" class="logo_marge">
                                <div class="img_virtuelle_icone" [ngStyle]="{ 'background-position': 'center', 'background-repeat': 'no-repeat', 'background-size': 'contain', 'background-image': 'url(' + detail?.src_img + ')'}"></div>
                            </div>
                        </div>
                        <div fxLayout="row wrap">
                            <div fxFlex="50">
                                <div fxLayoutAlign="center center" class="ic_height"><img src="../../../../../assets/img/icones/building-medium.jpg"></div>
                                <div class="resultat data_dynamic_done"><span>{{detail?.nbLot}}</span></div>
                            </div>
                            <div fxFlex="50">
                                <div fxLayoutAlign="center center" class="ic_height"><img src="../../../../../assets/img/icones/square-medium.jpg"></div>
                                <div class="resultat data_dynamic_done"><span>{{detail?.surface}}</span><span class="m2">m²</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
