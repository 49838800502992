<div fxLayout="row wrap" class="padding-card padding-card-pos">
    <div fxFlex="60" fxFlex.xs="100" fxFlex.sm="100">
        <mat-card fxFlex="90" fxFlex.xs="100" fxFlex.sm="100">
            <mat-card-title class="title title-pos">Ajout gestionnaire</mat-card-title>
            <mat-card-content class="title-pos input_outline">
                <form fxLayout="row wrap" [formGroup]="formGestionnaire" class="formCreation">
                    <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
                        <div class="input-gestion">
                            <mat-label>Email du compte :</mat-label>
                        </div>
                        <mat-form-field appearance="outline" class="input350">
                            <mat-label>Email du compte</mat-label>
                            <input [(ngModel)]="email" formControlName="emailForm" matInput required>
                            <mat-error class="ErrorInline" *ngIf="formCtrl.emailForm.hasError('required')">Le champ email est requis</mat-error>
                            <mat-error class="ErrorInline" *ngIf="formCtrl.emailForm.hasError('email')">Format de mail invalide.</mat-error>
                        </mat-form-field>
                    </div>
                    <!-- <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
                        <div class="input-gestion">
                            <mat-label>Mot de passe :</mat-label>
                        </div>
                        <mat-form-field appearance="outline" class="input350">
                            <mat-label>Mot de passe</mat-label>
                            <input [(ngModel)]="password" matInput [ngModelOptions]="{standalone: true}" [type]="hide ? 'password' : 'text'">
                            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>  -->
                    <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
                        <div class="input-gestion">
                            <mat-label>Prénom du Gestionnaire :</mat-label>
                        </div>
                        <mat-form-field appearance="outline" class="input350">
                            <mat-label>Prénom</mat-label>
                            <input [(ngModel)]="firstName" formControlName="firstNameForm" matInput required minlength="3" maxlength="100">
                            <mat-error class="ErrorInline" *ngIf="formCtrl.firstNameForm.hasError('required')">Le champ prénom est requis</mat-error>
                            <mat-error class="ErrorInline" *ngIf="formCtrl.firstNameForm.hasError('minlength')">Minimum <b>3 caractères</b>, maximum <b>100</b>.</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
                        <div class="input-gestion">
                            <mat-label>Nom du Gestionnaire :</mat-label>
                        </div>
                        <mat-form-field appearance="outline" class="input350">
                            <mat-label>Nom</mat-label>
                            <input [(ngModel)]="lastName" formControlName="lastNameForm" matInput required minlength="3" maxlength="50">
                            <mat-error class="ErrorInline" *ngIf="formCtrl.lastNameForm.hasError('required')">Le champ nom est requis</mat-error>
                            <mat-error class="ErrorInline" *ngIf="formCtrl.lastNameForm.hasError('minlength')">Minimum <b>3 caractères</b>, maximum <b>50</b>.</mat-error>
                        </mat-form-field>
                    </div>
                    <!-- <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
                        <div class="input-gestion">
                            <mat-label>Nom du syndic :</mat-label>
                        </div>
                        <mat-form-field appearance="outline" class="input350">
                            <mat-label>Nom du syndic</mat-label>
                            <input [(ngModel)]="syndic" formControlName="syndicForm" matInput required minlength="3" maxlength="50">
                            <mat-error class="ErrorInline" *ngIf="formCtrl.syndicForm.hasError('required')">Le champ nom du syndic est requis</mat-error>
                            <mat-error class="ErrorInline" *ngIf="formCtrl.syndicForm.hasError('minlength')">Minimum <b>3 caractères</b>, maximum <b>50</b>.</mat-error>
                        </mat-form-field>
                    </div> -->
                    <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100" class="pad15R">
                        <div class="input-gestion">
                            <mat-label>Numéro de contact du Gestionnaire :</mat-label>
                        </div>
                        <mat-form-field appearance="outline" class="input350">
                            <mat-label>Numéro de téléphone</mat-label>
                            <input [(ngModel)]="phone" formControlName="phoneForm" matInput (keypress)="numericOnly($event)" required minlength="10" maxlength="10">
                            <mat-error class="ErrorInline" *ngIf="formCtrl.phoneForm.hasError('required')">Le champ téléphone est requis</mat-error>
                            <mat-error class="ErrorInline" *ngIf="formCtrl.phoneForm.hasError('minlength')">Il doit comporter <b>10 chiffres</b></mat-error>
                        </mat-form-field>
                    </div>
                </form>
            </mat-card-content>
       </mat-card>
    </div>
    <div fxFlex="40" fxFlex.xs="100" fxFlex.sm="100">
        <mat-card fxFlex="90" fxFlex.xs="100" fxFlex.sm="100">
            <mat-card-title class="title title-pos">Droit gestionnaire</mat-card-title>
            <mat-card-content class="top_check title-pos" fxLayout="row wrap">
                <div class="titre_check" fxFlex="100" *ngFor="let etats of etatsArray; let i = index">
                    <mat-checkbox (change)="showOptions($event)" value="{{i}}"> {{ etats }} </mat-checkbox>
                </div>
                <div class="button_connexion marge40T">
                    <mat-button-toggle
                    [disabled]="!formGestionnaire.valid || permissions.length === 0"
                    (click)="addGestionnaire(email, firstName, lastName, phone)"
                    class="btn">
                        Créer le gestionnaire
                    </mat-button-toggle>
                    <div class="champsAlert" *ngIf="!formGestionnaire.valid">* Remplissez tous les champs obligatoires pour créer le gestionnaire</div>
                    <div class="champsOk" *ngIf="formGestionnaire.valid">* Vous pouvez créer le gestionnaire</div>
                </div>
                <p *ngIf="DataService.error" style="color: red;"> {{ DataService.error }}</p>
            </mat-card-content>
       </mat-card>
    </div>
</div>
