<!-- BLOC 1 -->

<div class="padding-card padding-card-pos">
    <mat-card>
        <mat-card-content>
            <div fxLayout="row wrap">
                <div fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxLayoutAlign="center">
                    <div fxLayout="row wrap">
                        <div fxFlex="100" >
                            <div fxLayoutAlign="center">
                              <div *ngIf="gestionnaire?.image" mat-card-avatar class="imgs marge_photo" [ngStyle]="{ 'background-position': 'center', ' background-color': '#F9F9F9', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-image': 'url(http://54.37.157.34/uploads/images/' + gestionnaire.image + ')'}"></div>
                              <div *ngIf="!gestionnaire?.image" mat-card-avatar class="imgs marge_photo ProfilSansImg"></div>
                            </div>
                            <div fxLayoutAlign="center" class="nom_droits">{{gestionnaire?.firstName }} {{gestionnaire?.lastName}}</div>
                        </div>
                    </div>
                </div>
                <div fxFlex="50" fxFlex.xs="100%" fxFlex.sm="100" fxFlex.md="100">
                    <mat-card-title class="title title-pos">Droit gestionnaire</mat-card-title>
                    <mat-card-content class="title-pos" fxLayout="row wrap">
                        <div style="font-size: 13px; margin: 15px 0; letter-spacing: 0.19px;" fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" *ngFor="let etats of etatsArray; let i = index">
                            <mat-checkbox [checked]="gestionnaire?.permissions[i] === 1" (change)="changePermissions(i)"> {{ etats }} </mat-checkbox>
                        </div>
                    </mat-card-content>
                    <div fxLayoutAlign="start" class="title-pos"><mat-button-toggle class="btn" (click)="updatePermissions()">Changer les droits</mat-button-toggle></div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>

<!-- BLOC 2 -->

<div class="padding-card padding-card-pos">
    <mat-card>
        <mat-card-content>
            <div fxLayout="row wrap" class="title title-pos">

                <!-- TITRES DU TABLEAU-->

                <div fxFlex="40" fxFlex.xs="100" fxFlex.sm="100">
                    <div class="title_droits full-width title_tab">Immeubles liés à {{gestionnaire?.lastName}}</div>
                    <div class="padding-card">
                        <div fxLayout="row wrap" class="padd_list" style="width: 100%;">
                            <div fxFlex="40"><img src="../../../../assets/img/photo/user-test.png"></div>
                            <div fxFlex="40">Copro</div>
                            <div fxFlex="20">Reference</div>
                        </div>
                        <div fxLayout="row wrap" *ngIf="listCoproAssigned.length === 0">
                          <div fxFlex="100">
                            <div fxFlex="40"></div>
                            <div fxFlex="50" class="list_height30">
                              <div style="text-align: center"><b>Aucune Copro Assignée</b></div>
                            </div>
                            <div fxFlex="40"></div>
                          </div>
                        </div>
                        <div fxLayout="row wrap" *ngFor="let copro of listCoproAssigned">
                            <div fxFlex="100">
                                <div id="{{ copro.id }}" class="colonne_gauche droits padd_list">
                                    <div fxLayout="row wrap" (click)="color_line($event)" class="pointer">
                                        <div fxFlex="40">
                                            <div mat-card-avatar class="icon_list" [ngStyle]="{ 'background-position': 'center', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-image': 'url(' + copro.image + ')'}"></div>
                                        </div>
                                      <div fxFlex="40" class="list_height30">
                                        <div>{{copro.address}}</div>
                                        <div>{{copro.codePostal}}</div>
                                      </div>
                                      <div fxFlex="20" class="date_presta">{{copro.reference}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ECHANGE -->

                <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="100%" fxLayoutAlign="center center" fxLayoutAlign.xs="center center" fxLayoutAlign.sm="center center" class="echange_box">
                    <div class="box_shadow box_size position_echange">
                        <div fxLayoutAlign="center" class="fleche txt_center echange">Retirer Immeuble(s)</div>
                        <div fxLayoutAlign="center"><mat-button-toggle (click)="echange_line($event)"><img src="../../../../assets/img/icones/arrow-droite.png"></mat-button-toggle></div>
                        <div fxLayoutAlign="center"><mat-button-toggle (click)="echange_line_2($event)"><img src="../../../../assets/img/icones/arrow-gauche.png"></mat-button-toggle></div>
                        <div fxLayoutAlign="center" class="fleche txt_center echange">Ajouter immeuble(s)</div>
                    </div>
                </div>

                <!-- IMMEUBLES DISPONIBLES-->

                <!-- TITRES DU TABLEAU-->

                <div fxFlex="40%" fxFlex.xs="100%" fxFlex.sm="100%">
                    <div class="title_droits full-width title_tab">Immeubles disponibles</div>
                    <div class="padding-card padding-card-list">
                        <div fxLayout="row wrap" class="padd_list" style="width: 100%;">
                            <div fxFlex="40"><img src="../../../../assets/img/photo/user-test.png"></div>
                            <div fxFlex="40">Copro</div>
                            <div fxFlex="20">Reference</div>
                        </div>
                        <div fxLayout="row wrap" *ngIf="listCoproDisponible.length === 0">
                          <div fxFlex="100">
                            <div fxFlex="40"></div>
                            <div fxFlex="50" class="list_height30">
                              <div style="text-align: center"><b>Aucune Copro Disponible</b></div>
                            </div>
                            <div fxFlex="40"></div>
                          </div>
                        </div>
                        <div fxLayout="row wrap" *ngFor="let disponible of listCoproDisponible">
                            <div fxFlex="100">
                                <div id="{{ disponible.id }}" class="colonne_droite droits padd_list">
                                    <div fxLayout="row wrap" (click)="color_line($event)" class="pointer">
                                        <div fxFlex="40">
                                        <div mat-card-avatar class="icon_list" [ngStyle]="{ 'background-position': 'center', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-image': 'url(' + disponible.image + ')'}"></div>
                                        </div>
                                        <div fxFlex="40" class="list_height30">
                                            <div>{{disponible.address}}</div>
                                            <div>{{disponible.codePostal}}</div>
                                        </div>
                                        <div fxFlex="20" class="date_presta">{{disponible.reference}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
