import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { Validators, FormBuilder, FormGroup, FormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { PrestataireDialogComponent } from '../../../dialog/prestataire-dialog/prestataire-dialog.component';

@Component({
  selector: 'app-form-prestataires-admin',
  templateUrl: './form-prestataires-admin.component.html',
  styleUrls: ['./form-prestataires-admin.component.scss']
})
export class FormPrestatairesAdminComponent implements OnInit {

  etatsArray: String[] = [];
  rcProfessionnelle: any;
  rcDecennale: any;

  msgErreur;

  email; phone; address; codePostal; company; lastName; siret; nbrSalaries; corpsEtat = [];
  formDecennal = new FormGroup({
    rcDecennal: new FormControl()
  });
  formProfessionnelle = new FormGroup({
    rcProfessionnelle: new FormControl()
  });
  addPrestataire(email, phone, address, codePostal, company, lastName, siret, nbrSalaries, corpsEtat) {
    if (!this.formPresta.valid)
      alert("Veuillez verifier et remplir correctement le formulaire");
    else if (this.corpsEtat.length === 0)
      alert("Veuillez renseigner le ou les corps d'état du prestataire");
    else {
      const data = new FormData();
      data.append('email', email);
      data.append('phone', phone);
      data.append('address', address);
      data.append('codePostal', codePostal);
      data.append('company', company);
      data.append('lastName', lastName);
      data.append('siret', siret);
      data.append('nbrSalaries', nbrSalaries);
      for (let i in corpsEtat)
        data.append('corpsEtat[]', corpsEtat[i]);
      data.append('rcProfessionnelle', this.rcProfessionnelle);
      data.append('rcDecennale', this.rcDecennale);
      this.DataService.api(
        '/create/prestataire',
        data,
        (res) => {
          console.log(res)
          this.dialog.open(PrestataireDialogComponent, {
            height: 'auto',
            width: '100%',
            maxWidth: '450px',
          });
          this.DataService.router.navigate(['admin-copro/dashboard']);
        },
        (error) => {
          console.log(error)
          if (error.error.message) {
            this.DataService.error= error.error.message;
            this.msgErreur = this.DataService.error;
          }
        })
    }
  }

  formPresta = this.fb.group({
    emailForm: ['', [Validators.required, Validators.email]],
    phoneForm: ['', [Validators.required, Validators.minLength(10)]],
    addressForm: ['', [Validators.required, Validators.minLength(3)]],
    codePostalForm: ['', [Validators.required, Validators.minLength(5)]],
    companyForm: ['', [Validators.required, Validators.minLength(3)]],
    lastNameForm: ['', [Validators.required, Validators.minLength(3)]],
    sirenForm: ['', [Validators.required, Validators.minLength(14)]],
    nbrSalariesForm: ['', [Validators.required, Validators.minLength(1)]],
  });
  registerFile = (e, fileType) => {
    if (e.target.files?.length > 0) {
      if (fileType === 'rcdecennal')
        this.rcDecennale = e.target.files[0];
      else
        this.rcProfessionnelle = e.target.files[0];
    }
  }
  deleteFile = (fileType) => {
    if (fileType === 'rcdecennal')
      this.rcDecennale = null;
    else {
      this.rcProfessionnelle = null;
    }
  }
  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  showOptions(event): void {
    if (event.checked)
      this.corpsEtat.push(event.source.name);
    else
      this.corpsEtat = this.corpsEtat.filter(e => e !== event.source.name);
  }

  get formCtrl() { return this.formPresta.controls; }

  constructor(public DataService : DataService, private fb: FormBuilder, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.etatsArray = ['Chauffagiste', 'Contrôle d’accès', 'Cordiste', 'Couvreur', 'Electricien', 'Façadier', 'Infiltrations', 'Maçon', 'Menuisier', 'Peintre', 'Plombier', 'Porte de garage', 'Ravalement', 'Recherche de fuite', 'Sécurité incendie', 'Serrurier', 'Vitrier'];
  }

}
