import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../data.service';
import { Validators, FormBuilder } from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {PrestataireDemandeDialogComponent} from "../../../../dialog/prestataire-demande-dialog/prestataire-demande-dialog.component";

@Component({
  selector: 'app-form-prestataires',
  templateUrl: './form-prestataires.component.html',
  styleUrls: ['./form-prestataires.component.scss']
})

export class FormPrestatairesComponent implements OnInit {
  etatsArray: String[] = [];

  email; phone; address; codePostal; company; lastName; siret; nbrSalaries; corpsEtat = [];

  addPrestataire(email, phone, address, codePostal, company, lastName, siret, nbrSalaries, corpsEtat) {
    if (!this.formPresta.valid)
      alert("Veuillez verifier et remplir correctement le formulaire");
    else if (this.corpsEtat.length === 0)
      alert("Veuillez renseigner le ou les corps d'état du prestataire");
    else {
      this.DataService.api(
        '/gestion/demande-prestataire',
        {
          email       : email,
          phone       : phone,
          address     : address,
          codePostal  : codePostal,
          company     : company,
          lastName    : lastName,
          siret       : siret,
          nbrSalaries : nbrSalaries,
          corpsEtat   : corpsEtat,
          syndic      : localStorage.getItem('id')
        },
        (res) => {
          this.dialog.open(PrestataireDemandeDialogComponent, {
            height: 'auto',
            width: '100%',
            maxWidth: '450px',
          });
        },
        (error) => {
          console.log (error)
          if (error.error.message) {
            this.DataService.error= error.error.message
          }
        })
    }
  }

  formPresta = this.fb.group({
    emailForm: ['', [Validators.required, Validators.email]],
    phoneForm: ['', [Validators.required, Validators.minLength(10)]],
    addressForm: ['', [Validators.required, Validators.minLength(3)]],
    codePostalForm: ['', [Validators.required, Validators.minLength(5)]],
    companyForm: ['', [Validators.required, Validators.minLength(3)]],
    lastNameForm: ['', [Validators.required, Validators.minLength(3)]],
    sirenForm: ['', [Validators.required, Validators.minLength(14)]],
    nbrSalariesForm: ['', [Validators.required, Validators.minLength(1)]],
  });

  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  showOptions(event): void {
    if (event.checked)
      this.corpsEtat.push(event.source.name);
    else
      this.corpsEtat = this.corpsEtat.filter(e => e !== event.source.name);
  }

  get formCtrl() { return this.formPresta.controls; }

  constructor(public DataService : DataService, private fb: FormBuilder, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.etatsArray = ['Chauffagiste', 'Contrôle d’accès', 'Cordiste', 'Couvreur', 'Electricien', 'Façadier', 'Infiltrations', 'Maçon', 'Menuisier', 'Peintre', 'Plombier', 'Porte de garage', 'Ravalement', 'Recherche de fuite', 'Sécurité incendie', 'Serrurier', 'Vitrier']
  }
}
