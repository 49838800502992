<div class="padding-card padding-card-pos">
    <mat-card>
        <div class="left15">
            <mat-card-content fxLayout="row wrap" class="content-pos">
                <div fxFlex="97" fxFlex.xs="100">
                    <mat-form-field appearance="outline" class="fill-width">
                        <mat-label>Sélectionnez un prestataire</mat-label>
                        <mat-select [(ngModel)]="searchVal">
                          <mat-option value="Tous" (click)="searchEngine()">Tous</mat-option>
                          <mat-option *ngFor="let topping of corpsEtat" [value]="topping" (click)="searchEngine()">{{topping}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </mat-card-content>
        </div>

        <mat-card-content fxLayout="row wrap" class="content-pos">
            <div fxFlex="33" fxFlex.xs="100" fxFlex.md="50" fxFlex.sm="100" fxFlex.lg="33" fxFlex.gt-lg="25" *ngFor="let detail of resultSearch">
                <div fxFlex="90" fxFlex.xs="100" fxFlex.sm="100" class="result-box  marge_presta" style="padding: 35px 15px;">
                    <div class="nom_societe data_dynamic_done">{{ detail.company }}</div>
                  <star-rating value="{{roundVal(detail.noteGlobale)}}" checkedcolor="gold" uncheckedcolor="gray" totalstars="{{5}}" readonly="true"></star-rating>
                    <div fxLayout="row wrap">
                        <div fxFlex="60" fxLayoutAlign="center center">
                            <div>
                                <div fxLayoutAlign="center center">
                                    <div width="70%" class="img_virtuelle_logo" [ngStyle]="{ 'background-position': 'center', 'background-repeat': 'no-repeat', 'background-size': 'contain', 'background-image': 'url(' + detail.src_img + ')'}"></div>
                                </div>
                                <div class="info_contact data_dynamic_done">
                                    M. {{detail.representant.lastName}}
                                </div>
                                <div class="info_contact data_dynamic_done">
                                  {{detail.representant.phone}}
                                </div>
                            </div>
                        </div>
                        <div fxFlex="40">
                            <div>
                                <div fxLayout="row wrap" class="marge_icone">
                                    <div fxFlex="50"><img src="../../../../assets/img/icones/calcule-medium.png"></div>
                                    <div fxFlex="50" class="resultat data_dynamic_done">{{detail?.nbDevis || 0}}</div>
                                </div>
                                <div fxLayout="row wrap" class="marge_icone">
                                    <div fxFlex="50"><img src="../../../../assets/img/icones/recherche-medium.png"></div>
                                    <div fxFlex="50" class="resultat data_dynamic_done">{{detail?.nbEval || 0}}</div>
                                </div>
                                <div fxLayout="row wrap"  class="marge_icone">
                                    <div fxFlex="50"><img class="" src="../../../../assets/img/icones/tools-medium.png"></div>
                                    <div fxFlex="50" class="resultat data_dynamic_done">{{detail?.travaux || 0}}<span class="resultat euro">€</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row wrap" class="rc_margin">
                      <div fxFlex="50">
                        <div>
                          <div class="rc_style">RC <br>PRO</div>
                          <div>
                            <a *ngIf="detail?.RCProfessionnelle" mat-button-toggle href="{{'https://copro-back.cantem.fr/uploads/RC-files/' +  detail?.RCProfessionnelle}}" target="_blank"><img src="../../../../assets/img/icones/upload-medium.png"></a>
                            <div *ngIf="!detail?.RCProfessionnelle" class="info_contact data_dynamic_done" style="color: red">indisponible</div>
                          </div>
                        </div>
                      </div>
                      <div fxFlex="50">
                        <div>
                          <div class="rc_style">RC <br>Décennale</div>
                          <div>
                            <a *ngIf="detail?.RCDecennale" mat-button-toggle href="{{'https://copro-back.cantem.fr/uploads/RC-files/' +  detail?.RCDecennale}}" target="_blank"><img src="../../../../assets/img/icones/upload-medium.png"></a>
                            <div *ngIf="!detail?.RCDecennale" class="info_contact data_dynamic_done" style="color: red">indisponible</div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
