import { Component, HostListener, ElementRef } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { DataService } from '../data.service';
import {CreditDialogComponent} from "../dialog/credit-dialog/credit-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import * as io from "socket.io-client";
import {SocketioService} from "../services/notification.service";
import {Router} from "@angular/router";

export interface SectionNotif {
  _id         : string,
  date_seen   : string,
  date_create : string,
  title       : string,
  message     : string,
  url         : string,
  emitter_id  : string,
  receiver_id : string
}

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})

export class MainNavComponent {
  panelOpenState = false;
  color = '#f9f9f9';
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

    isTablet$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Tablet)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  socket: any;
  notifs: SectionNotif[];
  unseenCount: Number;

    getAccountType = () => {
      if (localStorage.role.length > 0) {
        let ck = localStorage.role
        return ck
      }
    }

  deletToken($event) {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    this.DataService.router.navigate(['connexion']);
  }

  notify() {
    let id = localStorage.getItem('id')
    this.DataService.api_post_mes_notifications({id},
      (data) => {
        console.log(data)
      },
      (err) => {
        console.log('err notif', err)
      })
    //this.socketService.emitSocketTest()
  }

  toggle_info() {
    this.DataService.getCredit(
      (data) => {
        localStorage.setItem('credit', data.credit);
      },
      (error) => {
        console.log(error);
      }
    )
    this.dialog.open(CreditDialogComponent, {
      height: 'auto',
      width: '100%',
      maxWidth: '450px',
    });
  }

  routing() {
      let role = localStorage.getItem('role');

      switch (role) {
        case 'admin': return '../admin-copro';
        case 'syndic': return '../mon-parc';
        case 'gestionnaire': return '../mon-parc';
        case 'courtier': return '../mes-syndics';
        case 'architecte': return '../visites-a-faire';
        case 'prestataire': return '../mes-syndics';
        case 'pcs': return '../mon-immeuble';
        default: this.deletToken(''); break;
      }
  }

  unseenNotif() {
    let hasUnseen = false;
    this.notifs?.map(e => {
      if (!e.date_seen)
        hasUnseen = true
    })
    if (hasUnseen)
      this.DataService.api_post_unseen_notifications({}, (notifications) => {
        if (notifications.notifications.length) {
          this.notifs = notifications.notifications.reverse();
          this.unseenCount = null;
        }
      }, (err) => {
        console.log('err notif unseen', err)
      })
  }

  redirectTo(url) {
    this.router.navigate([url]);
  }

  fetchNotif = () => {
    let id = localStorage.getItem('id')
    this.DataService.api_post_mes_notifications({id}, (notifications) => {
      if (notifications.notifications.length > 0) {
        this.notifs = notifications.notifications.reverse();

        let unseenCount = 0;
        notifications.notifications.map(e => {
          unseenCount += !e.date_seen ? 1 : 0;
        });

        this.unseenCount = unseenCount === 0 ? null : unseenCount;
      }
    }, (err) => {
      console.log('err notif fetch', err)
    })
  }

  deleteNotif(i) {
      if (confirm("Supprimer la notification?")) {
        this.DataService.api_post_del_notification({_id: this.notifs[i]._id},
          (data) => {
            console.log(data.message);
            this.notifs.splice(i, 1);
          },
          (error) => {
            alert(error.error.message);
          });
      }
  }

  ngOnInit() {
    this.socketService.setupSocketConnection(this.fetchNotif);
  }

  constructor(private breakpointObserver: BreakpointObserver, public DataService : DataService, public dialog: MatDialog, private socketService: SocketioService, private router: Router) {
    this.fetchNotif()
  }
}

