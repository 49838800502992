import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSliderModule } from '@angular/material/slider';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBadgeModule } from '@angular/material/badge';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import { TokenInterceptorService } from './services/token-interceptor.service';
import {
  AuthGuard
} from './services/auth.guard';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { RatingModule } from 'ng-starrating';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import {MatChipsModule} from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
//page

import { ConnexionComponent } from './connexion/connexion.component';

import { SyndicComponent } from './page/syndic/syndic.component';
  //child
  import { DashboardSyndicsComponent } from './page/syndic/dashboard/dashboard-syndics/dashboard-syndics.component';
  import { SyndicsCourtiersComponent } from './page/syndic/syndics-courtiers/syndics-courtiers/syndics-courtiers.component';
  import { SyndicsCourtiersDetailsComponent } from './page/syndic/syndics-courtiers-details/syndics-courtiers-details/syndics-courtiers-details.component';
  import { SyndicsPrestatairesComponent } from './page/syndic/syndics-prestataires/syndics-prestataires/syndics-prestataires.component';
  import { EvaluationPrestatairesComponent } from './page/syndic/evaluation-prestataires/evaluation-prestataires.component';
  import { DevisPrestatairesComponent } from './page/syndic/devis-prestataires/devis-prestataires.component';

import { AjoutCollaborateursComponent } from './page/ajout-collaborateurs/ajout-collaborateurs.component';
  // child
  import { DashboardComponent } from './page/ajout-collaborateurs/dashboard/dashboard/dashboard.component';
  import { FormGestionnairesComponent } from './page/ajout-collaborateurs/formulaires/form-gestionnaires/form-gestionnaires.component';
  import { FormPrestatairesComponent } from './page/ajout-collaborateurs/formulaires/form-prestataires/form-prestataires.component';
  import { FormCourtiersComponent } from './page/ajout-collaborateurs/formulaires/form-courtiers/form-courtiers.component';
  import { ChangerDroitsComponent } from './page/ajout-collaborateurs/changer-droits/changer-droits/changer-droits.component';

import { MonParcComponent } from './page/mon-parc/mon-parc.component';
  //child
  import { DashboardMonParcComponent } from './page/mon-parc/dashboard/dashboard-mon-parc/dashboard-mon-parc.component';
  import { AjouterUnImmeubleComponent } from './page/mon-parc/ajouter-un-immeuble/ajouter-un-immeuble/ajouter-un-immeuble.component';
  import { AjouterUnParcComponent } from './page/mon-parc/ajouter-un-parc/ajouter-un-parc.component';
  import { MonParcImmeubleComponent } from './page/mon-parc/mon-parc-immeuble/mon-parc-immeuble/mon-parc-immeuble.component';

import { EnCoursSelectionComponent } from './page/en-cours-selection/en-cours-selection.component';
  //child
  import { DashboardEnCoursComponent } from './page/en-cours-selection/dashboard/dashboard-en-cours/dashboard-en-cours.component';
  import { AjouterUnImmeubleEnCoursComponent } from './page/en-cours-selection/ajouter-un-immeuble/ajouter-un-immeuble-en-cours/ajouter-un-immeuble-en-cours.component';
  import { AjouterUnParcEnCoursComponent } from './page/en-cours-selection/ajouter-un-parc-en-cours/ajouter-un-parc-en-cours.component';
  import { SelectionImmeubleComponent } from './page/en-cours-selection/selection-immeuble/selection-immeuble/selection-immeuble.component';
  import { VisiteEnCoursComponent } from './page/en-cours-selection/visite-en-cours/visite-en-cours.component';

import { MesCourtiersComponent } from './page/mes-courtiers/mes-courtiers.component';
  //child
  import { DashboardCourtiersComponent } from './page/mes-courtiers/dashboard/dashboard-courtiers/dashboard-courtiers.component';
  import { MesCourtiersSelectionComponent } from './page/mes-courtiers/mes-courtiers-selection/mes-courtiers-selection/mes-courtiers-selection.component';


import { VisitesAFairesComponent } from './page/visites-a-faires/visites-a-faires.component';
  //child
  import { DashboardVisitesComponent } from './page/visites-a-faires/dashboard/dashboard-visites/dashboard-visites.component';
  import { VisitesFormulaireComponent } from './page/visites-a-faires/visites-formulaire/visites-formulaire/visites-formulaire.component';

import { PreReceptionComponent } from './page/pre-reception/pre-reception.component';
  //child
  import { DashboardPreReceptionDetailsComponent } from './page/pre-reception/dashboard/dashboard-pre-reception-details/dashboard-pre-reception-details.component';
  import { PreReceptionDetailsComponent } from './page/pre-reception/pre-reception-details/pre-reception-details/pre-reception-details.component';

import { AEtudierComponent } from './page/a-etudier/a-etudier.component';
  //child
  import { DashboardAEtudierComponent } from './page/a-etudier/dashboard/dashboard-a-etudier/dashboard-a-etudier.component';
  import { AEtudierDetailsComponent } from './page/a-etudier/a-etudier-details/a-etudier-details/a-etudier-details.component';

import { MonAbonnementComponent } from './page/mon-abonnement/mon-abonnement.component';
  //child
  import { DashboardAboComponent } from './page/mon-abonnement/dashboard/dashboard-abo/dashboard-abo.component';


import { MesPrestatairesComponent } from './page/mes-prestataires/mes-prestataires.component';
  //child
  import { DashboardPrestaComponent } from './page/mes-prestataires/dashboard/dashboard-presta/dashboard-presta.component';

import { AdminCoproComponent } from './admin-copro/admin-copro.component';
  //child

import { MonParcPCSComponent } from "./page/pcs/mon-parc.component";
  //child
  import { DashboardMonParcPCSComponent } from "./page/pcs/dashboard/dashboard-mon-parc/dashboard-mon-parc.component";
  import { MonParcPCSImmeubleComponent } from "./page/pcs/mon-parc-immeuble/mon-parc-immeuble/mon-parc-immeuble.component";

import { MonProfilComponent } from './page/mon-profil/mon-profil.component';
  //child
  import { DashbordMonProfilComponent } from './page/mon-profil/dashboard/dashbord-mon-profil/dashbord-mon-profil.component';

import { ContactComponent } from './page/contact/contact.component';
  //child
  import { DashboardContactComponent } from './page/contact/dashboard/dashboard-contact/dashboard-contact.component';

import { DashboardAdminComponent } from './admin-copro/dashboard/dashboard-admin/dashboard-admin.component';
import { NavigationAdminComponent } from './admin-copro/navigation-admin/navigation-admin.component';
  //child
  import { FormSyndicComponent } from './admin-copro/form/form-syndic/form-syndic.component';
  import { FormArchitectesComponent } from './admin-copro/form/form-architectes/form-architectes.component';
  import { FormCourtiersAdminComponent } from './admin-copro/form/form-courtiers-admin/form-courtiers-admin.component';
  import { FormPrestatairesAdminComponent } from './admin-copro/form/form-prestataires-admin/form-prestataires-admin.component';
  import { ChangerDroitsAdminComponent } from './admin-copro/form/changer-droits/changer-droits-admin/changer-droits-admin.component';
  import { ProfilAdminComponent } from './admin-copro/form/profil-admin/profil-admin.component';
  import { ContactAdminComponent } from './admin-copro/form/contact-admin/contact-admin.component';
  import { GererCourtiersComponent } from './admin-copro/form/gerer-courtiers/gerer-courtiers.component';
  import { GererArchitectesComponent } from './admin-copro/form/gerer-architectes/gerer-architectes.component';
  import { GererSyndicsComponent } from './admin-copro/form/gerer-syndics/gerer-syndics.component';


import { MainNavComponent } from './main-nav/main-nav.component';
import { GalleryComponent } from './gallery/gallery.component';
import { DialogComponent } from './dialog/dialog.component';
import { SyndicDialogComponent } from './dialog/syndic-dialog/syndic-dialog.component';
import { PrestataireDialogComponent } from './dialog/prestataire-dialog/prestataire-dialog.component';
import { CourtierDialogComponent } from './dialog/courtier-dialog/courtier-dialog.component';
import {CourtierDemandeDialogComponent} from "./dialog/courtier-demande-dialog/courtier-demande-dialog.component";
import {CourtierAssignDialogComponent} from "./dialog/courtier-assign-dialog/courtier-assign-dialog.component";
import {CourtierEtudeDialogComponent} from "./dialog/courtier-etude-dialog/courtier-etude-dialog.component";
import {GestionnaireAssignDialogComponent} from "./dialog/gestionnaire-assign-dialog/gestionnaire-assign-dialog.component";
import { ArchitecteDialogComponent } from './dialog/architecte-dialog/architecte-dialog.component';
import { AjoutImmeubleDialogComponent } from './dialog/ajout-immeuble-dialog/ajout-immeuble-dialog.component';
import { GestionnaireDialogComponent } from './dialog/gestionnaire-dialog/gestionnaire-dialog.component';
import { FaireVisiteDialogComponent } from './dialog/faire-visite-dialog/faire-visite-dialog.component';
import { DesordresDialogComponent } from './dialog/desordres-dialog/desordres-dialog.component';
import {MajImmeubleDialogComponent} from "./dialog/maj-immeuble/maj-immeuble-dialog.component";
import {PrestataireDemandeDialogComponent} from "./dialog/prestataire-demande-dialog/prestataire-demande-dialog.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {CommentaireDialogComponent} from "./dialog/commentaire-dialog/commentaire-dialog.component";
import {GestionnaireUnAssignDialogComponent} from "./dialog/gestionnaire-unassign-dialog/gestionnaire-unassign-dialog.component";
import {CreditDialogComponent} from "./dialog/credit-dialog/credit-dialog.component";
import {CreditAlertDialogComponent} from "./dialog/credit-alert-dialog/credit-alert-dialog.component";
import {SocketioService} from "./services/notification.service";

@NgModule({
  declarations: [
    AppComponent,
    SyndicComponent,
    AjoutCollaborateursComponent,
    VisitesAFairesComponent,
    PreReceptionComponent,
    AEtudierComponent,
    MonAbonnementComponent,
    MonParcComponent,
    MonParcPCSComponent,
    EnCoursSelectionComponent,
    MesPrestatairesComponent,
    MesCourtiersComponent,
    MainNavComponent,
    ConnexionComponent,
    FormGestionnairesComponent,
    FormPrestatairesComponent,
    FormCourtiersComponent,
    DashboardComponent,
    ChangerDroitsComponent,
    AjouterUnImmeubleComponent,
    MonParcImmeubleComponent,
    MonParcPCSImmeubleComponent,
    DashboardMonParcComponent,
    DashboardMonParcPCSComponent,
    DashboardEnCoursComponent,
    AjouterUnImmeubleEnCoursComponent,
    SelectionImmeubleComponent,
    DashboardCourtiersComponent,
    MesCourtiersSelectionComponent,
    DashboardVisitesComponent,
    VisitesFormulaireComponent,
    DashboardPreReceptionDetailsComponent,
    PreReceptionDetailsComponent,
    DashboardAEtudierComponent,
    AEtudierDetailsComponent,
    DashboardSyndicsComponent,
    SyndicsCourtiersComponent,
    SyndicsCourtiersDetailsComponent,
    SyndicsPrestatairesComponent,
    AdminCoproComponent,
    DashboardAdminComponent,
    FormSyndicComponent,
    FormArchitectesComponent,
    FormCourtiersAdminComponent,
    FormPrestatairesAdminComponent,
    ChangerDroitsAdminComponent,
    EvaluationPrestatairesComponent,
    DevisPrestatairesComponent,
    VisiteEnCoursComponent,
    DashboardPrestaComponent,
    DashboardAboComponent,
    MonProfilComponent,
    DashbordMonProfilComponent,
    ContactComponent,
    DashboardContactComponent,
    ProfilAdminComponent,
    ContactAdminComponent,
    NavigationAdminComponent,
    GererCourtiersComponent,
    GererArchitectesComponent,
    GererSyndicsComponent,
    AjouterUnParcComponent,
    AjouterUnParcEnCoursComponent,
    GalleryComponent,
    DialogComponent,
    SyndicDialogComponent,
    PrestataireDialogComponent,
    PrestataireDemandeDialogComponent,
    CourtierDialogComponent,
    CourtierDemandeDialogComponent,
    CourtierAssignDialogComponent,
    GestionnaireAssignDialogComponent,
    GestionnaireUnAssignDialogComponent,
    CourtierEtudeDialogComponent,
    CreditDialogComponent,
    CreditAlertDialogComponent,
    ArchitecteDialogComponent,
    AjoutImmeubleDialogComponent,
    MajImmeubleDialogComponent,
    GestionnaireDialogComponent,
    FaireVisiteDialogComponent,
    DesordresDialogComponent,
    CommentaireDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatGridListModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTabsModule,
    MatSelectModule,
    FlexLayoutModule,
    MatSliderModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatBadgeModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatFileInputModule,
    RatingModule,
    GalleryModule,
    LightboxModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatMenuModule,
  ],
  exports: [
    MatMenuModule,
  ],
  entryComponents: [DialogComponent],
  providers: [
    GalleryComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    AuthGuard,
    SocketioService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
