<!-- MON PARC ONGLET MENU-->
<div id="mon_parc_onglet">
    <div fxLayout="row wrap">
        <div fxLayoutAlign.xs="center" fxLayoutAlign.sm="center" fxFlex="33" fxFlex.xs="100" fxFlex.md="33" fxFlex.sm="50" fxFlex.lg="33" fxFlex.gt-lg="25" class="padding-card padding-card-pos">
            <mat-card fxFlex="100" id={{listCopro?.id_el}} (click)="listCopro?.dateVisite ? info(listCopro?.id_el) : null" style="cursor: pointer;"> <!--routerLink="../mon-parc-immeuble/{{listCopro?.id_el}}"-->
                <mat-card-content>
                    <div fxLayoutAlign="center" class="btn_couleur_hover">
                        <div *ngIf="!listCopro?.imgProfil" class="img_virtuelle res_img" [ngStyle]="{ 'background-size': 'cover', 'background-image': 'url(../../../../../assets/img/photo/immeuble-base.jpg)'}">
                            <div *ngIf="listCopro?.dateDemandeVisite && !listCopro?.dateVisite" fxLayoutAlign="center" style="color: #888; background-color: rgba(248, 213, 126, 0.3); margin-top: 25px;">En cours de visite</div>
                        </div>

                        <div *ngIf="listCopro?.imgProfil" class="img_virtuelle res_img pointer" [ngStyle]="{ 'background-size': 'cover', 'background-image': 'url(https://copro-back.cantem.fr/uploads/batiment/'+ listCopro?.src_img +')'}">
                            <div *ngIf="listCopro?.dateDemandeVisite && !listCopro?.dateVisite" fxLayoutAlign="center" style="color: #888; background-color: rgba(248, 213, 126, 0.3); margin-top: 25px;">En cours de visite</div>
                        </div>
                    </div>

                    <div fxLayout="row wrap max_width" fxLayoutAlign="center" class="font_en_cours pointer">
                        <div class="res_adresse  max_width" fxFlex="70">
                            <div class="data_dynamic_done">{{listCopro?.address}}</div>
                            <div class="data_dynamic_done">{{listCopro?.codePostal}} {{listCopro?.ville}}</div>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="center" class="icon_pos">
                        <div fxFlex="70" fxFlex.xs="65" fxFlex.md="53">
                            <div fxLayout="row wrap">
                                <div fxFlex="25" fxFlex.xs="50">
                                    <div fxLayoutAlign="center" class="icon_height"><img src="../../../../assets/img/icones/recherche.jpg"></div>
                                    <div class="txt_marge data_dynamic_done txt_icon" fxLayoutAlign="center">{{listCopro?.nbEval || 0}}</div>
                                </div>
                                <div fxFlex="25" fxFlex.xs="50">
                                    <div fxLayoutAlign="center" class="icon_height"><img src="../../../../assets/img/icones/calcule.jpg"></div>
                                    <div class="txt_marge data_dynamic_done txt_icon" fxLayoutAlign="center">{{listCopro?.nbDevis || 0}}</div>
                                </div>
                                <div fxFlex="25" fxFlex.xs="50">
                                    <div fxLayoutAlign="center" class="icon_height"><img src="../../../../assets/img/icones/doc.jpg"></div>
                                    <div class="txt_marge data_dynamic_done txt_icon" fxLayoutAlign="center">{{listCopro?.nbVisite.length || 0}}</div>
                                </div>
                                <div fxFlex="25" fxFlex.xs="50">
                                    <div fxLayoutAlign="center" class="icon_height"><img src="../../../../assets/img/icones/silouette.jpg"></div>
                                    <div class="txt_marge data_dynamic_done txt_icon" fxLayoutAlign="center">{{listCopro?.gestionnaire?.lastName || 'aucun'}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

