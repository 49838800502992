
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
  [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation' || (isTablet$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) || (isTablet$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false && (isTablet$ | async) === false">

    <!--Compte admin-->
    <div routerLink="{{routing()}}" class="pointer"><mat-toolbar class="titre_site"><img src="../assets/img/icones/oval.png" class="item_img">Coprovisit</mat-toolbar></div>
    <mat-nav-list *ngIf="getAccountType() == 'admin'">
      <a mat-list-item routerLinkActive="selected" routerLink="/admin-copro/dashboard"><img src="../assets/img/icones/layout.png" class="item_img">Tableau de bord</a>
    </mat-nav-list>

    <!--Compte syndics-->
    <mat-nav-list *ngIf="getAccountType() == 'syndic'">
      <a mat-list-item routerLinkActive="selected" routerLink="../ajout-collaborateurs"><img src="../assets/img/icones/layout.png" class="item_img">Ajout collaborateur</a>
      <a mat-list-item routerLinkActive="selected" routerLink="../mon-parc"><img src="../assets/img/icones/noun-buildings-807884.png" class="item_img">Mon parc</a>
      <a mat-list-item routerLinkActive="selected" routerLink="../en-cours-selection" ><img src="../assets/img/icones/noun-selection-2840888.png" class="item_img">En cours de sélection</a>
      <a mat-list-item routerLinkActive="selected" routerLink="../mes-prestataires" ><img src="../assets/img/icones/noun-people-884724.png" class="item_img">Mes prestataires</a>
      <a mat-list-item routerLinkActive="selected" routerLink="../mes-courtiers" ><img src="../assets/img/icones/noun-analyse-chart-2372995.png" class="item_img">Mes courtiers</a>
    </mat-nav-list>

    <!--Compte gestionnaires-->
    <mat-nav-list *ngIf="getAccountType() == 'gestionnaire'">
      <a mat-list-item routerLinkActive="selected" routerLink="../mon-parc"><img src="../assets/img/icones/noun-buildings-807884.png" class="item_img">Mon parc</a>
      <a mat-list-item routerLinkActive="selected" routerLink="../en-cours-selection" ><img src="../assets/img/icones/noun-selection-2840888.png" class="item_img">En cours de sélection</a>
      <a mat-list-item routerLinkActive="selected" routerLink="../mes-prestataires" ><img src="../assets/img/icones/noun-people-884724.png" class="item_img">Mes prestataires</a>
      <a mat-list-item routerLinkActive="selected" routerLink="../mes-courtiers" ><img src="../assets/img/icones/noun-analyse-chart-2372995.png" class="item_img">Mes courtiers</a>
    </mat-nav-list>

    <!--Compte PCS-->
    <mat-nav-list *ngIf="getAccountType() == 'pcs'">
      <a mat-list-item routerLinkActive="selected" routerLink="../mon-immeuble"><img src="../assets/img/icones/noun-buildings-807884.png" class="item_img">Mon Immeuble</a>
    </mat-nav-list>

    <!--Compte courtiers-->
    <mat-nav-list *ngIf="getAccountType() == 'courtier'">
      <a mat-list-item routerLinkActive="selected" routerLink="../mes-syndics" ><img src="../assets/img/icones/mes-syndics.png" class="item_img">Mes syndics</a>
      <a mat-list-item routerLinkActive="selected" routerLink="../a-etudier" ><img src="../assets/img/icones/mon-abonnement.png" class="item_img">A étudier</a>
    </mat-nav-list>

    <!--Compte architectes-->
    <mat-nav-list *ngIf="getAccountType() == 'architecte'">
      <a mat-list-item routerLinkActive="selected" routerLink="../visites-a-faire" ><img src="../assets/img/icones/layout.png" class="item_img">Visites à faire</a>
      <a mat-list-item routerLinkActive="selected" routerLink="../pre-reception" ><img src="../assets/img/icones/mon-abonnement.png" class="item_img">Avis Travaux</a>
    </mat-nav-list>

    <!--Compte prestataires-->
    <mat-nav-list *ngIf="getAccountType() == 'prestataire'">
      <a mat-list-item routerLinkActive="selected" routerLink="../mes-syndics" ><img src="../assets/img/icones/mes-syndics.png" class="item_img">Mes syndics</a>
      <a mat-list-item routerLinkActive="selected" routerLink="../mon-abonnement" ><img src="../assets/img/icones/mon-abonnement.png" class="item_img">Mes abonnements</a>
    </mat-nav-list>

  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()" *ngIf="(isHandset$ | async) || (isTablet$ | async)">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
    </mat-toolbar>

    <div fxLayoutAlign="end" class="icons_top">
      <button mat-icon-button *ngIf="getAccountType() === 'syndic' || getAccountType() === 'gestionnaire'">
        <mat-icon matBadge="" matBadgePosition="after" matBadgeColor="accent"  matBadgeSize="small" class="pointer default-image icon_notifications" (click)="toggle_info()">credit_card</mat-icon>
      </button>
      <button mat-icon-button [matMenuTriggerFor]="menu" (click)="unseenNotif()" aria-label="Example icon-button with a menu">
        <mat-icon
          [matBadge]="unseenCount"
          matBadgePosition="after"
          matBadgeColor="accent"
          matBadgeSize="small"
          class="pointer default-image icon_notifications">
          notifications
        </mat-icon>
      </button>
      <button mat-icon-button [matMenuTriggerFor]="menu2"  aria-label="Example icon-button with a menu">
        <mat-icon>person</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item disabled *ngIf="!notifs">
          <mat-icon>notifications_off</mat-icon>
          <span>Aucune notifications pour le moment</span>
        </button>
        <mat-list-item
          *ngFor="let notif of notifs; let i = index"
          style="font-size: 10px;border-bottom: 0.5px solid #F9F9F9;"
          (click)="notify()"
        >
          <button mat-menu-item
                  style="margin-bottom: 8px; display: flex; justify-content: flex-start; align-items: center;"
                  [ngStyle]="{'background-color' : notif.date_seen === null ? 'rgba(255, 215, 64, 0.2)' : 'transparent'}">
            <mat-icon (click)="redirectTo(notif.url)">{{notif.date_seen === null ? "notification_important" : "notifications_on"}}</mat-icon>
            <span style="white-space: normal;" (click)="redirectTo(notif.url)">{{notif.message}}</span>
            <mat-icon (click)="deleteNotif(i)">delete</mat-icon>
          </button>
        </mat-list-item>
        <!--button mat-menu-item disabled>
          <mat-icon>notifications_on</mat-icon>
          <span>Activer les notifications</span>
        </button>
        <button mat-menu-item>
          <mat-icon>notifications_off</mat-icon>
          <span>Désactiver les notifications</span>
        </button-->
      </mat-menu>
      <mat-menu #menu2="matMenu">
        <button mat-menu-item>
          <a class="list_profil" style="text-align: center; background-color: #fff;color: inherit;text-decoration: none;" mat-list-item routerLink routerLink="../mon-profil">Profil</a>
        </button>
        <button mat-menu-item>
          <a class="list_profil" style="text-align: center; background-color: #fff;color: inherit;text-decoration: none;" mat-list-item routerLink routerLink="../contact">Contact</a>
        </button>
        <button mat-menu-item>
          <a class="list_profil" (click)="deletToken($event)" style="text-align: center; background-color: #fff;" mat-list-item>Déconnexion</a>
        </button>

      </mat-menu>

    </div>

      <!--<div fxLayoutAlign="end" class="icons_top">
        <span class="notifications" *ngIf="getAccountType() === 'syndic'">
          <mat-icon matBadge="" matBadgePosition="after" matBadgeColor="accent"  matBadgeSize="small" class="pointer default-image icon_notifications" (click)="toggle_info()">credit_card</mat-icon>
        </span>
        <span class="notifications">
          <mat-icon matBadge="" matBadgePosition="after" matBadgeColor="accent"  matBadgeSize="small" class="pointer default-image icon_notifications">notifications</mat-icon>
        </span>
        <div style="position: absolute;">
          <mat-nav-list>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-icon class="default-image pointer icon_profil">person</mat-icon>
              </mat-expansion-panel-header>
                <a class="list_profil" style="text-align: center; background-color: #fff;" mat-list-item routerLink routerLink="../mon-profil">Profil</a>
                <a class="list_profil" style="text-align: center; background-color: #fff;" mat-list-item routerLink routerLink="../contact">Contact</a>
                <a class="list_profil" (click)="deletToken($event)" style="text-align: center; background-color: #fff;" mat-list-item>Déconnexion</a>
            </mat-expansion-panel>
          </mat-nav-list>
        </div>
      </div>-->


    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
