import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {DataService} from "../../../../data.service";

export interface Section {
  image: string;
  nom_syndic: string;
  abo: boolean;
  codePostal: string;
  id: number;
}

@Component({
  selector: 'app-changer-droits-admin',
  templateUrl: './changer-droits-admin.component.html',
  styleUrls: ['./changer-droits-admin.component.scss']
})

export class ChangerDroitsAdminComponent implements OnInit {

onEvent(event, abo, syndicId) {
  event.stopPropagation();
  this.activatedRoute.params.subscribe(params => {
    const prestaId = params['id'];

    this.DataService.apiAboPrestaToSyndic(
      {prestaId, syndicId, option: !abo},
      (data) => {
        alert(data.message);
        window.location.reload();
      },
      (error) => {
        console.log(error)
      }
    )
  });
}

color_line(event){
  if(event.currentTarget.parentElement.classList.contains("droits")) {
    event.currentTarget.parentElement.classList.remove("droits");
    event.currentTarget.parentElement.classList.add("droits_selected");

  } else if (event.currentTarget.parentElement.classList.contains('droits_selected')) {
    event.currentTarget.parentElement.classList.remove('droits_selected');
    event.currentTarget.parentElement.classList.add('droits');
  }
}

move_item_colonne_gauche_to_droite_by_id(id) {
  let i;
  for ( i in this.listSyndicsPresta) {
    if (this.listSyndicsPresta[i].id === id) {
      this.listSyndicsDisponibles.push(this.listSyndicsPresta[i]);
      this.listSyndicsPresta.splice(i, 1);
    }
  }
}

move_item_colonne_droite_to_gauche_by_id(id) {
    let i;
    for ( i in this.listSyndicsDisponibles) {
        if (this.listSyndicsDisponibles[i].id === id) {
            this.listSyndicsPresta.push(this.listSyndicsDisponibles[i]);
            this.listSyndicsDisponibles.splice(i, 1);
        }
    }
}


echange_line(event){
  if (confirm("Voulez-vous enlever ces syndicats de votre selection?")) {
    let el = document.querySelectorAll(".colonne_gauche.droits_selected");
    let syndics = [];
    for (let i in el) {
      if (el[i].id)
        syndics.push(el[i].id)
    }
    this.activatedRoute.params.subscribe(params => {
      const id = params['id'];
      this.DataService.apiAssignPrestataireToSyndic(
        {prestataireId: id, syndics, option: false},
        (data) => {

          for (let i in data.successId) {
            this.move_item_colonne_gauche_to_droite_by_id(data.successId[i])
          }
          //window.location.reload();
        },
        (error) => {
          console.log("erreur: ", error)
        }
      )
    })
  }
}

echange_line_2(event){
  if(confirm("Voulez-vous ajouter ces syndicats à votre selection?")) {
    let el = document.querySelectorAll(".colonne_droite.droits_selected");
    let syndics = [];
    for (let i in el) {
      if (el[i].id)
        syndics.push(el[i].id)
    }
    this.activatedRoute.params.subscribe(params => {
      const id = params['id'];
      this.DataService.apiAssignPrestataireToSyndic(
        {prestataireId: id, syndics, option: true},
        (data) => {
          console.log("data: ", data.successId)

          for(let i in data.successId) {
            this.move_item_colonne_droite_to_gauche_by_id(data.successId[i])
          }
          //window.location.reload();
        }, (error) => {
          console.log("erreur: ", error)
        });
    });
  }
}

  listSyndicsPresta: Section[] = [];

  listSyndicsDisponibles: Section[] = [];

  resultPresta;
  idPresta;

  constructor(private activatedRoute: ActivatedRoute, public DataService: DataService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      const id = params['id'];
      this.idPresta = id;
      this.DataService.api_single_prestataire(
        {_id:id},
        (data) => {
          this.resultPresta = data.prestataire;
          this.DataService.api_get_syndic(
            async (data) => {
              for (let key in data.syndics) {
                let bool = await this.resultPresta.abonnements.includes(data.syndics[key]._id);

                if (data.syndics[key].prestataires.find(id => id === this.idPresta)) {
                  this.listSyndicsPresta[key] = {
                    image: data.syndics[key].image ?? '../../../../assets/img/icones/silouette.jpg',
                    nom_syndic: data.syndics[key].nomSyndic,
                    codePostal: data.syndics[key].codePostal,
                    id: data.syndics[key]._id,
                    abo: bool
                  };
                } else {
                  this.listSyndicsDisponibles[key] = {
                    image: data.syndics[key].image ?? '../../../../assets/img/icones/silouette.jpg',
                    nom_syndic: data.syndics[key].nomSyndic,
                    codePostal: data.syndics[key].codePostal,
                    id: data.syndics[key]._id,
                    abo: bool
                  };
                }
              }
            },
            (error) => {
              console.log("erreur: ", error);
            });
        },
        (error)=>{
          console.log("erreur: ", error)
        }
      );
    });
  }

}
