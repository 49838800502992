import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { Validators, FormBuilder } from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { ArchitecteDialogComponent } from '../../../dialog/architecte-dialog/architecte-dialog.component';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';

export interface CodePostal {
  name: string;
}
 
@Component({
  selector: 'app-form-architectes',
  templateUrl: './form-architectes.component.html',
  styleUrls: ['./form-architectes.component.scss']
})
export class FormArchitectesComponent implements OnInit {



  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  codepostaux: CodePostal[] = [];

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our code postal
    if ((value || '').trim()) {
      this.codepostaux.push({name: value.trim()});
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(codepostal: CodePostal): void {
    const index = this.codepostaux.indexOf(codepostal);

    if (index >= 0) {
      this.codepostaux.splice(index, 1);
    }
  }

  msgErreur;
  
  email; firstName; lastName; nomCabinet; siren; address; codePostal; ville; phone; zoneInter;

  addArchitecte(email, firstName, lastName, nomCabinet, siren, address, codePostal, ville, phone) {
    let zoneInter = new Array();
    for(var key in this.codepostaux)
      zoneInter.push(this.codepostaux[key].name)
    
       console.log(this.codepostaux);

    this.DataService.api('/create/architecte', {
      email: email, firstName: firstName, //
      lastName: lastName, nomCabinet: nomCabinet, //
      siren: siren, address: address, //
      codePostal: codePostal, ville: ville, //
      phone: phone, zoneInter: zoneInter //
    },

    (res)=>{
      if(this.formArchitecte.valid) 
      {
        console.log("Yipi :", res)  
        this.dialog.open(ArchitecteDialogComponent, {
          height: 'auto',
          width: '100%',
          maxWidth: '450px',
        }); 
        this.DataService.router.navigate(['admin-copro/dashboard']);  
      } 
    },
    (error) => {
       console.log (error)
       if (error.error.message)
       {
        this.DataService.error= error.error.message;
        this.msgErreur = this.DataService.error;  
       }
    })
  }

  formArchitecte = this.fb.group({
    emailForm: ['', [Validators.required, Validators.email]],
    firstNameForm: ['', [Validators.required, Validators.minLength(3)]],
    lastNameForm: ['', [Validators.required, Validators.minLength(3)]],
    nomCabinetForm: ['', [Validators.required, Validators.minLength(3)]],
    sirenForm: ['', [Validators.required, Validators.minLength(9)]],
    addressForm: ['', [Validators.required, Validators.minLength(3)]],
    codePostalForm: ['', [Validators.required, Validators.minLength(5)]],
    villeForm: ['', [Validators.required, Validators.minLength(3)]],
    phoneForm: ['', [Validators.required, Validators.minLength(10)]],
  }); 

  numericOnly(event): boolean {    
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  get formCtrl() { return this.formArchitecte.controls; }

  constructor(public DataService : DataService, private fb: FormBuilder, public dialog: MatDialog) { }

  ngOnInit(): void {
  }

}
