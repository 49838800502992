<!-- COURTIER -->

<div *ngIf="getAccountType() == 'courtier'" fxLayout="row wrap">
    <mat-card-content fxFlex="100">
      <mat-form-field fxLayoutAlign="right center" class="input_search" appearance="outline">
        <mat-label>Nom Syndic...</mat-label>
        <input matInput [(ngModel)]="searchVal" (keyup)="searchEngine()"/>
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </mat-card-content>
    <div  fxLayoutAlign.xs="center" fxLayoutAlign.sm="center" fxFlex="33" fxFlex.xs="100" fxFlex.md="33" fxFlex.sm="50" fxFlex.lg="33" fxFlex.gt-lg="25" class="padding-card padding-card-pos" *ngFor="let detail of resultSearch; let i = index">
        <mat-card fxFlex="80" routerLink="../mes-syndics-courtiers/{{detail.id_el}}" id={{detail.id_el}} class="pointer">
            <div class="ic_right"><mat-button-toggle (click)="onEvent($event)" (click)="confirmation($event)" class="ic_croix"><img src="../../../../../assets/img/icones/croix.png"></mat-button-toggle></div>
            <div class="ic_right"><mat-button-toggle (click)="onEvent($event)" class="ic_cloche ic_cloche_pos"><img src="../../../../../assets/img/icones/cloche.png"></mat-button-toggle></div>
            <mat-card-content class="content-140 contentxs font_result">
                <div fxLayoutAlign="center">
                    <div class="result-box res_img" fxLayoutAlign="center center">
                        <div width="70%" class="img_virtuelle_icone" [ngStyle]="{ 'background-position': 'center', 'background-repeat': 'no-repeat', 'background-size': 'contain', 'background-image': 'url(' + detail.src_img + ')'}"></div>
                    </div>
                </div>
                <div fxLayoutAlign="center" class="title res_name data_dynamic" style="margin: 20px 0 15px 0;">{{ detail.name }}</div>

                <div fxLayout="row wrap" class="ic_marge ic_bot">
                    <div fxFlex="33" fxFlex.xs="100">
                        <div fxLayoutAlign="center" class="iconesxs"><img style="margin-bottom: 10px; width: 30px; height: 30px;" src="../../../../../assets/img/icones/noun-building-3370296-1@2x.jpg"></div>
                        <div fxLayoutAlign="center" class="data_dynamic res_marge_bot">{{detail.nbCopro}}</div>
                    </div>
                    <div fxFlex="33" fxFlex.xs="100">
                        <div fxLayoutAlign="center" class="iconesxs"><img style="margin-bottom: 10px; width: 30px; height: 30px;" src="../../../../../assets/img/icones/square-gold-medium.jpg"></div>
                        <div fxLayoutAlign="center" class="data_dynamic res_marge_bot">{{detail.surface}} ㎡</div>
                    </div>
                    <div fxFlex="33" fxFlex.xs="100">
                        <div fxLayoutAlign="center" class="iconesxs"><img style="margin-bottom: 10px; width: 30px; height: 30px;" src="../../../../../assets/img/icones/tools.png"></div>
                        <div fxLayoutAlign="center" class="data_dynamic res_marge_bot">{{detail?.travaux}} <span>€</span></div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<!-- PRESTATAIRE -->

<div *ngIf="getAccountType() == 'prestataire'" fxLayout="row wrap">
    <mat-card-content fxFlex="100">
      <mat-form-field fxLayoutAlign="right center" class="input_search" appearance="outline">
        <mat-label>Nom Syndic...</mat-label>
        <input matInput [(ngModel)]="searchVal2" (keyup)="searchEngine2()"/>
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </mat-card-content>
    <div *ngIf="resultSearch2.length === 0" fxLayoutAlign="center center" fxFlex="100" fxFlex.xs="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.lg="100">
      <div style="background-color: #F9F9F9; padding: 35px 15px;" fxLayoutAlign="center center">
        <div class="font_content">Pas de Syndic associé, pour l'instant...</div>
      </div>
    </div>
    <div  fxLayoutAlign.xs="center" fxLayoutAlign.sm="center" fxFlex="33" fxFlex.xs="100" fxFlex.md="33" fxFlex.sm="50" fxFlex.lg="33" fxFlex.gt-lg="25" class="padding-card padding-card-pos" *ngFor="let detail2 of resultSearch2; let i = index">
        <mat-card fxFlex="80" routerLink="../mes-syndics-prestataires/{{detail2?.id_el}}" [id]="detail2.id_el" class="pointer" (click)="sendIdToChild(detail2.id_el)">
            <div class="ic_right"><mat-button-toggle (click)="onEvent($event)"  (click)="confirmation2($event)" class="ic_croix"><img src="../../../../../assets/img/icones/croix.png"></mat-button-toggle></div>
            <!--div class="ic_right"><mat-button-toggle (click)="onEvent($event)" class="ic_cloche ic_cloche_pos"><img src="../../../../../assets/img/icones/cloche.png"></mat-button-toggle></div-->
            <mat-card-content class="content-140 contentxs font_result">
                <div fxLayoutAlign="center">
                    <div class="result-box res_img" fxLayoutAlign="center center">
                        <div width="70%" class="img_virtuelle_icone" [ngStyle]="{ 'background-position': 'center', 'background-repeat': 'no-repeat', 'background-size': 'contain', 'background-image': 'url(' + detail2.src_img + ')'}"></div>
                    </div>
                </div>
                <div fxLayoutAlign="center" class="title res_name data_dynamic" style="margin: 20px 0 15px 0;">{{ detail2.name }}</div>

                <div fxLayout="row wrap" class="ic_marge ic_bot">
                    <div fxFlex="33" fxFlex.xs="100">
                      <div fxLayoutAlign="center" class="iconesxs"><img style="margin-bottom: 10px; width: 30px; height: 30px;" src="../../../../../assets/img/icones/recherche.jpg"></div>
                        <div fxLayoutAlign="center" class="data_dynamic res_marge_bot">{{detail2?.nbEval}}</div>
                    </div>
                    <div fxFlex="33" fxFlex.xs="100">
                      <div fxLayoutAlign="center" class="iconesxs"><img style="margin-bottom: 10px; width: 30px; height: 30px;" src="../../../../../assets/img/icones/calcule.jpg"></div>
                        <div fxLayoutAlign="center" class="data_dynamic res_marge_bot">{{detail2?.nbDevis}}</div>
                    </div>
                    <div fxFlex="33" fxFlex.xs="100">
                      <div fxLayoutAlign="center" class="iconesxs"><img style="margin-bottom: 10px; width: 30px; height: 30px;" src="../../../../../assets/img/icones/doc.jpg"></div>
                        <div fxLayoutAlign="center" class="data_dynamic res_marge_bot">{{detail2?.nbFacture}} <!--span>€</span--></div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
