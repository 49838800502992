import { Component, OnInit } from '@angular/core';
import { MainNavComponent } from '../../main-nav/main-nav.component';


@Component({
  selector: 'app-a-etudier',
  templateUrl: './a-etudier.component.html',
  styleUrls: ['./a-etudier.component.scss']
})
export class AEtudierComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
