<div fxLayout="row wrap" class="padding-card padding-card-pos">
  <div fxFlex="100">
    <div fxFlex="100" class="contact">
      <form [formGroup]="formData">
        <mat-form-field appearance="outline" class="fill-width">
          <mat-label>Raison du message</mat-label>
          <mat-select [value]="objet" formControlName="objetForm">
            <mat-option value=""></mat-option>
            <mat-option value="Question liée au compte">Question liée au compte</mat-option>
            <mat-option value="Bug lié à la plateforme">Bug lié à la plateforme</mat-option>
            <mat-option value="Suggestions diverses">Suggestions diverses</mat-option>
            <mat-option value="Autre">Autre</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="message_form">
          <mat-form-field appearance="outline" class="message_text" style="width: 100%; height: 50%;">
            <mat-label>Message</mat-label>
            <textarea matInput
                      placeholder="Tapez, ici, votre message"
                      rows="10"
                      pattern="[a-zA-Z0-9 ]*"
                      [value]="message"
                      formControlName="messageForm">
            </textarea>
          </mat-form-field>
        </div>
        <div fxLayoutAlign="center" class="btn_marge_top"><mat-button-toggle class="btn" (click)="sendMessage()">Envoyer</mat-button-toggle></div>
        <span class="alert_msg margeL10" *ngIf="msgErreur"> {{ msgErreur }}</span>
      </form>
    </div>
  </div>
</div>
