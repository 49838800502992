import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../data.service'
import {SocketioService} from "../../services/notification.service";

export interface SectionNotif {
  date_seen   : string,
  date_create : string,
  title       : string,
  message     : string,
  url         : string,
  emitter_id  : string,
  receiver_id : string
}

@Component({
  selector: 'app-navigation-admin',
  templateUrl: './navigation-admin.component.html',
  styleUrls: ['./navigation-admin.component.scss']
})

export class NavigationAdminComponent implements OnInit {
  socket: any;
  notifs: SectionNotif[];
  unseenCount: Number;

  notify() {
    let id = localStorage.getItem('id')
    this.DataService.api_post_mes_notifications({id},
      (datas) => {
        console.log("notifications: ", datas)
        if (datas.notifications.length) {
          this.notifs = datas.notifications.reverse();
          let unseenCount = 0;
          datas.notifications.map(e => {
            unseenCount += !e.date_seen ? 1 : 0;
          });

          this.unseenCount = unseenCount === 0 ? null : unseenCount;
        }
      },
      (err) => {
        console.log('err notif', err)
      })
    //this.socketService.emitSocketTest()
  }

  unseenNotif() {
    let hasUnseen = false;
    this.notifs?.map(e => {
      if (!e.date_seen)
        hasUnseen = true
    })
    if (hasUnseen)
      this.DataService.api_post_unseen_notifications({}, (notifications) => {
        if (notifications.notifications.length) {
          this.notifs = notifications.notifications;
          this.unseenCount = null;
        }
      }, (err) => {
        console.log('err notif unseen', err)
      })
  }

  fetchNotif = () => {
    let id = localStorage.getItem('id')
    this.DataService.api_post_mes_notifications({id},
      (notifications) => {
      if (notifications.notifications.length) {
        this.notifs = notifications.notifications.reverse();
        console.log("notifs: ",this.notifs)
        let unseenCount = 0;
        notifications.notifications.map(e => {
          unseenCount += !e.date_seen ? 1 : 0;
        });

        this.unseenCount = unseenCount === 0 ? null : unseenCount;
      }
    }, (err) => {
      console.log('err notif fetch', err)
    })
  }

  redirectTo(url) {
    this.router.navigate([url]);
  }

  ngOnInit() {

  }

  constructor(public router:Router, public DataService : DataService, private socketService: SocketioService) {
    this.socketService.setupSocketConnection(this.fetchNotif);
  }

  deletToken($event) {
    document.cookie = "doSomethingOnlyOnce=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    console.log (localStorage.getItem('token'))
    localStorage.removeItem('token');
    this.DataService.router.navigate(['connexion']);
  }

}
