import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataService } from '../../../../data.service';
import {MatDialog} from '@angular/material/dialog';
import { FaireVisiteDialogComponent } from '../../../../dialog/faire-visite-dialog/faire-visite-dialog.component';
import { Router } from '@angular/router';
import {CourtierAssignDialogComponent} from "../../../../dialog/courtier-assign-dialog/courtier-assign-dialog.component";
import {GestionnaireAssignDialogComponent} from "../../../../dialog/gestionnaire-assign-dialog/gestionnaire-assign-dialog.component";
import {CreditAlertDialogComponent} from "../../../../dialog/credit-alert-dialog/credit-alert-dialog.component";


export interface Section {}

export interface SectionCourtier {
  id_el: string,
  lastName: string
  company: string
}

export interface SectionGestionnaire {
  id_el: string,
  firstName: string,
  lastName: string
}

@Component({
  selector: 'app-dashboard-en-cours',
  templateUrl: './dashboard-en-cours.component.html',
  styleUrls: ['./dashboard-en-cours.component.scss']
})

export class DashboardEnCoursComponent implements OnInit {

  isChecked = false;
  action;

  role = localStorage.getItem('role');

  onEvent(event) {
    event.stopPropagation();
  }

  confirmation(event) {
    let id = event.srcElement.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.id
    if (id.length == 0) {
      id = event.srcElement.parentNode.parentNode.parentNode.parentNode.parentNode.id
    }
    if (confirm("Confirmer la suppresion ?")) {
      this.deleteCopro(id);
    } else {
      console.log('aborted')
    }
  }

  confirmation2(id, i) {
    if(confirm("Confirmer l'annulation ?")) {
      this.cancelVisit(id, i);
    } else {
      console.log('aborted')
    }
  }

  deleteCopro(_id) {
    let i: any;
    if (this.role === 'syndic') {
      this.DataService.apiCoproDelete({_id},
        (data) => {
          for( i in this.listCopro) {
            if(this.listCopro[i].id_el == _id) {
              this.listCopro.splice(i, 1);
            }
          }
          window.location.reload();
        },
        (error) => {
          alert('erreur lors de la suppression, veuillez réessayez!');
          console.log("error: ", error)
        })
    } else if (this.role === 'gestionnaire') {
      this.DataService.apiCoproDelete({_id, isParc: false},
        (data) => {
          alert(data.message);
          window.location.reload();
          console.log('status changed: ', data);
        },
        (error) => {
          alert('erreur lors de la suppression, veuillez réessayez!');
          console.log("error change status: ", error);
        })
    }
  }

  cancelVisit(_id, i) {
    this.DataService.apiCancelVisit({coproId: _id},
      (data) => {
        localStorage.setItem('credit', data.credit);
        this.resultSearch[i].dateDemandeVisite = null;
      },
      (error) => {
        console.log("error: ", error)
      })
  }

  hide: any;

  nomPCS; prenomPCS; emailPCS; phonePCS; accessCode; gardien; cleCabinet; commentaire


  errNomPCS = '';
  errPrenomPCS = '';
  errEmailPCS = '';
  errPhonePCS = '';

  addVisite(event, nomPCS,emailPCS,phonePCS,accessCode,gardien,cleCabinet,commentaire ) {
    this.DataService.getCredit(
      (data) => {
        localStorage.setItem('credit', data.credit);
        if (data.credit < this.listCopro[event].surface) {
          this.dialog.open(CreditAlertDialogComponent, {
            height: 'auto',
            width: '100%',
            maxWidth: '450px',
          });
        } else {
          this.errNomPCS = '';
          this.errPhonePCS = '';

          if (!nomPCS)
            this.errNomPCS = 'ce champs est requis';
          if (!phonePCS)
            this.errPhonePCS = 'ce champs est requis';
          if (nomPCS && phonePCS) {
            let key = event;
            let copro_id = this.listCopro[key].id
            let infoCopro = {
              nomPCS:nomPCS,
              emailPCS:emailPCS,
              phonePCS:phonePCS,
              accessCode:accessCode,
              gardien:gardien,
              cleCabinet:cleCabinet,
              commentaire:commentaire,
              coproId:copro_id
            }

            this.DataService.api('/gestion/demande-visite', infoCopro,
              (res) => {
                localStorage.setItem('credit', res.credit);
                this.listCopro[key].dateDemandeVisite=true;
                this.listCopro[key].visite=false;
                this.dialog.open(FaireVisiteDialogComponent, {
                  height: 'auto',
                  width: '100%',
                  maxWidth: '450px',
                });
                this.nomPCS = '';
                this.emailPCS = '';
                this.phonePCS = '';
                this.accessCode = '';
                this.gardien = false;
                this.cleCabinet = false;
                this.commentaire = ''
              },
              (error) => {
                if(error.status==200){
                  localStorage.setItem('credit', error.credit);
                  this.listCopro[key].dateDemandeVisite=true;
                  this.listCopro[key].visite=false;
                  this.dialog.open(FaireVisiteDialogComponent, {
                    height: 'auto',
                    width: '100%',
                    maxWidth: '450px',
                  });
                }
                console.log ("JE SUIS UNE ERREUR", error)
                if (error.error.message) {
                  this.DataService.error= error.error.message;
                }
              })
          }
        }
      },
      (error) => {
        console.log(error);
      })
  }

  accesPCS(event, nomPCS, prenomPCS, emailPCS, phonePCS, index) {
    this.errNomPCS = '';
    this.errPrenomPCS = '';
    this.errEmailPCS = '';
    this.errPhonePCS = '';
    let copro_id = this.listCopro[event].id

    let infoPCS = {
      nomPCS,
      prenomPCS,
      emailPCS,
      phonePCS,
      coproId: copro_id
    }

    if (!nomPCS)
      this.errNomPCS = 'ce champs est requis';
    if (!prenomPCS)
      this.errPhonePCS = 'ce champs est requis';
    if (!emailPCS)
      this.errEmailPCS = 'ce champs est requis';
    if (!this.DataService.isValidEmail(emailPCS))
      this.errEmailPCS = "vérifiez le format de l'email, pas d'espace ou de caractères spéciaux"
    if (!phonePCS)
      this.errPhonePCS = 'ce champs est requis';
    if (nomPCS && prenomPCS && this.DataService.isValidEmail(emailPCS) && phonePCS) {
      this.DataService.api('/gestion/access-pcs', infoPCS,
        (data) => {
          this.listCopro[index].pcs = {
            _id: data.pcs?._id ?? null,
            actuelN: data.pcs?.lastName ?? '',
            actuelP: data.pcs?.firstName ?? '',
            firstName: data.pcs?.firstName ?? '',
            lastName: data.pcs?.lastName ?? '',
            email: data.pcs?.email ?? '',
            phone: data.pcs?.phone ?? '',
          };
          alert(data.message);
          this.listCopro[index].action = '';
          this.nomPCS = '';
          this.prenomPCS = '';
          this.emailPCS = '';
          this.phonePCS = '';
        },
        (error) => {
          alert(error.error.message);
        });
    }
  }

  PrestatairesDetailsArray;
  searchVal: string;
  resultSearch = [];
  listCopro = [];

  isShow = false;

  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  listParc = [];

  listCourtiers: SectionCourtier [] = [];
  listGestionnaires: SectionGestionnaire [] = [];

  constructor(public DataService: DataService, public dialog: MatDialog, private router: Router) {
    this.resultSearch = this.listCopro;
  }

  info(id) {
    this.DataService.api_single_copro({_id:id},
      (data)=>{
        this.DataService.copro = data.copro
        //this.imgBat = 'https://copro-back.cantem.fr/uploads/batiment/'+this.copro.batiments[0].image.facadeRue[0]
        this.router.navigate(["en-cours-selection/selection-immeuble/"+id]);
      },
      (error)=>{
        console.log(error)
        this.DataService.copro = error.err.copro
      }
    );
  }

  courtier: string;
  gestionnaire: string;

  changeStatus(coproId, isParc) {
    this.DataService.apiChangeStatusCopro(coproId, isParc,
      (data) => {
        alert("la copropriété a été envoyé dans mon parc");
        window.location.reload();
      },
      (error) => {
        alert("erreur lors du changement de statut");
        console.log("error change status: ", error);
      })
  }

  chooseCourtier(id) {
    this.courtier = id;
  }

  assignCourtiertoCopro(copro) {
    if (this.courtier) {
      this.DataService.apiAssignCourtierToCopro({copro: copro, courtier: this.courtier},
        (data) => {
          this.dialog.open(CourtierAssignDialogComponent, {
            height: 'auto',
            width: '100%',
            maxWidth: '450px',
          });
        },
        (error) => {
          console.log("assign courtier error: ", error);
        });
    }
  }

  chooseGestionnaire(id) {
    this.gestionnaire = id;
  }

  assignGestionnairetoCopro(coproId) {
    if (this.gestionnaire) {
      let isParc = true;
      this.DataService.apiAssignGestionnaireToCopro({gestionnaireId: this.gestionnaire, coproId, isParc},
        (data) => {
          this.dialog.open(GestionnaireAssignDialogComponent, {
            height: 'auto',
            width: '100%',
            maxWidth: '450px',
          });
        },
        (error) => {
          console.log("assign gestionnaire error: ", error);
        });
    }
  }

  validerCP(coproId, action, i) {
    switch (action) {
      case 'nomi':  if (!this.listCopro[i].isChecked)
        this.listCopro[i].errSwitch = 'veuillez selectionner "oui" pour confirmer';
      else {
        this.listCopro[i].errSwitch = '';
        this.changeStatus(coproId, true)
      } break;
      case 'cour': this.assignCourtiertoCopro(coproId); break;
      case 'gest': this.assignGestionnairetoCopro(coproId); break;
      default: break;
    }
  }

  fillFormPCS(copro, i, option) {
    if (!option) {
      this.nomPCS = '';
      this.emailPCS = '';
      this.phonePCS = '';
      this.accessCode = '';
      this.gardien = false;
      this.cleCabinet = false;
      this.commentaire = ''
    } else {
      this.nomPCS = copro.pcs?.lastName ?? '';
      this.emailPCS = copro.pcs?.email ?? '';
      this.phonePCS = copro.pcs?.phone ?? '';
      this.accessCode = '';
      this.gardien = false;
      this.cleCabinet = false;
      this.commentaire = ''
    }
    this.resultSearch[i].visite = option;
  }

  ids = [];

  ngOnInit(): void {
    this.DataService.api_get_encourslist(
      (data)=>{
        console.log(data)
        data.enCours.reverse();
        for (let key in data.enCours) {
          this.ids.push(data.enCours[key]._id);
          if (data.enCours[key].batiments[0]) {
            this.listCopro[key] = {
              key: key,
              id: data.enCours[key]._id,
              address: data.enCours[key].address,
              imgProfil: data.enCours[key].batiments[0],
              src_img: data.enCours[key].batiments[0].image.facadeRue[0],
              ville: data.enCours[key].ville,
              id_el: data.enCours[key]._id,
              reference: data.enCours[key].reference,
              codePostal: data.enCours[key].codePostal,
              gestionnaire: data.enCours[key].gestionnaire,
              dateDemandeVisite: data.enCours[key].dateDemandeVisite,
              dateVisite: data.enCours[key].dateVisite,
              perdu: data.enCours[key].perdu,
              sansSuite: data.enCours[key].sansSuite,
              surface: data.enCours[key].surface,
              pcs: {
                _id: data.enCours[key].pcs?._id ?? null,
                actuelN: data.enCours[key].pcs?.lastName ?? '',
                actuelP: data.enCours[key].pcs?.firstName ?? '',
                firstName: data.enCours[key].pcs?.firstName ?? '',
                lastName: data.enCours[key].pcs?.lastName ?? '',
                email: data.enCours[key].pcs?.email ?? '',
                phone: data.enCours[key].pcs?.phone ?? '',
              },
              nomPCS: '',
              emailPCS: '',
              phonePCS: '',
              visite: false,
              ajout: false,
              action: '',
              nbDevis: 0,
              nbEval: 0,
              nbVisite: [],
              errSwitch: '',
              isChecked: false
            };
          } else {
            this.listCopro[key] = {
              key: key,
              id: data.enCours[key]._id,
              address: data.enCours[key].address,
              imgProfil: data.enCours[key].batiments[0],
              ville: data.enCours[key].ville,
              id_el: data.enCours[key]._id,
              reference: data.enCours[key].reference,
              codePostal: data.enCours[key].codePostal,
              gestionnaire: data.enCours[key].gestionnaire,
              dateDemandeVisite: data.enCours[key].dateDemandeVisite,
              dateVisite: data.enCours[key].dateVisite,
              perdu: data.enCours[key].perdu,
              sansSuite: data.enCours[key].sansSuite,
              surface: data.enCours[key].surface,
              pcs: {
                _id: data.enCours[key].pcs?._id ?? null,
                actuelN: data.enCours[key].pcs?.lastName ?? '',
                actuelP: data.enCours[key].pcs?.firstName ?? '',
                firstName: data.enCours[key].pcs?.firstName ?? '',
                lastName: data.enCours[key].pcs?.lastName ?? '',
                email: data.enCours[key].pcs?.email ?? '',
                phone: data.enCours[key].pcs?.phone ?? '',
              },
              nomPCS: '',
              emailPCS: '',
              phonePCS: '',
              visite: false,
              ajout: false,
              action: '',
              nbDevis: 0,
              nbEval: 0,
              nbVisite: [],
              errSwitch: '',
              isChecked: false
            };
          }
        }
        this.DataService.api_get_devis({copros: this.ids},
          (data) => {
            for (let i in data.list) {
              for (let j in this.listCopro) {
                if (data.list[i].coproId === this.listCopro[j].id_el) {
                  this.listCopro[j].nbEval += 1;
                  if (!this.listCopro[j].nbVisite.includes(data.list[i].visiteId))
                    this.listCopro[j].nbVisite.push(data.list[i].visiteId);
                }
                if (data.list[i].coproId === this.listCopro[j].id_el && data.list[i].devisPDF) {
                  this.listCopro[j].nbDevis += 1
                }
              }
            }
          },
          (error) => {
            console.log(error);
          });

        this.resultSearch = this.listCopro;
      },
      (error)=>{
        console.log(error.status)
      }
    );
    this.resultSearch = this.listCopro;

    this.DataService.api_get_courtier(
      (data) => {
        for (let key in data.courtiers)
          this.listCourtiers[key] = {
            id_el : data.courtiers[key]._id,
            lastName: data.courtiers[key].lastName,
            company: data.courtiers[key].company
          }
      },
      (error) => {
        console.log("erreur: ", error)
      }
    )

    this.DataService.api_get_gestionnaire(
      (data) => {
        for (let key in data.gestionnaires)
          this.listGestionnaires[key] = {
            id_el : data.gestionnaires[key]._id,
            lastName: data.gestionnaires[key].lastName,
            firstName: data.gestionnaires[key].firstName
          }
      },
      (error) => {
        console.log("erreur: ", error)
      }
    )
  }

  searchEngine() {
    this.resultSearch = [];
    //if(this.searchVal)
    for(let key in this.listCopro) {
      if(this.listCopro[key].reference && this.listCopro[key].reference.toLowerCase().indexOf(this.searchVal.toLowerCase())>-1 && this.resultSearch.length<3 || this.listCopro[key].address.toLowerCase().indexOf(this.searchVal.toLowerCase())>-1 && this.resultSearch.length<4){
        this.resultSearch.push(this.listCopro[key]);
      }
    }
    console.log("result: ",this.resultSearch);

  }

  RedirectionOuiNon($event) {
    for(let key in this.listCopro) {
      if (this.listCopro[key].dateVisite == !undefined){
        console.log ("redirection")
      }
      else if (this.listCopro[key].dateVisite == undefined) {
        console.log ("pas de redirection")
      }
    }
  }

}
