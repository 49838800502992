import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../data.service';
import { Validators, FormBuilder } from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { CourtierDemandeDialogComponent } from '../../../../dialog/courtier-demande-dialog/courtier-demande-dialog.component'; ;


@Component({
  selector: 'app-form-courtiers',
  templateUrl: './form-courtiers.component.html',
  styleUrls: ['./form-courtiers.component.scss']
})
export class FormCourtiersComponent implements OnInit {

  msgErreur;

  email; firstName; lastName; company; address; codePostal; ville; phone; syndic;

  addCourtier(email, firstName, lastName, company, address, codePostal, ville, phone) {
    if(this.formCourtier.valid) {
      this.DataService.api('/gestion/demande-courtier', {
          email     : email,
          firstName : firstName,
          lastName  : lastName,
          company   : company,
          phone     : phone,
          address   : address,
          codePostal: codePostal,
          ville     : ville,
          syndic    : localStorage.getItem('id')
        },

        (res) => {
          this.dialog.open(CourtierDemandeDialogComponent, {
            height: 'auto',
            width: '100%',
            maxWidth: '450px',
          });
          //this.DataService.router.navigate(['admin-copro/dashboard']);
        },
        (error) => {
          if (error.error.message) {
            this.DataService.error= error.error.message;
            this.msgErreur = this.DataService.error;
          }
        })
    } else {
      this.msgErreur = "Vérifiez les informations du formulaire"
    }
  }

  formCourtier = this.fb.group({
    emailForm: ['', [Validators.required, Validators.email]],
    firstNameForm: ['', [Validators.required, Validators.minLength(3)]],
    lastNameForm: ['', [Validators.required, Validators.minLength(3)]],
    companyForm: ['', [Validators.required, Validators.minLength(3)]],
    addressForm: ['', [Validators.required, Validators.minLength(3)]],
    codePostalForm: ['', [Validators.required, Validators.minLength(5)]],
    villeForm: ['', [Validators.required, Validators.minLength(3)]],
    phoneForm: ['', [Validators.required, Validators.minLength(10)]],
  });

  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  get formCtrl() { return this.formCourtier.controls; }

  constructor(public DataService : DataService, private fb: FormBuilder,  public dialog: MatDialog) { }

  ngOnInit(): void {
  }

}
