import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { GalleryComponent } from '../../../../gallery/gallery.component';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from '../../../../data.service'
import { HttpClient } from '@angular/common/http';
import { Gallery, GalleryItem, ImageItem } from 'ng-gallery';
import {MajImmeubleDialogComponent} from '../../../../dialog/maj-immeuble/maj-immeuble-dialog.component';
import {MatDialog} from "@angular/material/dialog";
import {FormControl} from "@angular/forms";
import {CourtierEtudeDialogComponent} from "../../../../dialog/courtier-etude-dialog/courtier-etude-dialog.component";

export interface SectionDescription {
  faitLe : string;
  habExclu : string;
  bureauxEclu : string;
  habPro : string;
  occupants : string;
  nbrNiveaux : string;
  caveOuiNon : string;
  caveAcces : string;
  nbrNiveauxCaves : string;
  parkingST : string;
  natureConstr : string;
  precisezConstr : string;
  etatFacadeCanal : string;
}

export interface SectionCarnet {
  images          : [string],
  date            : string,
  metrages        : number,
  desordre        : string,
  situation       : string,
  description     : string,
  corpsEtat       : [string],
  courtierId      : string,
  gestionnaireId  : string,
  architecteId    : string,
  visiteId        : string,
  syndicId        : string,
  coproId         : string,
  commentaire     : string,
  evaluationTTC   : number,
  devis           : [object],
  _id             : string
}

export interface SectionEtudes {
  lastName  : string,
  company   : string,
  date      : string
}

export interface SectionCourtier {
  id_el: string,
  lastName: string,
  company: string,
}

export interface SectionDevis {
  incidentId      : string,
  evaluationTTC   : number,
  metrages        : number,
  coproId    	    : string,
  pcsId           : string,
  syndicId        : string,
  visiteId        : string,
  courtierId      : string,
  architecteId    : string,
  prestataireId   : string,
  gestionnaireId  : string,
  commentaire     : string,
  desordre        : string,
  description     : string,
  situation       : string,
  corpsEtat       : [string],
  images          : [string],
  date            : string,
  demandeDevis    : boolean,
  devisPDF        : string,
  facturePDF      : string
}

@Component({
  selector: 'app-selection-immeuble',
  templateUrl: './selection-immeuble.component.html',
  styleUrls: ['./selection-immeuble.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectionImmeubleComponent implements OnInit{

  resultCourtiers: SectionCourtier [] = [];

  show = false;
  show2 = false;
  buttonName = 'Show';
  hide: any;

  toggle() {
    this.show = !this.show
    this.show2 = false
    if(this.show) {
      this.buttonName = 'Hide'
      //console.log("show: ", this.show);
    }
    else {
      //console.log("show: ", this.show);
      this.buttonName = 'Show'
    }
  }

  toggle2() {
    //console.log(this.show2)
    this.show2 = !this.show2
    this.show = false

    if(this.show2) {
      this.buttonName = 'Hide'
      //console.log("show: ", this.show);
    }
    else {
      //console.log("show: ", this.show);
      this.buttonName = 'Show'
    }
  }

  listCourtier: SectionCourtier[] = [];

  chooseCourtier(id, i) {
    if (!this.listCourtier.includes(id))
      this.listCourtier.push(id);
    else
      this.listCourtier.splice(i, 1);
  }

  sendToEtude(coproId) {
    if (this.listCourtier.length > 0) {
      this.DataService.apiSendToEtude({coproId, courtiers: this.listCourtier},
        () => {
          this.dialog.open(CourtierEtudeDialogComponent, {
            height: 'auto',
            width: '100%',
            maxWidth: '450px',
          });
        },
        (error) => {
          //console.log("error: ", error)
          alert(error.error.message);
        })
    } else {
      alert('Veuillez selectionner au moins un courtier')
    }
  }

  reference;surface;multiDevis;maxTravaux;moisAG;compagnie;echeance;courtier;
  update = {
    _id: null,
    reference: null,
    surface: null,
    multiDevis: null,
    maxTravaux: null,
    moisAG: null,
    "compagnie.assurance": null,
    "compagnie.echeance": null,
  };

  toppings = new FormControl();

  //toppingList: object[];

  changeCoproInfo(reference,surface,multiDevis,maxTravaux,moisAG,compagnie,echeance) {

    //console.log(reference,surface,multiDevis,maxTravaux,moisAG,compagnie,echeance)

    this.update._id = this.copro._id;

    for (let key in this.update) {
      switch (key) {
        case 'reference': reference ? this.update[key] = reference : delete this.update[key]; break;
        case 'surface': surface ? this.update[key] = surface : delete this.update[key]; break;
        case 'multiDevis': multiDevis ? this.update[key] = multiDevis : delete this.update[key]; break;
        case 'maxTravaux': maxTravaux ? this.update[key] = maxTravaux : delete this.update[key]; break;
        case 'moisAG': moisAG ? this.update[key] = moisAG : delete this.update[key]; break;
        case 'compagnie.assurance': compagnie ? this.update[key] = compagnie : delete this.update[key]; break;
        case 'compagnie.echeance': echeance ? this.update[key] = echeance : delete this.update[key]; break;
        default: //console.log('default: ', key); break;
      }
    }

    this.DataService.apiFormCoproPut('/update/copro', {update: this.update},
      (res)=>{
        //console.log("résultat :", res)
        this.dialog.open(MajImmeubleDialogComponent, {
          height: 'auto',
          width: '100%',
          maxWidth: '450px',
        });
      },
      (error) => {
        //console.log (error)
        if (error.error.message) {
          this.DataService.error= error.error.message
        }
      })
  }


  copro=this.DataService.copro;

  // resultDescription = this.DataService.copro.batiments;
  // resultEntree = this.DataService.copro.batiments[0]['entrees'];
  // resultCave = this.DataService.copro.batiments[0].cave;
  // resultCarnet = this.DataService.copro.incidentId;
  // resultCopro = this.DataService.copro
  // resultTravaux = this.resultCarnet.length
  // imgBat = 'https://copro-back.cantem.fr/uploads/batiment/'+this.copro.batiments[0].image.facadeRue[0]
  // imgInfo = this.copro.batiments[0].image.facadeRue[0]

  resultInfo;
  _id;
  urlImgLeft;
  imgIncident

  dataFacades = []
  dataAscenseur = []
  dataEntree = []
  dataCaves = []
  dataParking = []
  dataEnvironnement = []
  dataContiguite = []
  dataIncidents = []
  dataParcelle = []
  dataVueGG = []


  itemsFacades: GalleryItem[];
  itemsAscenseur: GalleryItem[];
  itemsEntree: GalleryItem[];
  itemsCaves: GalleryItem[];
  itemsParking: GalleryItem[];
  itemsEnvironnement: GalleryItem[];
  itemsContiguite: GalleryItem[];
  itemParcelle: GalleryItem[];
  itemVueGG: GalleryItem[];
  itemsIncidents: GalleryItem[];

  envoieEtude: SectionEtudes[] = [];

  constructor(public gallery: Gallery, private http: HttpClient, public GalleryComponent: GalleryComponent, private activatedRoute: ActivatedRoute, public DataService: DataService, public dialog: MatDialog) {

    // this.activatedRoute.params.subscribe(params => {

    // });
  }

  resultDescription;resultEntree;resultCave;resultCopro;resultTravaux;imgBat;imgInfo

  resultCarnet: SectionCarnet [] = [];

  prestaWork: SectionDevis [] = []
  devis: SectionDevis [] = [];
  evaluation: SectionDevis [] = [];
  reception = [];
  nbEval = 0;


  ngOnInit() {
    const id =  this.activatedRoute.snapshot.params['id'];

    this.DataService.api_get_devis_copro(
      {coproId: id},
      (data) => {
        console.log("data.list: ", data.list)
        for (let i in data.list) {
          this.prestaWork[i] = {
            incidentId      : data.list[i].incidentId,
            evaluationTTC   : data.list[i].evaluation,
            metrages        : data.list[i].metrages,
            coproId    	    : data.list[i].coproId,
            pcsId           : data.list[i].pcsId,
            syndicId        : data.list[i].syndicId,
            visiteId        : data.list[i].visiteId,
            courtierId      : data.list[i].courtierId,
            architecteId    : data.list[i].architecteId,
            prestataireId   : data.list[i].prestataireId,
            gestionnaireId  : data.list[i].gestionnaireId,
            commentaire     : data.list[i].commentaire,
            desordre        : data.list[i].desordre,
            description     : data.list[i].description,
            situation       : data.list[i].situation,
            corpsEtat       : data.list[i].corpsEtat,
            images          : data.list[i].images,
            date            : data.list[i].date,
            demandeDevis    : data.list[i].demandeDevis,
            devisPDF        : data.list[i].devisPDF,
            facturePDF      : data.list[i].facturePDF
          };
          if (data.list[i].evaluationTTC) {
            this.nbEval += 1;
            this.evaluation[i] = data.list[i];
          }
        }
      },
      (error) => {
        console.log("devis error: ", error)
      }
    )

    this.DataService.api_single_copro({_id:id},
      (data)=>{
        this.copro = data.copro;

        if (this.copro !== "undefined") {
          this.resultDescription = this.copro.batiments;
          this.resultEntree = this.copro.batiments[0]['entrees'];
          this.resultCave = this.copro.batiments[0].cave;
          this.resultCarnet = this.copro.incidentId;
          this.resultCopro = this.copro;

          this.resultTravaux = this.resultCarnet.length
          this.imgBat = 'https://copro-back.cantem.fr/uploads/batiment/'+this.copro.batiments[0].image.facadeRue[0]
          //console.log("resultat incident",this.copro)
          //console.log('img bat', this.imgBat)
          this.imgInfo = this.copro.batiments[0].image.facadeRue[0]

        } else {
          this.resultDescription = this.DataService.copro.batiments;
          this.resultEntree = this.DataService.copro.batiments[0].entrees;
          this.resultCave = this.DataService.copro.batiments[0].cave;
          this.resultCarnet = this.DataService.copro.incidentId;
          this.resultCopro = this.DataService.copro
          this.resultTravaux = this.resultCarnet.length
          this.imgBat = 'https://copro-back.cantem.fr/uploads/batiment/'+this.copro.batiments[0].image.facadeRue[0]
          this.imgInfo = this.copro.batiments[0].image.facadeRue[0]

          // this.imgIncident = 'https://copro-back.cantem.fr/uploads/batiment/'+this.copro.incidentId[2].images[0]
          // //console.log ("image incident", this.imgIncident)
        }
        for (let i in this.resultCarnet)
          for (let j in this.prestaWork)
            if (this.resultCarnet[i]._id === this.prestaWork[j].incidentId)
              this.resultCarnet[i] = {...this.resultCarnet[i], evaluationTTC: this.prestaWork[j].evaluationTTC}

        console.log(this.resultCarnet)
        this.dataParcelle = [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.ParcelleCadastrale[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.ParcelleCadastrale[0]),
          }
        ];
        this.dataVueGG = [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.VueGenGoogle[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.VueGenGoogle[0]),
          }
        ];
        this.dataFacades = [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[0]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[1]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[1]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[2]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeRue[2]),
          },
        ];
        this.dataAscenseur= [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[0]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[1]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[1]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[2]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.facadeArriere[2]),
          },
        ];
        this.dataEntree= [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[0]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[1]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[1]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[2]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.entrees[2]),
          },
        ];
        this.dataCaves= [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[0]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[1]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[1]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[2]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.caves[2]),
          },
        ];
        this.dataParking= [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[0]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[1]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[1]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[2]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.parking[2]),
          },
        ];
        this.dataEnvironnement = [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[0]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[1]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[1]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[2]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.environnement[2]),
          },
        ];
        this.dataContiguite = [
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[0]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[0]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[1]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[1]),
          },
          {
            srcUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[2]),
            previewUrl: 'https://copro-back.cantem.fr/uploads/batiment/'+(this.copro.batiments[0].image.contiguite[2]),
          },
        ];



      },
      (error)=>{
        //console.log(error)
        this.copro = error.err.copro
      }
    );
    //console.log('Url Id: ',id);
    // this.info = id
    this._id = id



    /** Bloc left mon parc **/
    this.DataService.api_post_mon_parc({_id:id},
      (data)=>{
        this.resultInfo = data.copro
        //console.log ("information copro gauche",  this.resultInfo.reference )
        this.reference = this.resultInfo.reference;
        this.surface = this.resultInfo.surface;
        this.multiDevis = this.resultInfo.multiDevis;
        this.maxTravaux = this.resultInfo.maxTravaux;
        this.moisAG = this.resultInfo.moisAG;
        this.compagnie = this.resultInfo.compagnie?.assurance?? null;
        this.echeance = this.resultInfo.compagnie?.echeance?? null;
        this.courtier = this.resultInfo.courtier?.lastName?? 'Sans Courtier';
      },
      (error)=>{
        //console.log(error)
        this.copro = error.err.copro
      }
    );

    this.DataService.api_get_courtier(
      (data) => {
        for (let i in data.courtiers) {
          this.resultCourtiers[i] = {
            id_el: data.courtiers[i]._id,
            lastName: data.courtiers[i].lastName,
            company: data.courtiers[i].company
          }
          for (let j in data.courtiers[i].dateEtudes) {
            if (data.courtiers[i]?.dateEtudes[j]?.coproId === id)
              this.envoieEtude.push({
                lastName: data.courtiers[i]?.lastName,
                company: data.courtiers[i]?.company,
                date: data.courtiers[i]?.dateEtudes[j]?.date
              });
          }
        }
      }, (error) => {
        this.resultCourtiers = [];
      });
  }
}
