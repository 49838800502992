import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visite-en-cours',
  templateUrl: './visite-en-cours.component.html',
  styleUrls: ['./visite-en-cours.component.scss']
})
export class VisiteEnCoursComponent implements OnInit {
  PrestatairesDetailsArray;
  searchVal: string;
  resultSearch = [];
  listCopro = [
    {
      "src_img": "../assets/img/photo/immeuble.jpg",
      "adresse1": "15 Rue de la Liberté"
    },
    {
      "src_img": "../assets/img/photo/immeuble.jpg",
      "adresse1": "89b Rue de la République"
    }, {
      "src_img": "../assets/img/photo/immeuble.jpg",
      "adresse1": "333 Port du canal"
    },
    {
      "src_img": "../assets/img/photo/immeuble.jpg",
      "adresse1": "255 Place de l'étoile"
    }
  ];

  constructor() {
    this.resultSearch = this.listCopro;
  }

  ngOnInit(): void {
  }

  searchEngine() {
    this.resultSearch = [];
    //if(this.searchVal)
    for(var key in this.listCopro) {
      if(this.listCopro[key].adresse1.toLowerCase().indexOf(this.searchVal.toLowerCase())>-1 && this.resultSearch.length<4){
        this.resultSearch.push(this.listCopro[key]);
      }
    }
    console.log(this.resultSearch)
  }


}
