import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataService } from '../../../../data.service'

export interface SectionCourtier {
  src_img : string;
  name    : string;
  company : string;
  parc    : [];
  id_el   : string;
  surface : number;
  nbLot   : number;
}

@Component({
  selector: 'app-dashboard-courtiers',
  templateUrl: './dashboard-courtiers.component.html',
  styleUrls: ['./dashboard-courtiers.component.scss']
})

export class DashboardCourtiersComponent implements OnInit {

  onEvent(event) {
    event.stopPropagation();
  }

  confirmation(event) {
    let i : any;
    let id = event.srcElement.parentNode.parentNode.parentNode.parentNode.parentNode.id
    if (id.length == 0) {
      id = event.srcElement.parentNode.parentNode.parentNode.parentNode.id
    }
    if (id !== "0") {
      if (confirm("Confirmer la suppresion ?")) {
        this.DataService.apiAssignCourtierToSyndic({_id: localStorage.getItem('id'), courtierId: id},
          (data) => {
            for (i in this.listCourtier) {
              if (this.listCourtier[i].id_el == id) {
                this.listCourtier.splice(i, 1)
              }
            }
            alert(data.message);
          },
          (error) => {
            alert('une erreur est survenue');
            console.log(error);
          })
      }
    } else
      alert("Cet élément n'est pas suppressible !")
  }

  PrestatairesDetailsArray;
  searchVal: string;
  resultSearch:SectionCourtier [] = [];
  listCourtier:SectionCourtier [] = [];
  permissions = [1,1,1];

  constructor(public DataService: DataService) {
    if (localStorage.getItem('role') === 'gestionnaire')
      this.DataService.api_get_single_gestionnaire({_id: localStorage.getItem('id')},
        async (data) => {
          for (let i in data.gestionnaire.permissions)
            this.permissions[i] = data.gestionnaire.permissions[i];
        },
        (error) => {
          console.log(error)
        });

    this.listCourtier.push({
      src_img : "../../../../../assets/img/icones/broker-line.png",
      name    : "",
      company : "Sans Courtier",
      id_el   : "0",
      parc    : [],
      surface : 0,
      nbLot   : 0
    });

    this.DataService.api_get_courtier(
      async (data)=>{
        for (let key in data.courtiers)
          await this.listCourtier.push({
            src_img : data.courtiers[key].imgage ? `https://copro-back.cantem.fr/uploads/images/${data.courtiers[key].imgage}` : "../../../../../assets/img/icones/broker.png",
            name    : data.courtiers[key].firstName + ' ' + data.courtiers[key].lastName,
            company : "Cabinet " + data.courtiers[key].company,
            id_el   : data.courtiers[key]._id,
            parc    : data.courtiers[key].parc,
            surface : 0,
            nbLot   : 0
          });

        this.DataService.api_get_copro(
          (data) => {
            for (let key in data.parc) {
              if (!data.parc[key].courtier) {
                this.listCourtier[0].surface += data.parc[key].surface;
                this.listCourtier[0].nbLot += 1;
              } else {
                for (let i in this.listCourtier) {
                  if (this.listCourtier[i].parc.find(c => c === data.parc[key]._id)) {
                    this.listCourtier[i].surface += data.parc[key].surface;
                    this.listCourtier[i].nbLot += 1;
                  }
                }
              }
            }

            this.DataService.api_get_encourslist(
              (data) => {
                for (let key in data.enCours) {
                  if (!data.enCours[key].courtier) {
                    this.listCourtier[0].surface += data.enCours[key].surface;
                    this.listCourtier[0].nbLot += 1;
                  } else {
                    for (let i in this.listCourtier) {
                      if (this.listCourtier[i].parc.find(c => c === data.enCours[key]._id)) {
                        this.listCourtier[i].surface += data.enCours[key].surface;
                        this.listCourtier[i].nbLot += 1;
                      }
                    }
                  }
                }
                this.resultSearch = this.listCourtier;
              },
              (error) => {
                console.log(error)
              });
          },
          (error) => {
            console.log(error)
          });
      },
      (error)=>{
        console.log(error)
      });
  }

  ngOnInit(): void {}

  searchEngine() {
    this.resultSearch = [];

    for (let key in this.listCourtier) {
      if(this.listCourtier[key].name.toLowerCase().indexOf(this.searchVal.toLowerCase())>-1 || this.listCourtier[key].company.toLowerCase().indexOf(this.searchVal.toLowerCase())>-1){
        this.resultSearch.push(this.listCourtier[key]);
      }
    }
  }

}
