import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { Validators, FormBuilder } from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { CourtierDialogComponent } from '../../../dialog/courtier-dialog/courtier-dialog.component';
import {SectionSyndi} from "../../dashboard/dashboard-admin/dashboard-admin.component";

export interface SectionSyndic {
  src_img: string;
  name: string;
  id_el: string;
}

@Component({
  selector: 'app-form-courtiers-admin',
  templateUrl: './form-courtiers-admin.component.html',
  styleUrls: ['./form-courtiers-admin.component.scss']
})

export class FormCourtiersAdminComponent implements OnInit {

  onEvent(event) {
    event.stopPropagation();
  }

  msgErreur;

  email; firstName; lastName; company; address; codePostal; ville; phone; syndic;

  addCourtier(email, firstName, lastName, company, address, codePostal, ville, phone, syndic) {
    console.log(email, firstName, lastName, company, address, codePostal, ville, phone, syndic);
    if (this.formCourtier.valid) {
      this.DataService.api('/create/courtier', {
          email: email,
          firstName: firstName, //
          lastName: lastName, company: company, //
          address: address, codePostal: codePostal, //
          ville: ville, phone: phone, //
          syndic: syndic.id_el
        },

        (res)=>{
          console.log("Yipi :", res)
          this.dialog.open(CourtierDialogComponent, {
            height: 'auto',
            width: '100%',
            maxWidth: '450px',
          });
          this.DataService.router.navigate(['admin-copro/dashboard']);
        },
        (error) => {
          console.log (error)
          if (error.error.message)
          {
            this.DataService.error= error.error.message;
            this.msgErreur = this.DataService.error;
          }
        })
    } else {
      this.msgErreur = "Vérifiez les informations du formulaire!!!";
    }
  }

  formCourtier = this.fb.group({
    emailForm: ['', [Validators.required, Validators.email]],
    firstNameForm: ['', [Validators.required, Validators.minLength(3)]],
    lastNameForm: ['', [Validators.required, Validators.minLength(3)]],
    companyForm: ['', [Validators.required, Validators.minLength(3)]],
    addressForm: ['', [Validators.required, Validators.minLength(3)]],
    codePostalForm: ['', [Validators.required, Validators.minLength(5)]],
    villeForm: ['', [Validators.required, Validators.minLength(3)]],
    phoneForm: ['', [Validators.required, Validators.minLength(10)]],
    syndicForm: ['', [Validators.required, Validators.minLength(5)]],
  });

  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  get formCtrl() { return this.formCourtier.controls; }

  resultSyndic  = [];
  searchSyndic  : SectionSyndic;
  listSyndic    : SectionSyndic [] = [];

  constructor(public DataService : DataService, private fb: FormBuilder,  public dialog: MatDialog) {
    this.DataService.api_get_syndic(
      (data)=>{
        console.log("syndics enregistrés: ", data.syndics);
        for(let key in data.syndics)
          this.listSyndic[key] = {
            src_img: data.syndics[key].img,
            name: data.syndics[key].nomSyndic?? data.syndics[key].company,
            id_el: data.syndics[key]._id
        };
        this.resultSyndic = this.listSyndic
      },
      (error)=>{
        console.log(error.status)
      }
    );
  }

  ngOnInit(): void {
  }

  searchEngine() {

    this.resultSyndic = [];
    //if(this.searchVal)
    for(let key in this.listSyndic) {
      if(this.listSyndic[key].name.toLowerCase().indexOf(this.searchSyndic.name.toLowerCase())>-1){
        this.resultSyndic.push(this.listSyndic[key]);
      }
    }
  }

}
