
<div fxLayout="row wrap" class="padding-card padding-card-pos-search">
    <mat-card-content fxFlex="100">
        <mat-form-field fxLayoutAlign="left center" class="input_search" appearance="outline">
            <mat-label>N° immeuble, rue ...</mat-label>
            <input matInput [(ngModel)]="searchVal" (keyup)="searchEngine()"/>
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
    </mat-card-content>
</div>

<div fxLayout="row wrap">
    <div fxLayout="row wrap" fxLayoutAlign.xs="center" fxLayoutAlign.sm="center" fxFlex="33" fxFlex.xs="100" fxFlex.md="33" fxFlex.sm="50" fxFlex.lg="33" fxFlex.gt-lg="25" class="padding-card padding-card-info sans_shadow" *ngFor="let detail of resultSearch ; let i = index">
        <mat-card fxFlex="100" routerLink="../pre-reception-details" id={{detail.id_el}} class="pointer" (click)="sendIdToChild(detail.id_info)">
            <div class="avec_shadow">
                <div class="ic_right"><button mat-icon-button	 (click)="onEvent($event)" (click)="confirmation($event)" class="ic_croix ic_info_croix"><img src="../../../../assets/img/icones/croix.png"></button></div>
                <div class="ic_right"><mat-button-toggle (click)="onEvent($event)" class="ic_cloche ic_info_cloche"><img src="../../../../assets/img/icones/cloche.png"></mat-button-toggle></div>
                <mat-card-content class="content-140 contentxs font-normal">
                    <div fxLayoutAlign="center">
                        <div width="70%" class="img_virtuelle res_img max_width" [ngStyle]="{ 'background-position': 'center', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-image': 'url(' + detail.src_img + ')'}"></div>
                    </div>
                    <div class="res_name marge25">
                        <div fxLayoutAlign="center" class="title data_dynamic">{{detail.name}}</div>
                        <div class="adresse">
                            <div fxLayoutAlign="center" class="data_dynamic">{{detail.address}} -</div>
                            <div fxLayoutAlign="center" class="data_dynamic">{{detail.codePostal}} {{detail.ville}}</div>
                            <div fxLayoutAlign="center">
                                <span>N°</span>
                                <span class="resultat_marge_left data_dynamic">{{detail.reference}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="res_doc iconesxs">
                        <div fxLayoutAlign="center" class="icon_height data_dynamic"><img src="../../../../assets/img/icones/doc.jpg"></div>
                        <div fxLayoutAlign="center" class="resultat data_dynamic"><b>{{detail.nbVisites}}</b></div>
                    </div>
                    <div class="res_info" fxLayoutAlign="center">
                        <div class="pointer info_icon" mat-raised-button  (click)="onEvent($event)" (click)="toggle_info($event)"><img src="../../../../assets/img/icones/info-small.png"></div>
                    </div>
                </mat-card-content>
            </div>
        </mat-card>
        <div fxFlex="100" id="{{ detail.id_info }}" className="{{ detail.display_global }}">
            <mat-card>
                <div fxLayout="column" class="avec_shadow pad_box_shadow">
                  <div fxLayoutAlign="center" class="info_courtier">Syndic: {{ detail.syndicName  }}</div>

                  <div fxLayoutAlign="center"><span class="bar_grey"></span></div>

                  <div *ngIf="!detail.gestionnaireId" fxLayoutAlign="center" class="info_courtier">Aucun gestionnaire assigné</div>
                  <div *ngIf="detail.gestionnaireId" fxLayoutAlign="center" class="info_courtier">Gestionnaire: {{detail?.gestionnaireId?.firstName}} {{detail?.gestionnaireId?.lastName}}</div>
                  <div *ngIf="detail.gestionnaireId" fxLayoutAlign="center" class="info_courtier">{{detail?.gestionnaireId?.email}}</div>
                  <div *ngIf="detail.gestionnaireId" fxLayoutAlign="center" class="info_courtier info_mid">Tél: {{detail?.gestionnaireId?.phone}}</div>

                  <div fxLayoutAlign="center"><span class="bar_grey"></span></div>

                  <div *ngIf="detail.accessCode && !detail.receptionDone" fxLayoutAlign="center" class="info_courtier">Code d'accès: {{ detail.accessCode }}</div>
                  <div *ngIf="detail.gardien && !detail.receptionDone" fxLayoutAlign="center" class="info_courtier">Gardien: {{detail.gardien ? 'oui' : 'non'}}</div>
                  <div *ngIf="detail.cleCabinet && !detail.receptionDone" fxLayoutAlign="center" class="info_courtier">Clés au cabinet: {{detail.cleCabinet ? 'oui' : 'non'}}</div>
                  <div *ngIf="detail.commentaire && !detail.receptionDone" fxLayoutAlign="center" class="info_courtier">Commentaire: {{ detail.commentaire }}</div>

                  <div fxLayoutAlign="center" *ngIf="!detail.receptionDone"><span class="bar_grey"></span></div>

                  <div fxLayoutAlign="center" class="info_courtier" *ngIf="!detail.receptionDone">Président du conseil: {{ detail.nomPCS  }}</div>
                  <div fxLayoutAlign="center" class="info_courtier" *ngIf="!detail.receptionDone">Mail: {{ detail.emailPCS  }}</div>
                  <div fxLayoutAlign="center" class="info_courtier" *ngIf="!detail.receptionDone">Port: {{ detail.phonePCS  }}</div>
                </div>
            </mat-card>
        </div>
    </div>
</div>
