import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service'

@Component({
  selector: 'app-commentaire-dialog',
  templateUrl: './commentaire-dialog.component.html',
  styleUrls: ['./commentaire-dialog.component.scss']
})
export class CommentaireDialogComponent implements OnInit {
  public commentaire: string;

  constructor(public DataService: DataService) { }

  ngOnInit(): void {}

}
